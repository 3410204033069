/* jshint -W138 */

import {permission} from '@/Utility/Helpers';

export default class Gate {
    /**
     * The Gate class can be used to check simple permissions a user has or more complex permissions that are implemented
     * via a policy. Policies need to be registered by mapping a Policy class and a corresponding model.
     *
     * @example
     * let gate = new Gate('currentUser', 'policies'); // will map to window.currentUser & window.policies
     * let gate = new Gate('currentUser', { Unit: new UnitPolicy });
     *
     * @param  {string|object}  user    User object or name of the property on the window object that holds the object
     * @param  {object} policies        Model name to policy mapping
     * @return {void}
     */
    constructor(user = 'user', policies = {}) {
        this._user = typeof user === 'object' ? user : (window[user] || null);
        this._policies = typeof policies === 'object' ? policies : (window[policies] || {});
    }

    get user() {
        return this._user;
    }

    /**
     * Get the registered model to policy mappings.
     *
     * @returns {*|{Model: *}}
     */
    get policies() {
        return this._policies;
    }

    /**
     * Register a policy
     *
     * @example
     * window.gate.policy('Unit', new UnitPolicy);
     *
     * @param {string} modelName
     * @param policy
     */
    policy(modelName, policy) {
        this._policies[modelName] = policy;
    }

    /**
     * Determine whether the user can perform the action on the model.
     *
     * @example
     * // Check if a user is allowed to perform an action. Can be a simple check for a user permission or a complex check
     * // implemented via a policy.
     * // To be able to determine which Policy class should be used, a model that is mapped the policy needs to be
     * // passed as the second argument. The model can be a string if the policy does not need a model instance to
     * // determine a result.
     * this.$gate.allows(Permission.ability(Permission.UnitsRead()), unit);
     *
     * @param  {string}  action                     A permission string or the name of an ability that exists in a policy
     * @param  {object|string|undefined}  model     A model instance or the name of a model that is mapped to a policy
     * @param  {...*} args
     * @return {boolean}
     */
    allows(action, model = undefined, ...args) {
        if (model === undefined) {
            return permission(action);
        }

        if (model === null) {
            return false;
        }

        const type = (typeof model === 'object') ? model.constructorName : model;
        const userAndPolicyExist = this.user && this.policies.hasOwnProperty(type);

        if (!type) {
            throw new Error("Type of the given model could not be determined. " +
                "Did you forget to define `constructorName` on your model?");
        }

        if (
            userAndPolicyExist === true &&
            typeof this.policies[type][action] === 'function'
        ) {
            // If the model is not an object it is only used to map the permission to a specific policy.
            // In that case we do not pass the model to the policy. This is the same way laravel handles this.
            if (typeof model === 'object') {
                return this.policies[type][action](this.user, model, ...args);
            }
            return this.policies[type][action](this.user, ...args);
        }

        return false;
    }

    /**
     * Determine whether the user can't perform the action on the model.
     *
     * @example
     * this.$gate.denies(Permission.ability(Permission.UnitsRead()), unit);
     *
     * @param  {string}  action                     A permission string or the name of an ability that exists in a policy
     * @param  {object|string|undefined}  model     A model instance or the name of a model that is mapped to a policy
     * @param  {...*} args
     * @return {boolean}
     */
    denies(action, model = undefined, ...args) {
        return !this.allows(action, model, ...args);
    }
}
