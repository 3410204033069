import _ from 'lodash';
import AbstractDataObject from '@/Models/AbstractDataObject';
import { deleteUidReferences, getObjectUids, updateUidReferences } from '@/Utility/Helpers';
import Command from '@/Models/UnitData/Commands/Command';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import ExecutionType from '@/Models/UnitData/Triggers/ExecutionType';
import SceneObjectivesSequenceType from '@/Models/UnitData/Scenes/Objectives/SceneObjectivesSequenceType';

export default class SceneObjectives extends AbstractDataObject
{
    static get constructorName() { return 'SceneObjectives'; }

    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     * @param {Object} parent               // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        // Clone the incoming data to avoid manipulation of variable references in memory:
        let attrs = (attributes instanceof Object) ? _.cloneDeep(attributes) : new Object(null);

        // Use specific sequence type as default:
        attrs.sequence = attrs.sequence || SceneObjectivesSequenceType.Free.type;

        // Check for mandatory properties:
        if (typeof attrs.sequence !== 'string' || SceneObjectivesSequenceType.isValidType(attrs.sequence) === false)
        {
            console.warn('SceneObjectives->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectives->constructor: Property "sequence" has to be set on SceneObjectives. Must be a valid type from SceneObjectivesSequenceType class.');
        }
        if (attrs.order !== undefined && attrs.order !== null && attrs.order instanceof Array === false)
        {
            console.warn('SceneObjectives->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectives->constructor: Property "order" has to be of type Array on SceneObjectives.');
        }

        // Support for older units < 0.34 that still have the "commands_as_sequence" property (#PRDA-3020):
        // @TODO: Remove once we have dropped support for older units
        if (typeof attributes.commands_as_sequence === 'boolean')
        {
            attrs.execution_type = (attrs.commands_as_sequence) ? ExecutionType.Linear.type : ExecutionType.Parallel.type;
        }

        // Populate the model:
        this.sequence = attrs.sequence;                      // SceneObjectivesSequenceType
        this.order = attrs.order || [];                      // Order of objectives (list of Trigger UIDs)
        this.commands = (attributes.commands || []).map(c => Command.createFromAttributes(c, this));    // List of commands which should be executed when all objectives are completed
        this.execution_type = attrs.execution_type || ExecutionType.Linear.type; // Execution order for the commands
    }

    /**
     * Do the objectives have any commands?
     *
     * @returns {Boolean}
     */
    get hasCommands() {
        return (this.commandsCount > 0);
    }

    /**
     * Get the count of commands
     *
     * @returns {Number}
     */
    get commandsCount() {
        return (this.commands instanceof Array) ? this.commands.length : 0;
    }

    /**
     * Get the ExecutionType for this trigger
     *
     * @returns {ExecutionType|null}
     */
    get executionType() {
        return ExecutionType.getByTypeName(this.execution_type);
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // All commands must be valid:
        return this.commands.every(c => c.isValid);
    }

    /**
     * Get supported CommandTypes for this object
     *
     * @returns {Array<CommandType>}
     */
    get supportedCommandTypes() {
        return CommandType.forObjectivesCompleted;
    }

    /**
     * Clean up data (e.g. remove forbidden commands)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = false;

        // Clean up commands:
        if (this.hasCommands)
        {
            // Remove forbidden commands:
            const commandsCount = this.commandsCount;
            const allowedCommandTypes = this.supportedCommandTypes.map(ct => ct.type);
            this.commands = this.commands.filter(c => allowedCommandTypes.includes(c.type));
            if (commandsCount !== this.commandsCount) {hasChanged = true;}

            // Clean up the remaining commands:
            if (this.commands.filter(c => c.cleanUpData()).length > 0) {hasChanged = true;}
        }

        return hasChanged;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {SceneObjectives}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = new SceneObjectives(this, this.parent);

        // Create new instances for child objects:
        duplicated.commands = duplicated.commands.map(c => c.duplicate(false));

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        // @NOTE: Make sure to call updateOrderFromParentScene() to set the correct order after duplicating

        return duplicated;
    }

    /**
     * Remove a given command
     *
     * @param {Command} command
     */
    removeCommand(command) {
        if (command instanceof Command && this.hasCommands === true)
        {
            const removeAtIndex = this.commands.findIndex(c => c.uid === command.uid);
            if (removeAtIndex >= 0)
            {
                this.commands.splice(removeAtIndex, 1);

                // Delete all UID references across the entire unit:
                deleteUidReferences(this.parentUnitData, getObjectUids(command));
            }
        }
        return this;
    }

    /**
     * Merge a single command into this objectives object and return the merged command on success
     *
     * @param {Command} command                 // The command to be inserted
     * @param {Command} insertAfterCommand      // Optional command after which the new command should be inserted
     * @returns {Command|Null}
     */
    mergeCommand(command, insertAfterCommand = null) {
        return this.mergeCommands([command], insertAfterCommand)[0] || null;
    }

    /**
     * Merge a list of commands into this objectives object and return the successfully merged commands
     *
     * @param {Array<Command>} commands         // List of commands to be inserted
     * @param {Command} insertAfterCommand      // Optional command after which the new commands should be inserted
     * @returns {Array<Command>}                // List of successfully merged commands
     */
    mergeCommands(commands, insertAfterCommand = null) {
        if (!(commands instanceof Array))
        {
            return [];
        }

        // Only use commands that are allowed for this trigger's type:
        const allowedCommandTypes = this.supportedCommandTypes.map(ct => ct.type);
        const commandsToMerge = commands
            .filter(c => c instanceof Command && allowedCommandTypes.includes(c.type))
            .map(c => c.duplicate(true))
            .reduce((filtered, command) => {
                // Set parent first or the maximum count check won't work (and it has to be set anyway when inserting the command):
                command.parent = this;
                if (!command.hasReachedMaxCount)
                {
                    filtered.push(command);
                }
                return filtered;
            }, []);

        // Return early if there's no valid commands to be merged:
        if (commandsToMerge.length === 0)
        {
            return [];
        }

        // Determine position where to insert the new commands:
        const insertIndex = (insertAfterCommand instanceof Command) ? (this.commands.findIndex(c => c.uid === insertAfterCommand.uid) + 1) || this.commandsCount : this.commandsCount;

        // Insert duplicated instances of all commands since UIDs have to be unique for all objects:
        this.commands.splice.apply(
            this.commands,
            [
                insertIndex,
                0
            ].concat(commandsToMerge)
        );

        // Clean up data:
        this.cleanUpData();

        return commandsToMerge;
    }

    /**
     * Build order from scene objects' triggers
     */
    updateOrderFromParentScene()
    {
        const objectiveTriggersUids = ((this.parentTrainingScene || {}).allTriggers || []).filter(t => t.is_objective === true).map(t => t.uid);
        this.order = [...new Set(this.order.filter(t => objectiveTriggersUids.includes(t)).concat(objectiveTriggersUids))];
        return this;
    }
}
