import BoundingBoxComponent from '@/Models/Unity/Components/BoundingBoxComponent';
import ConnectableComponent from '@/Models/Unity/Components/ConnectableComponent';
import ImageComponent from '@/Models/Unity/Components/ImageComponent';
import LabelComponent from '@/Models/Unity/Components/LabelComponent';
import PlaceableComponent from '@/Models/Unity/Components/PlaceableComponent';
import PrefabComponent from '@/Models/Unity/Components/PrefabComponent';
import SoundComponent from '@/Models/Unity/Components/SoundComponent';
import StartPointComponent from '@/Models/Unity/Components/StartPointComponent';
import TextComponent from '@/Models/Unity/Components/TextComponent';
import TransformComponent from '@/Models/Unity/Components/TransformComponent';
import VideoComponent from '@/Models/Unity/Components/VideoComponent';

export default class ComponentUtility
{
    /**
     * Cast a component
     *
     * @param {Object} componentData
     * @returns {Component|Object}
     */
    static castComponent(componentData = null)
    {
        if (componentData instanceof Object && typeof componentData.type === 'string')
        {
            switch(componentData.type.toLowerCase())
            {
                case BoundingBoxComponent.type:     return new BoundingBoxComponent(componentData);
                case ConnectableComponent.type:     return new ConnectableComponent(componentData);
                case ImageComponent.type:           return new ImageComponent(componentData);
                case LabelComponent.type:           return new LabelComponent(componentData);
                case PlaceableComponent.type:       return new PlaceableComponent(componentData);
                case PrefabComponent.type:          return new PrefabComponent(componentData);
                case SoundComponent.type:           return new SoundComponent(componentData);
                case StartPointComponent.type:      return new StartPointComponent(componentData);
                case TextComponent.type:            return new TextComponent(componentData);
                case TransformComponent.type:       return new TransformComponent(componentData);
                case VideoComponent.type:           return new VideoComponent(componentData);
                default:
                    console.warn('ComponentUtility->castComponent(): Unknown component type "' + componentData.type + '"');
                    return componentData;  // Leaving unknown component data as is
            }
        }
        console.warn('ComponentUtility->castComponent(): Invalid component data', componentData);
        return componentData;   // Leaving unknown component data as is
    }

    /**
     * Remove empty components from a given array
     *
     * @param {Array<Component>} components
     * @returns {Array<Component>}
     */
    static removeEmptyComponentsFromArray(components)
    {
        return (components.length === 0) ? components : components.filter(c => {

            // Remove empty label components:
            if (c instanceof LabelComponent && false === (typeof c.text === 'string' && c.text.length > 0))
            {
                console.info('ComponentUtility->removeEmptyComponentsFromArray(): Removing empty LabelComponent', c);
                c = null;
                return false;
            }

            // Remove non-object components that have no type:
            if (c instanceof Object === false || typeof c.type !== 'string' || c.type.length === 0)
            {
                console.info('ComponentUtility->removeEmptyComponentsFromArray(): Removing empty component', c);
                c = null;
                return false;
            }

            return true;
        });
    }
}
