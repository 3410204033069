import {snakeCase} from 'lodash';
import {trans} from "@/Utility/Helpers/trans";

export enum Feature {

    EntitlementCharacterAiExtensions,
    EntitlementTrialBannerDisabled,
    EntitlementOnboardingTourDisabled,
    EntitlementHelperAiExtensions,
    EntitlementDevicetypeWebapp,
    EntitlementPublicUnits,
    FeatureUserguiding,
    FeatureAssetProviderSketchfab,
    FeatureStatisticsPage,
    FeatureCreateTemplates

}

export namespace Feature {
    export function getName(feature: Feature): string {
        return snakeCase(Feature[feature]);
    }

    export function getTitle(feature: Feature): string {
        const name = getName(feature);
        return trans('features.title.' + name);
    }

    export function getNotAvailableText(feature: Feature): string {
        return trans('features.not_available.single_text', {feature: Feature.getTitle(feature)})
    }

    export function getNotAvailableTooltip(feature: Feature): string {
        return trans('features.not_available.single_tooltip', {feature: Feature.getTitle(feature)})
    }

    export function all(): Feature[] {
        return Object.keys(Feature)
            .map(index => Number(index))
            .filter(index => !isNaN(index));
    }

}
