<template>
    <Collapsible :class="'inspector-panel panel-contents' + ((sceneObject.hasContents === false) ? ' no-contents' : '')" v-shortcuts>

        <template #header>
            {{ trans('labels.contents') }}
        </template>

        <template #body>
            <!-- Content selection -->
            <template v-if="sceneObject.hasContents">

                <!-- Settings for each content -->
                <Draggable
                    v-for="(content, index) in sceneObject.contents"
                    groups="panel-contents"
                    :value="index"
                    :draggable-selector="'header[draggable]'"
                    @drop="onDropContent"
                    :class="'panel-card content' + (!content.isValid ? ' is-invalid' : '')"
                    :key="'content_settings'+content.uid+content.type+index+'_'+sceneObject.contents.length"
                    v-focusable
                    :data-content-uid="content.uid"
                >
                    <header :draggable="(sceneObject.contents.length >= 2)">
                        <Icon v-if="!content.isValid" name="icon_invalid" class="icon-invalid" />
                        <label>{{ index + 1 }}. {{ trans('contents.' + content.type) }}</label>
                        <Icon name="icon_close" class="icon-delete" @click="onClickRemoveContent(content)" />
                    </header>

                    <PanelContentSettings
                        :content="content"
                        @change="onChangeContent"
                    />
                </Draggable>

            </template>

            <!-- Dropdown for new/unassigned content -->
            <div v-if="showNewContentSelection" class="btn-add-with-dropdown">
                <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
                <ButtonSecondary v-not-focusable icon="icon_add" caption="labels.add_content" />
                <Dropdown
                    :key="'dropdown-'+sceneObject.uid+'_'+sceneObject.contents.length"
                    @select="onSelectContent"
                    :options="getContentOptions()"
                    :deselected-caption="trans('authoring.select_content')"
                />
            </div>
        </template>
    </Collapsible>
</template>

<script>
    // Import VueJS components:
    import PanelContentSettings     from '@/Vue/Inspector/PanelContentSettings.vue';

    // Import classes:
    import DropdownOption           from '@/Utility/DropdownOption';
    import { sortArrayByProperty, trans } from '@/Utility/Helpers';
    import SceneObject              from '@/Models/UnitData/SceneObjects/SceneObject';
    import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';
    import OverlayContent           from '@/Models/Unity/OverlayContent';

    export default {
        name: 'PanelContents',
        emits: [
            'change',
        ],
        components: {
            PanelContentSettings
        },
        props: {
            sceneObject: {                  // Selected SceneObject that the contents are assigned to
                type: SceneObject,
                default: null
            }
        },
        data() {
            return {
                shortcuts: new Map([
                    ['Delete', this.onShortcutDelete],          // Delete content
                    ['Duplicate.stop.prevent', null],           // Prevent duplicate shortcut from bubbling
                    ['Cut.stop.prevent', null],                 // Prevent cut shortcut from bubbling
                    ['Copy.stop.prevent', null],                // Prevent copy shortcut from bubbling
                    ['Paste.stop.prevent', null],               // Prevent paste shortcut from bubbling
                ]),
            }
        },
        computed: {

            /**
             * Whether to show the selection for adding a new content
             *
             * @returns {Boolean}
             */
            showNewContentSelection() {
                return (this.sceneObject.contents.length < 15);
            }
        },
        methods: {

            /**
             * Transform the content types to options for the dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            getContentOptions() {
                const contents = (this.sceneObject !== null) ? this.sceneObject.supportedContentTypes || [] : [];
                if (contents.length === 0)
                {
                    return [];
                }
                const options = [];
                contents.forEach(c => options[options.length] = new DropdownOption({
                    caption: trans('contents.' + c),
                    value: c
                }));
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Select handler for the content dropdown
             *
             * @param {String} value
             */
            onSelectContent(value) {

                // Create the default value:
                let contentValue = null;
                switch (value)
                {
                    // Default value for objectives counter content:
                    case OverlayContent.Type.ObjectivesCounter:
                        contentValue = 'scene';
                        break;

                    // Default value object for text content:
                    case OverlayContent.Type.Text:
                        contentValue = {
                            headline: null,
                            text: null
                        };
                        break;
                }

                // Add the new content:
                this.sceneObject.contents.push(new OverlayContent({
                    type: value,
                    value: contentValue,
                }, this.sceneObject));

                // Update buttons:
                this.updateContentButtons();

                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             * Click handler for content remove button
             *
             * @param {OverlayContent} content   // Content object reference
             */
            onClickRemoveContent(content) {
                this.sceneObject.removeContent(content);
                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             * Change handler for the content settings
             *
             * @param {OverlayContent} content
             */
            onChangeContent(content) {
                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             * Drop handler for contents
             *
             * @param {MouseEvent} e
             */
            onDropContent(e) {
                e.preventDefault();
                if (typeof e.dataDraggable === 'undefined' || typeof e.dataDropTarget === 'undefined') {
                    console.warn('PanelContents->onDropContent(): Invalid dragging data.', e);
                    return this;
                }

                const currentIndex = e.dataDraggable?.value || 0;
                const targetIndex = e.dataDropTarget?.value || 0;

                // Source and target contents must not be the same:
                let hasChanged = false;
                if (currentIndex !== targetIndex)
                {
                    if (targetIndex > currentIndex)
                    {
                        this.sceneObject.contents.splice(targetIndex + 1, 0, this.sceneObject.contents[currentIndex]);
                        this.sceneObject.contents.splice(currentIndex, 1);
                        hasChanged = true;
                    }
                    else if (targetIndex < currentIndex)
                    {
                        this.sceneObject.contents.splice(targetIndex, 0, this.sceneObject.contents[currentIndex]);
                        this.sceneObject.contents.splice(currentIndex + 1, 1);
                        hasChanged = true;
                    }
                    //console.log('onDrop', e, currentIndex, targetIndex);
                }
                if (hasChanged === true)
                {
                    // Update buttons:
                    this.updateContentButtons();

                    this.$emit('change', this.sceneObject);
                }
                return this;
            },

            /**
             * Shortcut handler: Delete
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                const contentUid = e.target.dataset.contentUid || null;
                if (contentUid !== null)
                {
                    e.stopImmediatePropagation();
                    e.detail.keyboardEvent.stopImmediatePropagation();
                    const content = this.sceneObject.contents.find(c => c.uid === contentUid) || null;
                    if (content !== null)
                    {
                        return this.onClickRemoveContent(content);
                    }
                }

                return this;
            },

            /**
             * Update content buttons
             */
            updateContentButtons() {
                if (this.sceneObject.type === SceneObjectType.TypeOfModule)
                {
                    switch(this.sceneObject.subtype)
                    {
                        case SceneObjectType.Modules.Intro.subtype:
                        case SceneObjectType.Modules.Outro.subtype:
                        case SceneObjectType.Modules.Overlay.subtype:
                            this.sceneObject.setButtons();
                            break;
                    }
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
