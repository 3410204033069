import _ from 'lodash';
import { trans } from '@/Utility/Helpers';
import AssetType from '@/Models/Asset/AssetType';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import HotspotStyleType from '@/Models/UnitData/SceneObjects/HotspotStyleType';
import TriggerType from '@/Models/UnitData/Triggers/TriggerType';
import OverlayContent from '@/Models/Unity/OverlayContent';
import { BoolVariable, NumberVariable } from '@/Models/UnitData/Variables/Variable';

export default class SceneObjectType
{
    /**
     * Main types
     *
     * @var {String}
     */
    static get TypeOfAsset()    { return 'asset'; }
    static get TypeOfGroup()    { return 'group'; }
    static get TypeOfHotspot()  { return 'hotspot'; }
    static get TypeOfModule()   { return 'widget'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || SceneObjectType.isValidType(attributes.type) === false)
        {
            console.warn('SceneObjectType->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectType->constructor(): Property "type" has to be set on SceneObjectType. Must be a valid type from SceneObjectType class.');
        }
        if (typeof attributes.subtype !== 'string' || SceneObjectType.isValidType(attributes.type, attributes.subtype) === false)
        {
            console.warn('SceneObjectType->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectType->constructor(): Property "subtype" has to be a valid subtype from SceneObjectType class.');
        }
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('SceneObjectType->constructor(): Invalid data.', attributes);
            throw new TypeError('SceneObjectType->constructor(): Property "title" has to be set on SceneObjectType.');
        }
        // @NOTE: Description is not mandatory at the moment since we don't have any description texts yet
        //if (typeof attributes.description !== 'string' || attributes.description.length === 0)
        //{
        //    console.warn('SceneObjectType->constructor(): Invalid data.', attributes);
        //    throw new TypeError('SceneObjectType->constructor(): Property "description" has to be set on SceneObjectType.');
        //}

        // Populate the model:
        this.type = attributes.type;                                        // Type of SceneObject (e.g. 'Hotspot')
        this.subtype = attributes.subtype || null;                          // Subtype of SceneObject (e.g. 'Fire')
        this.title = attributes.title;                                      // Translated title of this SceneObjectType (e.g. 'Fire Hotspot')
        this.description = attributes.description || null;                  // Translated description of this Hotspot
        this.preview = attributes.preview || null;                          // Preview image
        this.triggers = attributes.triggers || null;                        // List of supported TriggerTypes
        this.commands = attributes.commands || null;                        // List of supported CommandTypes
        this.contents = attributes.contents || null;                        // List of supported content types
        this.styles = attributes.styles || null;                            // List of supported style types
        this.maxCountGlobal = (typeof attributes.maxCountGlobal === 'number') ? attributes.maxCountGlobal : null;  // How many of the same object can be added globally to a unit?
        this.maxCountPerScene = (typeof attributes.maxCountPerScene === 'number') ? attributes.maxCountPerScene : null;  // How many of the same object can be added to a scene?
        this.variables = attributes.variables || null;                      // List of supported Variables
    }

    /**
     * Whether an object of this type can only be used globally in a unit
     *
     * @returns {Boolean}
     */
    get isGlobalOnly() {
        return (this.maxCountGlobal > 0) && !this.maxCountPerScene;
    }

    /**
     * Check if the scene object is of a given type
     *
     * @param {SceneObjectType} sceneObjectType
     * @returns {Boolean}
     */
    typeOf(sceneObjectType) {
        return (sceneObjectType instanceof SceneObjectType && this.type === sceneObjectType.type && this.subtype === sceneObjectType.subtype);
    }

    /**
     * SceneObjectType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {SceneObjectType}
     */
    static get Asset()      { return                StaticInstances.asset[ AssetType.Unknown.type ]; }
    static get Assets()     { return {
                                CharacterModel3D:   StaticInstances.asset[ AssetType.CharacterModel3D.type ],
                                EnvironmentImage:   StaticInstances.asset[ AssetType.EnvironmentImage.type ],
                                EnvironmentModel3D: StaticInstances.asset[ AssetType.EnvironmentModel3D.type ],
                                EnvironmentVideo:   StaticInstances.asset[ AssetType.EnvironmentVideo.type ],
                                Image:              StaticInstances.asset[ AssetType.Image.type ],
                                Model3D:            StaticInstances.asset[ AssetType.Model3D.type ],
                                Sound:              StaticInstances.asset[ AssetType.Sound.type ],
                                SoundTts:           StaticInstances.asset[ AssetType.SoundTts.type ],
                                Text:               StaticInstances.asset[ AssetType.Text.type ],
                                Video:              StaticInstances.asset[ AssetType.Video.type ]
                            };}
    static get Group()      { return                StaticInstances.group.generic; }
    static get Hotspot()    { return                StaticInstances.hotspot.generic; }
    static get Hotspots()   { return {
                                Area:               StaticInstances.hotspot.area,
                                Generic:            StaticInstances.hotspot.generic,
                                Fire:               StaticInstances.hotspot.fire,
                                Image:              StaticInstances.hotspot.image,
                                SceneChange:        StaticInstances.hotspot.scene_change,
                                Sound:              StaticInstances.hotspot.sound,
                                Text:               StaticInstances.hotspot.text,
                                Transparent:        StaticInstances.hotspot.transparent,
                                Video:              StaticInstances.hotspot.video,
                            };}
    static get Module()     { return                StaticInstances.widget.universal; }
    static get Modules()    { return {
                                Connection:         StaticInstances.widget.connection,
                                Input:              StaticInstances.widget.input,
                                Intro:              StaticInstances.widget.intro,
                                Helper:             StaticInstances.widget.helper,
                                Keypad:             StaticInstances.widget.keypad,
                                Outro:              StaticInstances.widget.outro,
                                Overlay:            StaticInstances.widget.overlay,
                                Script:             StaticInstances.widget.script,
                                Universal:          StaticInstances.widget.universal,
                                Variable:           StaticInstances.widget.variable,
                                Videowall:          StaticInstances.widget.videowall,
                            };}

    /**
     * Get all types as an array
     *
     * @returns {Array<SceneObjectType>}
     */
    static get all()
    {
        return [
            this.Asset,
            this.Assets.CharacterModel3D,
            this.Assets.EnvironmentImage,
            this.Assets.EnvironmentModel3D,
            this.Assets.EnvironmentVideo,
            this.Assets.Image,
            this.Assets.Model3D,
            this.Assets.Sound,
            this.Assets.SoundTts,
            this.Assets.Text,
            this.Assets.Video,

            this.Group,

            this.Hotspot,
            this.Hotspots.Area,
            this.Hotspots.Fire,
            this.Hotspots.Image,
            this.Hotspots.SceneChange,
            this.Hotspots.Sound,
            this.Hotspots.Text,
            this.Hotspots.Transparent,
            this.Hotspots.Video,

            this.Module,
            this.Modules.Connection,
            this.Modules.Input,
            this.Modules.Intro,
            this.Modules.Helper,
            this.Modules.Keypad,
            this.Modules.Outro,
            this.Modules.Overlay,
            this.Modules.Script,
            this.Modules.Universal,
            this.Modules.Variable,
            this.Modules.Videowall,
        ];
    }

    /**
     * Look for a given type
     *
     * @param {String} type
     * @param {String} subtype
     * @returns {SceneObjectType|null}
     */
    static findByType(type, subtype = null)
    {
        return (subtype !== null && StaticInstances[type][subtype] instanceof Object) ? StaticInstances[type][subtype] : null;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @param {String} subtype
     * @returns {Boolean}
     */
    static isValidType(type, subtype = null)
    {
        return (subtype === null) ? (TypeDefinitions[type] instanceof Object && Object.keys(TypeDefinitions[type]).length > 0) : (TypeDefinitions[type][subtype] instanceof Object);
    }
}

/**
 * Static lookup matrix for all different types and subtypes
 *
 * e.g. StaticInstances[type][subtype] = SceneObjectType()
 */
const TypeDefinitions = {};
TypeDefinitions.asset = {};
TypeDefinitions.group = {};
TypeDefinitions.hotspot = {};
TypeDefinitions.widget = {};
const StaticInstances = {};
StaticInstances.asset = {};
StaticInstances.group = {};
StaticInstances.hotspot = {};
StaticInstances.widget = {};

/**
 * Asset type definitions (generated from AssetType class)
 */
for (let i = 0, ilen = AssetType.all.length; i < ilen; ++i)
{
    let typeData = _.cloneDeep(AssetType.all[i]);
    typeData.subtype = typeData.type;
    typeData.type = SceneObjectType.TypeOfAsset;
    typeData.triggers = null;
    typeData.commands = null;
    typeData.contents = null;
    typeData.styles = null;
    typeData.maxCountGlobal = null;
    typeData.maxCountPerScene = null;
    typeData.variables = null;

    TypeDefinitions[SceneObjectType.TypeOfAsset][typeData.subtype] = typeData;
}

// Specific settings for Model3D Asset:
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.Model3D.type].triggers = [
    TriggerType.OnClick,
    TriggerType.OnGaze,
    TriggerType.OnEnter,
    TriggerType.OnLeave,
    TriggerType.OnActivate,
    TriggerType.OnDeactivate,
    TriggerType.OnCue,
];
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.Model3D.type].commands = CommandType.forModel3dAssets;

// Specific settings for Environments:
for (const environmentAssetType of [AssetType.EnvironmentVideo, AssetType.EnvironmentImage, AssetType.EnvironmentModel3D]) {
    TypeDefinitions[SceneObjectType.TypeOfAsset][environmentAssetType.type].triggers = [
        TriggerType.OnActivate,
        TriggerType.OnDeactivate,
        TriggerType.OnCue,
    ];
    TypeDefinitions[SceneObjectType.TypeOfAsset][environmentAssetType.type].commands = CommandType.forEnvironments;
}

// Specific settings for CharacterModel3D Asset:
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.CharacterModel3D.type].triggers = [
    //TriggerType.OnClick,  // Disabled for #PRDA-15000 until characters have colliders
    TriggerType.OnGaze,
    //TriggerType.OnEnter,  // Disabled for #PRDA-15000 until characters have colliders
    //TriggerType.OnLeave,  // Disabled for #PRDA-15000 until characters have colliders
    TriggerType.OnActivate,
    TriggerType.OnDeactivate,
    TriggerType.OnCue,
];
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.CharacterModel3D.type].commands = CommandType.forCharacterModel3dAssets;

// Specific settings for Image Asset:
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.Image.type].triggers = [
    TriggerType.OnClick,
    TriggerType.OnGaze,
    TriggerType.OnEnter,
    TriggerType.OnLeave,
    TriggerType.OnActivate,
    TriggerType.OnDeactivate,
    TriggerType.OnCue,
];
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.Image.type].commands = CommandType.forImageAssets;

// Specific settings for Text Asset:
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.Text.type].triggers = [
    TriggerType.OnClick,
    TriggerType.OnGaze,
    TriggerType.OnEnter,
    TriggerType.OnLeave,
    TriggerType.OnActivate,
    TriggerType.OnDeactivate,
    TriggerType.OnCue,
];
TypeDefinitions[SceneObjectType.TypeOfAsset][AssetType.Text.type].commands = CommandType.forTextAssets;

/**
 * Group type definitions
 */
 TypeDefinitions.group = {

    generic: {
        type: SceneObjectType.TypeOfGroup,
        subtype: 'generic',
        title: trans('sceneobjects.group.title'),
        description: trans('sceneobjects.group.description'),
        preview: '/images/preview/collapsible_group.jpg',
        triggers: null,
        commands: null,
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },
};

/**
 * Hotspot type definitions
 */
TypeDefinitions.hotspot = {

    area: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'area',
        title: trans('hotspots.area.title'),
        description: trans('hotspots.area.description'),
        preview: '/images/preview/hotspot_area.jpg',
        triggers: [
            TriggerType.OnEnter,
            TriggerType.OnLeave,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: [
            CommandType.InputStyle,
            CommandType.Fade,
            CommandType.ModuleActivate,
            CommandType.SceneChange,
            CommandType.Feedback,
            CommandType.Hide,
            CommandType.Show,
            CommandType.Wait,
            CommandType.HelperAnimationPlay,
            CommandType.HelperGlowChange,
            CommandType.HelperSpeak,
            CommandType.HelperWaypointGoTo,
            CommandType.HelperTriggerInvoke,
            CommandType.TriggerCancel,
            CommandType.TriggerInvoke,
            CommandType.WorldAnchorReset
        ],
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    generic: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'generic',
        title: trans('hotspots.generic.title'),
        description: trans('hotspots.generic.description'),
        preview: '/images/preview/hotspot.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnEnter,
            TriggerType.OnLeave,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forHotspots,
        contents: null,
        styles: [
            HotspotStyleType.Generic,
            HotspotStyleType.Info,
            HotspotStyleType.Small,
            HotspotStyleType.Transparent,
            HotspotStyleType.Warning
        ],
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    fire: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'fire',
        title: trans('hotspots.fire.title'),
        description: trans('hotspots.fire.description'),
        preview: '/images/preview/hotspot_fire.jpg',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forHotspots,
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    image: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'image',
        title: trans('hotspots.image.title'),
        description: trans('hotspots.image.description'),
        preview: '/images/preview/hotspot_image.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: [
            CommandType.InputStyle,
            CommandType.ImageShow,
            CommandType.Fade,
            CommandType.Hide,
            CommandType.Show,
            CommandType.SceneChange,
            CommandType.Feedback,
            CommandType.Wait,
            CommandType.ModuleActivate,
            CommandType.WorldAnchorReset
        ],
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    scene_change: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'scene_change',
        title: trans('hotspots.scene_change.title'),
        description: trans('hotspots.scene_change.description'),
        preview: '/images/preview/hotspot_scene_change.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: [
            CommandType.InputStyle,
            CommandType.Fade,
            CommandType.SceneChange,
            CommandType.Hide,
            CommandType.Show,
            CommandType.Feedback,
            CommandType.Wait,
            CommandType.ModuleActivate,
            CommandType.WorldAnchorReset
        ],
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    sound: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'sound',
        title: trans('hotspots.sound.title'),
        description: trans('hotspots.sound.description'),
        preview: '/images/preview/hotspot_sound.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: [
            CommandType.InputStyle,
            CommandType.SoundPlay,
            CommandType.Fade,
            CommandType.Hide,
            CommandType.Show,
            CommandType.SceneChange,
            CommandType.Feedback,
            CommandType.Wait,
            CommandType.ModuleActivate,
            CommandType.WorldAnchorReset
        ],
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    text: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'text',
        title: trans('hotspots.text.title'),
        description: trans('hotspots.text.description'),
        preview: '/images/preview/hotspot_text.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: [
            CommandType.InputStyle,
            CommandType.TextShow,
            CommandType.Fade,
            CommandType.Hide,
            CommandType.Show,
            CommandType.SceneChange,
            CommandType.Feedback,
            CommandType.Wait,
            CommandType.ModuleActivate,
            CommandType.WorldAnchorReset
        ],
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    transparent: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'transparent',
        title: trans('hotspots.transparent.title'),
        description: trans('hotspots.transparent.description'),
        preview: '/images/preview/hotspot_transparent_circle.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnEnter,
            TriggerType.OnLeave,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forTransparentShapes,
        contents: null,
        styles: [
            HotspotStyleType.TransparentCircle,
            HotspotStyleType.TransparentCube,
            HotspotStyleType.TransparentRectangleDoor,
            HotspotStyleType.TransparentRectangleLong,
            HotspotStyleType.TransparentRectangleShort,
            HotspotStyleType.TransparentRectangleThin,
        ],
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    video: {
        type: SceneObjectType.TypeOfHotspot,
        subtype: 'video',
        title: trans('hotspots.video.title'),
        description: trans('hotspots.video.description'),
        preview: '/images/preview/hotspot_video.jpg',
        triggers: [
            TriggerType.OnClick,
            TriggerType.OnGaze,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: [
            CommandType.InputStyle,
            CommandType.VideoPlay,
            CommandType.Fade,
            CommandType.Hide,
            CommandType.Show,
            CommandType.SceneChange,
            CommandType.Feedback,
            CommandType.Wait,
            CommandType.ModuleActivate,
            CommandType.WorldAnchorReset
        ],
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    }
};

/**
 * Module type definitions
 */
TypeDefinitions.widget = {

    connection: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'connection',
        title: trans('modules.connection.title'),
        description: trans('modules.connection.description'),
        preview: '/images/preview/module_connection.png',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnConnectionPathComplete,
            TriggerType.OnConnectionPathCompleteAll,
            TriggerType.OnConnectionPathCompleteUnknown,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: 0,
        maxCountPerScene: null,
        variables: null
    },

    // @deprecated since version 8.0.0, use universal module instead, #PRDA-12501
    input: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'input',
        title: trans('modules.input.title'),
        description: trans('modules.input.description'),
        preview: '/images/preview/module.png',
        triggers: [
            TriggerType.OnKeyPress,
            TriggerType.OnSpectatorKeyPress,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    // @deprecated since version 8.0.0, use regular overlay module instead, #PRDA-12536
    intro: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'intro',
        title: trans('modules.intro.title'),
        description: trans('modules.intro.description'),
        preview: '/images/preview/overlay.png',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: [
            OverlayContent.Type.Text,
            OverlayContent.Type.ImageReference,
            OverlayContent.Type.VideoReference,
            OverlayContent.Type.TrainingInfo,
            OverlayContent.Type.TrainingSceneInfo
        ],
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    helper: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'helper',
        title: trans('modules.helper.title'),
        description: trans('modules.helper.description'),
        preview: '/images/preview/module_helper.jpg',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: 1,
        maxCountPerScene: 0, // @NOTE: Set to 0 since it should only be available as a global scene object
        variables: null
    },

    // @deprecated since version 3.0.0, use input module instead
    keypad: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'keypad',
        title: trans('modules.keypad.title'),
        description: trans('modules.keypad.description'),
        preview: '/images/preview/module.png',
        triggers: [
            TriggerType.OnKeyPress,
            TriggerType.OnSpectatorKeyPress,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: 0, // @NOTE: Disable as the object is deprecated
        maxCountPerScene: 0, // @NOTE: Disable as the object is deprecated
        variables: null
    },

    // @deprecated since version 8.0.0, use regular overlay module instead, #PRDA-12536
    outro: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'outro',
        title: trans('modules.outro.title'),
        description: trans('modules.outro.description'),
        preview: '/images/preview/overlay.png',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: [
            OverlayContent.Type.ObjectivesCounter,
            OverlayContent.Type.Text,
            OverlayContent.Type.ImageReference,
            OverlayContent.Type.VideoReference,
            OverlayContent.Type.TrainingCompleted,
            OverlayContent.Type.TrainingSceneCompleted
        ],
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    overlay: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'overlay',
        title: trans('modules.overlay.title'),
        description: trans('modules.overlay.description'),
        preview: '/images/preview/overlay.png',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: [
            OverlayContent.Type.Text,
            OverlayContent.Type.ImageReference,
            OverlayContent.Type.VideoReference,
            OverlayContent.Type.ObjectivesCounter,
            OverlayContent.Type.TrainingInfo,
            OverlayContent.Type.TrainingSceneInfo,
            OverlayContent.Type.TrainingCompleted,
            OverlayContent.Type.TrainingSceneCompleted
        ],
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    script: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'script',
        title: trans('modules.script.title'),
        description: trans('modules.script.description'),
        preview: '/images/preview/module.png',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    universal: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'universal',
        title: trans('modules.universal.title'),
        description: trans('modules.universal.description'),
        preview: '/images/preview/module.png',
        triggers: [
            TriggerType.OnKeyPress,
            TriggerType.OnSpectatorKeyPress,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            //TriggerType.OnNetworkConnect,     // @NOTE: Disabled for #PRDA-2484
            //TriggerType.OnNetworkDisconnect,  // @NOTE: Disabled for #PRDA-2484
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },

    variable: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'variable',
        title: trans('modules.variable.title'),
        description: trans('modules.variable.description'),
        preview: '/images/preview/module_variable.jpg',
        triggers: [
            TriggerType.OnKeyPress,
            TriggerType.OnSpectatorKeyPress,
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: null,
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: [
            BoolVariable.Type,
            NumberVariable.Type,
        ]
    },

    videowall: {
        type: SceneObjectType.TypeOfModule,
        subtype: 'videowall',
        title: trans('modules.videowall.title'),
        description: trans('modules.videowall.description'),
        preview: '/images/preview/module.png',
        triggers: [
            TriggerType.OnActivate,
            TriggerType.OnDeactivate,
            TriggerType.OnCue,
        ],
        commands: CommandType.forModules,
        contents: [
            OverlayContent.Type.VideoReference
        ],
        styles: null,
        maxCountGlobal: null,
        maxCountPerScene: null,
        variables: null
    },
};

/**
 * Merge the definitions into the matrix
 */
for (let i in TypeDefinitions)
{
    for (let j in TypeDefinitions[i])
    {
        StaticInstances[i][j] = new SceneObjectType(TypeDefinitions[i][j]);
    }
}
