<template>
    <div id="inspector" class="inspector-content" v-not-focusable v-shortcuts>
        <header :class="'inspector-header asset asset-' + asset.type">
            <Icon :name="asset.assetType.icon" class="inspector-panel-header-icon" />
            {{ sidepanelHeadline }}
        </header>
        <div class="panels" v-not-focusable :key="'InspectorPanels' + refreshUI + sceneObject.asset_uid">
            <div :class="'inspector-panel panel-asset asset-' + asset.type">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    v-if="asset.preview"
                    :src="asset.preview"
                    :editable="false"
                    :supports-preview-modal="supportsAssetPreviewModal"
                    :preview-icon="asset.assetType.previewIcon"
                    @click="onClickShowAssetPreview"
                />

                <!-- Asset title header -->
                <div class="property property-title" :class="{ 'show-original-asset-name': showOriginalAssetName }" :title="originalAssetNameTitleAttribute">
                    <TextInput
                       :model="sceneObject"
                       property="title"
                       @change="onChangeProperty"
                       @blur="onBlurTitle"
                       :maxlength="50"
                       :placeholder="trans('authoring.asset_title_placeholder')"
                       :error-msg="trans('errors.asset.title')"
                    />
                    <span class="original-asset-name" v-if="showOriginalAssetName">{{ subtitle }}</span>
                </div>

                <Collapsible v-if="hasBasicProperties" :key="'asset_basics_' + sceneObject.uid + '_collapsible'">
                    <template #header>
                        {{ trans('labels.basics') }}
                    </template>
                    <template #body>
                        <!-- Visibility state -->
                        <div v-if="!sceneObject.isGlobal" class="property property-visibility">
                            <Checkbox
                                :model="sceneObject"
                                property="visible"
                                layout="checkbox-right"
                                @change="onChangeProperty"
                                :caption="visibilityCaption"
                            />
                        </div>

                        <!-- Video Autoplay -->
                        <div v-if="hasPropertyVideoAutoplay" class="property property-video-autoplay">
                            <Checkbox
                                :model="sceneObject.video"
                                property="autoplay"
                                layout="checkbox-right"
                                @change="onChangeProperty"
                                :caption="trans('authoring.autoplay_when_visible')"
                            />
                        </div>

                        <!-- Tag list -->
                        <div v-if="hasPropertyTags" class="property property-tags">
                            <TagList
                                class="no-wrap"
                                :suggestions="sceneObject.parentUnitData.getTagsFromSceneObjects()"
                                :model="sceneObject"
                                property="tags"
                                @change="onChangeProperty"
                            />
                        </div>

                        <!-- Character posture -->
                        <div v-if="hasPropertyPosture" class="property property-posture">
                            <Dropdown
                                class="no-wrap"
                                :label="trans('authoring.postures.caption')"
                                :model="sceneObject"
                                property="posture"
                                :options="postureOptions"
                                @select="onChangeProperty"
                            />
                        </div>
                    </template>
                </Collapsible>

            </div>

            <PanelAI
                v-if="hasPropertyAI"
                :sceneObject="sceneObject"
                @change="onChangeProperty"
            />

            <!-- Object transform -->
            <template v-if="sceneObject.supportsTransform">
                <PanelPlaceableTransform
                    v-if="shouldShowPlaceableTransformComponent"
                    class="panel-transform"
                    :sceneObject="sceneObject"
                    @change="onChangeProperty"
                    :showPanelPosition="shouldShowTransformPanelPosition"
                />
                <PanelTransform
                    v-else
                    class="panel-transform"
                    :transform="sceneObject.transform"
                    @change="onChangeProperty"
                    :showPanelPosition="shouldShowTransformPanelPosition"
                />
            </template>

            <PanelBehaviours
                v-if="hasPropertyBehaviours"
                :sceneObject="sceneObject"
                @change="onChangeProperty"
            />

            <PanelTriggers
                v-if="hasPropertyTriggers"
                :key="'panel-triggers-'+sceneObject.uid"
                :sceneObject="sceneObject"
                @change="onChangeTriggers"
            />
        </div>

        <div class="buttons">
            <!-- Btn: Duplicate -->
            <ButtonCustom :disabled="!canDuplicateSceneObject" v-tooltip="'buttons.authoring.asset.duplicate'" icon="icon_duplicate" caption="labels.duplicate" @trigger="onClickDuplicateSceneObject" />

            <!-- Btn: Replace -->
            <ButtonCustom v-tooltip="'buttons.authoring.asset.replace'" icon="icon_replace" caption="labels.replace" @trigger="onClickReplaceAssetOnSceneObject" />

            <!-- Btn: Remove -->
            <ButtonCustom v-tooltip="'buttons.authoring.asset.delete'" icon="icon_delete" caption="labels.remove" @trigger="onClickRemoveAssetFromSelectedScene" />
        </div>

        <!-- Modal dialog for asset remove confirmation -->
        <DialogApplyCancel
            event-type="MODAL_REMOVE_ASSET_FROM_SCENE"
            :title="trans('modals.remove_asset_from_scene.title')"
            :description="trans('modals.remove_asset_from_scene.description')"
        />
    </div>
</template>

<script>
// Import VueJS components:
import DialogApplyCancel        from '@/Vue/Modals/DialogApplyCancel.vue';
import PanelBehaviours          from '@/Vue/Inspector/PanelBehaviours.vue';
import PanelPlaceableTransform from "@/Vue/Inspector/PanelPlaceableTransform.vue";
import PanelTransform from "@/Vue/Inspector/PanelTransform.vue";
import PanelTriggers            from '@/Vue/Inspector/PanelTriggers.vue';

// Import classes:
import { shortId, trans }       from '@/Utility/Helpers';
import Asset                    from '@/Models/Asset/Asset';
import AssetType                from '@/Models/Asset/AssetType';
import EventType                from '@/Utility/EventType';
import {
    BaseSceneObjectAsset,
    SceneObjectAssetCharacterModel3D,
    SceneObjectAssetEnvironmentImage,
    SceneObjectAssetEnvironmentVideo,
    SceneObjectAssetSound,
    SceneObjectAssetTtsSound,
    SceneObjectAssetVideo,
} from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObjectFilters       from '@/Filters/SceneObjectFilters';
import PlaceableComponent from "@/Models/Unity/Components/PlaceableComponent";
import InspectorItemPreviewImage from "@/Vue/Inspector/Items/InspectorItemPreviewImage.vue";
import PanelAI from "@/Vue/Inspector/AI/PanelAI.vue";
import Dropdown from "@/Vue/Common/Dropdown.vue";
import {PostureHelpers} from "@/Models/UnitData/SceneObjects/Posture";
import DropdownOption from "@/Utility/DropdownOption.js";

export default {
    name: 'InspectorSceneObjectAsset',
    emits: [
        'change',
    ],
    components: {
        Dropdown,
        PanelAI,
        InspectorItemPreviewImage,
        DialogApplyCancel,
        PanelPlaceableTransform,
        PanelTransform,
        PanelTriggers,
        PanelBehaviours,
    },
    props: {
        sceneObject: {              // Selected SceneObject that the asset is assigned to
            type: BaseSceneObjectAsset,
            default: null
        }
    },
    data() {
        return {
            refreshUI: shortId(),                   // Helper for refreshing UI elements
            shortcuts: new Map([
                ['Duplicate.global.prevent', this.onShortcutDuplicate],
                ['Delete.global', this.onShortcutDelete],
                ['Replace.global.prevent', this.onShortcutReplace],
            ])
        }
    },
    computed: {
        shouldShowPlaceableTransformComponent() {
            return this.sceneObject && this.sceneObject.supportedComponents.includes(PlaceableComponent.type);
        },
        shouldShowTransformPanelPosition() {
            return !(
                this.sceneObject instanceof SceneObjectAssetEnvironmentImage
                || this.sceneObject instanceof SceneObjectAssetEnvironmentVideo
            );
        },

        asset() {
            return this.sceneObject.asset || Asset.createFromAttributes({
                uid: this.sceneObject.asset_uid,
                type: AssetType.Unknown.type,
                title: trans('labels.asset_unknown'),
            });
        },

        canDuplicateSceneObject() {
            return !this.sceneObject.hasReachedMaxCount;
        },

        hasBasicProperties() {
            return !this.sceneObject.isGlobal
                || this.hasPropertyVideoAutoplay
                || this.hasPropertyTags
            ;
        },

        hasPropertyAI() {
            return this.sceneObject.supportsAI;
        },

        hasPropertyBehaviours() {
            return this.sceneObject.supportsBehaviours;
        },

        hasPropertyTags() {
            return this.sceneObject.supportsTags;
        },

        hasPropertyPosture() {
            return (this.sceneObject instanceof SceneObjectAssetCharacterModel3D);
        },

        hasPropertyTriggers() {
            return (this.sceneObject.supportedTriggerTypes.length > 0);
        },

        hasPropertyVideoAutoplay() {
            return (this.sceneObject instanceof SceneObjectAssetVideo);
        },

        sidepanelHeadline() {
            return (this.sceneObject.isEnvironmentAsset === true) ? trans('labels.inspector.environment_properties') : trans('labels.inspector.asset_properties');
        },

        originalAssetNameTitleAttribute() {
            return this.showOriginalAssetName && this.subtitle.length >= 28 ? this.subtitle : null;
        },

        showOriginalAssetName() {
            return (this.sceneObject.title !== this.asset.title);
        },

        subtitle() {
            return this.showOriginalAssetName ? this.asset.title : '';
        },

        supportsAssetPreviewModal() {
            return this.asset && (this.asset.supportsPreview || this.asset.hasPreviewImage);
        },

        /**
         * Get the visibility property caption
         *
         * @returns {String}
         */
        visibilityCaption() {
            if (
                this.sceneObject instanceof SceneObjectAssetSound ||
                this.sceneObject instanceof SceneObjectAssetTtsSound
            ) {
                return trans('authoring.play_when_scene_loaded');
            }

            return trans('authoring.asset_initially_visible');
        },

        /**
         * @return {DropdownOption[]}
         */
        postureOptions() {
            return PostureHelpers.all().map(posture => new DropdownOption({
                caption: PostureHelpers.getCaption(posture),
                value: posture,
            }))
        }
    },
    methods: {

        /**
         * @param {Event} e
         * @param {Object} component
         */
        onBlurTitle(e, component) {
            // Fix empty asset name not being reset in UI
            component.$refs.domElement.value = this.sceneObject.title;
            return this;
        },

        /**
         * Common property change handler
         */
        onChangeProperty() {
            // Fix empty title string
            if (this.sceneObject.title === '') {
                this.sceneObject.title = this.asset.title;
            }
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Update the triggers on the scene object whenever a change happens
         *
         * @param {Array<Trigger>} triggers      // List of Trigger objects
         */
        onChangeTriggers(triggers) {
            //console.log('Inspector: Triggers have been updated on SceneObject', this.sceneObject);
            this.$globalEvents.emit(EventType.INSPECTOR_SCENE_OBJECT_UPDATED, this.sceneObject);

            // Update scene objectives:
            this.$globalEvents.emit(EventType.INSPECTOR_TRIGGERS_UPDATED, this.sceneObject, triggers);
            return this;
        },

        /**
         * Click handler for scene object duplicate button
         */
        onClickDuplicateSceneObject() {
            this.$globalEvents.emit(EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.sceneObject);
            return this;
        },

        /**
         * Click handler for asset replace button
         */
        onClickReplaceAssetOnSceneObject() {
            const filters = [];
            const assetTypes = [];

            // Set filter title dynamically:
            const filter = SceneObjectFilters.AssetsOfSameType.setActive(true);
            if (this.sceneObject.assetType !== null && this.sceneObject.assetType.type !== AssetType.Unknown.type)
            {
                filter.title = trans('assets.types.' + this.sceneObject.assetType.type + 's');
            }

            // Set filters:
            // Both sound asset types can be replaced with each other
            if ([AssetType.Sound.type, AssetType.SoundTts.type].includes(this.sceneObject?.assetType.type)) {
                filters.push(SceneObjectFilters.Sounds);
                filters.push(SceneObjectFilters.TextToSpeechSounds);
                assetTypes.push(AssetType.Sound);
                assetTypes.push(AssetType.SoundTts);
            } else {
                filters.push(filter);
                assetTypes.push(this.sceneObject.assetType);
            }

            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, filters, this.sceneObject);
            // Show asset selection side panel for adding a new object:
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, {
                callback: this.sceneObject,
                assetTypes: assetTypes,
                clearWordFilter: true
            });
            return this;
        },

        /**
         * Click handler for asset remove button
         */
        onClickRemoveAssetFromSelectedScene() {
            // Show modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_ASSET_FROM_SCENE_SHOW, this.sceneObject);
            return this;
        },

        /**
         * Click handler for asset preview button
         */
        onClickShowAssetPreview() {
            if (this.supportsAssetPreviewModal)
            {
                this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, this.asset, this.sceneObject.title);
            }
            return this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            return this.onClickRemoveAssetFromSelectedScene();
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDuplicate(e) {
            // Duplicate selected scene object if allowed:
            return this.canDuplicateSceneObject ? this.onClickDuplicateSceneObject() : this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutReplace(e) {
            return this.onClickReplaceAssetOnSceneObject();
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
