<template>
    <StatisticsCard class="statistics-card-asset-types" :is-loading="isLoading">
        <StatisticsCardHeader
            :title="trans('statistics.index.asset_types.title')"
            :count="assetCount"
        />

        <figure class="chart-wrapper" ref="chartWrapper">
            <svg ref="chart" class="chart">
                <g class="root">
                    <g class="x-scale"/>
                    <g class="y-scale"/>
                    <g class="bars"/>
                </g>
            </svg>

            <figcaption>
                {{ trans('statistics.index.asset_types.legend') }}
            </figcaption>
        </figure>
    </StatisticsCard>
</template>

<script lang="ts">

import {defineComponent, PropType, ref, toRaw} from "vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import {AssetTypeWithCount} from "@/Models/Statistics/Statistics";
import {trans} from "@/Utility/Helpers";
import {select} from "d3-selection";
import {axisBottom, axisLeft, format, max, scaleBand, scaleLinear} from "d3";
import StatisticsCardHeader from "@/Vue/Statistics/General/Cards/StatisticsCardHeader.vue";
import {useResize} from "@/Vue/Utility/useResize";

export default defineComponent({
    name: "StatisticsCardAssetTypes",

    components: {
        StatisticsCardHeader,
        StatisticsCard
    },

    props: {
        assets: {
            type: Object as PropType<Array<AssetTypeWithCount>>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
        chartHeight: {
            type: Number,
            default: 200,
        }
    },

    setup() {
        const chartWrapper = ref<HTMLElement>();
        const {width: chartWidth} = useResize(chartWrapper);

        return {
            chartWidth,
            chartWrapper,
        };
    },

    computed: {

        assetCount() {
            return this.assets.reduce((prev, data) => prev + data.count, 0);
        },

    },

    methods: {
        trans,

        redraw() {
            const width = this.chartWidth;
            const height = this.chartHeight;
            const padding = 8;
            const yAxisWidth = 30;
            const xAxisHeight = 30;
            const chartWidth = width - yAxisWidth - 2 * padding;
            const chartHeight = height - xAxisHeight - 2 * padding;
            const barCornerRadius = 8;
            const barPaddingPercent = 0.15;

            const xScale = scaleBand()
                .range([0, chartWidth])
                .domain(this.assets.map(asset => asset.type.title));

            const maxAssetCount = max(this.assets, asset => asset.count) || 0;
            const yScale = scaleLinear()
                .range([0, chartHeight])
                .domain([maxAssetCount, 0]);

            const chart = select('.statistics-card-asset-types .chart')
                .attr('width', width)
                .attr('height', height);

            const group = chart
                .select('.root')
                .attr('transform', `translate(${yAxisWidth + padding}, ${padding})`);

            const xAxis = axisBottom(xScale)
                .tickSize(-chartHeight);
            group
                .select<SVGGElement>('.x-scale')
                .attr('transform', `translate(0, ${chartHeight})`)
                .call(xAxis);

            const yAxis = axisLeft(yScale)
                .ticks(Math.min(5, maxAssetCount), 'd')
                .tickSize(-chartWidth);
            group
                .select<SVGGElement>('.y-scale')
                .call(yAxis);

            const finalBarWidth = xScale.bandwidth() * (1 - (barPaddingPercent * 2));
            const getBarX = (asset: AssetTypeWithCount) =>
                (xScale(asset.type.title) || 0) + (xScale.bandwidth() * barPaddingPercent);

            group.select('.bars')
                .selectAll('.bar')
                .data(toRaw(this.assets))
                .join(
                    enter => enter
                        .append('rect')
                        .attr('class', 'bar')
                        .attr('x', getBarX)
                        .attr('width', finalBarWidth)
                        .attr('y', asset => yScale(asset.count))
                        .attr('height', asset => chartHeight - yScale(asset.count)),

                    update => update
                        .attr('x', getBarX)
                        .attr('width', finalBarWidth)
                );
        },
    },

    watch: {
        chartWidth() {
            this.redraw();
        }
    },
});

</script>

<style lang="scss">

.statistics-card-asset-types {
    overflow: hidden; // Fix resize issue when changing media breakpoints

    .chart-wrapper {
        padding: 24px 30px 16px 20px;

        figcaption {
            font-size: var(--font-size-small);
            font-family: var(--font-family-condensed);
            margin-left: 30px;

            &:before {
                content: '';
                display: inline-block;
                width: 21px;
                height: 8px;
                background-color: var(--color-primary);
                margin-right: 8px;
            }
        }
    }

    .chart {

        text {
            font-size: 10px;
            font-family: var(--font-family-condensed);
            color: #4F4F4F;
        }

        .bar {
            fill: var(--color-primary);

            &:hover {
                fill: var(--color-primary-hover);
            }
        }

        .domain,
        .tick line {
            stroke: #ECECEC;
        }

        .x-scale text {
            transform: translateY(4px);
        }

        .x-scale .tick line {
            visibility: hidden;
        }

        .y-scale text {
            transform: translateX(-8px);
        }
    }
}

</style>
