<template>
    <div class="buttons" v-not-focusable v-shortcuts>

        <!-- Btn: Duplicate Scene -->
        <ButtonCustom
            :disabled="!canDuplicateTrainingScene"
            v-tooltip="'buttons.authoring.scene.duplicate'"
            icon="icon_duplicate"
            caption="labels.duplicate"
            @trigger="onClickDuplicateTrainingScene"
        />

        <!-- Btn: Delete Scene -->
        <ButtonCustom
            :disabled="!canDeleteTrainingScene"
            v-tooltip="'buttons.authoring.scene.delete'"
            icon="icon_delete"
            caption="labels.delete"
            @trigger="onClickDeleteTrainingScene"
        />

    </div>
</template>

<script>
    import { Permission }           from '@/Models/User/Permission';
    import TrainingScene            from '@/Models/UnitData/Scenes/TrainingScene';
    import EventType                from '@/Utility/EventType';

    export default {
        name: "SidepanelTrainingSceneButtons",
        props: {
            scene: {                    // Selected training scene for which to show the properties
                type: TrainingScene,
                default: null,
            },
        },

        data() {
            return {
                shortcuts: new Map([
                    ['Duplicate.global.prevent', this.onShortcutDuplicate],
                    ['Delete.global', this.onShortcutDelete],
                    ['Publish.global.prevent', this.onShortcutPublish],
                    ['Replace.global.prevent', this.onShortcutReplace],
                ]),
            }
        },

        computed: {
            /**
             * Should the duplicate scene button be enabled
             *
             * @returns {Boolean}
             */
            canDuplicateTrainingScene() {
                return (
                    this.$gate.allows(Permission.UnitsUpdate()) &&
                    !this.scene.readonly &&
                    this.unitData !== null &&
                    this.unitData.scenesCount < this.unitData.scenesCountLimit
                );
            },

            /**
             * Should the delete scene button be enabled
             *
             * @returns {Boolean}
             */
            canDeleteTrainingScene() {
                return (
                    this.$gate.allows(Permission.UnitsUpdate())
                );
            },

            /**
             * The UnitData this scene belongs to
             *
             * @returns {UnitData}
             */
            unitData() {
                return this.scene.parentUnitData;
            },
        },

        methods: {
            /**
             * Click handler for training scene duplicate button
             */
            onClickDuplicateTrainingScene() {
                // Show modal dialog:
                this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_SCENE_SHOW, this.scene);
                return this;
            },

            /**
             * Click handler for training scene delete button
             */
            onClickDeleteTrainingScene() {
                this.$globalEvents.emit(EventType.MODAL_REMOVE_UNIT_SCENE_SHOW, this.scene);
                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                if (this.canDeleteTrainingScene) {
                    this.onClickDeleteTrainingScene();
                }

                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcutDuplicate(e) {
                // Duplicate selected scene:
                if (this.canDuplicateTrainingScene) {
                    return this.onClickDuplicateTrainingScene();
                }

                return this;
            },
        },

    }
</script>

<style lang="scss" scoped>

</style>
