<template>
    <div v-if="isVisible" id="dialog-release-unit" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <h2 class="title">{{ trans('modals.release_unit.title') }}</h2>

            <p class="description" v-html="trans('modals.release_unit.description_intro', { title: this.unitRevision.title })"></p>

            <template v-if="unitRevision.parentUnit.isDraft">
                <p v-html="trans('modals.release_unit.description_first_release', { title: this.unitRevision.title })"></p>
                <p v-html="trans('modals.release_unit.description_first_changes')"></p>
            </template>
            <template v-else>

                <p class="description" v-html="trans('modals.release_unit.description_learners')"></p>

                <!-- Part of any course -->
                <template v-if="hasCourses">
                    <p class="description">{{ trans('modals.release_unit.description_courses') }}</p>
                    <ul>
                        <li v-for="course in courses"><b>{{ course.title }}</b></li>
                    </ul>
                </template>

            </template>

            <div class="buttons">
                <ButtonSecondary class="btn-cancel" @trigger="onClickCancel" caption="modals.release_unit.cancel" />
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="modals.release_unit.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    //

    // Import classes:
    import EventType from '@/Utility/EventType';
    import {trans} from "@/Utility/Helpers/trans";
    import TenantListItem from "@/Vue/Tenants/TenantListItem.vue";

    export default {
        name: 'DialogReleaseUnit',
      components: {TenantListItem},
        data() {
            return {
                isVisible: false,                       // Visibility state
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                /**
                 * @type UnitRevision|null
                 */
                unitRevision: null,
                /**
                 * @type CourseWithoutRelations[]
                 */
                courses: [],
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_RELEASE_UNIT_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_RELEASE_UNIT_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_RELEASE_UNIT_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_RELEASE_UNIT_HIDE, this.hide);
        },
        computed: {

            hasCourses() {
                return this.courses.length > 0;
            }

        },
        methods: {
            trans,

            /**
             * Show the dialog
             */
            show(event) {
                this.previousFocusElement = document.activeElement;
                this.unitRevision = event.unitRevision;
                this.courses = event.courses;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.unitRevision = null;
                this.courses = [];

                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_RELEASE_UNIT_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType.MODAL_RELEASE_UNIT_APPLY, this.unitType, this.keepAssetLocations);
                this.hide();
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
