import { trans } from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';
import {
    UnitPermissionPolicySample,
    UnitPermissionPolicyStandard,
    UnitPermissionPolicyTemplate
} from "@/Models/Unit/UnitPermissionPolicy.js";

/**
 * Filter definitions
 */
const FilterDefinitions = {

    Draft: {
        title: trans('labels.draft'),
        callback(elements, options = null) {
            return elements.filter(e => e.isDraft);
        },
        paramName: 'draft',
    },

    HasUnreleasedChanges: {
        title: trans('labels.unreleased_changes'),
        callback(elements, options = null) {
            return elements.filter(e => e.hasUnreleasedChanges);
        },
        paramName: 'unreleased_changes',
    },

    PolicyStandard: {
        title: trans('labels.my_units'),
        callback(elements, options = null) {
            return elements.filter(e => e.policy === UnitPermissionPolicyStandard.type);
        },
        paramName: 'standard',
    },

    PolicySample: {
        title: trans('labels.spaces_and_samples'),
        callback(elements, options = null) {
            return elements.filter(e => e.policy === UnitPermissionPolicySample.type);
        },
        paramName: 'sample',
    },

    PolicyTemplate: {
        title: trans('labels.templates'),
        callback(elements, options = null) {
            return elements.filter(e => e.policy === UnitPermissionPolicyTemplate.type);
        },
        paramName: 'template',
    },

    Released: {
        title: trans('labels.released'),
        callback(elements, options = null) {
            return elements.filter(e => e.isReleased);
        },
        paramName: 'released',
    },
};

export default class UnitFilters
{
    static get Draft()                  { return new FilterCategory(FilterDefinitions.Draft); }
    static get HasUnreleasedChanges()   { return new FilterCategory(FilterDefinitions.HasUnreleasedChanges); }
    static get PolicySample()           { return new FilterCategory(FilterDefinitions.PolicySample); }

    static get PolicyStandard()         { return new FilterCategory(FilterDefinitions.PolicyStandard); }

    static get PolicyTemplate()         { return new FilterCategory(FilterDefinitions.PolicyTemplate); }
    static get Released()               { return new FilterCategory(FilterDefinitions.Released); }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === UnitFilters)
        {
            throw new TypeError('Static class "UnitFilters" cannot be instantiated directly.');
        }
    }
}
