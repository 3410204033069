<template>
    <div v-if="isVisible" id="dialog-restore-unit-from-history" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div ref="dialog" class="dialog">
            <h2 class="title">{{ trans('modals.restore_unit_from_history.title') }}</h2>
            <p class="description" v-html="trans('modals.restore_unit_from_history.description', {versiondate: dateFormatted})"></p>
            <div class="buttons">
                <ButtonSecondary class="btn-cancel" @trigger="onClickCancel" caption="modals.restore_unit_from_history.cancel" />
                <ButtonSecondary class="btn-dismiss" @trigger="onClickAsNewUnit" caption="modals.restore_unit_from_history.save_as_new" />
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="modals.restore_unit_from_history.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    //

    // Import classes:
    import moment                   from 'moment';
    import EventType                from '@/Utility/EventType';

    export default {
        name: 'DialogRestoreUnitFromHistory',
        data() {
            return {
                isVisible: false,                       // Visibility state
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                unitRevision: null,                     // UnitRevision which to restore from history
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_HIDE, this.hide);
        },
        computed: {

            /**
             * Date format for the dialog
             *
             * @returns {String}
             */
            dateFormatted() {
                const date = moment(this.unitRevision.updated_at);
                return date.format('LL – HH:mm');
            }
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {UnitRevision} unitRevision
             */
            show(unitRevision) {
                this.previousFocusElement = document.activeElement;
                this.unitRevision = unitRevision;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_APPLY, this.unitRevision);
                this.hide();
                return this;
            },

            /**
             * Click handler for duplicate button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickAsNewUnit(e) {
                this.$globalEvents.emit(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_AS_NEW_UNIT, this.unitRevision);
                this.hide();
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
