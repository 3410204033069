<template>
    <div v-if="isVisible" class="modal-dialog fullscreen" v-not-focusable v-shortcuts>
        <div class="dialog modal-asset-preview">
            <Icon name="icon_close" class="icon-close" @click="onClickHide" />
            <h2 class="title">{{ titleToShow }}</h2>
            <div v-if="subtitleToShow" class="subtitle">{{ subtitleToShow }}</div>

            <div class="preview-wrapper">
                <SoundRenderer
                    v-if="isSoundAsset"
                    :sound-url="assetUrl"
                    :mime-type="assetData.mimetype"
                    :content-description="assetObject.content_description"
                />
                <video
                    ref="video-player"
                    v-else-if="isVideoAsset"
                    :src="assetUrl"
                    :type="assetData.mimetype"
                    controls=""
                    controlsList="nodownload"
                    disablePictureInPicture
                    @contextmenu.prevent
                    autoplay
                />
                <EnvironmentVideoRenderer
                    ref="environment-image-player"
                    v-else-if="isEnvironmentVideoAsset"
                    :src="assetUrl"
                />
                <img
                    ref="image-player"
                    v-else-if="isImageAsset"
                    :src="assetUrl"
                    @contextmenu.prevent
                />
                <EnvironmentImageRenderer
                    ref="environment-image-player-component"
                    v-else-if="isEnvironmentImageAsset"
                    :src="assetUrl"
                />
                <ThreeDObjectRenderer
                    ref="object-player"
                    v-else-if="isPreviewable3DObject"
                    :src="assetUrl"
                />
                <img
                    ref="preview-image-player"
                    v-else-if="hasPreviewImage"
                    :src="assetObject.preview"
                    @contextmenu.prevent
                />
            </div>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import Asset                    from '@/Models/Asset/Asset';
    import AssetType                from '@/Models/Asset/AssetType';
    import EventType                from '@/Utility/EventType';
    import EnvironmentImageRenderer from "@/Vue/Modals/AssetPreview/EnvironmentImageRenderer";
    import EnvironmentVideoRenderer from "@/Vue/Modals/AssetPreview/EnvironmentVideoRenderer";
    import ThreeDObjectRenderer     from "@/Vue/Modals/AssetPreview/ThreeDObjectRenderer";
    import SoundRenderer from "@/Vue/Modals/AssetPreview/SoundRenderer.vue";


    export default {
        name: 'ModalAssetPreview',
        components: {
            SoundRenderer,
            ThreeDObjectRenderer,
            EnvironmentImageRenderer,
            EnvironmentVideoRenderer,
        },
        props: {
            asset: {
                type: Asset,
                default: null
            }
        },
        data() {
            return {
                isVisible: false,                       // Visibility state
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onShortcutEnter],
                    ['Escape', this.onShortcutEscape],
                    ['Space', this.onShortcutSpace],
                ]),
                assetObject: null,
                title: null,
            }
        },
        created() {
            this.assetObject = this.asset;
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_ASSET_PREVIEW_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_ASSET_PREVIEW_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_ASSET_PREVIEW_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_ASSET_PREVIEW_HIDE, this.hide);
        },

        computed: {

            isCharacter3DObject() {
                return (this.assetObject.type === AssetType.CharacterModel3D.type);
            },

            is3DObject() {
                return (this.assetObject.type === AssetType.Model3D.type);
            },

            isEnvironment3DObject() {
                return (this.assetObject.type === AssetType.EnvironmentModel3D.type);
            },

            isPreviewable3DObject() {
                return (
                    (this.is3DObject || this.isEnvironment3DObject || this.isCharacter3DObject)
                    && !this.assetObject.bundle
                );
            },

            isImageAsset() {
                return (this.assetObject.type === AssetType.Image.type);
            },

            isEnvironmentImageAsset() {
                return (this.assetObject.type === AssetType.EnvironmentImage.type)
            },

            isSoundAsset() {
                return (
                    this.assetObject.type === AssetType.Sound.type
                    || this.assetObject.type === AssetType.SoundTts.type
                );
            },

            isVideoAsset() {
                return (
                    this.assetObject.type === AssetType.Video.type
                    || this.assetObject.type === AssetType.EnvironmentVideo.type //@NOTE:  Disables the EnvironmentVideoRenderer
                );
            },

            isEnvironmentVideoAsset() {
                return this.assetObject.type === AssetType.EnvironmentVideo.type;
            },

            hasPreviewImage() {
                return this.assetObject.hasPreviewImage;
            },

            assetData() {
                switch (this.assetObject.type) {
                    case AssetType.Image.type:
                    case AssetType.EnvironmentImage.type:
                        return this.assetObject.images[0];
                    case AssetType.Sound.type:
                    case AssetType.SoundTts.type:
                        return this.assetObject.sounds[0];
                    case AssetType.Video.type:
                    case AssetType.EnvironmentVideo.type:
                        return this.assetObject.videos[0];
                    case AssetType.Model3D.type:
                    case AssetType.EnvironmentModel3D.type:
                    case AssetType.CharacterModel3D.type:
                        return this.assetObject.files[0];
                    default:
                        return null;
                }
            },

            assetUrl() {
                let type = this.assetObject.type;

                if (type === AssetType.EnvironmentVideo.type) {
                    type = AssetType.Video.type;
                }

                if (type === AssetType.EnvironmentImage.type) {
                    type = AssetType.Image.type;
                }

                if (type === AssetType.SoundTts.type) {
                    type = AssetType.Sound.type;
                }

                if (
                    type === AssetType.Model3D.type
                    || type === AssetType.EnvironmentModel3D.type
                    || type === AssetType.CharacterModel3D.type
                ) {
                    type = 'file';
                }

                return (this.assetObject !== null) ? "/assets/" + type + "/" + this.assetData.uid : null;
            },

            titleToShow() {
                return this.title !== null ? this.title : this.assetObject.title;
            },

            subtitleToShow() {
                return this.title !== null && this.title !== this.assetObject.title ? this.assetObject.title : null;
            },

        },

        methods: {
            /**
             * Show the dialog
             *
             * @param asset
             * @param title
             */
            show(asset, title = null) {
                this.assetObject = asset;
                this.title = title;
                this.previousFocusElement = document.activeElement;
                this.isVisible = true;

                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});

                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickHide(e) {
                this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_HIDE);
                this.hide();
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Shortcut handler for Enter key
             *
             * @param {CustomEvent} e
             */
            onShortcutEnter(e) {
                //
            },

            /**
             * Shortcut handler for Escape key
             *
             * @param {CustomEvent} e
             */
            onShortcutEscape(e) {
                this.onClickCancel(e);
            },

            /**
             * Shortcut handler for Space key
             *
             * @param {CustomEvent} e
             */
            onShortcutSpace(e) {
                let element = null;
                if (this.isVideoAsset) {
                    element = this.$refs['video-player'];
                } else if (this.isSoundAsset) {
                    element = this.$refs['audio-player'];
                } else {
                    return this;
                }

                // Because Chrome and Edge already pause / play focused videos with the space key we need
                // to check if the players are focused before manually triggering pause() / play() or it will
                // get triggered twice and the player gets stuck.
                // This means the space key will not work in Firefox if the player is focused.
                if (document.activeElement === element) {
                    return this;
                }

                if (element.paused) {
                    element.play();
                } else {
                    element.pause();
                }

                return this;
            },

        }
    }
</script>

<style lang="scss" scoped>

</style>
