
import { Permission } from '@/Models/User/Permission';
import {UnitPermissionPolicySample, UnitPermissionPolicyStandard} from "@/Models/Unit/UnitPermissionPolicy.js";

export default class CoursePermissionPolicy
{
    /**
     * Returns a Course Policy for the specified permission.
     *
     * @param  {string} permission
     * @return {CoursePermissionPolicy|undefined}
     */
    static getPolicyForPermission(permission) {
        return PermissionPolicyMap.get(permission);
    }

    /**
     *
     * @return {string[]}
     */
    static getPolicyPermissions() {
        return [...PermissionPolicyMap.keys()];
    }

    static getPermissionPolicyMapping() {
        return [...PermissionPolicyMap.entries()];
    }

    /**
     * @param  {User}  user
     * @return {Array.<string, CoursePermissionPolicy>}
     */
    static allAvailablePoliciesForUser(user) {
        return this.getPermissionPolicyMapping().filter(function(mapping){
            return user.permissions.find(permission => permission === mapping[0]);
        });
    }

    /**
     * Returns a course policy with the specified type.
     *
     * @param  {string} type
     * @return {CoursePermissionPolicy|undefined}
     */
    static getPolicyForType(type) {
        return StaticCoursePolicyInstances.get(type);
    }

    static get type() {
        return 'undefined';
    }

    get type() {
        return this.constructor.type;
    }

    get isDeletable() {
        return false;
    }

    get isEditable() {
        return false;
    }

    get isStoreAsset() {
        return false;
    }

    get hasToExistInsideAssetDefaultTenant() {
        return true;
    }

    /**
     * @param {UnitPermissionPolicy} unitPermissionPolicy
     * @return {boolean}
     */
    doesAllowForUnitWithPolicy(unitPermissionPolicy) {
        return false;
    }
}

export class CoursePermissionPolicyStandard extends CoursePermissionPolicy {

    static get type() {
        return 'standard';
    }

    get isDeletable() {
        return true;
    }

    get isEditable() {
        return true;
    }

    get hasToExistInsideAssetDefaultTenant() {
        return false;
    }

    doesAllowForUnitWithPolicy(unitPermissionPolicy) {
        return unitPermissionPolicy.type === UnitPermissionPolicyStandard.type
            || unitPermissionPolicy.type === UnitPermissionPolicySample.type;
    }
}

export class CoursePermissionPolicySample extends CoursePermissionPolicy {

    static get type() {
        return 'sample';
    }

    doesAllowForUnitWithPolicy(unitPermissionPolicy) {
        return unitPermissionPolicy.type === UnitPermissionPolicySample.type;
    }
}

/**
 * Map of CoursePolicies and their types
 *
 * @type {Map<string, CoursePermissionPolicy>}
 */
const StaticCoursePolicyInstances = new Map([
    [CoursePermissionPolicySample.type, Object.freeze(new CoursePermissionPolicySample())],
    [CoursePermissionPolicyStandard.type, Object.freeze(new CoursePermissionPolicyStandard())],
]);

/**
 * Map of Permissions and associated CoursePolicies
 *
 * @type {Map<string, CoursePermissionPolicy|undefined>}
 */
const PermissionPolicyMap = new Map ([
    [Permission.CoursesAccessPolicySample(), CoursePermissionPolicy.getPolicyForType(CoursePermissionPolicySample.type)],
    [Permission.CoursesAccessPolicyStandard(), CoursePermissionPolicy.getPolicyForType(CoursePermissionPolicyStandard.type)],
]);
