import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, trans} from '@/Utility/Helpers';
import User from '@/Models/User/User';
import {UserRole} from "@/Models/User/UserRole";
import type Unit from "@/Models/Unit/Unit";
import type Course from "@/Models/Course/Course";
import {TenantRole} from "@/Models/Tenant/TenantRole";

export default class UserService {

    public users: Array<User> = [];
    public isLoading: Boolean = false;
    public isSaving: Boolean = false;
    private request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests(): Promise<any> {
        return await this.request?.cancel();
    }

    /**
     * Fetch all users for the current user from API.
     */
    async fetchUsers(): Promise<Array<User>> {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        return await this.request
            .get(route('api.users.index'))
            .then(({data}: any) => {
                this.users = [];
                data.data.forEach((userData: any): void => {
                    try {
                        this.users.push(new User(userData));
                    } catch (ex) {
                        console.warn('UserService->fetchUsers(): Skipping user with invalid or incompatible data.', userData, ex);
                    }
                });
                return Promise.resolve(this.users);
            })
            .finally((): void => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Creates a new user through the API.
     */
    async createUserFromFormData(formData: Record<string, any>): Promise<User> {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.users.create'),
            formData
        ).then(({data}: any): Promise<User> => {
            try {
                const user = new User(data);
                //console.info('UserService->createUserFromFormData(): User created.', user, data);
                return Promise.resolve(user);
            } catch (ex) {
                console.error('UserService->createUserFromFormData(): API returned invalid or incompatible user data.', data, ex);
                return Promise.reject(trans('errors.user.create_parse_failed'));
            }
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Get a specific user by its UID.
     */
    getUserByUid(uid: string): User | null {
        return this.users.find((user: User): boolean => user.uid === uid) || null;
    }

    /**
     * Get all loaded users with the given tenant role inside the current tenant.
     */
    getUsersByTenantRole(tenantRole: TenantRole.Name): User[] {
        return this.users.filter((user: User): boolean => user.tenant_role?.is(tenantRole) || false) || [];
    }

    /**
     * Get assigned authors for a given unit.
     */
    getAssignedAuthorsForUnit(unit: Unit): User[] {
        return (unit.authors.length >= 1) ? this.users.filter((user: User): boolean => unit.authors.includes(user.uid)) : [];
    }

    /**
     * Get unassigned authors for a given unit.
     */
    getUnassignedAuthorsForUnit(unit: Unit): User[] {
        const limitedAuthors: User[] = this.getUsersByTenantRole(TenantRole.Name.AuthorLimited);
        return (unit.authors.length >= 1) ?
            limitedAuthors.filter((author: User): boolean => !unit.authors.includes(author.uid)) :
            limitedAuthors;
    }

    /**
     * Get enrolled users for a given course.
     */
    getEnrolledUsersForCourse(course: Course): User[] {
        return course.hasEnrolledUsers ? this.users.filter((user: User): boolean => course.isEnrolled(user)) : [];
    }

    /**
     * Get unenrolled users for a given course.
     */
    getUnenrolledUsersForCourse(course: Course): User[] {
        return course.hasEnrolledUsers
            ? this.users.filter((user: User): boolean => !course.isEnrolled(user) && !user.hasAnyRole(UserRole.Lms, UserRole.WebApp))
            : this.users.filter((user: User): boolean => !user.hasAnyRole(UserRole.Lms, UserRole.WebApp));
    }
}
