<template>
    <div v-if="isVisible" class="modal-dialog dialog-loading fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <h2 class="title">{{ title }}</h2>
            <p class="description" v-html="description"></p>
            <div v-if="showProgress" class="progress-text">{{ progressFormatted }}</div>
            <progress
                v-if="showProgress"
                :value="progressPercent"
                :aria-label="title">
            </progress>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import { trans }                from '@/Utility/Helpers';

    export default {
        name: 'DialogLoading',
        props: {
            title: {
                type: String,
                default: trans('modals.loading.title')
            },
            description: {
                type: String,
                default: trans('modals.loading.description')
            },
            eventType: {
                type: String,
                default: 'MODAL_LOADING'    // Must be a string from EventType class
            }
        },
        data() {
            return {
                isVisible: false,                       // Visibility state
                model: null,                            // Reference to the model object for which this dialog is being shown
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Any', null],              // Prevent any shortcut
                ]),

                /**
                 * @type number
                 */
                progressPercent: 0,

                /**
                 * True when [eventType]_PROGRESS event has been fired once
                 * and the progress should be displayed.
                 * @type boolean
                 */
                showProgress: false,
            }
        },
        created() {
            // Make sure the given type's events exist:
            ['SHOW', 'HIDE', 'PROGRESS'].forEach((e) => {
                if (typeof EventType[this.eventType + '_' + e] !== 'string')
                {
                    throw new TypeError('DialogLoading: EventType ' + (this.eventType + '_' + e) + ' doesn\'t exist.');
                }
            });
        },
        mounted() {
            this.$globalEvents.on(EventType[this.eventType + '_SHOW'], this.show);
            this.$globalEvents.on(EventType[this.eventType + '_HIDE'], this.hide);
            this.$globalEvents.on(EventType[this.eventType + '_PROGRESS'], this.progress);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType[this.eventType + '_SHOW'], this.show);
            this.$globalEvents.off(EventType[this.eventType + '_HIDE'], this.hide);
            this.$globalEvents.off(EventType[this.eventType + '_PROGRESS'], this.progress);
        },
        computed: {

            /**
             * @return {String}
             */
            progressFormatted() {
                return `${(this.progressPercent * 100).toFixed(2)} %`;
            }
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {Object} model
             */
            show(model = null) {
                this.progressPercent = 0;
                this.previousFocusElement = document.activeElement;
                this.model = model;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.showProgress = false;
                this.model = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Update progress of the dialog.
             * @param {number} progressPercent
             */
            progress(progressPercent) {
                this.progressPercent = progressPercent;
                this.showProgress = true;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
