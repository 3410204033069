<template>
    <main id="layout-main" :data-loading="isLoading">

        <PageHeader
            :page-title="trans('users.index.headline')"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <div class="container user-list-filters-wrapper">
                    <ButtonPrimary
                        v-if="canCreateUsers"
                        :href="route('users.createForm')"
                        caption="labels.create_user"
                        class="btn-add-new"
                        icon="icon_add"
                    />
                </div>
                <div class="container">
                    <table class="users-list" width="100%">

                        <tbody>
                        <UserListItem v-for="user in users" :key="user.uid" :user="user"/>
                        </tbody>
                    </table>
                </div>
            </div>

            <DialogLoading/>
            <DialogNotification/>

        </div>
    </main>
</template>

<script lang="ts">

    import DialogLoading from '@/Vue/Modals/DialogLoading.vue';
    import EventType from '@/Utility/EventType';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import {permission, route, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import {defineComponent, inject} from "vue";
    import {userServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import DialogApplyCancel from "@/Vue/Modals/DialogApplyCancel.vue";
    import DialogSaving from "@/Vue/Modals/DialogSaving.vue";
    import DialogNotification from "@/Vue/Modals/DialogNotification.vue";
    import PageHeader from "@/Vue/Common/PageHeader.vue";
    import ButtonPrimary from "@/Vue/Common/ButtonPrimary.vue";
    import UserListItem from "@/Vue/Users/UserListItem.vue";

    export default defineComponent({

        components: {
            UserListItem,
            ButtonPrimary,
            PageHeader,
            DialogNotification,
            DialogSaving,
            DialogApplyCancel,
            DialogLoading
        },

        data() {
            return {
                userService: inject(userServiceKey)!,
            }
        },

        mounted() {
            this.fetchUsers();
        },

        computed: {

            canCreateUsers() {
                return permission(Permission.UsersCreate());
            },

            users() {
                return this.userService.users;
            },

            isLoading(): boolean {
                if (this.userService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
                return false;
            },
        },

        methods: {
            trans,
            route,

            /**
             * Fetch tenants for the current user from API
             */
            fetchUsers() {
                this.userService
                    .fetchUsers()
                    .catch(this.onErrorApi);
            },

            /**
             * Error handler for API errors.
             */
            onErrorApi(error: any) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root?.forceLogout;
                }

                this.$root?.showErrorDialog(error);
            },
        }
    });
</script>

<style lang="scss" scoped>

</style>
