<template>
    <header id="layout-header">

        <ButtonCircular
            class="btn-back bordered"
            v-tooltip="'labels.back'"
            v-if="showBackButton"
            icon="icon_back"
            @trigger="onClickBackButton"
        />

        <slot name="icon"></slot>

        <div class="page-header-main">

            <!-- Title Text -->
            <h1 class="page-title">{{ pageTitle }}</h1>

            <!-- Status Labels -->
            <StatusLabel
                v-for="labelConfig in labels"
                :key="'page-header-statuslabel'+(labelConfig.type || labelConfig.caption)"
                :config="labelConfig"
            />

            <!-- Subtitle -->
            <p v-if="pageSubtitle" class="subtitle">{{ pageSubtitle }}</p>

        </div>

        <div v-if="Object.keys(buttons).length" class="header-nav-actions">
            <template v-for="(button, key, index) in buttons">

                <a
                    v-if="button.visible && button.style === 'icon+text'"
                    v-not-focusable
                    v-tooltip="button.tooltip"
                    :href="button.href || '#'"
                    :class="'btn icon-with-text ' + (button.disabled ? ' disabled' : '')"
                    :key="'page-header-actions-button-'+index"
                    @click="onClickButton($event, button)"
                >
                    <Icon v-if="button.icon" :name="button.icon" :class="button.icon" />
                    <span class="caption">{{ button.caption }}</span>
                </a>

                <ButtonPrimary
                    v-if="button.visible && button.style === 'button'"
                    v-not-focusable
                    v-tooltip="button.tooltip"
                    :disabled="button.disabled"
                    class="btn-green"
                    :key="'page-header-actions-button-'+index"
                    :caption="button.caption"
                    :icon="button.icon"
                    @trigger="onClickButton($event, button)"
                />
            </template>
        </div>
    </header>
</template>

<script>

    // Import classes:
    import EventType from '@/Utility/EventType';
    import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";

    export default {
        name: 'PageHeader',
        components: {
            ButtonCircular,
        },
        props: {
            pageTitle: String,
            pageSubtitle: String,
            showBackButton: {
                type: Boolean,
                default: false,
            },
            buttons: {
                type: Object,
                default() {
                    return {};
                }
            },
            labels: {
                type: Array,
                default() {
                    return [];
                }
            }
        },
        methods: {

            /**
             * Click handler for buttons
             *
             * @param {MouseEvent} event
             * @param {PageHeaderButton} buttonConfig
             */
            onClickButton(event, buttonConfig) {
                if (buttonConfig.callback !== null) {
                    event.preventDefault();
                }

                if (typeof buttonConfig.disabled === 'undefined' || buttonConfig.disabled === false) {
                    this.$globalEvents.emit(EventType.HEADER_NAVIGATION_BUTTON_CLICK, buttonConfig);
                }

                return this;
            },

            onClickBackButton() {
                history.back();
            },
        },
    }
</script>

<style lang="scss" scoped>



</style>
