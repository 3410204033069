<template>

    <main id="layout-main" v-shortcuts.global>

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <form
                    id="create-unit-form"
                    ref="form"
                    class="content-panel"
                    :class="{'with-unit-policy': showPolicyDropdown}"
                    enctype="multipart/form-data"
                    method="post"
                    @submit.prevent="onSubmit">

                    <h1 class="headline">
                        <Icon name="icon_units" class="create-form-header-icon" />
                        {{ trans('units.create.panel_headline') }}
                    </h1>

                    <!-- Preview image -->
                    <div class="panel-row preview-image-upload-placeholder-wrapper">
                        <label for="preview_image">{{ trans('labels.preview_image') }}</label>
                        <ImageInputField
                            ref="imageInput"
                            field-id="preview_image"
                            field-name="preview_image"
                            :validation-rules="{
                                minWidth: 1280,
                                minHeight: 720,
                                aspectRatio: 16 / 9,
                                allowedTypes: ['PNG', 'JPG'],
                            }"
                            @validation-error="onImageValidationError"
                        />
                    </div>

                    <!-- Title -->
                    <TextInput
                        :errorMsg="trans('errors.unit.title')"
                        :label="trans('units.create.unit_title')"
                        :maxlength="50"
                        :model="unitData"
                        property="title"
                        :placeholder="trans('units.create.unit_title_placeholder')"
                        :required="true"
                        :validationErrors="validationErrors('unit.title')"
                        class="panel-row unit-name-input"
                    />

                    <!-- Description -->
                    <TextInput
                        :label="trans('units.create.unit_description')"
                        :maxlength="600"
                        :model="unitData"
                        property="description"
                        :placeholder="trans('units.create.unit_description_placeholder')"
                        :required="false"
                        :validationErrors="validationErrors('unit.description')"
                        class="panel-row unit-description-input"
                        type="textarea"
                    />

                    <RadioButtons
                        :buttons="getRadioButtonsForType()"
                        :label="trans('labels.type')"
                        :model="unitData"
                        property="type"
                        :required="true"
                        class="panel-row unit-type-selection"
                    />

                    <!-- Policy -->
                    <div class="panel-row panel-row-policy">
                        <Dropdown
                            v-if="showPolicyDropdown"
                            class="no-wrap"
                            :label="trans('labels.policy')"
                            :model="unitData"
                            property="policy"
                            :options="getOptionsForPolicy"
                        />
                    </div>

                    <!-- Buttons -->
                    <div class="panel-row buttons">
                        <ButtonPrimary
                            v-tooltip="'tooltips.buttons.units.create'"
                            :disabled="isSubmitting"
                            caption="units.create.btn_create"
                            @trigger="onSubmit"
                        />
                        <ButtonSecondary
                            :href="route('units.index')"
                            caption="units.create.btn_cancel"
                        />
                    </div>
                </form>
            </div>

            <DialogSaving :title="trans('units.create.saving')"/>
            <DialogLoading/>
            <DialogNotification/>
        </div>
    </main>
</template>

<script>

    import {permission, route, sortArrayByProperty, trans} from '@/Utility/Helpers';
    import EventType from '@/Utility/EventType';
    import DialogLoading from '@/Vue/Modals/DialogLoading.vue';
    import DialogSaving from '@/Vue/Modals/DialogSaving.vue';
    import DialogNotification from '@/Vue/Modals/DialogNotification.vue';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import UnitType from "@/Models/UnitData/UnitType";
    import RadioButtonConfig from "@/Utility/RadioButtonConfig";
    import ImageInputField from "@/Vue/Common/ImageInputField.vue";
    import UnitPermissionPolicy, {
        UnitPermissionPolicyStandard,
        UnitPermissionPolicyTemplate
    } from "@/Models/Unit/UnitPermissionPolicy";
    import DropdownOption from "@/Utility/DropdownOption";
    import Dropdown from "@/Vue/Common/Dropdown.vue";
    import {featureRepositoryKey} from "@/Vue/Bootstrap/InjectionKeys";
    import {Feature} from "@/Models/Features/Feature";
    import {inject} from "vue";
    import RequestError from "@/Errors/RequestError.js";

    export default {

        name: 'PageUnitCreate',

        components: {
            Dropdown,
            ImageInputField,
            DialogLoading,
            DialogSaving,
            DialogNotification,
        },

        props: {
            acceptPreviewTypes: {
                type: String,
                default() {
                    return '.jpeg,.jpg,.png';
                }
            },
        },

        data() {
            return {
                /**
                 * @type UnitService
                 */
                unitService: this.$unitService,

                /**
                 * @type FeatureRepository
                 */
                featureRepository: inject(featureRepositoryKey),

                unitData: {
                    title: null,
                    description: null,
                    type: UnitType.VR.type,
                    source_reference: null,
                    policy: UnitPermissionPolicyStandard.type,
                },

                isSubmitting: false,
                errors: {},

                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Save.prevent', null],
                    ['Enter', this.onSubmit],
                    ['Escape.prevent', this.onClickCancel],
                    ['Backspace.prevent', null]
                ])
            }
        },

        mounted() {
            this.applySourceReferenceFromUrl();
        },

        computed: {
            /**
             * @return {File|null}
             */
            previewImageFile() {
                return this.$refs.imageInput.imageFile;
            },

            /**
             * @returns {boolean}
             */
            showPolicyDropdown() {
                return (
                    window.currentUser.tenant.is_default_asset_tenant
                    && this.getOptionsForPolicy.length > 1
                );
            },

            /**
             * Options for Policy dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForPolicy() {
                const canCreateTemplates = this.featureRepository.active(Feature.FeatureCreateTemplates);
                const options = UnitPermissionPolicy
                    .getPermissionPolicyMapping()
                    .filter(([_, policy]) => {
                        const validPolicy = policy instanceof UnitPermissionPolicy;

                        // some policies are protected by features
                        const featureAllowed = !(policy instanceof UnitPermissionPolicyTemplate) || canCreateTemplates;

                        // some policies are only allowed in default asset tenant
                        const forbiddenInTenant = policy.hasToExistInsideAssetDefaultTenant &&
                            !window.currentUser.tenant.is_default_asset_tenant;

                        // policy is not allowed for user
                        const forbiddenForUser = !permission(policy);

                        return validPolicy && featureAllowed && !(forbiddenInTenant && forbiddenForUser);
                    })
                    .map(([policyPermission, policy]) => {
                        // some policies are only allowed in default asset tenant
                        const forbiddenInTenant = policy.hasToExistInsideAssetDefaultTenant &&
                            !window.currentUser.tenant.is_default_asset_tenant;

                        // policy is not allowed for user
                        const forbiddenForUser = !permission(policyPermission);

                        return new DropdownOption({
                            caption: trans('unit_policies.' + policy.constructor.type),
                            value: policy.constructor.type,
                            disabled: forbiddenInTenant || forbiddenForUser,
                        })
                    });
                return sortArrayByProperty(options, 'caption', false);
            },
        },

        methods: {

            /**
             * @param {Error} e
             */
            onImageValidationError(e) {
                this.$root.showErrorDialog(e.message);
            },

            /**
             * Get the validation errors for a specific field.
             *
             * @param {String} property
             * @returns {Array<string>}
             */
            validationErrors(property) {
                return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root.forceLogout;
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * @returns {Array<RadioButtonConfig>}
             */
            getRadioButtonsForType() {
                return UnitType.all.reverse().map(unitType =>
                    new RadioButtonConfig({
                        value: unitType.type,
                        caption: unitType.title,
                    })
                );
            },

            /**
             * @param {Event} e
             */
            onSubmit(e) {
                e.preventDefault();

                const form = this.$refs.form;

                if (this.isSubmitting || !form.reportValidity()) {
                    return;
                }

                this.isSubmitting = true;
                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);

                const formData = new FormData();
                formData.append('unit', JSON.stringify(this.unitData));
                if (this.previewImageFile !== null) {
                    formData.append('preview_image', this.previewImageFile);
                }

                this.unitService
                    .createUnitFromFormData(formData)
                    .then(unit => {
                        this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                        window.location.href = route('units.edit', {'unit': unit.uid});
                    })
                    .catch((error) => {

                        this.errors = error.validationErrors || {};

                        // Show error dialog unless it's a validation error:
                        if (
                            !(error instanceof RequestError && error.isValidationError)
                            || error.validationErrors['unit.source_reference']) {
                            this.$root.showErrorDialog(error);

                        // Show error dialog if preview image is invalid:
                        } else if (this.errors.preview_image) {
                            this.$root.showErrorDialog(trans('units.create.preview_requirements_error'));
                            this.$refs.imageInput.reset();
                        }
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                    });
            },

            /**
             * @param {Event} e
             */
            onClickCancel(e) {
                e.preventDefault();
                window.location.href = route('units.index');
                return this;
            },

            applySourceReferenceFromUrl() {
                // read location hash from url
                const urlParams = new URLSearchParams(location.search);
                const reference = urlParams.get('source_reference');

                // save as source_reference for new unit
                this.unitData.source_reference = reference ?? null;
            }
        }
    }
</script>

<style lang="scss" scoped>
    body {
        &.create-unit {
            .content-panel {
                &.with-unit-policy {
                    min-height: 590px;
                }
            }
        }
    }
</style>
