<template>
    <component
        :is="tag"
        v-bind="attributes"
    >
        <slot>
            <Icon v-if="icon" :name="icon" />
            {{ buttonCaption }}
        </slot>
    </component>
</template>

<script>
    import {mergeProps} from 'vue';
    import KeyboardKey from '@/Utility/KeyboardKey';
    import {trans} from "@/Utility/Helpers/trans";

    export default {
        name: 'ButtonCustom',
        inheritAttrs: false,
        emits: [
            'click',
            'keydown',
            'keyup',
            'trigger',
        ],
        props: {
            href: {                 // Turns the button into an anker tag
                type: String,
                default: null
            },
            disabled: {             // Disabled state
                type: Boolean,
                default: false
            },
            icon: {                 // SVG icon identifier
                type: String,
                default: null
            },
            caption: {              // Caption string that should be translated
                type: String,
                default: null
            },
            stopPropagation: {
                type: Boolean,
                default: true
            },
        },
        computed: {

            /**
             * @return {Object}
             */
            attributes() {

                // Copy initial attributes and merge CSS classes and button events
                const attrs = mergeProps(this.$attrs, {
                    class: this.cssClasses,
                    onKeydown: this.onTrigger,
                    onKeyup: this.onTrigger,
                    onClick: this.onTrigger,
                });

                if (this.hasHref) {
                    attrs.href = this.href;
                }

                if (this.disabled) {
                    // Remove any event handlers if the button is disabled
                    Object.keys(attrs).filter(k => k.match(/^on[a-z]+$/i)).forEach(key => delete attrs[key]);
                }

                return attrs;
            },

            /**
             * @return {Boolean}
             */
            hasHref() {
                return ![null, '#'].includes(this.href);
            },

            /**
             * @return {String}
             */
            cssClasses() {
                const classes = ['btn'];
                if (this.disabled) {
                    classes.push('disabled');
                }
                return classes.join(' ');
            },

            /**
             * Use <span>, <button> or <a> tag?
             *
             * @var {String}
             */
            tag() {
                if (this.disabled) {
                    return 'span';
                }
                if (this.hasHref) {
                    return 'a';
                }
                return 'button';
            },

            buttonCaption() {
                return trans(this.caption, {}, false, false) || this.caption || '';
            },
        },
        methods: {

            /**
             * Trigger handler
             *
             * @param {KeyboardEvent|MouseEvent} e
             */
            onTrigger(e) {

                // Check for [ENTER] key
                if (e instanceof KeyboardEvent && KeyboardKey.findByEvent(e) !== KeyboardKey.Enter) {
                    return this;
                }

                // Keydown event should not trigger the button
                if (e.type === 'keydown') {
                    e.preventDefault();
                    e.stopPropagation();
                    return this;
                }

                // Stop bubbling of native event to parents
                if (this.stopPropagation) {
                    e.stopPropagation();
                }

                // Send custom event to parents
                this.$emit('trigger', e);

                // Make the [ENTER] key trigger the page load (click event will work anyway since the component is rendered as <a> tag)
                if (e instanceof KeyboardEvent && !e.defaultPrevented && this.hasHref)
                {
                    window.location.href = this.href;
                }

                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
