<template>
    <div class="widget-model-layers">
        <Icon name="icon_close" class="icon-close" @click="$emit('hide')" />
        <div class="widget-model-layers-header">
            <h3>{{ trans('labels.layers') }}</h3>

            <ButtonCircular
                v-tooltip="'buttons.tools.model_viewer.copy_layers'"
                icon="icon_copy"
                @trigger="onCopyPathsClicked"
            />
        </div>
        <div class="widget-model-layers-body">
            <ul ref="layers-widget" class="paths"></ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ModelLayersWidget",

        props: {
            scene: {
                type: Object,
                default: null,
            },
        },

        mounted() {
            this.printScene();
        },

        methods: {

            onCopyPathsClicked(trigger) {
                navigator.clipboard.writeText(this.$refs['layers-widget'].textContent);
                trigger.target.blur();
            },

            printScene() {
                this.$refs['layers-widget'].innerHTML = '';

                if (this.scene !== null) {
                    this.printName("", this.scene, this.$refs['layers-widget']);
                }
            },

            printName(parentPath, node, parentHtml) {
                if (node === null) {
                    return;
                }
                const hasChildren = node.children.length > 0;
                const name = node.userData.name ?? node.name;
                const path = `<span class='parent'>${parentPath}/</span>${name}`;
                const listItemNode = document.createElement('li');
                let pathNode = null;

                if (hasChildren) {
                    const detailsNode = document.createElement('details');
                    detailsNode.setAttribute('open', '');

                    pathNode = document.createElement('summary');
                    detailsNode.append(pathNode);
                    listItemNode.append(detailsNode);
                    parentHtml.append(listItemNode);

                    const listNode = document.createElement('ul');
                    detailsNode.append(listNode);

                    node.children.forEach(child => {
                        this.printName(path, child, listNode);
                    });
                } else {
                    pathNode = listItemNode;
                    listItemNode.classList.add('no-children');
                    parentHtml.append(listItemNode);
                }

                pathNode.classList.add('path');
                pathNode.innerHTML = path + '\n';

                pathNode.addEventListener('mouseover', () => {
                    this.onMouseOver(node);
                });
                pathNode.addEventListener('mouseout', () => {
                    this.onMouseOut(node);
                });
            },

            onMouseOut(obj) {
                this.$emit('mouseOutNode', obj)
            },

            onMouseOver(obj) {
                this.$emit('mouseOverNode', obj)
            },
        },
        watch: {
            scene() {
                this.printScene();
                return this;
            },
        }
    }
</script>

<style lang="scss">
    .widget-model-layers {
        position: absolute;
        top: 20px;
        left: 20px;
        min-width: 300px;
        max-width: 50%;
        min-height: 150px;
        max-height: calc(100% - 110px);
        padding: 0 0 20px;
        background-color: #ffffff;
        border-radius: var(--card-border-radius);
        overflow: hidden;

        display: flex;
        flex-direction: column;

        & > .icon-close {
            position: absolute;
            top: 16px;
            right: 16px;
            width: 15px;
            height: 15px;
            cursor: pointer;

            &:hover {
                color: var(--color-primary-hover);
            }
        }
    }

    .widget-model-layers-header {
        padding: 10px 20px 10px 20px;
        display: flex;
        align-items: center;
        column-gap: 5px;

        .btn {
            padding: 0;
        }
    }

    .widget-model-layers-body {
        padding: 0 30px 0 20px;
        overflow: auto;

        ul {
            list-style-type: none;
            width: min-content;
            margin-bottom: 0 !important;

            ul {
                padding-left: 10px !important;
            }

            &.paths {
                padding: 0;
                margin-bottom: 0;
            }
        }

        .path {
            white-space: nowrap;

            &:hover {
                color: var(--color-primary-hover);
            }
        }

        .path.no-children {
            list-style-type: square;
            list-style-position: inside;
            white-space: nowrap;
        }

        .path span.parent {
            display: none;
        }
    }

</style>
