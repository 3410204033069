/*jshint esversion: 6 */

// Import classes:
import AssetType                    from '@/Models/Asset/AssetType';
import DropdownOption               from '@/Utility/DropdownOption';
import DropdownOptionGroup          from '@/Utility/DropdownOptionGroup';
import { trans }                    from '@/Utility/Helpers';
import Command                      from '@/Models/UnitData/Commands/Command';
import CommandType                  from '@/Models/UnitData/Commands/CommandType';
import CommandTargetType            from '@/Models/UnitData/Commands/CommandTargetType';
import SceneObject                  from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObjectType              from '@/Models/UnitData/SceneObjects/SceneObjectType';
import TriggerType                  from '@/Models/UnitData/Triggers/TriggerType';
import Trigger                      from "@/Models/UnitData/Triggers/Trigger";
import UnitData                     from "@/Models/UnitData/UnitData";

/**
 * Get dropdown options for target scene object selection
 *
 * @param {Command} command
 * @returns {Array<DropdownOption>}
 */
export function getSceneObjectTargetDropdownOptionsForCommand(command: Command): Array<DropdownOption> {
    const commandType = command.commandType;
    const targetType = commandType.targetType;
    const unitData = command.parentUnitData;
    const currentScene = command.parentTrainingScene || null;
    const sceneObject = command.parentSceneObject || null;
    const options = new Array<DropdownOption>;
    if (sceneObject !== null && commandType !== null && commandType.allowTargetSelf === true)
    {
        // Apply type filter:
        const selfTargetObjects = filterSceneObjectsByTargetType(command, [sceneObject], targetType);
        if (selfTargetObjects.length === 1)
        {
            options[options.length] = new DropdownOption({
                caption: trans('authoring.target_this_' + sceneObject.type),
                disabled: false,
                value: Command.TargetSelf,
                icon: sceneObject.icon,
                referenceUid: sceneObject.uid,
            });
        }
    }

    // Filter global objects by the type of allowed targets:
    if (unitData.hasObjects)
    {
        const matchingGlobalObjects = filterSceneObjectsByTargetType(command, unitData.allGlobalObjects, targetType);
        const globalObjectsGroup = new DropdownOptionGroup({
            caption: trans('labels.global_objects'),
            isSeparator: true,
            collapsible: true,
            referenceUid: 'no-ref',
        });
        // @NOTE: Disabled since we always want to be able to collapse groups no matter how many options they have
        //globalObjectsGroup.collapsible = (matchingGlobalObjects.length > globalObjectsGroup.autoCollapseLimit);
        matchingGlobalObjects.filter(o => sceneObject === null || o.uid !== sceneObject.uid).forEach(o => {
            globalObjectsGroup.options[globalObjectsGroup.options.length] = new DropdownOption({
                caption: o.title,
                disabled: false,
                value: o.uid,
                icon: o.icon,
                referenceUid: o.uid,
            });
        });
        if (globalObjectsGroup.options.length >= 1)
        {
            options[options.length] = globalObjectsGroup;
        }
    }

    // Filter objects by the type of allowed targets:
    const scenes = (sceneObject && sceneObject.isGlobal === true) ? unitData.scenes : (currentScene !== null ? [currentScene] : []);
    scenes.filter(s => s.hasObjects).forEach(s => {
        const sceneObjects = filterSceneObjectsByTargetType(command, s.allSceneObjects, targetType);
        const optGroup = new DropdownOptionGroup({
            caption: s.indexAndTitleFormatted,
            isSeparator: true,
            collapsible: true,
            referenceUid: s.uid,    // For scene UID reference highlighting
        });
        // @NOTE: Disabled since we always want to be able to collapse groups no matter how many options they have
        //optGroup.collapsible = (sceneObjects.length > optGroup.autoCollapseLimit);
        sceneObjects.filter(o => sceneObject === null || o.uid !== sceneObject.uid).forEach(so => {
            optGroup.options[optGroup.options.length] = new DropdownOption({
                caption: so.title,
                disabled: false,
                value: so.uid,
                icon: so.icon,
                referenceUid: so.uid,
            });
        });
        if (optGroup.options.length >= 1)
        {
            options[options.length] = optGroup;
        }
    });

    return options;
}


/**
 * Get dropdown options for target scene selection
 *
 * @param {Command} command
 * @returns {Array<DropdownOption>}
 */
export function getSceneTargetDropdownOptionsForCommand(command: Command): Array<DropdownOption> {
    const parentUnitData = command.parentUnitData;
    const selectedScene = command.parentTrainingScene || parentUnitData.selectedScene || null;
    const scenes = parentUnitData.deselectedScenes || [];
    const options = new Array<DropdownOption>;
    const commandType = command.commandType;

    // Self:
    if (commandType !== null && commandType.allowTargetSelf === true)
    {
        options[options.length] = new DropdownOption({
            caption: trans('authoring.target_this_scene'),
            disabled: false,
            value: Command.TargetSelf,
            referenceUid: selectedScene?.uid || 'no-ref',
        });
    }

    // First:
    if (selectedScene === null || selectedScene.order !== 0)
    {
        options[options.length] = new DropdownOption({
            caption: trans('labels.first'),
            disabled: false,
            value: Command.TargetFirst,
            referenceUid: parentUnitData.scenes[0]?.uid || 'no-ref',
        });
    }

    // Previous:
    if (selectedScene === null || selectedScene.order > 0)
    {
        const previousIndex = selectedScene ? (parentUnitData.scenes.findIndex(s => s.uid === selectedScene.uid) - 1) : null;
        options[options.length] = new DropdownOption({
            caption: trans('labels.previous'),
            disabled: false,
            value: Command.TargetPrevious,
            referenceUid: parentUnitData.scenes[previousIndex]?.uid || 'no-ref',
        });
    }

    // Next and last:
    if (selectedScene === null || selectedScene.order < scenes.length)
    {
        const nextIndex = selectedScene ? (parentUnitData.scenes.findIndex(s => s.uid === selectedScene.uid) + 1) : null;
        options[options.length] = new DropdownOption({
            caption: trans('labels.next'),
            disabled: false,
            value: Command.TargetNext,
            referenceUid: parentUnitData.scenes[nextIndex]?.uid || 'no-ref',
        });
        options[options.length] = new DropdownOption({
            caption: trans('labels.last'),
            disabled: false,
            value: Command.TargetLast,
            referenceUid: (parentUnitData.hasScenes) ? parentUnitData.scenes[parentUnitData.scenes.length - 1]?.uid || 'no-ref' : 'no-ref',
        });
    }

    // Scene UIDs:
    let group = new DropdownOptionGroup({
        caption: trans('labels.by_name'),
        isSeparator: true,
        showGroupNameInCaption: false
    });
    scenes.forEach(s => group.options[group.options.length] = new DropdownOption({
        caption: s.title,
        disabled: s.selected,
        value: s.uid,
        referenceUid: s.uid,
    }));
    if (group.options.length >= 1)
    {
        options[options.length] = group;
    }

    // Scene indexes:
    group = new DropdownOptionGroup({
        caption: trans('labels.by_index'),
        isSeparator: true,
        showGroupNameInCaption: false
    });
    for (let i = 0; i < scenes.length; ++i)
    {
        group.options[group.options.length] = new DropdownOption({
            caption: scenes[i].indexFormatted,
            disabled: scenes[i].selected,
            value: '' + scenes[i].order,
            referenceUid: scenes[i].uid,
        });
    }
    if (group.options.length >= 1)
    {
        options[options.length] = group;
    }

    return options;
}

/**
 * Filter a list of scene objects by allowed target type
 */
export function filterSceneObjectsByTargetType(command: Command, sceneObjects: Array<SceneObject>, commandTargetType: CommandTargetType | null = null): Array<SceneObject> {
    if (commandTargetType === null)
    {
        return sceneObjects;
    }
    switch(commandTargetType.type)
    {
        // Filter objects according to the condition's target type:
        case CommandTargetType.Condition.type:
            return filterSceneObjectsByTargetType(command, sceneObjects, (command.condition) ? command.condition.targetType : null);

        // Filter only 3D model assets and 3D environment models:
        case CommandTargetType.Model3d.type:
            sceneObjects = sceneObjects.filter(o => o.typeOf(SceneObjectType.Assets.Model3D) || o.typeOf(SceneObjectType.Assets.EnvironmentModel3D));
            break;

        // Filter only AI enabled scene objects:
        case CommandTargetType.SceneObject_AI.type:
            sceneObjects = sceneObjects.filter(o => (
                (o.type === SceneObjectType.TypeOfAsset && o.subtype === AssetType.CharacterModel3D.type)
                || (o.type === SceneObjectType.TypeOfModule && o.subtype === SceneObjectType.Modules.Helper.subtype)
            ));
            break;

        // Filter only assets:
        case CommandTargetType.SceneObject_Asset.type:
            sceneObjects = sceneObjects.filter(o => o.type === SceneObjectType.TypeOfAsset);
            break;

        // Filter assets and hotspots:
        case CommandTargetType.SceneObject_AssetAndHotspot.type:
            sceneObjects = sceneObjects.filter(o => o.type === SceneObjectType.TypeOfAsset || o.type === SceneObjectType.TypeOfHotspot);
            break;

        // Filter only objects with behaviours:
        case CommandTargetType.SceneObject_Behaviour.type:
            sceneObjects = sceneObjects.filter(o => (o.supportsBehaviours) && o.hasBehaviours);
            break;

        // Filter only 3d characters:
        case CommandTargetType.SceneObject_CharacterModel3d.type:
            sceneObjects = sceneObjects.filter(o => o.type === SceneObjectType.TypeOfAsset && o.subtype === AssetType.CharacterModel3D.type);
            break;

        // Filter only hotspots:
        case CommandTargetType.SceneObject_Hotspot.type:
            sceneObjects = sceneObjects.filter(o => o.type === SceneObjectType.TypeOfHotspot);
            break;

        // Filter only modules:
        case CommandTargetType.SceneObject_Module.type:
            sceneObjects = sceneObjects.filter(o => o.type === SceneObjectType.TypeOfModule);

            // #PRDA-2417: Exclude overlay modules for "Deactivate Module" commands:
            if (command.commandType.type === CommandType.ModuleDeactivate.type)
            {
                sceneObjects = sceneObjects.filter(o => [
                    SceneObjectType.Modules.Intro.subtype,
                    SceneObjectType.Modules.Outro.subtype,
                    SceneObjectType.Modules.Overlay.subtype
                ].indexOf(o.subtype) === -1);
            }
            break;

        // Filter only objects with triggers:
        case CommandTargetType.Trigger.type:
            sceneObjects = sceneObjects.filter(o => (
                (typeof command.target === 'string' && o.uid === command.target) ||
                (typeof command.target_object === 'string' && o.uid === command.target_object) ||
                (o.triggers instanceof Array && o.triggers.length >= 1)
            ));
            // Exclude Helper modules for TriggerInvoke and TriggerCancel commands:
            if (command.type === CommandType.TriggerCancel.type || command.type === CommandType.TriggerInvoke.type)
            {
                sceneObjects = sceneObjects.filter(o => o.typeOf(SceneObjectType.Modules.Helper) === false);
            }
            // Use only Helper Module for HelperTriggerInvoke commands:
            else if (command.type === CommandType.HelperTriggerInvoke.type)
            {
                sceneObjects = sceneObjects.filter(o => o.typeOf(SceneObjectType.Modules.Helper) === true);
            }
            break;

        // Filter only variable modules:
        case CommandTargetType.Variable.type:
            sceneObjects = sceneObjects.filter(o => o.typeOf(SceneObjectType.Modules.Variable) === true);
            break;
    }
    return sceneObjects;
}

/**
 * Get caption for trigger on scene object
 *
 * @param sceneObject
 * @param trigger
 * @param showSceneObjectTitle Adds (or uses completely) the title of the attached scene object to the caption.
 * @param useShortTriggerNames Uses a shorter version of the trigger name; useful when scene object title will be added, too.
 * @returns {String}
 */
export function getCaptionForSceneObjectTrigger(sceneObject: SceneObject, trigger: Trigger, showSceneObjectTitle: Boolean = true, useShortTriggerNames: Boolean = true): string | null {
    let triggerName = '';

    if (trigger.triggerType !== null) {
        switch (trigger.triggerType.type) {
            case TriggerType.OnKeyPress.type:
            case TriggerType.OnSpectatorKeyPress.type:
                triggerName = (trigger.value !== null && trigger.value.key !== null) ?
                    trigger.triggerType.title + ': ' + trigger.value.key :
                    trigger.triggerType.title;
                break;
            case TriggerType.OnConnectionPathComplete.type:
                const pathIndex = (sceneObject.hasConnections === true) ?
                    (sceneObject.connections.findIndex(c => c.name === trigger.value) + 1) : 0;
                const indexLabel = (pathIndex >= 1) ? pathIndex : '';
                if (useShortTriggerNames) {
                    triggerName = trans('labels.path_index', {index: indexLabel});
                } else {
                    triggerName = trans('triggers.connection_path_complete_index', {index: indexLabel});
                }
                break;
            case TriggerType.OnConnectionPathCompleteAll.type:
                triggerName = useShortTriggerNames ? trans('labels.all_paths') : trigger.triggerType.title;
                break;
            case TriggerType.OnConnectionPathCompleteUnknown.type:
                triggerName = useShortTriggerNames ? trans('labels.unknown_path') : trigger.triggerType.title;
                break;
            case TriggerType.OnCue.type:
                triggerName = trigger.title;
                break;
            default:
                break;
        }
    }

    if (triggerName === '' || triggerName == null) {
        return getTitleFromSceneObject(sceneObject);
    }

    if (showSceneObjectTitle) {
        return triggerName + ' - ' + getTitleFromSceneObject(sceneObject);
    } else {
        return triggerName;
    }
}

/**
 * Get title from scene object
 */
export function getTitleFromSceneObject(sceneObject: SceneObject): string | null {
    if (sceneObject.type === SceneObjectType.TypeOfHotspot && sceneObject.title === null) {
        return trans('labels.hotspot');
    }

    if (typeof sceneObject.title === 'string') {
        return sceneObject.title;
    }

    return (sceneObject.asset instanceof Asset) ? sceneObject.asset.title || null : null;
}

/**
 * Uses scene object as target?
 */
export function usesSceneObject(command: Command): boolean {
    return (command && command.commandType.targetType !== null && [
        CommandTargetType.SceneObject,
        CommandTargetType.SceneObject_Asset,
        CommandTargetType.SceneObject_AssetAndHotspot,
        CommandTargetType.SceneObject_Hotspot,
        CommandTargetType.SceneObject_Module,
    ].map(c => c.type).indexOf(command.commandType.targetType.type) >= 0);
}

/**
 * Uses a 3D model as target scene object?
 */
export function usesSceneObjectModel3d(command: Command, unitData: UnitData): boolean {
    if (usesSceneObject(command) && command.target !== null)
    {
        // Find the target object for this command:
        let targetObj: SceneObject | null = null;
        if (command.target === Command.TargetSelf)
        {
            targetObj = unitData.allSceneObjects.find(o => o instanceof SceneObject && o.hasCommand(command)) || null;
        }
        else
        {
            targetObj = unitData.findSceneObjectByUid(command.target);
        }

        if (targetObj !== null && targetObj.type === AssetType.type && (targetObj.subtype === AssetType.Model3D.type || targetObj.subtype === AssetType.EnvironmentModel3D.type))
        {
            return true;
        }
    }
    return false;
}

/**
 * Get the panel component name for the command
 */
export function getPanelComponentName(command: Command): string | null {
    const commandComponentMappings = {
        [CommandType.AIKnowledge.type]:         'PanelCommandAIKnowledge',
        [CommandType.AIPrompt.type]:            'PanelCommandAIPrompt',
        [CommandType.BehaviourChange.type]:     'PanelCommandBehaviourChange',
        [CommandType.Condition.type]:           'PanelCommandCondition',
        [CommandType.Control3dAnimation.type]:  'PanelCommandControl3dAnimation',
        [CommandType.ControlCharacterAnimation.type]: 'PanelCommandControlCharacterAnimation',
        [CommandType.Fade.type]:                'PanelCommandFade',
        [CommandType.Feedback.type]:            'PanelCommandFeedback',
        [CommandType.HelperAnimationPlay.type]: 'PanelCommandHelperAnimationPlay',
        [CommandType.HelperGlowChange.type]:    'PanelCommandHelperGlowChange',
        [CommandType.HelperKnowledge.type]:     'PanelCommandHelperKnowledge',
        [CommandType.HelperPrompt.type]:        'PanelCommandHelperPrompt',
        [CommandType.HelperSpeak.type]:         'PanelCommandHelperSpeak',
        [CommandType.HelperTriggerInvoke.type]: 'PanelCommandHelperTriggerInvoke',
        [CommandType.HelperWaypointGoTo.type]:  'PanelCommandHelperWaypointGoTo',
        [CommandType.Hide.type]:                'PanelCommandShowHide',
        [CommandType.ImageShow.type]:           'PanelCommandImageShow',
        [CommandType.InputStyle.type]:          'PanelCommandInputStyle',
        [CommandType.LearningRecord.type]:      'PanelCommandLearningRecord',
        [CommandType.ModuleActivate.type]:      'PanelCommandActivateModule',
        [CommandType.ModuleDeactivate.type]:    'PanelCommandDeactivateModule',
        [CommandType.SceneChange.type]:         'PanelCommandChangeScene',
        [CommandType.Script.type]:              'PanelCommandScript',
        [CommandType.Show.type]:                'PanelCommandShowHide',
        [CommandType.SoundPlay.type]:           'PanelCommandSoundPlay',
        [CommandType.TextShow.type]:            'PanelCommandShowText',
        [CommandType.TriggerCancel.type]:       'PanelCommandTriggerCancel',
        [CommandType.TriggerInvoke.type]:       'PanelCommandTriggerInvoke',
        [CommandType.UnitExit.type]:            "PanelCommandUnitExit",
        [CommandType.UnitReset.type]:           "PanelCommandUnitReset",
        [CommandType.VariableOperation.type]:   'PanelCommandVariableOperation',
        [CommandType.VideoPlay.type]:           'PanelCommandVideoPlay',
        [CommandType.Wait.type]:                'PanelCommandWait',
        [CommandType.WorldAnchorReset.type]:    "PanelCommandWorldAnchorReset",
    };

    return commandComponentMappings.hasOwnProperty(command.type) ? commandComponentMappings[command.type] : null;
}
