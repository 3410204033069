<template>
    <main id="layout-main" :data-loading="isLoading" :data-saving="isSaving" v-shortcuts.global>

        <PageHeader
            :page-title="pageHeadline"
            :page-subtitle="pageSubtitle"
            :buttons="headerButtons"
            :labels="pageHeaderLabels"
        />

        <div id="layout-content">
            <div ref="content" id="content" v-focusable>

                <GridScenes
                    v-if="selectedUnitRevision"
                    :key="'GridScenes'+renderTimestamp"
                    :unit-data="selectedUnitRevision.unit_data"
                    @click-outside-cell="onClickOutsideCell"
                    @change="onChangeUnit"
                />
            </div>
            <aside id="layout-inspector" ref="inspector" :class="{ 'open': showInspector }">
                <Inspector />
            </aside>
            <aside id="layout-sidepanel" :class="{ 'layout-sidepanel': true, 'open': showSidePanel }">
                <SidepanelSceneObjects
                    :is-visible="showSceneObjects"
                    :objects="filteredAssetsAndSceneObjectTypes"
                    :disable-virtual-scrolling="selectedUnit && selectedUnit.isUserGuidingUnit"
                />
                <SidepanelTriggers
                    :is-visible="showTriggers"
                    :triggers="triggers"
                />
            </aside>

            <!-- Asset create dialog -->
            <DialogCreateAsset :policies="assetPoliciesAllowedForNewAssets" />

            <!-- Asset replace dialog -->
            <DialogReplaceAsset />

            <!-- Revisions History -->
            <DialogUnitHistory
                v-if="canRestoreUnit"
                :unit="selectedUnit"
                @select="onSelectUnitRevisionFromHistory"
            />
            <DialogRestoreUnitFromHistory/>
            <DialogCreateUnitFromHistory/>

            <!-- Modal dialog for incompatible unit -->
            <DialogApplyCancel
                class="dialog-unit-incompatible"
                event-type="MODAL_UNIT_UPDATE_INCOMPATIBLE"
                :title="trans('modals.unit_incompatible.title')"
                :description="descriptionTextUpdateIncompatibleUnit"
                :applyText="trans('modals.unit_incompatible.apply')"
                :cancelText="null"
            />

            <!-- Modal dialog for releasing the unit -->
            <DialogReleaseUnit/>

            <!-- Modal dialogs for unit changes on the server -->
            <DialogApplyCancel
                event-type="MODAL_UNIT_CHANGED_ON_SERVER"
                :title="trans('modals.unit_changed_on_server.title')"
                :description="trans('modals.unit_changed_on_server.description')"
                :apply-text="trans('modals.unit_changed_on_server.apply')"
                :cancel-text="trans('modals.unit_changed_on_server.cancel')"
            />
            <DialogApplyCancel
                event-type="MODAL_UNIT_OVERRIDE_ON_SERVER"
                :title="trans('modals.override_unit_on_server.title')"
                :description="trans('modals.override_unit_on_server.description')"
                :apply-text="trans('modals.override_unit_on_server.apply')"
                :cancel-text="trans('modals.override_unit_on_server.cancel')"
            />

            <DialogSaveUnitChanges/>
            <DialogLoading/>
            <DialogSaving/>
            <DialogNotification/>
            <ModalAssetPreview/>
        </div>
    </main>
</template>

<script>

    // Import VueJS components:
    import Inspector from '@/Vue/Inspector/Inspector.vue';
    import DialogApplyCancel from '@/Vue/Modals/DialogApplyCancel.vue';
    import DialogCreateAsset from '@/Vue/Modals/DialogCreateAsset';
    import DialogReplaceAsset from "@/Vue/Modals/DialogReplaceAsset.vue";
    import DialogNotification from '@/Vue/Modals/DialogNotification.vue';
    import DialogLoading from '@/Vue/Modals/DialogLoading.vue';
    import DialogSaving from '@/Vue/Modals/DialogSaving.vue';
    import GridScenes from '@/Vue/Authoring/GridScenes.vue';
    import ModalAssetPreview from '@/Vue/Modals/ModalAssetPreview.vue';
    import SidepanelSceneObjects from '@/Vue/Sidepanel/SidepanelSceneObjects.vue';
    import SidepanelTriggers from '@/Vue/Sidepanel/SidepanelTriggers.vue';
    import DialogUnitHistory from '@/Vue/Modals/DialogUnitHistory.vue';
    import DialogRestoreUnitFromHistory from '@/Vue/Modals/DialogRestoreUnitFromHistory.vue';
    import DialogCreateUnitFromHistory from '@/Vue/Modals/DialogCreateUnitFromHistory.vue';
    import DialogSaveUnitChanges from '@/Vue/Modals/DialogSaveUnitChanges.vue';
    import DialogReleaseUnit from "@/Vue/Modals/DialogReleaseUnit.vue";

    // Import classes:
    import moment from 'moment';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import {permission, route, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import EventType from '@/Utility/EventType';
    import PageHeaderButton from '@/Utility/PageHeaderButton';
    import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
    import TriggerType from '@/Models/UnitData/Triggers/TriggerType';
    import UnitPermissionPolicy, {UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
    import StatusLabelConfig from '@/Utility/StatusLabelConfig';
    import Unit from '@/Models/Unit/Unit';
    import UnitRevision from '@/Models/Unit/UnitRevision';
    import AssetPolicy from "@/Models/Asset/AssetPolicy";
    import {inject} from "vue";
    import {featureRepositoryKey} from "@/Vue/Bootstrap/InjectionKeys";
    import KeyboardKey from "@/Utility/KeyboardKey.js";

    export default {
        name: 'Authoring',
        components: {
            DialogReleaseUnit,
            DialogApplyCancel,
            DialogCreateAsset,
            DialogReplaceAsset,
            DialogNotification,
            DialogLoading,
            DialogSaving,
            DialogUnitHistory,
            DialogRestoreUnitFromHistory,
            DialogCreateUnitFromHistory,
            DialogSaveUnitChanges,
            GridScenes,
            Inspector,
            ModalAssetPreview,
            SidepanelSceneObjects,
            SidepanelTriggers,
        },
        props: {
            initialUnitUid: {                           // ID of the initially selected unit
                type: String,
                default: null
            },
        },
        data() {
            return {
                assetService: this.$assetService,       // Global AssetService instance
                /** @type {UnitService} */
                unitService: this.$unitService,         // Global UnitService instance
                featureRepository: inject(featureRepositoryKey),
                pageTitle: '',                          // Page title to be displayed in the header
                scrollPos: {                            // Content scroll position
                    x: 0,
                    y: 0
                },
                scrollDelayForHighlighting: null,       // Timeout delay for scrolling referenced object into view
                showInspector: false,                   // Whether the inspector is visible
                showSidePanel: false,                   // Whether the side panel is visible
                showSceneObjects: false,                // Whether the scene objects are visible in the side panel
                showTriggers: false,                    // Whether the triggers are visible in the side panel
                assetsAndSceneObjectTypes: [            // List of assets and SceneObjectTypes for the side panel

                    SceneObjectType.Group,

                    // @NOTE: Assets are being added through the AssetService
                    SceneObjectType.Assets.Text,

                    //SceneObjectType.Hotspots.Area,     // @NOTE: Disabled for #PRDA-2484
                    SceneObjectType.Hotspots.Generic,
                    SceneObjectType.Hotspots.Transparent,

                    SceneObjectType.Modules.Connection,
                    //SceneObjectType.Modules.Input,    // @deprecated: Disabled for #PRDA-12501
                    //SceneObjectType.Modules.Intro,    // @deprecated: Disabled for #PRDA-12536
                    SceneObjectType.Modules.Helper,
                    SceneObjectType.Modules.Keypad,
                    //SceneObjectType.Modules.Outro,    // @deprecated: Disabled for #PRDA-12536
                    SceneObjectType.Modules.Overlay,
                    //SceneObjectType.Modules.Script,   // @NOTE: Disabled until developed further #PRDA-7515
                    SceneObjectType.Modules.Universal,
                    SceneObjectType.Modules.Variable,
                    //SceneObjectType.Modules.Videowall, // @NOTE: Disabled for #PRDA-2484
                ],
                selectedUnitRevision: null,     // The selected unit revision that is being edited
                currentRefObject: null,         // DOM element that the mouse cursor is currently over
                currentRefUids: [],             // List of UIDs from currently highlighted objects
                lastShiftKeyState: false,       // Whether the [SHIFT] has been pressed or released during mouse move
                domStyleTag: null,              // Global <style> tag for dynamic CSS rules
                unitHasChanged: false,          // Whether the unit has unsaved changes
                timerCheckForServerChanges: null,   // Timer for checking unit changes on the server
                timerDurationServerChanges: 30, // Timer duration in seconds for checking changes on the server
                checkForUnitChangesInBackground: false,  // Whether to show a loading overlay or run the check invisible in the background
                afterSaveCallback: null,        // Callback method for the save changes dialog's apply/dismiss buttons (after the unit has been saved)
                triggers: TriggerType.all,      // List of available triggers
                renderTimestamp: null,          // Helper for forcing re-rendering
                keepAliveInterval: null,        // Interval returned from SetInterval()
                keepAliveIntervalTimeout: 600,  // Timeout between keep alive requests in seconds
                shortcuts: new Map([        // Shortcut mapping to methods
                    ['Save.prevent', this.saveUnit],
                    ['Backspace.prevent', null],    // Prevent going back in browser history
                    ['Reload', this.onShortcutReload], // Catch page reloading
                    ['Publish.prevent', this.onShortcutRelease],    // Release unit
                ]),
                events: new Map([
                    [EventType.INSPECTOR_SCENE_OBJECT_UPDATED, this.onUpdateInspectorSceneObject],
                    [EventType.INSPECTOR_UNIT_UPDATED, this.onUpdateInspectorUnit],
                    [EventType.MODAL_SAVE_UNIT_CHANGES_CANCEL, this.onCancelSaveUnitChanges],
                    [EventType.MODAL_SAVE_UNIT_CHANGES_APPLY, this.saveUnit],
                    [EventType.MODAL_SAVE_UNIT_CHANGES_DISMISS, this.onDismissSaveUnitChanges],
                    [EventType.MODAL_UNIT_CHANGED_ON_SERVER_CANCEL, this.onCancelUnitChangedOnServer],
                    [EventType.MODAL_UNIT_CHANGED_ON_SERVER_APPLY, this.onApplyUnitChangedOnServer],
                    [EventType.MODAL_UNIT_OVERRIDE_ON_SERVER_CANCEL, this.onCancelUnitOverrideOnServer],
                    [EventType.MODAL_UNIT_OVERRIDE_ON_SERVER_APPLY, this.onApplyUnitOverrideOnServer],
                    [EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_APPLY, this.onApplyUnitOverrideOnServer],
                    [EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_AS_NEW_UNIT, this.onRestoreFromHistoryAsNewUnit],
                    [EventType.MODAL_UNIT_CREATE_FROM_HISTORY_APPLY, this.onApplyNewUnitFromHistory],
                    [EventType.MODAL_UNIT_UPDATE_INCOMPATIBLE_APPLY, this.onApplyUnitIncompatibleUnitFromHistory],
                    [EventType.MODAL_RELEASE_UNIT_APPLY, this.onApplyReleaseUnit],
                    [EventType.SIDEPANEL_HIDE, this.onHideSidePanel],
                    [EventType.SIDEPANEL_SCENEOBJECTS_SHOW, this.onShowSceneObjectsSidePanel],
                    [EventType.SIDEPANEL_SCENEOBJECTS_HIDE, this.onHideSceneObjectsSidePanel],
                    [EventType.SIDEPANEL_SCENEOBJECTS_SELECT, this.onHideSceneObjectsSidePanel],
                    [EventType.SIDEPANEL_SCENEOBJECTS_CANCEL, this.onHideSceneObjectsSidePanel],
                    [EventType.SIDEPANEL_TRIGGERS_SHOW, this.onShowTriggersSidePanel],
                    [EventType.SIDEPANEL_TRIGGERS_HIDE, this.onHideTriggersSidePanel],
                    [EventType.SIDEPANEL_TRIGGERS_SET, this.setTriggersSidePanel],
                    [EventType.SIDEPANEL_TRIGGERS_SELECT, this.onHideTriggersSidePanel],
                    [EventType.SIDEPANEL_TRIGGERS_CANCEL, this.onHideTriggersSidePanel],
                    [EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav],
                    [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
                ]),
            }
        },
        mounted() {

            this.domStyleTag = document.createElement('style');
            document.body.append(this.domStyleTag);

            // Add global events:
            this.$globalEvents.addEvent('click.global.authoring', this.onClickGlobal);
            document.addEventListener('mousemove', this.onHighlightReferencedObjects);
            document.addEventListener('keyup', this.onHighlightReferencedObjects);
            document.addEventListener('keydown', this.onHighlightReferencedObjects);
            document.addEventListener('focusin', this.onHighlightReferencedObjects);
            this.events.forEach((value, key) => {
                this.$globalEvents.on(key, value);
            });

            // Add keep alive interval:
            this.keepAliveInterval = setInterval(this.keepSessionAlive, this.keepAliveIntervalTimeout * 1000);

            // Fetch assets:
            const assetsLoaded = permission(Permission.AssetsRead()) ? this.assetService.fetchAssets().then(this.onSuccessFetchAssets).catch(this.onErrorApi) : Promise.resolve();

            // Fetch only the current unit:
            if (this.initialUnitUid !== null && this.initialUnitUid !== 'null')
            {
                // Wait for assets to be loaded:
                assetsLoaded.then(() => {
                    this.fetchAndSelectUnit(new Unit({uid: this.initialUnitUid}));
                });
            }
        },
        beforeUnmount() {
            // Remove global events:
            this.$globalEvents.removeEvent('click.global.authoring', this.onClickGlobal);
            document.removeEventListener('mousemove', this.onHighlightReferencedObjects);
            document.removeEventListener('keyup', this.onHighlightReferencedObjects);
            document.removeEventListener('keydown', this.onHighlightReferencedObjects);
            document.removeEventListener('focusin', this.onHighlightReferencedObjects);
            this.events.forEach((value, key) => {
                this.$globalEvents.off(key, value);
            });

            this.domStyleTag.remove();

            // Clear keep alive interval:
            clearInterval(this.keepAliveInterval);
        },
        computed: {

            assetPoliciesAllowedForNewAssets() {
                return AssetPolicy
                    .allPoliciesUserIsAllowedToCreateInTenant(window.currentUser, window.currentUser.tenant)
                    .filter(policy => {
                        if (this.selectedUnit?.policy) {
                            return UnitPermissionPolicy.getPolicyForType(this.selectedUnit.policy).isAssetPolicyAllowed(policy.type);
                        }

                        return false;
                    })
                    .map(policy => {
                        return policy.type;
                    });
            },

            /**
             * @returns {Unit|null}
             */
            selectedUnit() {
                return this.selectedUnitRevision ? this.selectedUnitRevision.parentUnit || null : null;
            },

            /**
             * @returns {UnitRevision|null}
             */
            latestUnitRevision() {
                return this.selectedUnit ? this.selectedUnit.latestRevision || null : null;
            },

            /**
             * @returns {Boolean}
             */
            selectedRevisionIsLatest() {
                return (this.selectedUnit && this.selectedUnitRevision && this.selectedUnitRevision.uid === this.selectedUnit.latest_revision_uid);
            },

            /**
             * @returns {Boolean}
             */
            selectedRevisionIsFromHistory() {
                return !this.selectedRevisionIsLatest;
            },

            /**
             * @returns {Boolean}
             */
            canReleaseUnit() {
                return (
                    this.selectedUnit !== null
                    && this.selectedUnitRevision !== null
                    && !this.isLoading
                    && !this.isSaving
                    && !this.unitHasChanged
                    && this.currentUnitIsNotReleased
                    && this.selectedRevisionIsLatest
                    && this.$gate.allows(Permission.ability(Permission.UnitsRelease()), this.selectedUnit)
                );
            },

            /**
             * Determine if the current user can restore the selected unit from an older revision
             * @returns {Boolean}
             */
            canRestoreUnit() {
                return this.selectedUnit !== null && this.$gate.allows(Permission.ability(Permission.UnitsRestore()), this.selectedUnit);
            },

            /**
             * @returns {Boolean}
             */
            canSaveUnit() {
                return (
                    this.selectedUnit !== null
                    && this.selectedUnitRevision !== null
                    && !this.isLoading
                    && !this.isSaving
                    && this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.selectedUnit)
                    && (
                        this.unitHasChanged
                        || this.selectedRevisionIsFromHistory
                    )
                );
            },

            /**
             * @returns {Boolean}
             */
            canUpdateUnit() {
                return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.selectedUnit);
            },

            /**
             * @returns {string}
             */
            descriptionTextUpdateIncompatibleUnit() {
                return trans(
                    this.selectedUnit && this.selectedUnit.isReleased
                    ? 'modals.unit_incompatible.description_released'
                    : 'modals.unit_incompatible.description'
                );
            },

            /**
             * Filter the available scene objects and assets
             * @returns {any[]}
             */
            filteredAssetsAndSceneObjectTypes() {
                if (this.selectedUnit === null) {
                    return [];
                }

                const unitPolicy = this.selectedUnit.parsedPolicy;

                const assetsForPolicy = this.assetService.assets
                    .filter(asset => unitPolicy.isAssetPolicyAllowed(asset.policy));

                return this.assetsAndSceneObjectTypes.concat(assetsForPolicy);
            },

            /**
             * @returns {Boolean}
             */
            currentUnitIsDraft() {
                return (
                    this.selectedUnit !== null
                    && this.selectedUnit.isDraft
                );
            },

            /**
             * @returns {Boolean}
             */
            currentUnitIsNotReleased() {
                return (
                    this.selectedUnit !== null
                    && (
                        this.currentUnitIsDraft
                        || this.selectedUnit.hasUnreleasedChanges
                    )
                );
            },

            /**
             * List of button configurations for the page header
             */
            headerButtons() {
                return (this.selectedUnit === null) ? {} : {
                    unitHistory: new PageHeaderButton({
                        disabled: this.isLoading || this.isSaving,
                        visible: this.canRestoreUnit,
                        caption: trans('labels.history'),
                        tooltip: 'buttons.units.history',
                        icon: 'icon_history',
                        callback: this.showHistory
                    }),
                    saveUnit: new PageHeaderButton({
                        disabled: !this.canSaveUnit,
                        caption: trans('labels.save'),
                        tooltip: 'buttons.units.save',
                        icon: (this.unitHasChanged || this.selectedRevisionIsFromHistory) ? 'icon_save' : 'icon_saved',
                        callback: this.saveUnit
                    }),
                    releaseUnit: new PageHeaderButton({
                        disabled: !this.canReleaseUnit,
                        caption: trans('labels.release'),
                        tooltip: this.releaseUnitButtonTooltip,
                        callback: this.onClickReleaseUnit,
                        style: 'button',
                    })
                };
            },

            /**
             * Header page title
             *
             * @returns {Boolean}
             */
            pageHeadline() {
                return (this.pageTitle !== '' && this.pageTitle !== null) ? this.pageTitle : null;
            },

            /**
             * @returns {null|String}
             */
            pageSubtitle() {
                if (this.selectedUnitRevision === null) {
                    return null;
                }

                const revision = this.selectedUnitRevision;
                const authorName = revision.owner ? revision.owner.fullName : trans('labels.unknown_author');
                return `${moment(revision.updated_at).format(trans('courses.edit.header.revision_date_format'))} ${trans('labels.by')} ${authorName}`;
            },

            /**
             * @returns {Array<StatusLabelConfig>}
             */
            pageHeaderLabels() {
                const labels = [];

                if (this.selectedUnit === null || this.selectedUnitRevision === null) {
                    return [];
                }

                // From History
                if (this.selectedRevisionIsFromHistory) {
                    labels.push(
                        new StatusLabelConfig({
                            icon: 'icon_history',
                            caption: 'from_history',
                            tooltip: 'from_history_tooltip'
                        })
                    );
                }

                // Policy (if non-standard)
                if (this.selectedUnit.policy !== UnitPermissionPolicyStandard.type) {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'policy',
                            caption: this.selectedUnit.policy
                        })
                    );
                }

                // Formerly Released
                if (this.selectedRevisionIsFromHistory) {
                    if (this.selectedUnitRevision.isReleased) {
                        labels.push(
                            new StatusLabelConfig({
                                type: this.selectedUnitRevision.uid === this.selectedUnit.latest_released_revision_uid ? 'status' : 'disabled',
                                caption: 'released'
                            })
                        );
                    }

                // Draft
                } else if (this.currentUnitIsDraft) {
                    labels.push(
                        new StatusLabelConfig({
                            caption: 'draft'
                        })
                    );

                // Unreleased changes
                } else if (this.currentUnitIsNotReleased) {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'notification',
                            caption: 'unreleased_changes'
                        })
                    );

                // Released
                } else {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'status',
                            caption: 'released'
                        })
                    );
                }

                // Incompatible
                if (!this.selectedUnitRevision.isCompatible)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'warning',
                            caption: 'incompatible_unit',
                            tooltip: 'incompatible_unit_save_tooltip',
                        })
                    );
                }

                return labels;
            },

            /**
             * Get the tooltip for the release button. Changes based on the unit's current state.
             *
             * @returns {string}
             */
            releaseUnitButtonTooltip() {

                if (this.selectedUnit === null || this.selectedUnitRevision === null) {
                    return '';
                }

                if (this.unitHasChanged || this.selectedRevisionIsFromHistory) {
                    return 'buttons.units.unit_has_unsaved_changes';
                }

                if (
                    this.selectedUnit.isReleased
                    && !this.selectedUnit.hasUnreleasedChanges
                ) {
                    return 'buttons.units.unit_is_already_released';
                }

                return 'buttons.units.release';
            },

            /**
             * Loading state
             *
             * @returns {Boolean}
             */
            isLoading() {
                if (this.assetService.isLoading || this.unitService.isLoading)
                {
                    // Show the loading status overlay only if it should block user interaction:
                    if (this.checkForUnitChangesInBackground === false)
                    {
                        this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    }
                    // Disable the timer for checking server changes:
                    this.disableCheckUnitServerChanges();
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
                return false;
            },

            /**
             * Saving state
             *
             * @returns {Boolean}
             */
            isSaving() {
                if (this.unitService.isSaving)
                {
                    this.disableCheckUnitServerChanges();
                    this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                return false;
            }
        },
        methods: {

            /**
             * Keep the current session alive by sending a request
             */
            keepSessionAlive()
            {
                axios.get(route('keepAlive'))
                    .then(() => {})
                    .catch((error) => {
                        if (error.response.status === 401)
                        {
                            console.error('Authoring->keepSessionAlive(): User is unauthenticated. Redirecting to login...');
                            // @NOTE: We do not have permission to save a unit anymore so we discard the changes.
                            this.unitHasChanged = false;
                            window.location = route('logout');
                        } else {
                            console.error('Authoring->keepSessionAlive(): Failed to keep session alive.', error);
                        }
                    });
                return this;
            },

            /**
             * Check for unit changes on the server
             *
             * @param {Boolean} inBackground
             */
            checkUnitServerChanges(inBackground = true)
            {
                // Clear the timer at first:
                this.disableCheckUnitServerChanges();
                this.checkForUnitChangesInBackground = inBackground;
                this.unitService
                    .fetchUnitUpdatedAt(this.selectedUnit)
                    .then(date => {
                        if (date !== null && moment(date).isAfter(this.selectedUnit.updated_at))
                        {
                            return this.unitService
                                .fetchUnit(this.selectedUnit)
                                .then(this.onSuccessHasUnitChangedOnServer)
                                .catch(this.onErrorHasUnitChangedOnServer);
                        }
                        // Enable timer again:
                        this.checkForUnitChangesInBackground = inBackground;
                        this.enableCheckUnitServerChanges();
                    })
                    .catch(this.onErrorHasUnitChangedOnServer);
                return this;
            },

            /**
             * Disable timer for checking unit changes on the server
             */
            disableCheckUnitServerChanges()
            {
                if (this.timerCheckForServerChanges !== null)
                {
                    window.clearTimeout(this.timerCheckForServerChanges);
                    this.timerCheckForServerChanges = null;
                    this.checkForUnitChangesInBackground = true;
                }
                return this;
            },

            /**
             * Enable timer for checking unit changes on the server
             */
            enableCheckUnitServerChanges()
            {
                // Clear the timer at first:
                this.disableCheckUnitServerChanges();
                // Do nothing if no unit is selected or a unit from the history is being edited:
                if (this.selectedUnit === null || this.selectedRevisionIsFromHistory)
                {
                    return this;
                }
                // Create new timer that checks for changes:
                this.timerCheckForServerChanges = window.setTimeout(() => {
                    this.checkUnitServerChanges();
                }, this.timerDurationServerChanges * 1000);
                return this;
            },

            /**
             * Success handler for refreshing the selected unit
             *
             * @param {Unit} unit
             */
            onSuccessHasUnitChangedOnServer(unit) {
                //console.log('onSuccessHasUnitChangedOnServer', unit);
                this.checkForUnitChangesInBackground = true;
                if (this.selectedUnit === null)
                {
                    return this;
                }
                // Show modal dialog if the unit has changed on the server:
                // @TODO: Only check for newer date instead of a differing date?
                if (false === moment(unit.updated_at).isSame(this.selectedUnit.updated_at))
                {
                    // Clear after save callback:
                    if (this.afterSaveCallback !== null)
                    {
                        this.afterSaveCallback = null;
                        this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_HIDE);
                    }
                    this.$globalEvents.emit(EventType.MODAL_UNIT_CHANGED_ON_SERVER_SHOW, unit);
                }
                else
                {
                    // Enable timer again if there are no changes:
                    this.enableCheckUnitServerChanges();
                }
                return this;
            },

            /**
             * Error handler for refreshing the selected unit
             *
             * @param {String} error
             */
            onErrorHasUnitChangedOnServer(error) {
                //console.log('onErrorHasUnitChangedOnServer', error);
                this.checkForUnitChangesInBackground = true;
                return this.onErrorApi(error);
            },

            /**
             * Cancel click handler for save changes dialog
             *
             * @param {Unit} unit
             */
            onCancelUnitChangedOnServer(unit) {
                // @NOTE: Disabled to save immediately instead #PRDA-5032
                //this.$root.showNotification(trans('modals.unit_changed_on_server.external_changes_discarded'), trans('modals.unit_changed_on_server.external_changes_discarded_text'));
                // Force saving:
                this.onApplyUnitOverrideOnServer(this.selectedUnit.latestRevision);
                return this;
            },

            /**
             * Apply click handler for save changes dialog
             *
             * @param {Unit} unit
             */
            onApplyUnitChangedOnServer(unit) {
                // Clear local changes:
                this.unitHasChanged = false;
                this.afterSaveCallback = null;
                // Reload the updated unit from the server:
                unit.fetched_at = null;
                this.fetchAndSelectUnit(unit);
                this.$root.showNotification(trans('modals.unit_changed_on_server.local_changes_discarded'), trans('modals.unit_changed_on_server.local_changes_discarded_text'), true, true);
                return this;
            },

            /**
             * Fetch and select a given unit
             *
             * @param {Unit} unit
             */
            fetchAndSelectUnit(unit) {
                // Stop checking for unit changes:
                this.disableCheckUnitServerChanges();
                // Fetch the latest data from server if a certain time has passed since the last fetch:
                if (unit.fetched_at === null || moment(unit.fetched_at).isBefore(moment().subtract(this.timerDurationServerChanges, 'seconds')))
                {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    // Cancel any ongoing requests and then refresh the unit data:
                    this.unitService.cancelRequests().then(() => {
                        this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                        this.unitService
                            .fetchUnit(unit)
                            .then((fetchedUnit) => {
                                // Use the updated unit from the server:
                                this.selectUnitOrRevision(fetchedUnit, true);
                            })
                            .catch(this.onErrorApi);
                    }, this);
                }
                else
                {
                    this.selectUnitOrRevision(unit, true);
                }
                return this;
            },

            /**
             * Select a unit with specific revision
             *
             * @param {Unit|UnitRevision} unitOrRevision
             * @param {Boolean} force           // Whether to force the selection
             */
            selectUnitOrRevision(unitOrRevision, force = false) {

                // Reset scroll position:
                this.scrollPos.x = 0;
                this.scrollPos.y = 0;

                // Reset selections and hide UI elements:
                if (unitOrRevision === null) {
                    this.selectedUnitRevision = null;
                    this.showInspector = false;
                    this.unitHasChanged = false;
                    return this;
                }

                let unitToSelect = (unitOrRevision instanceof Unit) ? unitOrRevision : unitOrRevision.parentUnit || null;
                const revisionToSelect = (unitOrRevision instanceof UnitRevision) ? unitOrRevision : unitOrRevision.latestRevision || null;

                if (unitToSelect === null || revisionToSelect === null) {
                    throw new Error('Unable to select unit or revision because of invalid data.');
                }

                // Only select if it's a different revision:
                if (
                    force === true ||
                    this.selectedUnit === null ||
                    this.selectedUnitRevision === null ||
                    this.selectedUnit.uid !== unitToSelect.uid ||
                    this.selectedUnitRevision.uid !== revisionToSelect.uid ||
                    !moment(this.selectedUnit.updated_at).isSame(unitToSelect.updated_at)
                ) {
                    // Prevent losing unsaved changes:
                    if (
                        this.unitHasChanged === true &&
                        this.canUpdateUnit
                    ) {
                        this.afterSaveCallback = () => {
                            this.selectUnitOrRevision(unitOrRevision, force);
                        };
                        this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_SHOW, this.selectedUnit);
                        return this;
                    }

                    // Select unit and revision:
                    unitToSelect = new Unit(unitToSelect);  // @NOTE: Creating new instances, so we can later revert any changes
                    this.selectedUnitRevision = new UnitRevision(revisionToSelect, unitToSelect);   // @NOTE: Creating new instances, so we can later revert any changes
                    this.unitHasChanged = this.selectedUnitRevision.cleanUpData();  // Initial validation/cleanup
                    this.afterSaveCallback = null;
                }

                // Update UI:
                this.pageTitle = this.selectedUnitRevision.title;
                this.showInspector = true;
                this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.selectedUnitRevision);

                // Show notification if the unit is incompatible and needs to be updated:
                if (!this.selectedRevisionIsFromHistory && !this.selectedUnitRevision.isCompatible) {
                    this.$globalEvents.emit(EventType.MODAL_UNIT_UPDATE_INCOMPATIBLE_SHOW, this.selectedUnitRevision);
                }

                // Show notification if errors were found and fixed:
                else if (this.unitHasChanged)
                {
                    this.$root.showNotification(
                        trans('modals.unit_repaired.title'),
                        trans('modals.unit_repaired.description')
                    );
                }

                // Show toast for missing features/entitlements
                if (!this.featureRepository.allActive(this.selectedUnitRevision.unit_data.entitlementsNeeded)) {
                    this.$toast.warning(trans('features.not_available.some_text'));
                }

                // Enable timer for checking unitOrRevision changes on the server:
                this.enableCheckUnitServerChanges();

                return this;
            },

            /**
             * Show history overlay
             */
            showHistory() {
                if (this.canRestoreUnit) {
                    this.$globalEvents.emit(EventType.MODAL_UNIT_HISTORY_SHOW);
                }
                return this;
            },

            /**
             * Selection handler for unit revisions history entries
             *
             * @param {UnitRevision} revision
             */
            onSelectUnitRevisionFromHistory(revision) {
                const isLatestRevision = (revision.uid === this.selectedUnit.latest_revision_uid);

                // Do nothing if it's the currently selected revision and unchanged:
                if (
                    !this.unitHasChanged && (
                        (
                            this.selectedRevisionIsLatest
                            && isLatestRevision
                        ) ||
                        revision.uid === this.selectedUnitRevision.uid
                    )
                )
                {
                    return this;
                }

                // Prevent loss of changes:
                if (this.unitHasChanged === true)
                {
                    this.afterSaveCallback = () => {
                        this.onSelectUnitRevisionFromHistory(revision);
                    };
                    this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_SHOW, this.selectedUnit);
                    return this;
                }

                // Re-select latest revision:
                if (isLatestRevision) {
                    return this.selectUnitOrRevision(revision, true);
                }

                // Disable background check for new unit data:
                this.disableCheckUnitServerChanges();

                // Select old revision from cache:
                if (revision.fetched_at !== null) {

                    return this.selectUnitOrRevision(revision, true);
                }

                // Cancel any ongoing requests and then fetch "old" unit revision from server:
                this.unitService.cancelRequests().then(() => {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    this.unitService
                        .fetchUnitRevision(revision)
                        .then((fetchedUnitRevision) => {
                            this.selectUnitOrRevision(fetchedUnitRevision, true);
                        })
                        .catch(this.onErrorApi);
                }, this);

                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError)
                {
                    error.callback = () => {
                        this.afterSaveCallback = null;
                        this.unitHasChanged = false;
                        this.$root.forceLogout();
                    };
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * Success handler for loading assets
             *
             * @param {Array<Asset>} assets
             */
            onSuccessFetchAssets(assets) {
                return this;
            },

            /**
             * Show the scene objects in the side panel
             */
            onShowSceneObjectsSidePanel() {
                this.showTriggers = false;
                this.showSceneObjects = true;
                this.showSidePanel = true;
                return this;
            },

            /**
             * Hide the side panel
             */
            onHideSidePanel() {
                this.showSceneObjects = false;
                this.showTriggers = false;
                this.showSidePanel = false;
                return this;
            },

            /**
             * Hide the scene objects side panel
             */
            onHideSceneObjectsSidePanel() {
                if (this.showSceneObjects === true) {
                    this.showSceneObjects = false;
                    this.showSidePanel = false;
                }
                return this;
            },

            /**
             * Set list of triggers in the side panel
             *
             * @param {Array<TriggerType>} triggers
             */
            setTriggersSidePanel(triggers) {
                this.triggers = triggers;
                return this;
            },

            /**
             * Show the triggers in the side panel
             */
            onShowTriggersSidePanel() {
                this.showSceneObjects = false;
                this.showTriggers = true;
                this.showSidePanel = true;
                return this;
            },

            /**
             * Hide the triggers side panel
             */
            onHideTriggersSidePanel() {
                if (this.showTriggers === true)
                {
                    this.showTriggers = false;
                    this.showSidePanel = false;
                }
                return this;
            },

            /**
             * Click handler for header navigation buttons that delegates the action to the button callback method
             *
             * @param {PageHeaderButton} buttonConfig
             */
            onClickHeaderNav(buttonConfig) {
                if (buttonConfig.callback === null)
                {
                    return this;
                }
                // Prevent losing unsaved changes:
                if (
                    this.unitHasChanged === true &&
                    buttonConfig.callback !== this.saveUnit &&
                    buttonConfig.callback !== this.showHistory &&
                    this.canUpdateUnit
                ) {
                    this.afterSaveCallback = () => {
                        this.onClickHeaderNav(buttonConfig);
                    };
                    this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_SHOW, this.selectedUnit);
                    return this;
                }
                buttonConfig.callback.call(this, buttonConfig);
                return this;
            },

            /**
             * Update handler for inspector changes to a scene object
             */
            onUpdateInspectorSceneObject() {
                this.unitHasChanged = true;
                return this;
            },

            /**
             * Update handler for inspector changes to the unit
             *
             * @param {Unit} unit
             */
            onUpdateInspectorUnit(unit) {
                // Update the page headline:
                this.pageTitle = this.selectedUnitRevision.title;
                this.unitHasChanged = true;
                return this;
            },

            /**
             * Change handler for the selected unit
             *
             * @param {Unit} unit
             */
            onChangeUnit(unit) {
                this.unitHasChanged = true;
                // Update highlighted elements (usually when a different value is selected in a dropdown)
                this.$nextTick(() => this.onHighlightReferencedObjects({target: document.activeElement || null}));
                return this;
            },

            /**
             * Click handler for non-grid-cells
             *
             * @param {MouseEvent} e
             */
            onClickOutsideCell(e) {
                // Hide any side panels
                if (this.showTriggers === true)
                {
                    this.onHideTriggersSidePanel();
                }
                else if (this.showSceneObjects === true)
                {
                    this.onHideSceneObjectsSidePanel();
                }
                return this;
            },

            /**
             * Save the current unit
             */
            saveUnit() {
                if (!this.canSaveUnit) {
                    return this;
                }

                // Disable unit changes check:
                this.disableCheckUnitServerChanges();

                // Check if restoring from history:
                if (this.selectedRevisionIsFromHistory)
                {
                    this.$globalEvents.emit(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_SHOW, this.selectedUnitRevision);
                    return this;
                }

                // Store the current scroll position:
                this.scrollPos.x = this.$refs.content.scrollLeft;
                this.scrollPos.y = this.$refs.content.scrollTop;

                // Check for unit changes on the server before saving:
                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
                this.unitService.cancelRequests().then(() => {
                    this.unitService
                        .fetchUnitUpdatedAt(this.selectedUnit)
                        .then(date => {
                            // Check if there is a different unit on the server:
                            if (date !== null && moment(date).isAfter(this.selectedUnit.updated_at))
                            {
                                return this.unitService
                                    .fetchUnit(this.selectedUnit)
                                    .then(() => {
                                        // Show overlay asking the user for confirmation:
                                        this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                                        this.$globalEvents.emit(EventType.MODAL_UNIT_OVERRIDE_ON_SERVER_SHOW, this.selectedUnitRevision);
                                    })
                                    .catch(this.onErrorSaveUnit);
                            }
                            this.onApplyUnitOverrideOnServer(this.selectedUnitRevision);
                        }).catch(this.onErrorSaveUnit);
                });
                return this;
            },

            /**
             * Cancel click handler for override server changes dialog
             */
            onCancelUnitOverrideOnServer() {
                // Load the latest version from server:
                this.onApplyUnitChangedOnServer(this.selectedUnit.latestRevision);
                return this;
            },

            /**
             * Apply click handler for override server changes dialog
             *
             * @param {UnitRevision} unitRevision
             */
            onApplyUnitOverrideOnServer(unitRevision) {
                // Continue with the save process:
                this.unitService
                    .updateUnit(unitRevision.parentUnit, unitRevision)
                    .then((updatedUnit) => {
                        // Refresh the assets if the unitRevision was updated from history:
                        if (!unitRevision.isLatestRevision)
                        {
                            const assets = updatedUnit.latestRevision.assets;
                            for (const asset of Object.values(assets))
                            {
                                this.assetService.setAsset(asset);
                            }
                            this.onSuccessFetchAssets(this.assetService.assets);
                        }
                        this.onSuccessSaveUnit(updatedUnit);
                    })
                    .catch(this.onErrorSaveUnit);
                return this;
            },

            /**
             * Click handler for creating history entry as new unit
             *
             * @param {Unit} unit
             */
            onRestoreFromHistoryAsNewUnit(unit) {
                this.$globalEvents.emit(EventType.MODAL_UNIT_CREATE_FROM_HISTORY_SHOW, this.selectedUnitRevision);
                return this;
            },

            /**
             * Click handler for creating a new unit from history entry
             *
             * @param {Object} options
             */
            onApplyNewUnitFromHistory(options) {
                this.unitService
                    .createUnitFromRevision(
                        options.unitRevision.parentUnit,
                        options.unitRevision.uid,
                        options.title,
                        options.keepAssignedAuthors
                    )
                    .then(this.onSuccessCreateUnitFromHistory)
                    .catch(this.onErrorSaveUnit);
                return this;
            },

            /**
             * Success handler for creating a new unit from history
             *
             * @param {Unit} unit
             */
            onSuccessCreateUnitFromHistory(unit) {
                // Open unit in new tab:
                const newRoute = route('units.edit', {unit: unit.uid});
                const opened = window.open(newRoute, '_blank');
                // Redirect to new unit if it couldn't be opened in a new tab:
                if (opened === null)
                {
                    if (this.unitHasChanged === false)
                    {
                        window.location.href = newRoute;
                        return this;
                    }
                    // Show modal dialog:
                    this.afterSaveCallback = () => {
                        window.location.href = newRoute;
                    };
                    this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_SHOW, this.selectedUnit);
                }
                return this;
            },

            /**
             * Click handler for updating the incompatible unit to the latest version
             */
            onApplyUnitIncompatibleUnitFromHistory() {
                this.unitHasChanged = true;
                this.saveUnit();
                return this;
            },

            /**
             * Success handler for saving the selected unit
             *
             * @param {Unit} unit
             */
            onSuccessSaveUnit(unit) {
                //console.log('onSuccessSaveUnit', unit);
                this.showInspector = true;
                this.selectedUnitRevision = new UnitRevision(unit.latestRevision, unit);    // @NOTE: Creating a new instance, so we can later revert any changes
                this.unitHasChanged = false;

                // Pass the updated unit to the inspector:
                if (typeof this.afterSaveCallback !== 'function')
                {
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.selectedUnitRevision);
                }

                // After save callback:
                this.onAfterSaveOrDismissChanges();

                // Set the content scroll position since it gets lost when the unit is hidden with display none:
                window.setTimeout(() => {
                    this.$refs.content.scrollLeft = this.scrollPos.x;
                    this.$refs.content.scrollTop = this.scrollPos.y;
                }, 10);

                // Enable timer for checking unit changes on the server:
                this.enableCheckUnitServerChanges();

                return this;
            },

            /**
             * Error handler for saving the selected unit
             *
             * @param {String} error
             */
            onErrorSaveUnit(error) {
                //console.log('onErrorSaveUnit', error);
                return this.onErrorApi(error);
            },

            /**
             * Cancel click handler for save changes dialog
             */
            onCancelSaveUnitChanges() {
                // Clear the callback:
                this.afterSaveCallback = null;
                return this;
            },

            /**
             * Dismiss click handler for save changes dialog
             *
             * @param {Unit} unit
             */
            onDismissSaveUnitChanges(unit) {
                // Reset the selected unit's data:
                if (this.selectedUnit !== null && this.selectedUnitRevision !== null)
                {
                    // Reset data to the initial revision:
                    const originalUnit = this.unitService.getUnitByUid(this.selectedUnit.uid);
                    const originalUnitRevision = originalUnit.revisions.find(r => r.uid === this.selectedUnitRevision.uid) || null;
                    if (originalUnitRevision !== null)
                    {
                        const newInstanceOfUnit = new Unit(originalUnit);   // @NOTE: Creating a new instance, so we can later revert any changes
                        this.selectedUnitRevision = new UnitRevision(originalUnitRevision, newInstanceOfUnit); // @NOTE: Creating a new instance, so we can later revert any changes
                        this.pageTitle = this.selectedUnitRevision.title;
                        // Force inspector update:
                        this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.selectedUnitRevision);
                        // Force re-render of the component:
                        this.renderTimestamp = (new Date()).toString();
                    }
                }
                this.unitHasChanged = false;
                this.onAfterSaveOrDismissChanges();
                return this;
            },

            /**
             * Execute the callback after saving data
             */
            onAfterSaveOrDismissChanges() {
                if (typeof this.afterSaveCallback === 'function')
                {
                    this.disableCheckUnitServerChanges();
                    this.afterSaveCallback.call(this);
                    this.afterSaveCallback = null;
                }
                return this;
            },

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                // Prevent losing unsaved changes:
                // Check if it is an actual link with a destination
                // Links that change the page need to trigger a save dialog if there are changes.
                // This includes links in modals! So links in modals should always use target="_blank".
                if (
                    this.unitHasChanged === true &&
                    this.canUpdateUnit &&
                    this.$globalEvents.isEventTargetDescendantOfSelector(e, '#layout-sidemenu a', '[href="#"], [target="_blank"]') === true
                ) {
                    e.preventDefault();
                    e.returnValue = '';
                    // Show modal dialog:
                    this.afterSaveCallback = () => {
                        e.target.closest('a, .btn').click();
                    };
                    this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_SHOW, this.selectedUnit);
                }
                return this;
            },

            clearHighlightRules() {
                while (this.domStyleTag.sheet.cssRules.length) {
                    this.domStyleTag.sheet.deleteRule(0);
                }
                return this;
            },

            setHighlightRules(isolateMode = false) {

                const uids = this.currentRefUids;
                const selector = uids.map(uid => `[data-uid="${uid}"], [data-children-uids*="${uid}"]`).join(',');

                // Color highlighting:
                this.domStyleTag.sheet.insertRule(uids.map(uid => `.header-cell[data-uid="${uid}"]`).join(',') + `{color: var(--color-primary) !important;}`);
                this.domStyleTag.sheet.insertRule(uids.map(uid => `.group-cell[data-uid="${uid}"], .group-cell[data-children-uids*="${uid}"]`).join(',') + `{background-color: var(--color-item-lightgrey-hover) !important; border-color: var(--color-item-lightgrey-hover) !important;}`);
                this.domStyleTag.sheet.insertRule(uids.map(uid => `.asset[data-uid="${uid}"]`).join(',') + `{background-color: var(--color-item-green-hover) !important; border-color: var(--color-item-green-hover) !important;}`);
                this.domStyleTag.sheet.insertRule(uids.map(uid => `.environment[data-uid="${uid}"]`).join(',') + `{background-color: var(--color-item-blue-hover) !important; border-color: var(--color-item-blue-hover) !important;}`);
                this.domStyleTag.sheet.insertRule(uids.map(uid => `.hotspot[data-uid="${uid}"]`).join(',') + `{background-color: var(--color-item-yellow-hover) !important; border-color: var(--color-item-yellow-hover) !important;}`);
                this.domStyleTag.sheet.insertRule(uids.map(uid => `.widget[data-uid="${uid}"]`).join(',') + `{background-color: var(--color-item-grey-hover) !important; border-color: var(--color-item-grey-hover) !important;}`);

                // Find first matching object:
                const matches = document.querySelectorAll(selector);
                const match = uids.reduce((value, uid) => {
                    return value || [...matches].find(m => m.matches(`[data-uid="${uid}"], .collapsed[data-children-uids*="${uid}"]`)) || null;
                }, null);
                if (match) {

                    // Animation:
                    this.domStyleTag.sheet.insertRule(`[data-uid="${match.dataset.uid}"]{animation: highlight-objects 2s ease-in-out .5s infinite;}`);

                    if (isolateMode) {

                        // Opacity:
                        this.domStyleTag.sheet.insertRule('.header-cell, .scene-object-cell, .group-cell, .add-object-cell, .objectives-cell {opacity: 0.35;}');
                        this.domStyleTag.sheet.insertRule(`${selector} {opacity: 1 !important;}`);

                        // Scroll into view:
                        window.setTimeout(() => {
                            match.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center',
                                inline: 'center',
                            });

                            // Fix scrolling on small viewports:
                            if (window.innerHeight < 680) {
                                document.getElementById('app').scrollTop = 0;
                            }
                        }, 250);
                    }
                }

                return this;
            },

            /**
             * Mouse move for highlighting referenced objects
             *
             * @param {MouseEvent} e
             */
            onHighlightReferencedObjects(e) {

                const hasToggledShiftKey = (e.shiftKey && !this.lastShiftKeyState) || (!e.shiftKey && this.lastShiftKeyState);
                this.lastShiftKeyState = e.shiftKey;

                window.clearTimeout(this.scrollDelayForHighlighting);

                // Toggle highlighting without mouse move
                if (e.type === 'keyup' || e.type === 'keydown') {

                    // Ignore events when no object is highlighted:
                    if (
                        this.currentRefObject === null
                        || this.currentRefUids.length === 0
                    ) {
                        return this;
                    }

                    // Ignore [SHIFT] on input elements:
                    // Ignore [SHIFT]+[TAB]:
                    if (e.shiftKey && (
                            e.target instanceof HTMLInputElement
                            || e.target instanceof HTMLTextAreaElement
                            || KeyboardKey.findByEvent(e) === KeyboardKey.Tab
                        )
                    ) {
                        return this;
                    }

                    this.clearHighlightRules();
                    this.setHighlightRules(e.shiftKey);

                    return this;
                }

                const inspectorElement = this.$refs.inspector.firstElementChild || null;
                const isEventFromInspector = Boolean(inspectorElement && (e.target.compareDocumentPosition(inspectorElement) & Node.DOCUMENT_POSITION_CONTAINS));

                // Reset when not over the inspector:
                if (!isEventFromInspector) {
                    if (this.currentRefObject !== null) {
                        this.currentRefObject = null;
                        this.clearHighlightRules();
                    }
                    return this;
                }

                // Look for referenced objects including parents inside the inspector component:
                this.currentRefUids = [];
                let isSameRefObject = false;
                if (e.target.dataset?.refUid) {
                    isSameRefObject = (e.target === this.currentRefObject);
                    this.currentRefObject = e.target;
                    this.currentRefUids.push(e.target.dataset.refUid);
                }
                let parent = e.target.parentNode;
                while (
                    parent
                    && (
                        parent === inspectorElement
                        || (parent.compareDocumentPosition(inspectorElement) & Node.DOCUMENT_POSITION_CONTAINS)
                    )
                ) {
                    if (parent.dataset?.refUid) {
                        if (this.currentRefUids.length === 0) {
                            isSameRefObject = (parent === this.currentRefObject);
                            this.currentRefObject = parent;
                        }
                        this.currentRefUids.push(parent.dataset.refUid);

                        // Manually stop bubbling to parent objects:
                        if (parent.dataset.refUid === 'no-ref') {
                            break;
                        }
                    }
                    parent = parent.parentNode;
                }

                // Do nothing if still highlighting the same object
                if (!hasToggledShiftKey && isSameRefObject) {
                    return this;
                }

                // Clear previous rules
                this.clearHighlightRules();

                // Do nothing if no objects are referenced:
                if (this.currentRefUids.length === 0 || this.currentRefUids[0] === 'no-ref') {
                    this.currentRefObject = null;
                    return this;
                }

                // Remove duplicate UIDs:
                this.currentRefUids = [...new Set(this.currentRefUids)];

                this.setHighlightRules(e.shiftKey);

                return this;
            },

            /**
             * Shortcut handler for releasing a unit
             *
             * @param {CustomEvent} e
             */
            onShortcutRelease(e) {
                if (this.canReleaseUnit)
                {
                    this.$globalEvents.emit(EventType.MODAL_RELEASE_UNIT_SHOW, this.selectedUnit);
                }
                return this;
            },

            /**
             * Shortcut handler for reloading
             *
             * @param {CustomEvent} e
             */
            onShortcutReload(e) {
                if (
                    this.unitHasChanged === true &&
                    this.canUpdateUnit
                ) {
                    this.afterSaveCallback = () => {
                        window.location.reload();
                    };
                    this.onBeforeUnload(e.detail.keyboardEvent);
                }
                return this;
            },

            /**
             * Before unload handler
             *
             * @param {BeforeUnloadEvent} e
             */
            onBeforeUnload(e) {
                if (
                    this.unitHasChanged === true &&
                    this.canUpdateUnit
                ) {
                    e.preventDefault();
                    e.returnValue = '';
                    this.disableCheckUnitServerChanges();
                    // Hide side panels:
                    this.onHideSidePanel();
                    // Show modal dialog:
                    this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_SHOW, this.selectedUnit);
                }
                else
                {
                    // Hide side panels:
                    this.onHideSidePanel();
                    // Cancel requests to avoid "Request Aborted" error
                    this.assetService.cancelRequests();
                    this.unitService.cancelRequests();
                    // @NOTE: $nextTick() doesn't work here since isLoading will be recomputed afterwards (hiding the overlay) so the quick fix is to use setTimeout
                    setTimeout(() => {this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);},1);
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                }
                return this;
            },

            onClickReleaseUnit() {
                if (!this.canReleaseUnit) {
                    return;
                }

                const oldCheckForUnitChangesInBackground = this.checkForUnitChangesInBackground;
                this.checkForUnitChangesInBackground = false;

                this.unitService
                    .getCourses(this.selectedUnit)
                    .then((courses) => {
                        this.$globalEvents.emit(EventType.MODAL_RELEASE_UNIT_SHOW, {
                            unitRevision: this.selectedUnitRevision,
                            courses: courses
                        });
                    })
                    .catch(this.onErrorApi)
                    .finally(() => {
                        this.checkForUnitChangesInBackground = oldCheckForUnitChangesInBackground;
                    });
            },

            /**
             * Apply handler for releasing the unit.
             */
            onApplyReleaseUnit() {
                this.unitService
                    .releaseUnit(this.selectedUnit)
                    .then((unit) => {
                        this.selectUnitOrRevision(unit);
                        this.$toast.success(trans('modals.release_unit.success', {unit: unit.latestRevision.title}));
                    })
                    .catch(this.onErrorApi);

                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
