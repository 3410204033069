import { isEqual } from 'lodash';
import { ClipboardData } from '@/Utility/Clipboard';
import {Feature} from "@/Models/Features/Feature";

/**
 * Abstract base class for all objects being created from a training's data (e.g. all child data nodes on a Training model)
 *
 * @abstract
 */
export default class AbstractDataObject
{
    /**
     * Define the constructor's class name since we can't use constructor.name
     * because of Javascript minifier and cannot use instanceof because of circular dependencies
     *
     * @static
     * @var {String}
     */
    static get constructorName(): string
    {
        // Force implementation on inherited classes:
        if (!this.hasOwnProperty('constructorName') && !this.prototype.hasOwnProperty('constructorName') && !(this instanceof AbstractDataObject))
        {
            throw new Error(`AbstractDataObject->constructorName(): Subclass "${this.name}" must implement static getter "constructorName()"`);
        }
        return 'AbstractDataObject';
    }

    /**
     * @param {Object} parent   // Parent object reference
     */
    constructor(parent: Object | null = null)
    {
        if (new.target === AbstractDataObject)
        {
            throw new TypeError('Cannot construct instances from abstract class AbstractDataObject.');
        }

        // Hidden parent attribute (not enumerable which makes it "hidden" so it doesn't get stored in the database when sent to the API):
        Object.defineProperty(
            this,
            'parent',
            {
                value: parent,
                configurable: false,
                enumerable: false,
                writable: true,
            }
        );

        // Create and return a proxy instead of the regular instance so we can intercept getters and setters:
        return new Proxy(this, {
            defineProperty: ProxyHandlers.defineProperty,
            get: ProxyHandlers.getProperty,
            set: ProxyHandlers.setProperty,
        });
    }

    /**
     * Get the constructor name from the instance's class
     */
    get constructorName(): string
    {
        return this.constructor.constructorName;
    }

    /**
     * Is this object global? (e.g. not part of a TrainingScene)
     */
    get isGlobal(): boolean
    {
        return (this.parentTrainingScene === null);
    }

    /**
     * Check if the object is valid
     */
    get isValid(): boolean
    {
        // Force implementation on inherited classes:
        if (!this.constructor.prototype.hasOwnProperty('isValid'))
        {
            throw new Error(`AbstractDataObject->isValid(): Subclass "${this.constructor.name}" must implement its own getter for isValid()`);
        }
        return true;
    }

    get parents(): Array<Object>
    {
        return this.parent ? [this.parent].concat(this.parent.parents || []) : [];
    }

    /**
     * Entitlements (or features) needed to use this piece of data inside a training.
     * Empty if no entitlement is needed (default).
     */
    get entitlementsNeeded(): Array<Feature>
    {
        return [];
    }

    /**
     * Callback method after a property has been changed
     *
     * @param {String|Symbol} property  // Name or symbol of the property
     * @param {*} oldValue              // Previous value of the property
     * @param {*} newValue              // New value that has been set on the property
     */
    onPropertyChanged(property, oldValue, newValue)
    {
        throw new Error(`AbstractDataObject->onChanged(): Not implemented. Override this method on inherited objects to react to property changes.`);
    }

    /**
     * Clean up data (e.g. remove forbidden nested commands or invalid targets and values)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean
    {
        // Force implementation on inherited classes:
        if (!this.constructor.prototype.hasOwnProperty('cleanUpData'))
        {
            throw new Error(`AbstractDataObject->cleanUpData(): Subclass "${this.constructor.name}" must implement cleanUpData()`);
        }
        return false;
    }

    /**
     * Convert to ClipboardData object
     */
    toClipboardData(): ClipboardData
    {
        return new ClipboardData({
            constructorName: this.constructorName,
            ...this
        });
    }

    /**
     * Create a new instance from given attributes
     *
     * @throws {Error}
     */
    static createFromAttributes(attributes: {}): any
    {
        // @NOTE: This works but since we have models with different classes depending on their "type" attribute we instead force subclass implementation for now!
        //return new (this)(...arguments);

        // Force implementation on inherited classes:
        if (!this.hasOwnProperty('createFromAttributes') && !this.prototype.hasOwnProperty('createFromAttributes') && !(this instanceof AbstractDataObject))
        {
            throw new Error(`AbstractDataObject->createFromAttributes(): Subclass "${this.name}" must implement static method "createFromAttributes()"`);
        }
    }

    /**
     * Parse model from ClipboardData
     */
    static fromClipboardData(clipboardData: ClipboardData): object | any | null
    {
        if (!(clipboardData instanceof ClipboardData) || !clipboardData.isInstanceOf(this))
        {
            return null;
        }
        try
        {
            return this.createFromAttributes(clipboardData.data);
        }
        catch (exception)
        {
            console.error(`${this.constructorName}->fromClipboardData(): Unable to parse data.`, exception);
            return null;
        }
    }
}

/**
 * Helper class for the proxy handlers so the main class has less code
 *
 * @private
 * @static
 */
class ProxyHandlers
{
    constructor() {if (new.target === ProxyHandlers) {throw new TypeError('Cannot construct instances from static class ProxyHandlers.');}}

    /**
     * Handler for defining properties on any inherited object
     *
     * @static
     * @param {Object} target           // The target object on which to define the property
     * @param {String|Symbol} property  // Name or symbol of the property
     * @param {*} attributes            // Descriptors for the property
     * @returns {Object}
     */
    static defineProperty(target, property, attributes)
    {
        // Prevent any "parent" properties from being defined again since they are handled automatically by this class
        if (typeof property === 'string' && property.indexOf('parent') === 0)
        {
            throw new Error(`${target.constructor.name}: Parent property "${property}" is already defined on the object.`);
        }

        return Reflect.defineProperty(...arguments);
    }

    /**
     * Handler for getting properties from any inherited object
     *
     * @static
     * @param {Object} target           // The target object from which to get the property
     * @param {String|Symbol} property  // Name or symbol of the property
     * @param {Proxy} receiver          // The value of this provided for the call to target
     * @returns {*}
     */
    static getProperty(target, property, receiver)
    {
        // Get a specific parent object by its class name (e.g. "parentTrainingScene" will get the first parent instance of TrainingScene)
        if (property !== 'parent' && property !== 'parents' && typeof property === 'string' && property.indexOf('parent') === 0)
        {
            const parentClassName = property.substring(6);
            return target.parent ? (
                (
                    target.parent.constructorName && (target.parent.constructorName === parentClassName) ? target.parent : null
                ) || target.parent[`parent${parentClassName}`] || null
            ) : null;
        }

        return Reflect.get(...arguments);
    }

    /**
     * Handler for setting properties on any inherited object
     *
     * @static
     * @param {Object} target           // The target object on which to set the property
     * @param {String|Symbol} property  // Name or symbol of the property
     * @param {*} value                 // The value to be set
     * @param {Proxy} receiver          // The value of this provided for the call to target
     * @returns {Boolean}
     */
    static setProperty(target, property, value, receiver)
    {
        // Prevent any properties with "parent" prefix from being set since they are supposed to be read-only:
        if (property !== 'parent' && typeof property === 'string' && property.indexOf('parent') === 0)
        {
            throw new Error(`${target.constructor.name}: Property "${property}" is read-only and cannot be set.`);
        }

        // Prevent defineProperty() from being called again for properties that already exist:
        if (target.hasOwnProperty(property))
        {
            const oldValue = target[property];
            target[property] = value;

            // Call onPropertyChanged handler if it is implemented on the inherited class:
            if (oldValue !== undefined && target.onPropertyChanged !== AbstractDataObject.prototype.onPropertyChanged && !isEqual(oldValue, value))
            {
                target.onPropertyChanged(property, oldValue, value);
            }

            return true;
        }

        return Reflect.set(...arguments);
    }
}
