<template>
    <div
        v-if="isVisible"
        id="dialog-replace-asset"
        class="modal-dialog fullscreen"
        v-not-focusable
        v-shortcuts.global.prevent.stop
    >
        <div class="dialog">
            <h2 class="title">{{ trans('modals.replace_asset.title') }}</h2>
            <p class="description" v-html="trans('modals.replace_asset.description', {asset: asset.title, count: count})"></p>
            <RadioButtons
                ref="radiobuttons"
                :buttons="buttons"
                initial-value="only_one"
            />
            <div class="buttons">
                <ButtonSecondary class="btn-cancel" @trigger="onClickCancel" caption="modals.replace_asset.cancel" />
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="modals.replace_asset.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    import {trans} from "@/Utility/Helpers";
    import EventType from '@/Utility/EventType';
    import RadioButtonConfig from "@/Utility/RadioButtonConfig";
    import RadioButtons from "@/Vue/Common/RadioButtons.vue";

    export default {
        name: 'DialogReplaceAsset',
        components: {
            RadioButtons
        },
        data() {
            return {
                isVisible: false,
                callback: null,
                count: null,
                asset: null,
                buttons: [
                    new RadioButtonConfig({
                        value: 'only_one',
                        caption: trans('modals.replace_asset.option_only_one'),
                    }),
                    new RadioButtonConfig({
                        value: 'all',
                        caption: trans('modals.replace_asset.option_all'),
                    }),
                ],
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_REPLACE_ASSET_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_REPLACE_ASSET_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_REPLACE_ASSET_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_REPLACE_ASSET_HIDE, this.hide);
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {Object} functions
             */
            show(options) {
                this.previousFocusElement = document.activeElement;
                this.asset = options.asset;
                this.callback = options.callback;
                this.count = options.count;
                this.buttons[1].caption = trans('modals.replace_asset.option_all', {count: this.count});
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.asset = null;
                this.callback = null;
                this.count = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_REPLACE_ASSET_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.callback(this.$refs.radiobuttons.selectedValue);
                this.$globalEvents.emit(EventType.MODAL_REPLACE_ASSET_APPLY, this.$refs.radiobuttons.selectedValue);
                this.hide();
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
