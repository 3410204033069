<template>
    <div class="content-settings" v-if="content">

        <!-- Assign asset -->
        <div v-if="usesAsset">

            <!-- Button for adding an asset -->
            <ButtonPrimary v-if="hasValue === false" class="btn-add-asset" icon="icon_add" caption="labels.add_asset" @trigger="onClickAddAsset" />

            <!-- The assigned asset -->
            <div v-else class="assigned-asset">
                <div v-if="assignedAsset" class="preview preview-image-pointer" @click="showAssetPreview(assignedAsset)">
                    <Icon :name="assignedAsset.assetType.previewIcon" />
                    <img :src="assignedAsset.preview" alt="" />
                </div>
                <div class="info">
                    <span class="title">
                        {{ (assignedAsset) ? assignedAsset.title : trans('labels.asset_unknown') }}
                    </span>
                </div>
                <div class="btn-delete">
                    <Icon name="icon_delete" class="icon-delete" @click="onClickRemoveAsset" />
                </div>

                <!-- Delete confirm overlay -->
                <OverlayConfirmRemove v-if="isRemoveConfirmDialogVisible === true" @confirm="onConfirmAssetRemove" @cancel="onCancelAssetRemove" />
            </div>
        </div>

        <!-- Objectives Counter -->
        <div v-else-if="content.type === overlayContentTypes.ObjectivesCounter">
            <RadioButtons class="color-light" :buttons="getRadiobuttonsForObjectivesCounter()" :model="content" property="value" :required="true" @change="onChangeProperty" />
        </div>

        <!-- Intro: TrainingInfo -->
        <div v-else-if="content.type === overlayContentTypes.TrainingInfo">
            <TextInput :disabled="true" :initial-value="unitData?.title || trans('units.intro.contents.training_info.title_placeholder')" :maxlength="50" />
            <TextInput :disabled="true" :initial-value="unitData?.description || trans('units.intro.contents.training_info.description_placeholder')" type="textarea" :maxlength="500" />
        </div>

        <!-- Intro: TrainingSceneInfo -->
        <div v-else-if="content.type === overlayContentTypes.TrainingSceneInfo">
            <TextInput :disabled="true" :initial-value="trainingScene?.title || trans('units.intro.contents.trainingscene_info.title_placeholder')" :maxlength="50" />
            <TextInput :disabled="true" :initial-value="trainingScene?.description || trans('units.intro.contents.trainingscene_info.description_placeholder')" type="textarea" :maxlength="500" />
        </div>

        <!-- Outro: TrainingCompleted -->
        <div v-else-if="content.type === overlayContentTypes.TrainingCompleted">
            <TextInput :disabled="true" :initial-value="trans('units.outro.default_headline')" :maxlength="50" />
            <TextInput :disabled="true" :initial-value="trans('units.outro.training_completed')" type="textarea" :maxlength="500" />
        </div>

        <!-- Outro: TrainingSceneCompleted -->
        <div v-else-if="content.type === overlayContentTypes.TrainingSceneCompleted">
            <TextInput :disabled="true" :initial-value="trans('units.outro.default_headline')" :maxlength="50" />
            <TextInput :disabled="true" :initial-value="trans('units.outro.trainingscene_completed')" type="textarea" :maxlength="500" />
        </div>

        <!-- Edit text -->
        <div v-else-if="usesText">
            <TextInput :model="content.value" property="headline" :required="!content.isValid" :maxlength="50" :placeholder="trans('labels.headline')" @change="onChangeProperty" />
            <TextInput :model="content.value" property="text" :required="!content.isValid" type="textarea" :maxlength="500" :placeholder="trans('labels.text')" @change="onChangeProperty" />
        </div>

    </div>
</template>

<script>
    // Import VueJS components:
    import OverlayConfirmRemove     from '@/Vue/Inspector/OverlayConfirmRemove.vue';

    // Import classes:
    import RadioButtonConfig        from '@/Utility/RadioButtonConfig';
    import EventType                from '@/Utility/EventType';
    import { trans }                from '@/Utility/Helpers';
    import Asset                    from '@/Models/Asset/Asset';
    import AssetType                from '@/Models/Asset/AssetType';
    import SceneObjectFilters       from '@/Filters/SceneObjectFilters';
    import OverlayContent           from '@/Models/Unity/OverlayContent';

    export default {
        name: 'PanelContentSettings',
        emits: [
            'change',
        ],
        components: {
            OverlayConfirmRemove
        },
        props: {
            content: {                      // The content object to be edited
                type: OverlayContent,
                default: null
            }
        },
        data() {
            return {
                overlayContentTypes: OverlayContent.Type,
                isRemoveConfirmDialogVisible: false        // Whether the remove confirmation dialog is visible
            }
        },
        computed: {

            /**
             * @returns {UnitData}
             */
            unitData() {
                return this.content.parentUnitData;
            },

            /**
             * @returns {UnitRevision}
             */
            unitRevision() {
                return this.content.parentUnitRevision;
            },

            /**
             * @returns {TrainingScene}
             */
            trainingScene() {
                return this.content.parentTrainingScene;
            },

            /**
             * Does the content have a set value?
             *
             * @returns {Boolean}
             */
            hasValue() {
                return this.content.value !== undefined && this.content.value !== null;
            },

            /**
             * Get data for the assigned asset
             *
             * @returns {Asset|null}
             */
            assignedAsset() {
                if (this.content !== null && this.unitRevision !== null && typeof this.content.value === 'string' && this.unitRevision.assets[this.content.value] instanceof Asset)
                {
                    return this.unitRevision.assets[this.content.value];
                }
                return null;
            },

            /**
             * Uses an asset as target?
             *
             * @returns {Boolean}
             */
            usesAsset() {
                return (this.content !== null && [
                    OverlayContent.Type.ImageReference,
                    OverlayContent.Type.SoundReference,
                    OverlayContent.Type.VideoReference,
                ].indexOf(this.content.type) >= 0);
            },

            /**
             * Uses text as target?
             *
             * @returns {Boolean}
             */
            usesText() {
                return (this.content !== null && [
                    OverlayContent.Type.Text,
                    OverlayContent.Type.TrainingCompleted,
                    OverlayContent.Type.TrainingInfo,
                    OverlayContent.Type.TrainingSceneCompleted,
                    OverlayContent.Type.TrainingSceneInfo,
                ].indexOf(this.content.type) >= 0);
            }

        },
        methods: {

            /**
             * Get radiobuttons for objectives counter settings
             *
             * @returns {Array<RadioButtonConfig>}
             */
            getRadiobuttonsForObjectivesCounter() {
                return [
                    new RadioButtonConfig({
                        value: 'scene',
                        caption: trans('labels.scene'),
                    }),
                    new RadioButtonConfig({
                        value: 'training',
                        caption: trans('labels.unit'),
                    }),
                ];
            },

            /**
             * Click handler for add asset button
             */
            onClickAddAsset() {
                // Determine which types of assets can be selected:
                let assetTypes = null;
                switch(this.content.type)
                {
                    case OverlayContent.Type.ImageReference:
                        this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, [ SceneObjectFilters.Images.setActive(true) ]);
                        assetTypes = [AssetType.Image];
                        break;
                    case OverlayContent.Type.SoundReference:
                        this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, [ SceneObjectFilters.Sounds.setActive(true) ]);
                        assetTypes = [AssetType.Sound];
                        break;
                    case OverlayContent.Type.VideoReference:
                        this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, [ SceneObjectFilters.Videos.setActive(true) ]);
                        assetTypes = [AssetType.Video];
                        break;
                }
                this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, {
                    callback: this.onSelectAsset,
                    assetTypes: assetTypes,
                    clearWordFilter: true
                });
                return this;
            },

            /**
             * Click handler for remove asset button
             */
            onClickRemoveAsset() {
                this.isRemoveConfirmDialogVisible = true;
                return this;
            },

            /**
             * Confirm handler for asset remove confirmation dialog
             */
            onConfirmAssetRemove() {
                this.isRemoveConfirmDialogVisible = false;
                // Remove the asset:
                this.content.value = null;
                this.$emit('change', this.content);
                return this;
            },

            /**
             * Cancel handler for asset remove confirmation dialog
             */
            onCancelAssetRemove() {
                this.isRemoveConfirmDialogVisible = false;
                return this;
            },

            /**
             * Select handler for assigning an asset to the content
             *
             * @param {Asset} asset
             */
            onSelectAsset(asset) {
                // Add the selected asset to the unit:
                this.unitRevision.addAssets(asset);
                // Set the content's value:
                this.content.value = asset.uid;
                this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);
                this.$emit('change', this.content);
                return this;
            },

            /**
             * Change handler for properties
             */
            onChangeProperty() {
                this.$emit('change', this.content);
                return this;
            },

            /**
             * Show Asset preview modal for asset
             *
             * @param {Asset} asset
             */
            showAssetPreview(asset) {
                if (asset.supportsPreview)
                {
                    this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, asset);
                }
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
