import KeyboardKey from '@/Utility/KeyboardKey';

/**
 * Operating system flags
 */
const isMac = navigator.platform.toLowerCase().indexOf('mac') === 0;

/**
 * Key aliases
 *
 * @var {Map<String, String>>}
 */
const KeyboardKeyAliases = new Map([

    ['LeftAlt', 'Alt'],
    ['RightAlt', 'Alt'],

    ['LeftApple', 'OS'],
    ['RightApple', 'OS'],

    ['LeftCommand', 'OS'],
    ['RightCommand', 'OS'],

    ['LeftCtrl', 'Ctrl'],
    ['RightCtrl', 'Ctrl'],

    ['LeftMeta', 'OS'],
    ['RightMeta', 'OS'],

    ['LeftShift', 'Shift'],
    ['RightShift', 'Shift'],

    ['LeftWindows', 'OS'],
    ['RightWindows', 'OS'],

    ['OS+C', 'Copy'],
    ['Ctrl+C', 'Copy'],

    ['OS+D', 'Duplicate'],
    ['Ctrl+D', 'Duplicate'],

    ['OS+X', 'Cut'],
    ['Ctrl+X', 'Cut'],

    ['OS+V', 'Paste'],
    ['Ctrl+V', 'Paste'],

    ['OS+S', 'Save'],
    ['Ctrl+S', 'Save'],

    ['OS+A', 'SelectAll'],
    ['Ctrl+A', 'SelectAll'],

    ['OS+P', 'Publish'],
    ['Ctrl+P', 'Publish'],

    ['OS+H', 'Replace'],
    ['Ctrl+H', 'Replace'],

    ['OS+Backspace', 'Delete'],
    ['Ctrl+Backspace', 'Delete'],

    ['F5', 'Reload'],
    ['OS+R', 'Reload'],
    ['OS+Shift+R', 'Reload'],
    ['Shift+OS+R', 'Reload'],
    ['Ctrl+R', 'Reload'],
    ['Ctrl+Shift+R', 'Reload'],
    ['Shift+Ctrl+R', 'Reload'],

    ['OS+Alt+I', 'DevTools'],
    ['Alt+OS+I', 'DevTools'],
    ['Ctrl+Shift+I', 'DevTools'],
]);

/**
 * List of key enums currently being pressed by the user
 *
 * @var {Array<String>}
 */
let keysPressed = [];

/**
 * Mac OS keyup workaround timers
 */
const keyupTimers = new Map();

/**
 * Remove a pressed key from the list, simulating a (fake) keyup event
 *
 * @NOTE: On Mac OS the keyup event will not be triggered for any other keys if the command (meta) key is being held down!
 *
 * @param {String} keyEnum
 */
const removePressedKeyAfterDelay = function(keyEnum) {
    if (keyupTimers.has(keyEnum))
    {
        window.clearTimeout(keyupTimers.get(keyEnum));
    }
    keyupTimers.set(keyEnum, window.setTimeout(function() {
        keysPressed = keysPressed.filter(k => k !== keyEnum);
        window.clearTimeout(keyupTimers.get(keyEnum));
        keyupTimers.delete(keyEnum);
    }, 100));
};

/**
 * Shortcut listener class for keeping track of all listeners
 */
class ShortcutListener
{
    /**
     * Constructor
     *
     * @param {Object} el               // Reference to the DOM element that registered the listener
     * @param {string} shortcut         // Name of the shortcut, e.g. 'ctrl+shift+c', 'copy'
     * @param {Function} callback       // Reference to the callback function
     * @param {Object} vnode            // Reference to the vnode of the component that registered the listener
     * @param {Object} binding          // Reference to the binding of the component that registered the listener
     */
    constructor(el, shortcut, callback, vnode, binding)
    {
        const shortcutLowerCase = shortcut.toLowerCase();

        // Populate the model:
        this.el = el;
        this.shortcut = shortcutLowerCase.replace(/\.prevent|\.stop|\.global/gi, '');  // Only use lowercase name without modifiers
        this.callback = callback;
        this.vnode = vnode;
        this.binding = binding;
        this.prevent = binding.modifiers.prevent || shortcutLowerCase.indexOf('.prevent') > 0;
        this.stop = binding.modifiers.stop || shortcutLowerCase.indexOf('.stop') > 0;
        this.global = binding.modifiers.global || shortcutLowerCase.indexOf('.global') > 0;
    }

    /**
     * Handle the shortcut event
     *
     * @param {CustomEvent} e
     */
    handleEvent(e)
    {
        // Add a reference to the target element that the shortcut was registered on:
        e.detail.currentTarget = this.el;

        // Add a reference to the vnode for the component that registered the shortcut:
        e.detail.vnode = this.vnode;

        // Add a reference to the binding for the component that registered the shortcut:
        e.detail.binding = this.binding;

        if (this.prevent)
        {
            e.preventDefault();
            (e.detail.keyboardEvent || e.detail.clipboardEvent).preventDefault();
            e.returnValue = false;
            (e.detail.keyboardEvent || e.detail.clipboardEvent).returnValue = false;
        }
        if (this.stop)
        {
            e.stopPropagation();
            (e.detail.keyboardEvent || e.detail.clipboardEvent).stopPropagation();
            e.stopImmediatePropagation();
            (e.detail.keyboardEvent || e.detail.clipboardEvent).stopImmediatePropagation();
        }
        if (this.callback instanceof Function)
        {
            this.callback.call(this.binding.instance, e);
        }

        return !e.defaultPrevented;
    }
}

/**
 * List of ShortcutListeners for all Vue components with the order in which they were registered
 *
 * @var {Array<ShortcutListener>}
 */
let shortcutListeners = [];

/**
 * Sort shortcuts with "Any" being first because specific shortcuts should always be triggered before the "Any" shortcut
 */
const sortWithAnyFirst = (a, b) => a[0].toLowerCase().indexOf('any') === 0 ? -1 : b[0].toLowerCase().indexOf('any') === 0 ? 1 : 0;

/**
 * Create the shortcut listeners for an element
 *
 * @param {Object} el               // Reference to the DOM element that registered the listener
 * @param {Object} vnode            // Reference to the vnode of the component that registered the listener
 * @param {Object} binding          // Reference to the binding of the component that registered the listener
 */
const createShortcutListeners = (el, vnode, binding) => {

    // Get the shortcut mapping from binding.value or binding.value.shortcuts or data() of the component:
    const shortcutMap = (binding.value instanceof Map) ? binding.value : ((binding.value instanceof Object && binding.value.shortcuts instanceof Map) ? binding.value.shortcuts : (binding.instance.shortcuts || null));
    if (shortcutMap instanceof Map === false || shortcutMap.size === 0)
    {
        console.warn(`KeyboardShortcuts->createShortcutListeners(): Invalid shortcut parameters on component ${binding.instance.$options.name}. Must be data of type Map<String, Function>.`, shortcutMap);
        return;
    }

    // Maintain order like in the DOM:
    const insertIndex = shortcutListeners.findIndex(l => Boolean(el.compareDocumentPosition(l.el) & Node.DOCUMENT_POSITION_FOLLOWING));

    // Register events (always register "Any" first to make sure specific shortcuts are later being triggered before the "Any" shortcut):
    shortcutListeners.splice.apply(
        shortcutListeners,
        [
            insertIndex >= 0 ? insertIndex : shortcutListeners.length,
            0
        ].concat(
            [...shortcutMap]
                .sort(sortWithAnyFirst)
                .map(s => new ShortcutListener(el, s[0], s[1], vnode, binding))
        )
    );
};

/**
 * Dispatch a custom shortcut event on the currently active DOM element
 *
 * @param {String} shortcut
 * @param {KeyboardEvent|ClipboardEvent} originalEvent
 */
const dispatchShortcutEvent = (shortcut, originalEvent) => {
    const shortcutEvent = new CustomEvent('shortcut', {
        bubbles: true,
        cancelable: true,
        detail: {
            keyboardEvent: (originalEvent instanceof KeyboardEvent) ? originalEvent : null,
            clipboardEvent: (originalEvent instanceof ClipboardEvent) ? originalEvent : null,
            shortcut: shortcut
        }
    });
    // Inject custom methods:
    shortcutEvent.stopShortcutPropagation = function() {
        this.preventDefault();
        this.stopPropagation();
        this.stopImmediatePropagation();
        if (this.detail.keyboardEvent)
        {
            this.detail.keyboardEvent.preventDefault();
            this.detail.keyboardEvent.stopPropagation();
            this.detail.keyboardEvent.stopImmediatePropagation();
        }
        if (this.detail.clipboardEvent)
        {
            this.detail.clipboardEvent.preventDefault();
            this.detail.clipboardEvent.stopPropagation();
            this.detail.clipboardEvent.stopImmediatePropagation();
        }
    };
    document.activeElement.dispatchEvent(shortcutEvent);
};

/**
 * Global keyboard shortcut handling
 */
export default {

    /**
     * Install
     *
     */
    install(app)
    {
        // Register global events:
        window.addEventListener('blur', this.onWindowBlur);
        document.addEventListener('keydown', this.onKeyDown);
        document.addEventListener('keyup', this.onKeyUp);
        document.addEventListener('copy', this.onClipboardEvent);
        document.addEventListener('cut', this.onClipboardEvent);
        document.addEventListener('paste', this.onClipboardEvent);
        document.addEventListener('shortcut', this.onShortcut);

        // Create the VueJS directive:
        app.directive(
            'shortcuts',
            {
                mounted: (el, binding, vnode) => {

                    // Create shortcut listeners:
                    createShortcutListeners(el, vnode, binding);
                    //console.log('inserted', shortcutListeners.map(l => l.vnode.context.$options.name + ' ' + l.shortcut));
                },
                unmounted: (el, binding) => {

                    // Remove all listeners for this element:
                    shortcutListeners = shortcutListeners.filter(l => false === Object.is(l.el, el));
                    //console.log('unbind', shortcutListeners.map(l => l.vnode.context.$options.name + ' ' + l.shortcut));
                }
            }
        );
    },

    /**
     * Blur handler for the global window
     *
     * @param {FocusEvent} e
     */
    onWindowBlur(e)
    {
        // Cancel all shortcuts since we cannot detect keyup outside of the browser window:
        keysPressed = [];
    },

    /**
     * Keydown event
     *
     * @param {KeyboardEvent} e
     */
    onKeyDown(e)
    {
        const key = KeyboardKey.findByEvent(e);
        if (key === null)
        {
            return !e.defaultPrevented;
        }
        // Map aliases and store the pressed key if it's not being pressed already:
        const keyEnum = KeyboardKeyAliases.get(key.enum) || key.enum;
        if (keysPressed.indexOf(keyEnum) >= 0)
        {
            // Workaround for Mac only sending a keyup event for the command (meta) key if it is being used in a shortcut:
            if (isMac === true && e.metaKey && ['OS', 'Shift', 'Apple', 'Command', 'Ctrl', 'Meta', 'Alt', 'AltGr', 'CapsLock'].indexOf(keyEnum) === -1)
            {
                removePressedKeyAfterDelay(keyEnum);
            }

            // Prevent shortcut spamming:
            if (['Tab', 'Enter', 'Escape', 'OS', 'Alt', 'Apple', 'Command', 'Ctrl', 'Meta', 'Shift', 'Windows'].some(k => keysPressed.indexOf(k) >= 0))
            {
                e.preventDefault();
                e.stopImmediatePropagation();
                e.stopPropagation();
            }

            return !e.defaultPrevented;
        }
        keysPressed[keysPressed.length] = keyEnum;

        // Map shortcut name and convert it to lowercase:
        let shortcut = keysPressed.join('+');
        shortcut = KeyboardKeyAliases.get(shortcut) || shortcut;
        shortcut = shortcut.toLowerCase();

        // Ignore copy, paste and cut since they're handled by the clipboard events:
        if (['copy', 'cut', 'paste'].includes(shortcut))
        {
            return !e.defaultPrevented;
        }

        // Dispatch a custom event if one was registered:
        if (shortcutListeners.filter(l => l.shortcut === shortcut || l.shortcut === 'any').length === 0)
        {
            return !e.defaultPrevented;
        }
        dispatchShortcutEvent(shortcut, e);

        // Workaround for Mac only sending a keyup event for the command (meta) key if it is being used in a shortcut:
        if (isMac === true && e.metaKey && ['OS', 'Shift', 'Apple', 'Command', 'Ctrl', 'Meta', 'Alt', 'AltGr', 'CapsLock'].indexOf(keyEnum) === -1)
        {
            removePressedKeyAfterDelay(keyEnum);
        }

        return !e.defaultPrevented;
    },

    /**
     * Keyup event
     *
     * @NOTE: On Mac OS the keyup event will not be triggered for any other keys if the command (meta) key is being held down!
     *
     * @param {KeyboardEvent} e
     */
    onKeyUp(e)
    {
        const key = KeyboardKey.findByEvent(e);
        if (key === null)
        {
            return !e.defaultPrevented;
        }
        // Remove key from list of pressed keys:
        const keyEnum = KeyboardKeyAliases.get(key.enum) || key.enum;
        keysPressed = keysPressed.filter(k => k !== keyEnum);

        // Workaround for Mac only sending a keyup event for the command (meta) key if it is being used in a shortcut:
        if (isMac === true && keyEnum === 'OS')
        {
            for (const [k, v] of keyupTimers)
            {
                if (v !== null)
                {
                    window.clearTimeout(v);
                }
            }
            keyupTimers.clear();
            keysPressed = [];
        }

        return !e.defaultPrevented;
    },

    /**
     * Handler for clipboard events
     *
     * @param {ClipboardEvent} e
     */
    onClipboardEvent(e)
    {
        // Ignore any events on the clipboard helper textarea:
        if (e.target.matches('textarea#clipboard-copy-helper'))
        {
            return !e.defaultPrevented;
        }

        let shortcut = null;
        switch (e.type)
        {
            case 'copy':
            case 'cut':
            case 'paste':
                shortcut = e.type;
                break;

            default:
                return !e.defaultPrevented;
        }
        // Dispatch a custom event if one was registered:
        if (shortcutListeners.filter(l => l.shortcut === shortcut || l.shortcut === 'any').length === 0)
        {
            return !e.defaultPrevented;
        }
        dispatchShortcutEvent(shortcut, e);
        return !e.defaultPrevented;
    },

    /**
     * Shortcut event
     *
     * @param {CustomEvent} e
     */
    onShortcut(e)
    {
        // Do nothing if the event was stopped:
        if (e.cancelBubble === true)
        {
            return !e.defaultPrevented;
        }
        // Call the listeners in reversed order so the listener that was registered last gets triggered first!
        // @NOTE: Only using global listeners or those from within the element that registered a shortcut
        let listeners = shortcutListeners.filter(l => {
            delete l.containsTarget;
            if (l.shortcut === 'any' || l.shortcut === e.detail.shortcut)
            {
                // Add a temporary attribute so we don't have to filter again for sorting:
                l.containsTarget = l.el.contains(e.target);
                return (l.global || l.containsTarget);
            }
            return false;
        }).reverse();
        // Sort any listeners from the target element first (before any other global listeners):
        listeners = listeners.filter(l => l.containsTarget === true).concat(listeners.filter(l => l.containsTarget === false));
        const mapByElement = new Map();
        for (const listener of listeners)
        {
            // Skip "Any" shortcuts if another shortcut on the element has been triggered already:
            if (mapByElement.has(listener.el))
            {
                continue;
            }
            mapByElement.set(listener.el, true);
            listener.handleEvent(e);
            if (listener.stop === true || e.cancelBubble === true)
            {
                break;
            }
        }
        return !e.defaultPrevented;
    }
};
