
/**
 * Regular expression to match all words from a string individually
 * 
 * @var {RegExp}
 */
const REGEX_SEARCH_WORDS = /[^|\s]+?((?=\|)|(?=\s+)|$)|\|/g;  // Match any words, followed by spaces or pipes and match pipe characters separately

export default class Search
{
    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === Search)
        {
            throw new TypeError('Static class "Search" cannot be instantiated directly.');
        }
    }

    /**
     * Parse a list of search word groups from a string
     * 
     * @static
     * @param {String} s
     * @returns {Array<Array<String>>}
     */
    static parseSearchGroups(s = null)
    {
        // Do nothing if no string is given:
        if (s === null || s === undefined) return [];

        // Check for a valid string:
        if (typeof s !== 'string')
        {
            console.error('Search->parseSearchGroups(): Parameter must be a string', s);
            throw new TypeError('Search->parseSearchGroups(): Parameter must be a string.');
        }

        // Replace "OR" with pipe characters:
        s = s.replace(/ OR /g, '|');

        // Remove multiple pipe characters:
        s = s.replace(/\|[|\s]+/g, '|');

        // Remove multiple hyphen characters:
        s = s.replace(/-[-\s]+/g, '-');

        // Trim characters:
        s = s.replace(/^[|\s]|[-|\s]$/, '');

        // Capture all words from the string and group them:
        const groups = [];
        let groupIndex = 0;
        (s.match(REGEX_SEARCH_WORDS) || []).map(m => m.trim().toLowerCase()).forEach(w => {
            if (w === '|')
            {
                ++groupIndex;
                return;
            }
            if (typeof groups[groupIndex] !== 'undefined')
            {
                groups[groupIndex].push(w);
            }
            else
            {
                groups[groupIndex] = [w];
            }
        });

        return groups;
    }

    /**
     * Filter a list of objects with a given search string
     *
     * @static
     * @param {Array<Object>} elements      // The list of elements to search in
     * @param {Array<String>} properties    // Property names of the objects to search in
     * @param {String} query                // Search query string
     * @returns {Array<Objects>}
     */
    static filterObjects(elements, properties, query = null)
    {
        // Check for valid list of elements:
        if (elements instanceof Array === false)
        {
            console.error('Search->filterObjects(): Parameter must be an array of objects', properties);
            throw new TypeError('Search->filterObjects(): Parameter must be an array of objects.');
        }

        // Check for valid property names:
        if (properties instanceof Array === false || properties.length === 0 || properties.filter(p => typeof p !== 'string').length >= 1)
        {
            console.error('Search->filterObjects(): Parameter must be an array of strings', properties);
            throw new TypeError('Search->filterObjects(): Parameter must be an array of strings.');
        }

        // Parse search groups from the given query:
        const searchGroups = Search.parseSearchGroups(query);

        // Return the given elements if the query is invalid or empty:
        if (searchGroups.length === 0 || properties.length === 0)
        {
            return elements;
        }

        // Only return matching elements:
        return elements.filter(e => {

            // Only use existing string values as lowercase:
            const values = properties.filter(p => typeof e[p] === 'string').map(p => e[p].toLowerCase());

            // All words from at least one group must match:
            const matches = searchGroups.map(words => words.map(word => {
                if (word.indexOf('-') === 0)
                {
                    return (values.find(v => v.indexOf(word.substr(1)) >= 0) || null) === null;
                }
                return (values.find(v => v.indexOf(word) >= 0) || null) !== null;
            }).indexOf(false) === -1);

            return matches.indexOf(true) >= 0;
        });
    }
}
