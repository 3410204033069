<template>
    <div class="trigger-property property">
        <Checkbox
            :model="trigger"
            property="is_objective"
            layout="checkbox-right"
            @change="onTriggerChanged"
            :caption="trans('authoring.trigger_is_objective')"
        />
    </div>
</template>

<script>

import CommandType              from '@/Models/UnitData/Commands/CommandType';
import {ObjectiveCompletedCondition} from '@/Models/UnitData/Conditions/Condition';
import Trigger                  from '@/Models/UnitData/Triggers/Trigger';

export default {
    name: 'PanelTriggerPropertyIsObjective',
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
    },
    methods: {

        /**
         * Change handler for trigger properties
         *
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);

            // Remove the trigger reference from all related ObjectiveCompletedConditions:
            if (!this.trigger.is_objective)
            {
                this.trigger.parentUnitData.allCommands.filter(c =>
                    c.type === CommandType.Condition.type
                    && c.condition !== null
                    && c.condition.type === ObjectiveCompletedCondition.type
                    && c.condition.objective === this.trigger.uid
                ).map(c => c.condition.objective = null);
            }

            return this;
        },

    },
}
</script>

<style lang="scss" scoped>

</style>
