<template>
    <div class="command-settings">
        <Dropdown
            :initial-value="command.object"
            @select="onSelectObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />
        <TextInput
            :disabled="!targetObjectIsSet"
            :model="command"
            property="code"
            :required="!command.isValid"
            type="textarea"
            :maxlength="5000"
            :placeholder="trans('authoring.enter_script_code')"
            @change="onChangeCommand"
            error-msg="Error"
        />
    </div>
</template>

<script>

import {ScriptCommand} from '@/Models/UnitData/Commands/Command';
import { getSceneObjectTargetDropdownOptionsForCommand }     from '@/Models/UnitData/Commands/CommandHelpers';

export default {
    name: 'PanelCommandScript',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: ScriptCommand,
            default: null
        }
    },
    computed: {

        targetObjectIsSet() {
            return this.command.object !== null;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },
    },
    methods: {
        /**
         * Select handler for target object
         *
         * @param {String} value
         */
        onSelectObject(value) {
            if (value !== this.command.object)
            {
                this.command.object = value;
                return this.onChangeCommand();
            }
            return this;
        },
        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>
