import { trans } from '@/Utility/Helpers';
import AuthorizationError from '@/Errors/AuthorizationError';
import RequestError from '@/Errors/RequestError';
import User from '@/Models/User/User';

// Parse the current user:
if (window.currentUser instanceof Object)
{
    window.currentUser = new User(window.currentUser);
}

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

import jquery from 'jquery';
import bootstrap from 'bootstrap';

try {
    window.$ = window.jQuery = jquery;
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['API-Platform'] = 'wms';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

// Add API version to all API requests:
if (typeof window.appData.HEADER_API_VERSION === 'string' && typeof window.appData.API_VERSION === 'string')
{
    window.axios.defaults.headers.common[window.appData.HEADER_API_VERSION] = window.appData.API_VERSION;
}
else
{
    console.error('Header data for API version not found');
}

// Add the user's current tenant to all API requests:
if (typeof window.appData.HEADER_API_TENANT === 'string')
{
    window.axios.interceptors.request.use( config => {
        if (window.currentUser && window.currentUser.tenant)
        {
            config.headers[window.appData.HEADER_API_TENANT] = window.currentUser.tenant.uid;
        }
        return config;
    }, error => Promise.reject(error), { synchronous: true });
}
else
{
    console.error('Header data for API tenant not found');
}

// Handle all axios request errors globally:
window.axios.interceptors.response.use(response => {
    return response;
}, error => {

    // Default handling if there is no status code:
    if (isNaN(error?.response?.status)) {
        return Promise.reject(error);
    }

    const apiInfo = error.response.headers[window.appData.HEADER_API_INFO] || error.response.headers[window.appData.HEADER_API_INFO.toLowerCase()] || null;
    switch (error.response.status)
    {
        // Access denied:
        case 401:
        case 403:
            error.message = trans((apiInfo === 'Invalid API tenant header') ? 'errors.tenants.'+error.response.status : 'errors.error_actions.'+error.response.status + '.message');
            throw new AuthorizationError(error);

        // Validation error:
        case 422:
            error.message = error.response?.data?.message || error.message;
            throw new RequestError(error);

        // Other server errors:
        default:
            error.message = trans('errors.error_actions.' + error.response.status + '.message', {}, true, false) || (error.response.status + ' ' + error.response.statusText);
            throw new RequestError(error);
    }
}, { synchronous: true });
