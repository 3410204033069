<template>
    <li class="support">
        <a :title="trans('tooltips.navigation.support')"
           :href="href"
           @click.prevent="launchResourceCenter"
        >
            <svg class="icon icon-help-center"><use xlink:href="#icon_help-center"></use></svg>
        </a>
    </li>
</template>

<script>
    export default {
        name: 'SidemenuItemUserGuiding',
        props: {
            href: {
                type: String,
                default() {
                    return null;
                },
            }
        },
        computed: {
            isUserGuidingInitiated() {
                if (!(window.userGuiding instanceof Object)) {
                    console.error("UserGuiding script not available!")
                    return false;
                }

                return true;
            },
        },
        methods: {
            launchResourceCenter(e) {
                if (this.isUserGuidingInitiated) {
                    window.userGuiding.expandResourceCenter();
                } else if (this.href !== null) {
                    window.open(this.href, '_blank');
                }
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
