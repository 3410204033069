
/**
 * Dragging initiator element Vue component
 *
 * @static
 * @var {Object}
 */
let draggingComponent = null;

/**
 * Dragging initiator element ID
 *
 * @static
 * @var {String}
 */
let draggingElementId = null;

/**
 * Dragging initiator element DOM node
 *
 * @static
 * @var {HTMLElement}
 */
let draggingElement = null;

/**
 * Dragging groups
 *
 * @static
 * @var {Array<String>}
 */
let draggingGroups = [];

/**
 * Dragging value
 *
 * @static
 * @var {*}
 */
let draggingValue = null;

/**
 * Cloning mode (while pressing [SHIFT] key)
 *
 * @static
 * @var {Boolean}
 */
let cloningMode = false;

export default class Draggable
{
    /**
     * Constructor
     *
     * @NOTE: This class is static since the user will only ever be able to drag'n'drop a single element at the same time
     */
    constructor()
    {
        if (this.constructor === Draggable)
        {
            throw new TypeError('Static class "Draggable" cannot be instantiated directly.');
        }
    }

    /**
     * Get dragging element Vue component
     *
     * @static
     * @returns {Object}
     */
    static get component()
    {
        return draggingComponent;
    }

    /**
     * Set dragging element Vue component
     *
     * @static
     * @param {Object} component
     */
    static set component(component)
    {
        draggingComponent = component;
    }

    /**
     * Get dragging element DOM node
     *
     * @static
     * @returns {HTMLElement}
     */
    static get element()
    {
        return draggingElement;
    }

    /**
     * Set dragging element DOM node
     *
     * @static
     * @param {HTMLElement} element
     */
    static set element(element)
    {
        draggingElement = element;
    }

    /**
     * Get dragging element ID
     *
     * @static
     * @returns {String}
     */
    static get elementId()
    {
        return draggingElementId;
    }

    /**
     * Set dragging element ID
     *
     * @static
     * @param {String} id
     */
    static set elementId(id)
    {
        draggingElementId = id;
    }

    /**
     * Get dragging groups
     *
     * @static
     * @returns {Array<String>}
     */
    static get groups()
    {
        return draggingGroups;
    }

    /**
     * Set dragging groups
     *
     * @static
     * @param {Array<String>} groups
     */
    static set groups(groups)
    {
        // Always convert to array:
        if (typeof groups === 'string')
        {
            groups = groups.split(',');
        }
        // Check for valid data types:
        if (groups !== null && !(groups instanceof Array))
        {
            throw new TypeError('Draggable->groups(): Draggable groups can only be null, a string or an array of strings.');
        }
        draggingGroups = groups || [];
    }

    /**
     * Check if any of the given groups matches
     *
     * @param {String|Array<String>} groups
     * @returns {Boolean}
     */
    static hasGroups(groups) {
        // Always convert to array:
        if (typeof groups === 'string')
        {
            groups = groups.split(',');
        }
        // Check for valid data types:
        if (groups !== null && !(groups instanceof Array))
        {
            throw new TypeError('Draggable->hasGroups(): Draggable groups can only be null, a string or an array of strings.');
        }
        groups = groups || [];
        return (draggingGroups.length === 0 && groups.length === 0) || (draggingGroups.filter(g => groups.includes(g)).length > 0);
    }

    /**
     * Get dragging value
     *
     * @static
     * @returns {*}
     */
    static get value()
    {
        return draggingValue;
    }

    /**
     * Set dragging value
     *
     * @static
     * @param {*} value
     */
    static set value(value)
    {
        draggingValue = value;
    }

    /**
     * Get cloning mode
     *
     * @static
     * @returns {Boolean}
     */
    static get cloningMode()
    {
        return cloningMode;
    }

    /**
     * Set cloning mode
     *
     * @static
     * @param {Boolean} enabled
     */
    static set cloningMode(enabled = true)
    {
        cloningMode = enabled;
    }
}
