<template>

    <main id="layout-main" v-shortcuts.global>

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <form id="create-course-form" ref="form" class="content-panel" method="post" enctype="multipart/form-data" @submit.prevent="onSubmit">
                    <h1 class="headline">
                        <Icon name="icon_courses" class="create-form-header-icon" />
                        {{ trans('courses.create.panel_headline') }}
                    </h1>

                    <!-- Preview image -->
                    <div class="panel-row preview-image-upload-placeholder-wrapper">
                        <label for="preview_image">{{ trans('labels.preview_image') }}</label>
                        <ImageInputField
                            ref="imageInput"
                            field-id="preview_image"
                            field-name="preview_image"
                            :validation-rules="{
                                minWidth: 1280,
                                minHeight: 720,
                                aspectRatio: 16 / 9,
                                allowedTypes: ['PNG', 'JPG'],
                            }"
                            @validation-error="onImageValidationError"
                        />
                    </div>

                    <!-- Title -->
                    <TextInput
                        :errorMsg="trans('errors.course.title')"
                        :label="trans('courses.create.course_title')"
                        :maxlength="50"
                        :model="courseData"
                        property="title"
                        :placeholder="trans('courses.create.course_title_placeholder')"
                        :required="true"
                        :validationErrors="validationErrors('course.title')"
                        class="panel-row course-name-input"
                    />

                    <!-- Description -->
                    <TextInput
                        :label="trans('courses.create.course_description')"
                        :maxlength="600"
                        :model="courseData"
                        property="description"
                        :placeholder="trans('courses.create.course_description_placeholder')"
                        :required="false"
                        :validationErrors="validationErrors('course.description')"
                        class="panel-row course-description-input"
                        type="textarea"
                    />

                    <!-- Buttons -->
                    <div class="panel-row buttons">
                        <ButtonPrimary
                            v-tooltip="'tooltips.buttons.courses.create'"
                            :disabled="isSubmitting"
                            caption="courses.create.btn_create"
                            @trigger="onSubmit"
                        />
                        <ButtonSecondary
                            :href="route('courses.index')"
                            caption="courses.create.btn_cancel"
                        />
                    </div>
                </form>
            </div>

            <DialogSaving :title="trans('courses.create.saving')"/>
            <DialogLoading/>
            <DialogNotification/>
        </div>
    </main>
</template>

<script>

import {route, trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import DialogLoading from '@/Vue/Modals/DialogLoading.vue';
import DialogSaving from '@/Vue/Modals/DialogSaving.vue';
import DialogNotification from '@/Vue/Modals/DialogNotification.vue';
import AuthorizationError from '@/Errors/AuthorizationError';
import ImageInputField from "@/Vue/Common/ImageInputField.vue";
import RequestError from "@/Errors/RequestError.js";

export default {

        name: 'PageCourseCreate',

        components: {
            ImageInputField,
            DialogLoading,
            DialogSaving,
            DialogNotification,
        },

        data() {
            return {
                /**
                 * @type CourseService
                 */
                courseService: this.$courseService,

                courseData: {
                    title: null,
                    description: null,
                    policy: 'standard',
                },

                isSubmitting: false,
                errors: {},

                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Save.prevent', null],
                    ['Enter', this.onSubmit],
                    ['Escape.prevent', this.onClickCancel],
                    ['Backspace.prevent', null]
                ])
            }
        },

        computed: {
            /**
             * @return {File|null}
             */
            previewImageFile() {
                return this.$refs.imageInput.imageFile;
            }
        },

        methods: {

            /**
             * @param {Error} e
             */
            onImageValidationError(e) {
                this.$root.showErrorDialog(e.message);
            },

            /**
             * Get the validation errors for a specific field.
             *
             * @param {String} property
             * @returns {String}
             */
            validationErrors(property) {
                return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root.forceLogout;
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * @param {Event} e
             */
            onSubmit(e) {
                e.preventDefault();

                const form = this.$refs.form;

                if (this.isSubmitting || !form.reportValidity()) {
                    return;
                }

                this.isSubmitting = true;
                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);

                const formData = new FormData();
                formData.append('course', JSON.stringify(this.courseData));
                if (this.previewImageFile !== null) {
                    formData.append('preview_image', this.previewImageFile);
                }

                this.courseService
                    .createCourseFromFormData(formData)
                    .then(course => {
                        this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                        window.location.href = route('courses.edit', {'course': course.uid});
                    })
                    .catch((error) => {
                        this.errors = error.validationErrors || {};

                        // Show error dialog unless it's a validation error:
                        if (!(error instanceof RequestError && error.isValidationError)) {
                            this.$root.showErrorDialog(error);

                        // Show error dialog when preview image is invalid:
                        } else if (this.errors.preview_image) {
                            this.$root.showErrorDialog(trans('courses.create.preview_requirements_error'));
                            this.$refs.imageInput.reset();
                        }
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                    });
            },

            /**
             * @param {Event} e
             */
            onClickCancel(e) {
                e.preventDefault();
                window.location.href = route('courses.index');
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
