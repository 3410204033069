<template>
    <div
        class="modal-preview-wrapper"
        @contextmenu.prevent>

        <div
            class="content-description"
            v-if="contentDescription"
        >
            <p v-for="paragraph in contentDescriptionParagraphs">
                {{ paragraph }}
            </p>
        </div>
        <audio
            ref="audio-player"
            controls=""
            controlsList="nodownload"
            @contextmenu.prevent
            autoplay
        >
            <source
                :src="soundUrl"
                :type="mimeType"
            />
        </audio>
    </div>
</template>

<script type="module">

export default {
    name: "SoundRenderer",
    props: {
        soundUrl: {
            type: String,
            required: true,
        },
        mimeType: {
            type: String,
            required: true,
        },
        contentDescription: {
            type: String,
            default: null,
        },
    },
    computed: {

        contentDescriptionParagraphs() {
            return this.contentDescription.split('\n');
        }

    },
}

</script>

<style scoped lang="scss">

.modal-preview-wrapper {
    width: 100%;
}

.content-description {
    max-width: 600px;
    margin-bottom: 16px;
}

audio {
    width: 100%;
    border-radius: var(--btn-border-radius);
}

</style>
