<template>
    <div class="property variable">

        <Dropdown
            :deselected-caption="trans('authoring.select_variable')"
            :initial-value="condition.variable"
            :model="condition"
            property="variable"
            :options="optionsForTargetVariable"
            :required="true"
            @select="onSelectTargetVariable"
        />

        <div v-if="conditionDropdownVisible" class="variable-property-operation">

            <Dropdown
                class="variable-property-operation-dropdown"
                :key="'comparison_type_'+condition.variable"
                :deselected-caption="trans('authoring.select_comparison')"
                :disabled="optionsForComparisonType.length <= 1"
                :model="condition.comparison"
                property="type"
                :options="optionsForComparisonType"
                :required="true"
                @select="onSelectComparisonType"
            />

            <Dropdown
                v-if="shouldShowOperandDropdown"
                class="variable-property-operand-dropdown"
                :key="'comparison_operand_'+condition.variable"
                :deselected-caption="trans('authoring.select_comparison')"
                :model="condition.comparison"
                property="operand"
                :options="optionsForComparisonOperand"
                :required="true"
                @select="onSelectComparisonOperand"
            />
            <template v-if="shouldShowOperandValueComponent">
                <component
                    v-if="componentForOperand"
                    :is="componentForOperand"
                    :operand="condition.comparison.operand"
                    class="variable-property-operand-value"
                    @change="onChangeOperand"
                />

                <Icon
                    name="icon_close"
                    class="icon-delete"
                    @click="onClickRemoveOperand()"
                />
            </template>
        </div>
    </div>
</template>

<script>
    import {
        defaultComparisonForVariableType,
        dropdownOptionsForVariables,
        getAvailableComparisonOptionsForVariableOfType,
        getAvailableOperandOptionsForVariableOfType,
        getOperandComponentNameFromComparison,
        getVariable,
        getVariableModuleUid
    } from '@/Models/UnitData/Variables/VariableHelpers';
    import {
        NumberValueOperand,
    } from '@/Models/UnitData/Variables/Operand';
    import Command from '@/Models/UnitData/Commands/Command';
    import Condition from '@/Models/UnitData/Conditions/Condition';
    import VariableComparison from '@/Models/UnitData/Variables/VariableComparison';
    import DropdownOption from '@/Utility/DropdownOption';

    export default {
        name: 'PanelVariableCondition',
        emits: [
            'change',
        ],
        props: {
            command: Command,
            condition: Condition,
        },
        computed: {
            conditionDropdownVisible() {
                return this.targetVariableIsSet;// && this.optionsForComparisonType.length > 0;
            },

            targetVariable() {
                if (!this.targetVariableIsSet) {
                    return null;
                }

                return getVariable(this.command.parentUnitData, this.condition.object, this.condition.variable);
            },

            targetVariableIsSet() {
                return this.condition.variable !== null && this.condition.object !== null;
            },

            /**
             * Should the select operand dropdown be visible?
             */
            shouldShowOperandDropdown() {

                return this.targetVariableIsSet && this.condition.comparison !== null &&
                    (this.condition.comparison.operand === null || this.condition.comparison.operand.type !== NumberValueOperand.Type);
            },

            /**
             * Should the select operand value be visible?
             */
            shouldShowOperandValueComponent() {

                const comparison = this.condition.comparison;

                return comparison !== null && comparison.type
                    && comparison.operand !== null && comparison.operand.type === NumberValueOperand.Type;
            },

            /**
             * Get options for target scene object selection dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            optionsForTargetVariable() {
                return dropdownOptionsForVariables(this.command);
            },

            /**
             * Get options for comparison type dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            optionsForComparisonType() {
                if (!this.targetVariableIsSet || this.targetVariable === null) {
                    return [];
                }

                return getAvailableComparisonOptionsForVariableOfType(this.targetVariable.type);
            },

            /**
             * Get options for comparison operand dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            optionsForComparisonOperand() {
                if (!this.targetVariableIsSet || this.targetVariable === null) {
                    return [];
                }

                return getAvailableOperandOptionsForVariableOfType(this.command, this.targetVariable.type);
            },

            /**
             * Get the name of the component that should be rendered for selecting an operand.
             */
            componentForOperand() {
                return getOperandComponentNameFromComparison(this.condition.comparison);
            },

        },
        methods: {

            /**
             * Select handler for target variable
             *
             * @param {String} targetVariableUid
             */
            onSelectTargetVariable(targetVariableUid) {
                this.condition.variable = targetVariableUid;

                const targetObjectUid = getVariableModuleUid(this.command.parentUnitData, targetVariableUid);
                this.condition.object = targetObjectUid;

                // Only reset the comparison when the variable type changed:
                const targetVariable = this.condition.targetVariable;
                const comparison = this.condition.comparison;
                if (comparison === null || comparison.type.indexOf(targetVariable.type) !== 0)     // @NOTE: This only works when comparison type name starts with variable type (e.g. "number_less_or_equal" starts with type "number")
                {
                    this.resetComparison();
                }

                this.$emit('change', this.condition);

                return this;
            },

            /**
             * Select handler for comparison type
             *
             * @param {String} value
             */
            onSelectComparisonType(value) {

                // Set the comparison selected in the Dropdown
                const newComparison = VariableComparison.createWithType(value, this.condition.comparison || {}, this.condition);

                // construct full new comparison from just the type
                this.condition.comparison = newComparison;
                this.$emit('change', this.condition);

                return this;
            },

            /**
             * Select handler for comparison operand
             *
             * @param {Operand} value
             */
            onSelectComparisonOperand(value) {

                value.parent = this.condition.comparison;
                this.condition.comparison.operand = value;
                this.$emit('change', this.condition);

                return this;
            },

            /**
             *  Remove the Operand of the condition's comparison
             */
            onClickRemoveOperand() {

                // Set the operation selected in the Dropdown
                this.condition.comparison.operand = null;

                // Trigger change event for the command
                this.$emit('change', this.condition);

                return this;
            },

            onChangeOperand() {
                // bubble changes in comparison up to condition
                this.$emit('change', this.condition);
            },

            /**
             * Reset to basic comparison object of the command. Necessary because not all comparisons have the same properties.
             */
            resetComparison() {

                const defaultComparison = defaultComparisonForVariableType(this.targetVariable.type);
                defaultComparison.operand = null;
                defaultComparison.parent = this.condition;
                this.condition.comparison = defaultComparison;
                this.$emit('change', this.condition);

                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
