<template>
    <div v-if="isVisible" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <h2 class="title">{{ title }}</h2>
            <p class="description" v-html="description"></p>
            <div class="buttons">
                <ButtonSecondary v-if="cancelText !== null" class="btn-cancel" @trigger="onClickCancel" :caption="cancelText" />
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" :caption="applyText" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import { trans }                from '@/Utility/Helpers';

    export default {
        name: 'DialogApplyCancel',
        props: {
            title: String,
            description: String,
            eventType: {
                type: String,
                default: 'MODAL_APPLY_CANCEL'    // Must be a string from EventType class
            },
            applyText: {
                type: String,
                default() {
                    return trans('labels.okay')
                }
            },
            cancelText: {
                type: String,
                default() {
                    return trans('labels.cancel')
                }
            },
        },
        data() {
            return {
                isVisible: false,                       // Visibility state
                model: null,                            // Reference to the model object for which this dialog is being shown
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        created() {
            // Make sure the given type's events exist:
            ['SHOW', 'HIDE', 'CANCEL', 'APPLY'].forEach((e) => {
                if (typeof EventType[this.eventType + '_' + e] !== 'string')
                {
                    throw new TypeError('DialogApplyCancel: EventType ' + (this.eventType + '_' + e) + ' doesn\'t exist.');
                }
            });
        },
        mounted() {
            this.$globalEvents.on(EventType[this.eventType + '_SHOW'], this.show);
            this.$globalEvents.on(EventType[this.eventType + '_HIDE'], this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType[this.eventType + '_SHOW'], this.show);
            this.$globalEvents.off(EventType[this.eventType + '_HIDE'], this.hide);
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {Object} model
             */
            show(model = null) {
                this.previousFocusElement = document.activeElement;
                this.model = model;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.model = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType[this.eventType + '_CANCEL']);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType[this.eventType + '_APPLY'], this.model);
                this.hide();
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
