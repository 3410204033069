<template>
    <span :class="'dropdown-element ' + (label ? 'has-label' : '')" :data-ref-uid="selectedObjectReferenceUid">

        <!-- Label -->
        <label v-if="label" :for="uid">{{ label }}</label>

        <!-- Dropdown container -->
        <span
            ref="domObject"
            :id="uid"
            :class="cssClasses"
            v-focusable-if="!disabled"
            @blur="onBlur"
            v-shortcuts
        >
            <!-- Button -->
            <span
                :title="titleAttributeForSelectedOption"
                class="dropdown-button"
                @click="toggleList"
            >
                <!-- Icon -->
                <Icon
                    v-if="selectedOption && selectedOption.icon"
                    :name="selectedOption.icon"
                    class="dropdown-icon"
                />

                <!-- Caption -->
                <span class="selected-value">{{ selectedCaption }}</span>

                <!-- Arrow icon -->
                <Icon name="icon_arrow-dropdown" class="icon-arrow" />
            </span>

            <!-- Options List -->
            <span
                v-if="options && isListVisible"
                class="dropdown-list"
                :style="listStyle"
                v-not-focusable
                @blur="onBlur"
            >

                <!-- Loop through options -->
                <template v-for="(option, index) in options">

                    <!-- Disabled/Separator -->
                    <template v-if="!isGroup(option) && !isOptionFocusable(option)">
                        <span
                            :title="getTitleAttributeForOption(option)"
                            :data-ref-uid="option.referencedObjectUid"
                            :data-ug-title="getUgTitleAttribute(option)"
                            :class="cssOptionClasses(option)"
                            :key="'option'+index"
                        >
                            <!-- Icon -->
                            <Icon
                                v-if="option.icon"
                                :key="'optionIcon'+index"
                                :name="option.icon"
                                class="dropdown-icon icon disabled"
                            />

                            <!-- Caption -->
                            <span v-if="option.caption" class="option-caption">{{ option.caption }}</span>
                        </span>
                    </template>

                    <!-- Enabled -->
                    <template v-else>

                        <!-- Group (Collapsible) -->
                        <Collapsible
                            v-if="isGroup(option) && option.collapsible"
                            :key="'optiongroup'+index"
                            :initial-collapsed="(option.autoCollapseLimit > 0 && option.options.length > option.autoCollapseLimit && null === option.findOptionByValue(selectedValue))"
                            :data-ref-uid="option.referencedObjectUid"
                            @blur="onBlur"
                        >
                            <template #header>

                                <!-- Collapsible group header -->
                                <span
                                    :title="getTitleAttributeForString(option.caption + ' (' + option.options.length + ')')"
                                    :data-ug-title="getUgTitleAttribute(option)"
                                    :class="cssOptionClasses(option)" :key="'option'+index"
                                >
                                    <!-- Icon -->
                                    <Icon
                                        v-if="option.icon"
                                        :key="'optionIcon'+index"
                                        :name="option.icon"
                                        class="dropdown-icon icon"
                                    />

                                    <!-- Caption -->
                                    <span class="option-caption">{{ option.caption }} <span class="count">({{ option.options.length }})</span></span>
                                </span>

                            </template>

                            <template #body>

                                <!-- Loop through group options -->
                                <span
                                    v-for="(groupoption, gindex) in option.options"
                                    v-focusable-if="isOptionFocusable(groupoption)"
                                    @blur="onBlur"
                                    @focus="onFocusOption(groupoption)"
                                    :title="getTitleAttributeForOption(groupoption)"
                                    :data-ref-uid="groupoption.referencedObjectUid"
                                    :data-ug-title="getUgTitleAttribute(groupoption)"
                                    :class="cssOptionClasses(groupoption) + ' group-option'"
                                    :key="'group' + index + 'option'+gindex"
                                    @click="!isOptionFocusable(groupoption) ? null : onSelect(groupoption.value)"
                                >
                                    <!-- Icon -->
                                    <Icon
                                        v-if="groupoption.icon"
                                        :key="'group'+index+'optionIcon'+gindex"
                                        :name="groupoption.icon"
                                        class="dropdown-icon icon"
                                    />

                                    <!-- Caption -->
                                    <span v-if="groupoption.caption" class="option-caption">{{ groupoption.caption }}</span>
                                </span>
                            </template>
                        </Collapsible>

                        <!-- Group (not collapsible) -->
                        <template v-else-if="isGroup(option) && !option.collapsible">

                            <!-- Group header -->
                            <span
                                :title="getTitleAttributeForOption(option)"
                                :data-ref-uid="option.referencedObjectUid"
                                :data-ug-title="getUgTitleAttribute(option)"
                                :class="cssOptionClasses(option)"
                                :key="'option'+index"
                            >
                                <!-- Icon -->
                                <Icon
                                    v-if="option.icon"
                                    :key="'optionIcon'+index"
                                    :name="option.icon"
                                    class="dropdown-icon icon"
                                />

                                <!-- Caption -->
                                <span class="option-caption">{{ option.caption }} <span class="count">({{ option.options.length }})</span></span>
                            </span>

                            <!-- Loop through group options -->
                            <span
                                v-for="(groupoption, gindex) in option.options"
                                v-focusable-if="isOptionFocusable(groupoption)"
                                @blur="onBlur"
                                @focus="onFocusOption(groupoption)"
                                :title="getTitleAttributeForOption(groupoption)"
                                :data-ref-uid="groupoption.referencedObjectUid"
                                :data-ug-title="getUgTitleAttribute(groupoption)"
                                :class="cssOptionClasses(groupoption) + ' group-option'"
                                :key="'group' + index + 'option'+gindex"
                                @click="!isOptionFocusable(groupoption) ? null : onSelect(groupoption.value)"
                            >
                                <!-- Icon -->
                                <Icon
                                    v-if="groupoption.icon"
                                    :key="'group'+index+'optionIcon'+gindex"
                                    :name="groupoption.icon"
                                    class="dropdown-icon icon"
                                />

                                <!-- Caption -->
                                <span v-if="groupoption.caption" class="option-caption">{{ groupoption.caption }}</span>
                            </span>
                        </template>

                        <!-- Option -->
                        <span v-else
                            v-focusable-if="isOptionFocusable(option)"
                            @blur="onBlur"
                            @focus="onFocusOption(option)"
                            :title="getTitleAttributeForOption(option)"
                            :data-ref-uid="option.referencedObjectUid"
                            :data-ug-title="getUgTitleAttribute(option)"
                            :class="cssOptionClasses(option)"
                            :key="'option'+index"
                            @click="!isOptionFocusable(option) ? null : onSelect(option.value)"
                        >
                            <!-- Icon -->
                            <Icon
                                v-if="option.icon"
                                :key="'optionIcon'+index"
                                :name="option.icon"
                                class="dropdown-icon icon"
                            />

                            <!-- Caption -->
                            <span class="option-caption">{{ option.caption }}</span>
                        </span>

                    </template>
                </template>
            </span>
        </span>
    </span>
</template>

<script>
    // Import classes:
    import _ from 'lodash';
    import DropdownOptionGroup      from '@/Utility/DropdownOptionGroup';
    import { shortId, trans }       from '@/Utility/Helpers';

    export default {
        name: 'Dropdown',
        emits: [
            'select',
        ],
        props: {
            initialValue: {         // The initially selected value (either use this or model+property)
                default: null
            },
            label: {                // Optional label text
                type: String,
                default: null
            },
            model: {                // Associated model
                type: Object,
                default: null
            },
            property: {             // Property name from the associated model that should be modified
                type: String,
                default: null
            },
            disabled: {             // Disabled state
                type: Boolean,
                default: false
            },
            required: {             // Required state
                type: Boolean,
                default: false
            },
            overlay: {              // Whether to show the dropdown list as an overlay or inline
                type: Boolean,
                default: true
            },
            options: {              // List of DropdownOptions and DropdownOptionGroups
                type: Array,
                default() {
                    return [];
                }
            },
            deselectedCaption: {    // Caption text to show when no value is selected
                type: String,
                default: trans('labels.choose_option')
            }
        },
        data() {
            return {
                uid: shortId('dropdown'),   // A unique identifer for HTML id="" attribute
                selectedValue: null,        // The selected value
                focusedValue: null,         // The currently focused value
                isListVisible: false,       // Whether the dropdown list is visible
                maxListHeight: 500,         // Maximum list height
                shortcuts: new Map([
                    ['Enter', this.onShortcut],
                    ['Escape', this.onShortcut],
                    ['DownArrow', this.onShortcut],
                    ['UpArrow', this.onShortcut],
                    ['Space', this.onShortcut],
                ])
            }
        },
        computed: {

            /**
             * CSS classes for the dropdown
             *
             * @returns {String}
             */
            cssClasses() {
                const classes = ['dropdown'];

                // Enabled/disabled state:
                classes.push((this.disabled === true) ? 'disabled' : 'enabled');

                // Open or closed:
                if (this.isListVisible === true)
                {
                    classes.push('open');
                }

                // Overlay mode:
                if (this.overlay === true)
                {
                    classes.push('overlay-list');

                    if (this.isListVisible === true && this.showListAbove() === true)
                    {
                        classes.push('open-above');
                    }
                }

                // Required state:
                if (this.required === true)
                {
                    classes.push('required');
                }

                // Empty state:
                if (this.selectedValue === null || this.selectedValue === '')
                {
                    classes.push('is-empty');
                }

                return classes.join(' ');
            },

            /**
             * List CSS style
             *
             * @returns {String}
             */
            listStyle() {
                return 'max-height:' + Math.floor(this.maxListHeight) + 'px;';
            },

            /**
             * The selected value's label
             *
             * @returns {String}
             */
            selectedCaption() {
                const caption = (this.selectedOption !== null && typeof this.selectedOption.caption === 'string') ? this.selectedOption.caption : this.deselectedCaption;
                // Append the group name:
                const selectedValue = this.selectedValue;
                const group = (this.options.length >= 1) ? this.options.filter(o => o instanceof DropdownOptionGroup && o.options.length >= 1).find(o => o.findOptionByValue(selectedValue) !== null) || null : null;
                return caption + (group !== null && group.showGroupNameInCaption === true ? ' (' + group.caption + ')' : '');
            },

            /**
             * The selected dropdown option
             *
             * @returns {DropdownOption}
             */
            selectedOption() {
                const groupOptions = this.options.filter(o => o instanceof DropdownOptionGroup && o.options.length >= 1).map(o => o.options);
                const options = [...this.options, ...groupOptions].flat();

                if (typeof this.selectedValue === 'object') {
                    return (options.length >= 1 && this.selectedValue !== null) ? options.find(o => _.isEqual(o.value, this.selectedValue)) || null : null;
                }

                const selectedOption = (options.length >= 1 && this.selectedValue !== null) ? options.find(o => o.value === this.selectedValue) || null : null;

                if (selectedOption === null) {
                    this.selectedValue = null;
                }

                return selectedOption;
            },

            selectedObjectReferenceUid() {
                return this.selectedOption?.referencedObjectUid || null;
            },

            /**
             * The title attribute for the selected option
             *
             * @returns {String}
             */
            titleAttributeForSelectedOption() {
                const selectedOption = this.selectedOption;
                return (selectedOption !== null) ? selectedOption.title || this.selectedCaption : this.selectedCaption;
            },
        },
        beforeMount() {

            // Check properties
            if (this.model !== null && this.property === null) {
                console.warn('Dropdown->beforeMount(): Property :model="" is set but no property="" name is given.', this);
            }
            if (this.model !== null && this.initialValue !== null) {
                console.warn('Dropdown->beforeMount(): Both :model="" and :initial-value="" are set. You should use just one of them.', this);
            }

            // Set initial value:
            if (this.initialValue !== null)
            {
                this.selectedValue = this.initialValue;
            }
            else if (this.model !== null && this.property !== null && typeof this.model[this.property] !== 'undefined')
            {
                this.selectedValue = this.model[this.property];
            }
        },
        methods: {
            /**
             * Find the first parent DOM element that has overflow hidden so we can calculate the maximum height for the dropdown list
             *
             * @returns {HTMLElement}
             */
            findFirstParentWithOverflowHidden() {
                let parent = (this.$refs.domObject !== null) ? this.$refs.domObject.parentNode : null;
                let style;
                while (!(parent instanceof HTMLBodyElement))
                {
                    style = getComputedStyle(parent);
                    const check = ['auto', 'hidden', 'scroll'];
                    if (check.indexOf(style.overflow) >= 0 || check.indexOf(style.overflowY) >= 0 || check.indexOf(style.overflowX) >= 0)
                    {
                        return parent;
                    }
                    parent = parent.parentNode;
                }
                return document.body;
            },

            /**
             * Whether to show the dropdown list above the element
             *
             * @returns {Boolean}
             */
            showListAbove() {
                const posRect = (this.options !== null && this.$refs.domObject !== null) ? this.$refs.domObject.getBoundingClientRect() : null;
                if (posRect === null)
                {
                    this.maxListHeight = 500;
                    return false;
                }
                const viewportHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                const containerRect = this.findFirstParentWithOverflowHidden().getBoundingClientRect();
                const spaceAbove = posRect.y - containerRect.y - 5;
                const spaceBelow = containerRect.bottom - posRect.bottom - 5;
                let countVisibleOptions = this.options.length;
                this.options.map(o => {
                    if (o.disabled === false && o instanceof DropdownOptionGroup)
                    {
                        countVisibleOptions += o.options.length;
                    }
                });
                if (spaceAbove > 100 && ((posRect.y > viewportHeight * 0.5 && countVisibleOptions > 10)
                    || (posRect.y > viewportHeight * 0.6 && countVisibleOptions > 8)
                    || (posRect.y > viewportHeight * 0.7 && countVisibleOptions > 5)
                    || (posRect.y > viewportHeight * 0.8)))
                {
                    this.maxListHeight = Math.min(500, spaceAbove);
                    return true;
                }
                this.maxListHeight = Math.min(500, Math.max(100, spaceBelow));
                return false;
            },

            /**
             * CSS classes for options and groups
             *
             * @param {DropdownOption|DropdownOptionGroup} optionOrGroup
             * @returns {String}
             */
            cssOptionClasses(optionOrGroup) {
                const classes = [];

                // Group or option:
                if (optionOrGroup instanceof DropdownOptionGroup)
                {
                    classes.push('dropdown-option-group');
                    classes.push(optionOrGroup.collapsible ? 'is-collapsible' : 'not-collapsible');
                    if (optionOrGroup.isSeparator) {classes.push('is-separator');}
                }
                else if (optionOrGroup.isSeparator)
                {
                    classes.push('dropdown-option-separator is-separator');
                }
                else
                {
                    classes.push('dropdown-option');
                }

                // Disabled state:
                if (optionOrGroup.disabled)
                {
                    classes.push('disabled');
                }

                // Selected state:
                if (
                    optionOrGroup.value !== null
                    && this.selectedValue !== null
                    && _.isEqual(optionOrGroup.value, this.selectedValue)
                )
                {
                    classes.push('selected');
                }

                return classes.join(' ');
            },

            /**
             * @param {String} title
             * @returns {String}
             */
            getTitleAttributeForString(title) {
                return title !== null && title.length > 18 ? title : '';
            },

            /**
             * @param {DropdownOption|DropdownOptionGroup} option
             * @returns {String}
             */
            getTitleAttributeForOption(option) {
                return option.title || this.getTitleAttributeForString(option.caption);
            },

            /**
             * @param {DropdownOption|DropdownOptionGroup} option
             * @returns {String}
             */
            getUgTitleAttribute(option) {
                return option.caption;
            },

            /**
             * Check if a given option is a group
             *
             * @param {DropdownOption|DropdownOptionGroup} optionOrGroup
             * @returns {Boolean}
             */
            isGroup(optionOrGroup) {
                return (optionOrGroup instanceof DropdownOptionGroup);
            },

            /**
             * Determine if an option is focusable (disabled options, non-collapsible groups and separators are not!)
             *
             * @param {DropdownOption|DropdownOptionGroup} optionOrGroup
             * @returns {Boolean}
             */
            isOptionFocusable(optionOrGroup)
            {
                const isGroup = this.isGroup(optionOrGroup);
                return !(optionOrGroup.disabled || (isGroup && !optionOrGroup.collapsible) || (!isGroup && optionOrGroup.isSeparator));
            },

            /**
             * Select handler
             *
             * @param {String} value
             */
            onSelect(value) {
                this.toggleList(false);
                if (value === this.selectedValue)
                {
                    return this;
                }
                this.selectedValue = value;
                this.focusedValue = null;
                if (this.model !== null && this.property !== null)
                {
                    this.model[this.property] = value;
                }
                this.$emit('select', value, this);
                return this;
            },

            /**
             * Focus handler for options
             *
             * @param {DropdownOption|DropdownOptionGroup} option
             */
            onFocusOption(option) {
                this.focusedValue = option.value;
                return this;
            },

            /**
             * Blur handler
             *
             * @param {FocusEvent} e
             */
            onBlur(e) {
                if (this.isListVisible === true && false === this.$el.contains(e.relatedTarget))
                {
                    this.toggleList(false);
                }
                return this;
            },

            /**
             * Toggle visibility for the list
             *
             * @param {Boolean} show
             */
            toggleList(show) {
                this.focusedValue = null;
                if (this.disabled === true)
                {
                    this.isListVisible = false;
                    return this;
                }
                if (typeof show === 'boolean')
                {
                    this.isListVisible = show;
                }
                else
                {
                    this.isListVisible = !this.isListVisible;
                }
                // Keep focus on the dropdown:
                if (this.isListVisible || this.$el.contains(document.activeElement))
                {
                    this.$refs.domObject.focus();
                }
                return this;
            },

            /**
             * Stop shortcut event
             *
             * @param {CustomEvent} e
             */
            stopShortcutEvent(e) {
                e.preventDefault();
                e.detail.keyboardEvent.preventDefault();
                e.stopImmediatePropagation();
                e.detail.keyboardEvent.stopImmediatePropagation();
                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcut(e) {
                if (this.disabled === true)
                {
                    return this;
                }
                if (this.isListVisible === true)
                {
                    switch (e.detail.shortcut)
                    {
                        // Close the list on escape:
                        case 'escape':
                            this.toggleList(false);
                            this.stopShortcutEvent(e);
                            return this;

                        // Move selection up:
                        case 'uparrow':
                            // @TODO
                            this.stopShortcutEvent(e);
                            return this;

                        // Move selection down:
                        case 'downarrow':
                            // @TODO
                            this.stopShortcutEvent(e);
                            return this;

                        // Select entry:
                        case 'enter':
                        case 'space':
                            if (this.focusedValue !== null)
                            {
                                this.onSelect(this.focusedValue);
                            }
                            else
                            {
                                this.toggleList(false);
                            }
                            this.stopShortcutEvent(e);
                            return this;
                    }
                }
                else
                {
                    // Open the list on DownArrow:
                    if (['enter', 'space', 'downarrow'].indexOf(e.detail.shortcut) >= 0)
                    {
                        this.toggleList(true);
                        this.stopShortcutEvent(e);
                        return this;
                    }
                }
                return this;
            },

            scrollSelectedItemIntoView() {
                this.$el.querySelector('.selected')?.scrollIntoView({block: 'nearest', inline: 'nearest'});
            },
        },
        watch: {
            isListVisible(isVisible) {
                if (isVisible && this.selectedValue) {
                    this.$nextTick(this.scrollSelectedItemIntoView);
                }
            }
        }
    }
</script>

<style lang="scss">

    .dropdown {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;

        .dropdown-button {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            height: 27px;
            align-items: center;

            .dropdown-icon {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 40px;
                padding: 0 4px 0 12px;  // @NOTE: Affects SVG icon size
                margin-right: -3px; // For alignment with dropdown options
                margin-top: 1px;
            }

            .selected-value {
                flex-grow: 1;
                flex-basis: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                padding-left: 8px;
            }

            .icon-arrow {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 40px;
                padding: 0 11px;  // @NOTE: Affects SVG icon size
                transition: transform 0.1s linear 0s;
                color: var(--color-anthracite40);
            }
        }

        .dropdown-list {
            max-height: 500px;
            overflow: auto;
        }

        .collapsible-header {
            display: block;
            height: auto !important;
            padding: 0 0 0 14px !important;

            &:before {
                left: 8px !important;
                margin-top: -7px;
            }
        }

        .dropdown-option,
        .dropdown-option-group,
        .dropdown-option-separator {
            display: flex;
            align-items: center;
            cursor: pointer;
            flex-basis: 100%;
            padding-left: 8px;
            padding-right: 8px;
            line-height: 29px;
            transition: background-color .1s, color .1s, border-color .1s, opacity .1s;

            .dropdown-icon {
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 24px;
                width: 24px;
                height: 24px;
                margin-left: 4px;

                & + span.option-caption {
                    padding-left: 12px;
                }
            }

            span.option-caption {
                flex-grow: 1;
                //flex-basis: 100%; // Disabled because of the separator line
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                vertical-align: middle;
            }

            &.group-option {
                padding-left: 20px;

                .dropdown-icon {
                    margin-left: 0;
                }
            }

            &.disabled,
            &.disabled:hover,
            &.disabled:focus,
            &.disabled:focus-within,
            &.disabled:active {
                cursor: default;
                opacity: 0.5;
            }
        }

        .dropdown-option-group,
        .dropdown-option-separator {
            cursor: default;
            pointer-events: none;
        }

        &.overlay-list {
            .dropdown-list {
                position: absolute;
                top: 100%;
                width: 100%;
                box-shadow: 0 2px 5px rgba(0,0,0,0.25);
            }

            &.open-above .dropdown-list {
                top: auto;
                bottom: 100%;
            }
        }

        &.disabled {
            pointer-events: none;

            .dropdown-button {
                cursor: not-allowed;
                opacity: 0.5;
            }
        }

        &.open {
            .dropdown-button {
                .icon-arrow {
                    transform: rotate(180deg);
                }
            }
        }
    }

</style>
