<template>

    <div :class="classes" @click="onCreateAvatarClicked">

        <input
            :id="fieldId"
            :name="fieldName"
            ref="fileInput"
            class="image-upload-input"
            type="file"
            :accept="imageValidation.acceptTypes"
            required
        />

        <div v-if="hasError" class="error">
            <span>?</span>
        </div>

        <template v-else-if="!isLoading && avatar_glb_url === null">
            <Icon name="icon_add" class="icon-add"/>
        </template>

        <img
            v-if="imageSrc"
            :src="imageSrc"
            :class="previewImageClasses"
            @error="onLoadError"
            @load="onLoadFinished"
        />

        <ButtonCircular
            v-if="showEditButton"
            class="btn-change-character"
            icon="icon_edit"
            @trigger="onChangeAvatarClicked"
        />

        <div
            v-if="isLoading"
            class="loader">
            <LoadingIndicator/>
        </div>
    </div>

</template>

<script lang="ts">
    import {trans} from "@/Utility/Helpers/trans";
    import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
    import Icon from "@/Vue/Common/Icon.vue";
    import EventType from "@/Utility/EventType.js";
    import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";
    import ImageValidation, {ImageValidationRules} from "@/Models/Asset/ImageValidation";
    import {defineComponent, PropType, ref} from "vue";
    import Compressor from "compressorjs";

    export default defineComponent({
        name: 'ReadyPlayerMeCharacterCreator',

        components: {
            ButtonCircular,
            Icon,
            LoadingIndicator,
        },

        emits: {
            'character-model-url-changed': (_: string) => true,
            'character-model-preview-changed': (_: File | null) => true,
        },

        props: {

            error_image_url: {
                type: String,
                default: "/svg/error-image-2.svg"
            },

            allowed_base_urls: {
                type: Array<string>,
                default: () => [
                    'https://models.readyplayer.me/', // new
                    'https://api.readyplayer.me/v1/avatars/', // old
                    `${window.location.origin}/avatars/v1/`
                ]
            },

            /**
             * Rules about which image type and dimensions are allowed.
             */
            validationRules: {
                type: Object as PropType<ImageValidationRules>,
                required: true,
            },

            /**
             * Name of the file input field for usage in html forms.
             */
            fieldName: {
                type: String,
                required: true,
            },

            /**
             * ID of the file input field for label relations.
             */
            fieldId: {
                type: String,
                required: false,
            },
        },

        data() {
            return {
                avatar_glb_url: null as string | null,
                avatarLoadKey: Math.random(),
                hasError: false as Boolean,
                isLoading: false as Boolean,
                imageFile: null as File | null,
                imageSrc: null as string | null,
            }
        },

        mounted() {
            this.$globalEvents.on(EventType.MODAL_READY_PLAYER_ME_APPLY, this.onAvatarCreated);
        },

        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_READY_PLAYER_ME_APPLY, this.onAvatarCreated);
        },

        setup() {
            return {
                fileInput: ref<HTMLInputElement>(),
            }
        },

        computed: {

            showEditButton() {
                return (!this.isLoading && this.readyPlayerMeImageSource() !== null && this.readyPlayerMeImageSource() !== '');
            },

            imageValidation() {
                return new ImageValidation(this.validationRules);
            },

            classes() {
                let classes = ['read-player-me-character-creator'];

                if (this.isLoading) {
                    classes.push('loading');
                }

                if (!this.showEditButton && !this.isLoading) {
                    classes.push('interactive')
                }

                return classes.join(' ');
            },

            previewImageClasses() {
                let classes = ['preview-image'];

                if (this.isLoading) {
                    classes.push('opacity-0');
                }

                return classes.join(' ');
            },
        },

        methods: {
            trans,

            /**
             * @return {String} url of the avatar image to load
             */
            readyPlayerMeImageSource(): string | null {
                if (this.avatar_glb_url === null) {
                    return null;
                }

                // do not make requests to arbitrary urls in our name
                if (this.allowed_base_urls.every(baseUrl => this.avatar_glb_url !== null && !this.avatar_glb_url.startsWith(baseUrl))) {
                    console.log('imageSource error');
                    this.hasError = true;
                    return null;
                }

                const size = this.validationRules.minHeight || 720;
                return this.avatar_glb_url.replace(
                    '.glb',
                    `.png?size=${size}&blendShapes[mouthSmile]=0.3&blendShapes[browInnerUp]=0.2`
                );
            },

            onLoadStarted() {
                this.isLoading = true;
            },

            onLoadFinished() {
                this.hasError = false;
                this.isLoading = false;
            },

            onLoadError() {
                const url = this.readyPlayerMeImageSource();
                if (url !== "" && url) {
                    this.hasError = true;
                    this.isLoading = false;
                }
            },

            onChangeAvatarClicked(event) {
                event.preventDefault();

                if (event.target !== null) {
                    event.target.blur();
                }

                this.$globalEvents.emit(EventType.MODAL_READY_PLAYER_ME_SHOW);
            },

            onCreateAvatarClicked(event) {
                event.preventDefault();

                if (event.target !== null) {
                    event.target.blur();
                }

                if (this.avatar_glb_url !== null) {
                    return false;
                }

                this.$globalEvents.emit(EventType.MODAL_READY_PLAYER_ME_SHOW);
            },

            /**
             * @param {String} avatarUrl
             */
            onAvatarCreated(avatarUrl: string) {
                this.isLoading = true;

                this.$emit('character-model-url-changed', avatarUrl);
                this.avatar_glb_url = avatarUrl;

                // cache busting for avatar preview - because url might not change, but the underlying image data does
                this.avatarLoadKey = Math.random();

                const imageUrl = this.readyPlayerMeImageSource() as string;

                fetch(imageUrl)
                    .then(response => response.blob())
                    .then(blob => {
                        this.createPreview(blob);
                    });
            },

            createPreview(imageBlob: Blob) {
                new Compressor(imageBlob, {
                    retainExif: true,
                    resize: 'contain',
                    width: this.validationRules.minWidth || 1280,
                    height: this.validationRules.minHeight || 720,
                    beforeDraw(context, canvas) {
                        context.fillStyle = '#E8EDEF';
                        context.fillRect(0, 0, canvas.width, canvas.height);
                    },
                    success: (imageBlob: Blob) => {
                        this.imageSrc = window.URL.createObjectURL(imageBlob);
                        const compressedImageFile = new File([imageBlob], this.getFileNameFromUrl(this.imageSrc, 'image/png'));
                        this.imageFile = compressedImageFile;
                        const dataTransfer = new DataTransfer();
                        dataTransfer.items.add(compressedImageFile);
                        this.fileInput.files = dataTransfer.files;
                        this.imageFile.resized = true;
                        this.isLoading = false;
                        this.$emit('character-model-preview-changed', this.imageFile);
                        //console.info(`ImageInputField: Image has been resized to fit maximum dimensions.`)
                    },
                    error: (err) => {
                        this.isLoading = false;
                        console.error(err);
                    }
                });
            },

            getFileNameFromUrl(url: string, mimetype: string | null): string {
                let extension;
                switch (mimetype) {
                    case 'image/png':
                        extension = 'png';
                        break;
                    default:
                        extension = 'jpg';
                        break;
                }

                const fallbackName = `image.${extension}`;

                if (url.startsWith('data')) {
                    return fallbackName;
                } else {
                    return new URL(url).pathname.split('/').pop() || fallbackName;
                }
            },

            /**
             * Resets this component to its initial state.
             *
             * @public
             */
            reset() {
                this.avatar_glb_url = null;
                this.avatarLoadKey = Math.random();
                this.isLoading = false;
                this.hasError = false;
                this.imageFile = null;
                this.imageSrc = null;
                this.fileInput.files = null;
            },
        }
    });
</script>

<style lang="scss" scoped>

    .read-player-me-character-creator {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: 100%;
        flex-basis: 240px;
        flex-shrink: 0;
        width: 240px;
        height: 135px;
        box-sizing: content-box;
        background-color: var(--background-color-light);
        border-radius: var(--card-border-radius-small);
        border: var(--forminput-border);
        transition: border-color .1s;
        overflow: hidden;

        &.interactive {
            cursor: pointer;

            &:hover,
            &:focus-within {
                border-color: var(--color-primary-hover);
            }
        }

        &:focus-within {
            border-color: var(--color-primary-hover);
        }

        .opacity-0 {
            opacity: 0;
        }

        .icon {
            width: 24px;
            height: 24px;
            color: var(--color-anthracite40);
            transition: color .1s;
            pointer-events: none;
        }

        &:hover .icon {
            color: var(--color-primary-hover);
        }

        & > img {
            height: 100%;
            width: 100%;
            object-fit: contain;
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .loader {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .btn-change-character {
            position: absolute;
            right: 10px;
            bottom: 10px;
            background-color: var(--background-color-white);
        }

        .error {
            height: 100%;
            width: 100%;
            display: table;
            text-align: center;
            color: var(--color-anthracite40);
            font-size: var(--font-size-largest);
            font-family: var(--font-family-condensed);

            span {
                vertical-align: middle;
                display: table-cell;
            }
        }

        .image-upload-input {
            position: absolute;
            width: 100%;
            opacity: 0;
            left: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
        }
    }

</style>
