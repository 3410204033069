<template>
    <div class="property condition-script">
        <Dropdown
            :initial-value="condition.object"
            @select="onSelectObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />
        <TextInput
            :disabled="!targetObjectIsSet"
            :model="condition"
            property="code"
            :required="!command.isValid"
            type="textarea"
            :maxlength="5000"
            :placeholder="trans('authoring.enter_script_code')"
            @change="onChangeCode"
            error-msg="Error"
        />
    </div>
</template>

<script>
import { getSceneObjectTargetDropdownOptionsForCommand }     from '@/Models/UnitData/Commands/CommandHelpers';
import Command                              from '@/Models/UnitData/Commands/Command';
import { ScriptCondition }                  from '@/Models/UnitData/Conditions/Condition';

export default {
    name: 'PanelConditionScript',
    emits: [
        'change',
    ],
    props: {
        command: Command,
        condition: ScriptCondition,
    },
    computed: {

        targetObjectIsSet() {
            return this.condition.object !== null;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

    },
    methods: {

        /**
         * Select handler for target object
         *
         * @param {String} value
         */
        onSelectObject(value) {
            if (value !== this.condition.object)
            {
                this.condition.object = value;
                this.$emit('change', this.condition);
            }
            return this;
        },

        /**
         * Change handler for the code text field
         *
         * @param {String} value
         */
        onChangeCode(value) {
            // @TODO: Implement checks for illegal characters and invalid code
            if (value !== this.condition.code)
            {
                this.condition.code = value;
                this.$emit('change', this.condition);
            }
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
