<template>
    <div id="inspector" class="inspector-content" v-not-focusable>
        <header class="inspector-header">
            <Icon name="icon_scene" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.trainingscene_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div class="panels" v-not-focusable :key="'SidepanelTrainingScene' + renderTimestamp">
            <div class="inspector-panel panel-trainingscene">

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :disabled="shouldDisableTrainingSceneEditing"
                        :model="scene"
                        property="title"
                        @change="onChangeProperty"
                        :required="true"
                        :maxlength="50"
                        :placeholder="trans('units.create.scene_title_placeholder')"
                        :error-msg="trans('errors.trainingscene.title')"
                    />
                </div>

                <!-- Monoscopic camera -->
                <div v-if="hasMonoscopicProperty" class="property property-monoscopic">
                    <Checkbox
                        :disabled="isMonoscopicDisabled"
                        :model="scene"
                        property="monoscopic"
                        layout="checkbox-right"
                        @change="onChangeProperty"
                        :caption="trans('authoring.monoscopic')"
                    />
                </div>

                <!-- Description -->
                <div class="property property-description">
                    <TextInput
                        :disabled="shouldDisableTrainingSceneEditing"
                        type="textarea"
                        :label="trans('labels.description')"
                        :model="scene"
                        property="description"
                        @change="onChangeProperty"
                        :maxlength="600"
                        :placeholder="trans('units.create.scene_description_placeholder')"
                        :error-msg="trans('errors.trainingscene.description')"
                    />
                </div>

                <PanelStartPosition
                    :scene="scene"
                    @change="onChangeProperty"
                />

            </div>

        </div>

        <SidepanelTrainingSceneButtons
            :scene="scene"
        />

        <!-- Modal dialog for duplicating a training scene -->
        <DialogDuplicateTrainingScene/>

        <!-- Modal dialog for scene remove confirmation -->
        <DialogApplyCancel
            event-type="MODAL_REMOVE_UNIT_SCENE"
            :title="trans('modals.remove_scene_from_unit.title')"
            :description="trans('modals.remove_scene_from_unit.description')"
        />
    </div>
</template>

<script>
    // Import VueJS components:
    import SidepanelTrainingSceneButtons from '@/Vue/Sidepanel/SidepanelTrainingSceneButtons';
    import DialogApplyCancel         from '@/Vue/Modals/DialogApplyCancel.vue';
    import DialogDuplicateTrainingScene         from '@/Vue/Modals/DialogDuplicateTrainingScene.vue';

    // Import classes:
    import { permission }           from '@/Utility/Helpers';
    import { Permission }           from '@/Models/User/Permission';
    import TrainingScene            from '@/Models/UnitData/Scenes/TrainingScene';
    import UnitType                 from '@/Models/UnitData/UnitType';
    import PanelStartPosition from "@/Vue/Inspector/PanelStartPosition.vue";


    export default {
        name: 'SidepanelTrainingScene',
        emits: [
            'change',
        ],
        components: {
            PanelStartPosition,
            SidepanelTrainingSceneButtons,
            DialogApplyCancel,
            DialogDuplicateTrainingScene,
        },
        props: {
            scene: {                    // Selected training scene for which to show the properties
                type: TrainingScene,
                default: null
            },
        },
        data() {
            return {
                renderTimestamp: null,                          // Helper for forcing re-rendering
            }
        },
        computed: {

            /**
             * @returns {UnitData}
             */
            unitData() {
                return this.scene.parentUnitData;
            },

            /**
             * @returns {UnitData}
             */
            shouldDisableTrainingSceneEditing() {
                return !permission(Permission.UnitsUpdate()) || this.scene.readonly;
            },

            /**
             * Does the scene have a monoscopic camera property?
             *
             * @returns {Boolean}
             */
            hasMonoscopicProperty() {
                return (this.unitData !== null && [UnitType.VR.type, UnitType.ThreeSixty.type].indexOf(this.unitData.type) >= 0);
            },

            /**
             * Does the scene have a monoscopic camera property?
             *
             * @returns {Boolean}
             */
            isMonoscopicDisabled() {
                return (!permission(Permission.UnitsUpdate()) || (this.scene !== null && this.scene.readonly) || (this.unitData !== null && (this.unitData.type === UnitType.ThreeSixty.type)));
            }

        },
        methods: {

            /**
             * Change handler for properties
             */
            onChangeProperty() {
                this.$emit('change', this.scene);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
