<template>
    <div id="inspector" v-not-focusable class="inspector-content">
        <header class="inspector-header">
            <Icon name="icon_courses" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.course_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div v-not-focusable class="panels">
            <div v-if="course" :key="'PanelViewCourse'+course.uid" class="inspector-panel panel-view-course">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    :editable="false"
                    :src="previewUrl"
                />

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :error-msg="trans('errors.course.title')"
                        :maxlength="50"
                        :model="course"
                        property="title"
                        :required="true"
                        :disabled="true"
                    />
                </div>

                <!-- Description -->
                <div v-if="course.description" class="property property-description">
                    <TextInput
                        :maxlength="600"
                        :model="course"
                        property="description"
                        :required="false"
                        type="textarea"
                        :disabled="true"
                    />
                </div>

                <PanelUserEnrollment
                    v-if="showUserEnrollments && (canEnrollUsers || courseIsSample)"
                    :course="course"
                />

            </div>

        </div>
    </div>
</template>

<script>
    // Import VueJS components:
    import InspectorItemPreviewImage from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
    import PanelUserEnrollment from "@/Vue/Inspector/PanelUserEnrollment";

    // Import classes:
    import Course from "@/Models/Course/Course";
    import CoursePermissionPolicy, {CoursePermissionPolicySample} from "@/Models/Course/CoursePermissionPolicy";
    import DropdownOption from "@/Utility/DropdownOption";
    import {permission, sortArrayByProperty, trans} from "@/Utility/Helpers";
    import {Permission} from "@/Models/User/Permission";

    export default {
        name: 'SidepanelViewCourse',
        emits: [
            'change',
        ],
        components: {
            InspectorItemPreviewImage,
            PanelUserEnrollment,
        },
        props: {
            course: {
                type: Course,
                default: null,
            },
            showUserEnrollments: {
                type: Boolean,
                default: false,
            },
        },
        computed: {

            canEnrollUsers() {
                return this.$gate.allows(Permission.ability(Permission.CoursesEnrollUsers()), this.course);
            },

            courseIsSample() {
                return CoursePermissionPolicySample.type === this.course.policy;
            },

            /**
             * Options for Policy dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForPolicy() {
                return sortArrayByProperty(
                    CoursePermissionPolicy.getPermissionPolicyMapping().map(
                        ([policyPermission, policy]) => new DropdownOption({
                            caption: trans('course_policies.' + policy.constructor.type),
                            value: policy.constructor.type,
                            disabled: !(permission(policyPermission) && policy instanceof CoursePermissionPolicy),
                        })
                    ),
                    'caption',
                    false
                );
            },

            previewUrl() {
                if (this.course.preview.startsWith('data:')) {
                    // do not append anything to user selected images
                    return this.course.preview;
                }

                // Append last updated millis of course to avoid caching a changed course image with same url.
                // This construct can be removed as soon as we have unique urls per image revision.
                return `${this.course.preview}?t=${this.course.updated_at.getTime()}`
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
