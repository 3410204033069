<template>
    <div v-if="isVisible" id="dialog-save-course-changes" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <h2 class="title">{{ trans('modals.save_course_changes.title') }}</h2>
            <p class="description" v-html="trans('modals.save_course_changes.description')"></p>
            <div class="buttons">
                <ButtonSecondary class="btn-cancel" @trigger="onClickCancel" caption="modals.save_course_changes.cancel" />
                <ButtonSecondary class="btn-dismiss" @trigger="onClickDismiss" caption="modals.save_course_changes.dismiss" />
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="modals.save_course_changes.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';

    export default {
        name: 'DialogSaveCourseChanges',
        data() {
            return {
                isVisible: false,                      // Visibility state
                course: null,                          // Reference to the course for which this dialog is being shown
                previousFocusElement: null,            // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_SAVE_COURSE_CHANGES_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_SAVE_COURSE_CHANGES_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_SAVE_COURSE_CHANGES_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_SAVE_COURSE_CHANGES_HIDE, this.hide);
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param course
             */
            show(course = null) {
                this.previousFocusElement = document.activeElement;
                this.course = course;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.course = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_SAVE_COURSE_CHANGES_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType.MODAL_SAVE_COURSE_CHANGES_APPLY, this.course);
                this.hide();
                return this;
            },

            /**
             * Click handler for dismiss button
             *
             * @param {MouseEvent} e
             */
            onClickDismiss(e) {
                this.$globalEvents.emit(EventType.MODAL_SAVE_COURSE_CHANGES_DISMISS, this.course);
                this.hide();
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
