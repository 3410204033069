import { trans } from '@/Utility/Helpers';

export default class RetriggerType
{
    /**
     * Constructor
     *
     * @param {String} type
     * @param {String} title
     * @param {String} description
     */
    constructor(type, title, description)
    {
        this.type = type;
        this.title = title;
        this.description = description;
    }

    /**
     * RetriggerType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {RetriggerType}
     */
    static get Cancel()                 { return RetriggerTypeDefinitions.Cancel; }
    static get CancelAndRestart()       { return RetriggerTypeDefinitions.CancelAndRestart; }
    static get IgnoreAndContinue()      { return RetriggerTypeDefinitions.IgnoreAndContinue; }

    /**
     * Get all execution types as an array
     *
     * @returns {Array<RetriggerType>}
     */
    static get all()
    {
        return Object.values(RetriggerTypeDefinitions);
    }

    /**
     * Get ExecutionType by a given type name
     *
     * @param {String} type
     * @returns {ExecutionType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        return RetriggerType.all.some(definition => definition.type === type);
    }
}


/**
 * Type definitions
 */
const RetriggerTypeDefinitions = {
    Cancel: new RetriggerType(
        'cancel',
        trans('retrigger.cancel'),
        trans('retrigger.cancel_description')
    ),
    CancelAndRestart: new RetriggerType(
        'cancel_and_restart',
        trans('retrigger.cancel_and_restart'),
        trans('retrigger.cancel_and_restart_description')
    ),
    IgnoreAndContinue: new RetriggerType(
        'ignore_and_continue',
        trans('retrigger.ignore_and_continue'),
        trans('retrigger.ignore_and_continue_description')
    ),
};
