<template>
    <div v-if="isVisible"
         id="dialog-ready-player-me"
         v-not-focusable
         v-shortcuts.global.prevent.stop
         class="modal-dialog fullscreen">
        <div class="dialog">
            <Icon name="icon_close" class="icon-close" @click="onClickHide" />
            <iframe ref="frame"
                    allow="camera *; microphone *; clipboard-write"
                    class="frame"
                    :src="iFrameSrc">
            </iframe>
        </div>
    </div>
</template>

<script lang="ts">

    // Import classes:
    import EventType from '@/Utility/EventType';
    import {defineComponent, PropType} from "vue";


    // Type definitions:
    type ReadyPlayerMeEvent = {
        source: string,
        eventName: 'v1.frame.ready' | 'v1.avatar.exported',
        data: {
            url: string,
        }
    };

    type AvatarType = 'user' | 'npc';


    export default defineComponent({
        name: 'DialogReadyPlayerMe',

        props: {
            avatarType: {
                type: String as PropType<AvatarType>,
                required: true,
            },
            clearCache: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                isVisible: false,                             // Visibility state
                previousFocusElement: null as Element | null, // DOM element that had focus before the dialog was shown
                shortcuts: new Map([                   // Shortcut mapping to methods
                    ['Escape', this.hide],
                    ['Any', null],                            // Prevent any other shortcut
                ])
            }
        },

        mounted() {
            this.$globalEvents.on(EventType.MODAL_READY_PLAYER_ME_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_READY_PLAYER_ME_HIDE, this.hide);

            window.addEventListener('message', this.onMessageReceived);
        },

        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_READY_PLAYER_ME_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_READY_PLAYER_ME_HIDE, this.hide);

            window.removeEventListener('message', this.onMessageReceived);
        },

        computed: {

            iFrameSrc() {
                let baseUrl = (this.avatarType === 'user')
                    ? 'https://3spinlearning.readyplayer.me/avatar?frameApi'
                    : 'https://3spinlearning-npc.readyplayer.me/avatar?frameApi';

                return this.clearCache
                    ? baseUrl + '&clearCache'
                    : baseUrl;
            },

        },

        methods: {

            /**
             * Show the dialog
             */
            show() {
                this.previousFocusElement = document.activeElement;
                this.isVisible = true;
                this.$nextTick(() => {
                    if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();
                });
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof HTMLElement) {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            onClickHide() {
                // Click events will not register on the iframe itself. So we can confidently close
                // this dialog, because the user clicked on the dialogs background.
                this.hide();
            },

            onMessageReceived(event: { data: string; }) {
                const json = this.parseMessage(event);
                if (json === null || json.source !== 'readyplayerme') {
                    return;
                }

                switch (json.eventName) {
                    case 'v1.frame.ready':
                        this.onFrameReady();
                        break;
                    case 'v1.avatar.exported':
                        this.onAvatarExported(json);
                        break;
                }
            },

            onFrameReady() {
                // subscribe to all events sent from Ready Player Me
                this.$refs.frame.contentWindow.postMessage(
                    JSON.stringify({
                        target: 'readyplayerme',
                        type: 'subscribe',
                        eventName: 'v1.**'
                    }),
                    '*'
                );
            },

            onAvatarExported(event: ReadyPlayerMeEvent) {
                this.$globalEvents.emit(EventType.MODAL_READY_PLAYER_ME_APPLY, event.data.url);
                this.hide();
            },

            parseMessage(event: { data: string; }): ReadyPlayerMeEvent | null {
                try {
                    return JSON.parse(event.data);
                } catch (error) {
                    return null;
                }
            }
        }
    });
</script>

<style lang="scss" scoped>

    #dialog-ready-player-me .dialog {
        overflow: hidden;
        width: 100%;
        height: 724px;
        padding-top: 40px;
        padding-bottom: 32px;
    }

    iframe {
        display: block;
        border: none;
        width: 100%;
        height: 100%;
    }

</style>
