import { trans } from '@/Utility/Helpers';

export default class TrainingInput
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('TrainingInput->constructor(): Invalid data.', attributes);
            throw new TypeError('TrainingInput->constructor(): Property "title" has to be set on TrainingInput.');
        }
        if (typeof attributes.type !== 'string' || TrainingInput.isValidType(attributes.type) === false)
        {
            console.warn('TrainingInput->constructor(): Invalid data.', attributes);
            throw new TypeError('TrainingInput->constructor(): Property "type" has to be set on TrainingInput. Must be a valid type from TrainingInput class.');
        }
        if (attributes.description === null || typeof attributes.description !== 'string' || attributes.description.length === 0)
        {
            console.warn('TrainingInput->constructor(): Invalid data.', attributes);
            throw new TypeError('TrainingInput->constructor(): Property "description" has to be set on TrainingInput.');
        }
        if (typeof attributes.icon !== 'string' || attributes.icon.length === 0)
        {
            console.warn('TrainingInput->constructor(): Invalid data.', attributes);
            throw new TypeError('TrainingInput->constructor(): Property "icon" has to be set on TrainingInput.');
        }

        // Populate the model:
        this.type = attributes.type;                                        // Type identifier
        this.icon = attributes.icon;                                        // Icon identifier
        this.title = attributes.title;                                      // Translated title of this TrainingInput (e.g. 'Virtual Hands')
        this.description = attributes.description || null;                  // Translated description of this TrainingInput
    }

    /**
     * TrainingInput "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {TrainingInput}
     */
    static get Controllers()                { return StaticTrainingInputs.Controllers; }
    static get Hands()                      { return StaticTrainingInputs.Hands; }

    /**
     * Get all types as an array
     *
     * @returns {Array<TrainingInput>}
     */
    static get all()
    {
        return Object.values(StaticTrainingInputs);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in TrainingInputs)
        {
            if (TrainingInputs[i].type === type)
            {
                return true;
            }
        }
        return false;
    }

    /**
     * Get TrainingInput by a given type name
     *
     * @param {String} type
     * @returns {TrainingInput|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }
}

/**
 * Type definitions
 */
const StaticTrainingInputs = {};
const TrainingInputs = {

    Controllers: {
        type: 'controllers',
        icon: 'icon_ar',
        title: trans('traininginputs.controllers'),
        description: trans('traininginputs.controllers_description'),
    },

    Hands: {
        type: 'hands',
        icon: 'icon_360deg',
        title: trans('traininginputs.hands'),
        description: trans('traininginputs.hands_description'),
    }
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in TrainingInputs)
{
    StaticTrainingInputs[i] = new TrainingInput(TrainingInputs[i]);
}
