<template>
    <main
        id="layout-main"
        :data-loading="isLoading"
        :data-saving="isSaving"
        class="course-index"
        v-shortcuts.prevent
    >

        <PageHeader
            :page-title="pageHeadline"
        />

        <div id="layout-content">
            <div id="content" ref="content" @scroll="onScrollList" v-not-focusable>

                <div class="list-filters-wrapper">

                    <ListFiltersBar
                        ref="listFiltersBar"
                        :categories="tabFilterCategories"
                        :sorting-filters="sortingFilters"
                        :sort-descending="sortDescending"
                        :filter-words="searchKeywords"
                        :show-search-button="true"
                        @change="applyFilters"
                        @reset="onResetFilters"
                        @trigger-search="onTriggerSearch"
                    >
                        <template v-slot:buttons>
                            <ButtonPrimary
                                v-if="shouldShowCreateCourseButton"
                                v-tooltip="'buttons.courses.create'"
                                :href="$root.route('courses.createForm')"
                                caption="courses.index.btn_create"
                                class="btn-add-new"
                                icon="icon_add"
                            />
                        </template>
                    </ListFiltersBar>

                </div>

                <div ref="courseList" class="course-list">

                    <CourseListItem
                        v-focusable
                        v-for="course in courses.slice(0, renderItemsLoaded)"
                        :key="'course_' + course.uid"
                        :course="course"
                        :selected="(selectedCourse && selectedCourse.uid === course.uid)"
                        @focus="selectCourse"
                        @click="selectCourse"
                        @dblclick="onDoubleClickCourseListItem"
                    />

                    <template v-if="!isLoading && courses.length === 0">
                        <NoItemsFound v-if="searchKeywords" />
                        <NoItemsAvailable v-else />
                    </template>

                </div>

                <Pagination
                    v-if="courses.length > 0"
                    :currentPage="pagingMetadata.current_page"
                    :numberOfPages="pagingMetadata.last_page"
                    @click="onClickChangePage"
                />

            </div>

            <aside
                v-if="selectedCourse !== null"
                id="layout-inspector"
                class="open">
                <SidepanelViewCourse
                    :key="selectedCourse.uid"
                    :course="selectedCourse"
                    :show-user-enrollments="true"
                />
            </aside>
            <aside
                class="layout-sidepanel"
                :class="{ 'open': showSidePanel }"
            >
                <SidepanelUsers
                    :is-visible="showUsers"
                    :users="unenrolledUsers"
                    :assignment-button-label="'labels.enroll_for_learning'"
                />
            </aside>
            <DialogLoading/>
            <DialogNotification/>
            <DialogSaving/>

            <DialogApplyCancel
                event-type="MODAL_DELETE_COURSE"
                :title="trans('modals.delete_course.title')"
                :description="descriptionTextDelete"
                :applyText="trans('modals.delete_course.apply')"
                :cancelText="trans('modals.delete_course.cancel')"
            />

            <DialogApplyCancel
                event-type="MODAL_REMOVE_ENROLLMENTS_FROM_COURSE"
                :title="trans('modals.remove_enrollments_from_course.title')"
                :description="trans('modals.remove_enrollments_from_course.description')"
            />
        </div>
    </main>
</template>

<script>

    import {trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import EventType from "@/Utility/EventType";
    import DialogApplyCancel from '@/Vue/Modals/DialogApplyCancel.vue';
    import DialogLoading from '@/Vue/Modals/DialogLoading.vue';
    import DialogNotification from '@/Vue/Modals/DialogNotification.vue';
    import DialogSaving from '@/Vue/Modals/DialogSaving.vue';
    import AuthorizationError from "@/Errors/AuthorizationError";
    import CourseListItem from "@/Vue/Courses/CourseListItem";
    import SidepanelViewCourse from "@/Vue/Sidepanel/SidepanelViewCourse";
    import SidepanelUsers from "@/Vue/Sidepanel/SidepanelUsers";
    import ListFiltersBar from "@/Vue/Common/ListFiltersBar.vue";
    import CourseFilters from "@/Filters/CourseFilters.js";
    import SortingFilters from "@/Filters/SortingFilters.js";
    import NoItemsAvailable from "@/Vue/Search/NoItemsAvailable.vue";
    import NoItemsFound from "@/Vue/Search/NoItemsFound.vue";
    import {inject} from "vue";
    import {userServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

    export default {

        name: 'PageCourseList',

        components: {
            NoItemsFound, NoItemsAvailable,
            ListFiltersBar,
            SidepanelUsers,
            CourseListItem,
            DialogApplyCancel,
            DialogLoading,
            DialogNotification,
            DialogSaving,
            SidepanelViewCourse,
        },

        mounted() {
            this.fetchUsers();

            this.$globalEvents.addEvent('click.global.course-list', this.onClickGlobal);
            this.$globalEvents.on(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);

            // Delete Course
            this.$globalEvents.on(EventType.MODAL_DELETE_COURSE_SHOW, this.onShowDeleteCourse);
            this.$globalEvents.on(EventType.MODAL_DELETE_COURSE_APPLY, this.onApplyDeleteCourse);

            // User Enrollment
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_SHOW, this.onShowUsersSidePanel);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_HIDE, this.onHideUsersSidePanel);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_ASSIGN, this.onEnrollUsersToCourse);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_CANCEL, this.onHideUsersSidePanel);
            this.$globalEvents.on(EventType.USER_ASSIGNMENT_USERS_CHANGED, this.onChangeUserEnrollment);
        },

        beforeUnmount() {
            this.$globalEvents.removeEvent('click.global.course-list', this.onClickGlobal);
            this.$globalEvents.off(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);

            // Delete Course
            this.$globalEvents.off(EventType.MODAL_DELETE_COURSE_SHOW, this.onShowDeleteCourse);
            this.$globalEvents.off(EventType.MODAL_DELETE_COURSE_APPLY, this.onApplyDeleteCourse);

            // User Enrollment
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_SHOW, this.onShowUsersSidePanel);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_HIDE, this.onHideUsersSidePanel);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_ASSIGN, this.onEnrollUsersToCourse);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_CANCEL, this.onHideUsersSidePanel);
            this.$globalEvents.off(EventType.USER_ASSIGNMENT_USERS_CHANGED, this.onChangeUserEnrollment);
        },

        data() {
            return {
                /**
                 * Page title to be displayed in the header.
                 */
                pageTitle: '',

                /**
                 * @type CourseService
                 */
                courseService: this.$courseService,

                /**
                 * @type UserService
                 */
                userService: inject(userServiceKey),

                /**
                 * @type Course|null
                 */
                selectedCourse: null,

                /**
                 * Helpers for showing the course title in generic modal dialogs
                 */
                descriptionTextDelete: trans('modals.delete_course.description'),
                showSidePanel: false,           // Whether the side panel is visible
                showUsers: false,               // Whether the users are visible in the side panel
                enrolledUsers: [],              // List of users that are enrolled in the currently selected course
                unenrolledUsers: [],            // List of users that aren't enrolled in the currently selected course

                /**
                 * How many items to display initially
                 *
                 * @type Number
                 */
                renderItemsLoaded: 30,

                /**
                 * How many items to add at once when scrolling
                 *
                 * @type Number
                 */
                renderItemsPerPage: 30,

                /**
                 * Filter categories for navigation tabs
                 *
                 * @var {Array<FilterCategory>}
                 */
                tabFilterCategories: [
                    CourseFilters.MyCourses.setActive(true, true),
                    CourseFilters.SpacesAndSamples,
                ],

                /**
                 * Filters for sorting
                 */
                sortingFilters: [
                    SortingFilters.Alphabetical.setActive(true, true),
                    SortingFilters.CreatedAt,
                    SortingFilters.UpdatedAt,
                ],

                /**
                 * Sorting order
                 */
                sortDescending: false,

                /**
                 * Search keywords from the search input
                 * @type String
                 */
                searchKeywords: null,

                /**
                 * Shortcut mapping to methods
                 * @type Map
                 */
                shortcuts: new Map([
                    ['Save.global.prevent', null],              // Prevent browser saving
                    ['Backspace.global.prevent', null],         // Prevent going back in browser history
                    ['Enter', this.onShortcutEnter],            // Open course
                    ['Delete', this.onShortcutDelete],          // Delete course
                ]),
            }
        },

        computed: {

            /**
             * @returns {Boolean}
             */
            shouldShowCreateCourseButton() {
                return this.$gate.allows(Permission.CoursesCreate());
            },

            /**
             * @return {Array<Course>} the current list of courses from the service
             */
            courses() {
                return this.courseService.coursePage.courseList;
            },

            /**
             * @return {PagingMetadata} the current metadata about the page from the service
             */
            pagingMetadata() {
                return this.courseService.coursePage.pagingMetadata;
            },

            /**
             * Header page title
             *
             * @returns {Boolean}
             */
            pageHeadline() {
                return (this.pageTitle !== '' && this.pageTitle !== null) ? this.pageTitle : trans('courses.index.headline');
            },

            /**
             * Loading state
             *
             * @returns {Boolean}
             */
            isLoading() {
                if (this.courseService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
                return false;
            },

            /**
             * Saving state
             *
             * @returns {Boolean}
             */
            isSaving() {
                if (this.courseService.isSaving) {
                    this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                return false;
            },

            canDeleteCourse() {
                return this.$gate.allows(Permission.ability(Permission.CoursesDelete()), this.selectedCourse);
            },
        },

        methods: {
            /**
             * Fetch courses for the current user from API
             * @param {number} page
             */
            fetchCourses(page = 1) {

                const filters = {
                    policy: this.tabFilterCategories.filter(f => f.isActive).map(f => f.paramName),
                };
                const orderBy = this.sortingFilters.find(f => f.active)?.paramName || null;
                this.courseService
                    .fetchCourses(page, filters, this.searchKeywords, orderBy, this.sortDescending)
                    .then(this.onSuccessFetchCourses)
                    .catch(this.onErrorApi);
                return this;
            },

            onSuccessFetchCourses() {
                // Reset scrolling list
                this.renderItemsLoaded = this.renderItemsPerPage;

                // Scroll to top:
                this.$refs.content.scrollTo(0,0);

                if (this.courses.length > 0) {
                    // Select and focus first course
                    this.selectCourse(this.courses[0]);
                    this.$refs.courseList?.children[0]?.focus();
                } else {
                    this.selectedCourse = null;
                }
                return this;
            },

            /**
             * Fetch users in the current tenant
             */
            fetchUsers() {
                if (this.$gate.denies(Permission.UsersRead())) {
                    return this;
                }

                this.userService.fetchUsers()
                    .then(this.onSuccessFetchUsers);

                return this;
            },

            /**
             * Called when the user triggered a page change.
             * @param {number} page
             */
            onClickChangePage(page) {
                this.fetchCourses(page);
                return this;
            },

            selectCourse(course) {
                this.selectedCourse = course;
                this.setUserEnrollments();

                return this;
            },

            /**
             * Opens the given course for editing
             * @param {Course} course
             */
            editCourse(course) {
                window.location.href = this.$root.route('courses.edit', {'course': course.uid});
                return this;
            },

            /**
             * Opens the given course for viewing
             * @param {Course} course
             */
            viewCourse(course) {
                window.location.href = this.$root.route('courses.view', {'course': course.uid});
                return this;
            },

            onDoubleClickCourseListItem(course) {
                if (
                    this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), course)
                    || this.$gate.allows(Permission.ability(Permission.CoursesRead()), course)
                ) {
                    this.viewCourse(course);
                }

                return this;
            },

            /**
             * Show handler for deleting a course
             * @param {Course} course
             */
            onShowDeleteCourse(course) {
                this.descriptionTextDelete = trans('modals.delete_course.description', {title: course.title});
                return this;
            },

            /**
             * Delete the given course
             * @param {Course} course
             */
            onApplyDeleteCourse(course) {
                this.courseService
                    .deleteCourse(course)
                    .then(() => {
                        const pageToLoad = this.courseService.coursePage.courseList.length > 1 // Are there more courses on the current page?
                            ? this.pagingMetadata.current_page // Yes -> Load updated courses for the current page
                            : this.pagingMetadata.current_page > 0 // No -> Are there still more pages?
                                ? this.pagingMetadata.current_page - 1 // Yes -> Load previous page
                                : 0; // No -> Load first page

                        this.fetchCourses(pageToLoad)
                    })
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root.forceLogout;
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * Before unload handler
             *
             * @param {BeforeUnloadEvent} e
             */
            onBeforeUnload(e) {
                this.courseService.cancelRequests();
                // @NOTE: $nextTick() doesn't work here since isLoading will be recomputed afterwards so the quick fix is to use setTimeout
                setTimeout(() => {this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);},1);
                this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                return this;
            },

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                // Close users side panel:
                // @NOTE: This will stop working if the SidepanelUsers component is replaced!
                if (
                    this.showUsers === true
                    && e.target.matches('.btn.assign-users') === false
                    && document.getElementById('sidepanel-users').contains(e.target) === false
                ) {
                    this.onHideUsersSidePanel();
                }
                return this;
            },

            /**
             * Update the users side panel
             */
            setUserEnrollments() {
                if (this.selectedCourse !== null)
                {
                    this.enrolledUsers = this.userService.getEnrolledUsersForCourse(this.selectedCourse);
                    this.unenrolledUsers = this.userService.getUnenrolledUsersForCourse(this.selectedCourse);
                }
                else
                {
                    this.enrolledUsers = [];
                    this.unenrolledUsers = [];
                }
                return this;
            },

            /**
             * Enroll users in the selected course
             *
             * @param {Array<User>} users
             */
            onEnrollUsersToCourse(users) {
                this.onHideUsersSidePanel();
                // users will be added in {UserEnrollment} component
                return this;
            },

            /**
             * Success handler for loading users
             *
             * @param {Array<User>} users
             */
            onSuccessFetchUsers(users) {
                // Update the user enrollments:
                this.setUserEnrollments();
                return this;
            },

            /**
             * Show the users in the side panel
             */
            onShowUsersSidePanel() {
                this.showUsers = true;
                this.showSidePanel = true;
                return this;
            },

            /**
             * Hide the users side panel
             */
            onHideUsersSidePanel() {
                if (this.showUsers === true) {
                    this.showUsers = false;
                    this.showSidePanel = false;
                }
                return this;
            },

            /**
             * User enrollment of the currently selected course has changed.
             */
            onChangeUserEnrollment() {
                // Update list of assigned users:
                this.setUserEnrollments();
            },

            /**
             * Apply filters
             *
             * @param {FilterCategory|String|Object|Null} filterObj
             */
            applyFilters(filterObj = undefined) {
                // Store filter keywords text:
                if ((filterObj === null || typeof filterObj === 'string') && filterObj !== this.searchKeywords)
                {
                    this.searchKeywords = filterObj;
                }

                // Order by ascending/descending:
                if (typeof filterObj?.descending === 'boolean') {
                    this.sortDescending = filterObj.descending;
                }

                // Trigger the search:
                if (filterObj instanceof Object)
                {
                    this.fetchCourses(1);
                }

                return this;
            },

            onTriggerSearch(e, keywords) {
                this.searchKeywords = keywords;
                this.fetchCourses(1);
                return this;
            },

            onResetFilters() {
                this.searchKeywords = null;
                this.sortDescending = false;
                this.$refs.listFiltersBar.setFocus();
                this.fetchCourses(1);
                return this;
            },

            /**
             * @param {Event} e
             */
            onScrollList(e) {
                // Maximum number of items showing already
                if (this.renderItemsLoaded >= this.courses.length) {
                    return this;
                }
                const list = e.target;
                if (list.scrollTop >= (list.scrollHeight - list.offsetHeight - (list.offsetHeight * 0.85))) {
                    this.renderItemsLoaded += this.renderItemsPerPage;
                }
                return this;
            },

            onShortcutEnter() {
                if (
                    this.selectedCourse !== null
                    && (
                        this.$refs.courseList.contains(document.activeElement)
                        || document.activeElement.matches('.panels')
                    )
                )
                {
                    this.onDoubleClickCourseListItem(this.selectedCourse);
                }
                return this;
            },

            onShortcutDelete() {
                if (
                    this.selectedCourse !== null
                    && this.canDeleteCourse
                    && (
                        this.$refs.content.contains(document.activeElement)
                        || document.activeElement.matches('.panels')
                    )
                )
                {
                    this.$globalEvents.emit(EventType.MODAL_DELETE_COURSE_SHOW, this.selectedCourse);
                }
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
