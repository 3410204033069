<template>

    <div class="container" :data-saving="isSaving">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('tenants.update.headline') }}</div>

                    <div class="card-body">

                        <form method="post" @submit.prevent="onSubmit">

                            <TenantFormFields
                                :api-validation-errors="apiValidationErrors"
                                :tenant-to-edit="tenantToEdit"
                            />

                            <div class="form-group row mb-0">
                                <div class="col-md-6 offset-md-4 buttons">
                                    <button type="submit" class="btn btn-primary">
                                        {{ trans('labels.save') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script lang="ts">
import {defineComponent, inject, PropType} from 'vue'
import {trans} from "@/Utility/Helpers/trans";
import {route} from "@/Utility/Helpers";
import TextInput from "@/Vue/Common/TextInput.vue";
import {tenantServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import RequestError from "@/Errors/RequestError";
import EventType from "@/Utility/EventType";
import {Permission} from "@/Models/User/Permission";
import TenantFormFields from "@/Vue/Tenants/TenantFormFields.vue";
import Tenant from "@/Models/Tenant/Tenant";
import {TenantForm} from "@/Services/TenantService";

export default defineComponent({
    name: "TenantUpdateForm",

    components: {
        TenantFormFields,
        TextInput,
    },

    props: {
        tenant: {
            type: Object as PropType<Tenant>,
            required: true,
        }
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            tenantToEdit: this.getTenantToEdit(this.tenant) as TenantForm,
            apiValidationErrors: null as Record<string, Array<string>> | null,
        }
    },

    computed: {

        isSaving() {
            if (this.tenantService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
            } else {
                this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
            }
        },

        canChangeHubspotId() {
            return this.$gate.allows(Permission.TenantsConfigureHubspot());
        },
    },

    methods: {
        route,
        trans,

        async onSubmit(_: SubmitEvent) {
            try {
                const newTenant = await this.tenantService.updateTenant(this.tenant.uid, this.tenantToEdit);
                this.tenantToEdit = this.getTenantToEdit(newTenant);
            } catch (error: any) {
                if (error instanceof RequestError && error.isValidationError) {
                    this.apiValidationErrors = error.validationErrors;
                } else {
                    this.$root!.showErrorDialog(trans('errors.server_communication_failed'));
                }
            }
        },

        getTenantToEdit(tenant: Tenant): TenantForm {
            return {
                name: tenant.name,
                hubspot_company_id: tenant.hubspot_company_id,
                logo: null,
                initialLogoUrl: tenant.logo,
            }
        }
    }
});
</script>

<style scoped lang="scss">

</style>
