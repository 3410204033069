<template>

    <!-- SceneObject -->
    <component
        v-if="getComponentForSelectedObject !== null"
        :key="'panel-sceneObject-' + selectedObject.uid + renderTimestamp"
        :is="getComponentForSelectedObject"
        :sceneObject="selectedObject"
        @change="onChangeSceneObject"
    />

    <!-- Unit (Revision) -->
    <SidepanelEditUnitRevision
        v-else-if="showUnitPanel"
        :key="'panel-unit-'+selectedObject.uid"
        :unit-revision="selectedObject"
        @change="onChangeUnit"
    />

    <!-- TrainingScene -->
    <SidepanelTrainingScene
        v-else-if="showTrainingScenePanel"
        :key="'sidepanel-trainingscene-'+selectedObject.uid"
        :scene="selectedObject"
        @change="onChangeUnit"
    />

    <!-- SceneObjectives -->
    <div
        v-else-if="showObjectivesPanel"
        id="inspector"
        class="inspector-content"
        v-not-focusable
        v-shortcuts
    >
        <header class="inspector-header objectives">
            <Icon name="icon_objectives" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.objectives_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div class="panels" v-not-focusable>
            <PanelObjectives
                :key="'panel-objectives-'+selectedObject.uid"
                :objectives="selectedObject"
                @change="onChangeUnit"
            />
        </div>

    </div>
</template>

<script>

    // Import VueJS components:
    import PanelObjectives                      from '@/Vue/Inspector/PanelObjectives.vue';
    import InspectorItemPreviewImage            from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
    import InspectorSceneObjectAsset            from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectAsset.vue';
    import InspectorSceneObjectAssetText        from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectAssetText.vue';
    import InspectorSceneObjectGroup            from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectGroup.vue';
    import InspectorSceneObjectHotspot          from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectHotspot.vue';
    import InspectorSceneObjectModule           from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectModule.vue';
    import InspectorSceneObjectModuleHelper     from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectModuleHelper.vue';
    import InspectorSceneObjectModuleOverlay    from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectModuleOverlay.vue';
    import InspectorSceneObjectModuleConnection from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectModuleConnection.vue';
    import InspectorSceneObjectModuleVariable   from '@/Vue/Inspector/SceneObjects/InspectorSceneObjectModuleVariable.vue';
    import SidepanelTrainingScene               from '@/Vue/Sidepanel/SidepanelTrainingScene.vue';
    import SidepanelEditUnitRevision            from '@/Vue/Sidepanel/SidepanelEditUnitRevision.vue';

    // Import classes:
    import EventType                            from '@/Utility/EventType';
    import SceneObject                          from '@/Models/UnitData/SceneObjects/SceneObject';
    import SceneObjectives                      from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
    import { getInspectorComponentName }        from '@/Models/UnitData/SceneObjects/SceneObjectHelpers';
    import TrainingScene                        from '@/Models/UnitData/Scenes/TrainingScene';
    import Unit                                 from '@/Models/Unit/Unit';
    import UnitRevision                         from '@/Models/Unit/UnitRevision';

    export default {
        name: 'Inspector',
        components: {
            PanelObjectives,
            InspectorItemPreviewImage,
            InspectorSceneObjectAsset,
            InspectorSceneObjectAssetText,
            InspectorSceneObjectGroup,
            InspectorSceneObjectHotspot,
            InspectorSceneObjectModule,
            InspectorSceneObjectModuleConnection,
            InspectorSceneObjectModuleHelper,
            InspectorSceneObjectModuleOverlay,
            InspectorSceneObjectModuleVariable,
            SidepanelTrainingScene,
            SidepanelEditUnitRevision,
        },
        data() {
            return {
                selectedObject: null,               // Selected object that should be inspected
                renderTimestamp: null,              // Helper for forcing re-rendering
                shortcuts: new Map([
                    ['Duplicate.global.prevent', this.onShortcutDuplicate],
                    ['Replace.global.prevent', this.onShortcutReplace],
                ])
            }
        },
        computed: {

            /**
             * @returns {null|String}
             */
            getComponentForSelectedObject() {
                return (this.selectedObject !== null) ? getInspectorComponentName(this.selectedObject.type, this.selectedObject.subtype) : null;
            },

            /**
             * @returns {Boolean}
             */
            showObjectivesPanel() {
                return (this.selectedObject instanceof SceneObjectives);
            },

            /**
             * @returns {Boolean}
             */
            showUnitPanel() {
                return (this.selectedObject instanceof UnitRevision);
            },

            /**
             * @returns {Boolean}
             */
            showTrainingScenePanel() {
                return (this.selectedObject instanceof TrainingScene);
            },
        },
        mounted() {
            // Add global events:
            this.$globalEvents.on(EventType.INSPECTOR_INSPECT, this.onInspectObject);
            this.$globalEvents.on(EventType.INSPECTOR_REFRESH, this.refresh);
        },
        beforeUnmount() {
            // Remove global events:
            this.$globalEvents.off(EventType.INSPECTOR_INSPECT, this.onInspectObject);
            this.$globalEvents.off(EventType.INSPECTOR_REFRESH, this.refresh);
        },
        methods: {
            /**
             * Refresh the UI
             */
            refresh() {
                // Force re-render of the component:
                this.renderTimestamp = (new Date()).toString();
                return this;
            },

            /**
             * Show properties panel(s) for a given object
             *
             * @param {Unit} obj    // Data of the selected obj
             */
            onInspectObject(obj = null) {
                this.selectedObject = obj;

                // Set available triggers and commands for SceneObjects:
                if (this.selectedObject instanceof SceneObject)
                {
                    // Set available triggers in the side panel:
                    this.$globalEvents.emit(
                        EventType.SIDEPANEL_TRIGGERS_SET,
                        [...(this.selectedObject.supportedTriggerTypes || [])]
                    );
                }

                // Hide the side panel:
                this.$globalEvents.emit(EventType.SIDEPANEL_HIDE);

                // Force re-render of the component:
                this.refresh();

                return this;
            },

            /**
             * Update the scene object whenever a change happens
             *
             * @param {SceneObject} sceneObject             // Instance of the SceneObject being inspected
             */
            onChangeSceneObject(sceneObject) {
                //console.log('Inspector: SceneObject has been updated', this.selectedObject, sceneObject);
                this.$globalEvents.emit(EventType.INSPECTOR_SCENE_OBJECT_UPDATED, sceneObject);
                return this;
            },

            /**
             * Update the unit object whenever a change happens
             */
            onChangeUnit() {
                //console.log('Inspector: Unit has been updated', this.selectedObject);
                this.$globalEvents.emit(EventType.INSPECTOR_UNIT_UPDATED, this.selectedObject instanceof Unit ? this.selectedObject : this.selectedObject.parentUnit || this.selectedObject);
                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcutDuplicate(e) {
                return this;
            },

            /**
             * Shortcut handler
             *
             * @param {CustomEvent} e
             */
            onShortcutReplace(e) {
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
