<template>

    <header>
        <div class="logo">
            <a :href="trans('assets.marketplaces.sketchfab.home_url')" target="_blank">
                <img src="/images/logos/logo_sketchfab.svg" :alt="trans('assets.marketplaces.sketchfab.name')">
            </a>
        </div>

        <div class="text">
            <Icon name="icon_info"></Icon>
            <p v-html="trans('assets.marketplaces.sketchfab.header')"></p>
        </div>

        <div class="user-info" v-if="sketchfabService.user">
            <a class="avatar"
               :href="sketchfabService.user.profileUrl"
               :title="loggedInAsMessage"
               target="_blank">
                <img
                    :src="sketchfabService.user.avatarUrl"
                    alt=""
                />
            </a>
        </div>

        <div class="login" v-else>
            <LoadingIndicator v-if="isLoading"></LoadingIndicator>
            <ButtonPrimary
                v-else
                @trigger="authenticate(false)"
                caption="labels.login"
            />
        </div>
    </header>

</template>

<script>

import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
import {inject} from "vue";
import {sketchfabServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import ButtonPrimary from "@/Vue/Common/ButtonPrimary.vue";
import {trans} from "@/Utility/Helpers";
import RequestError from "@/Errors/RequestError";
import Icon from "@/Vue/Common/Icon.vue";

export default {
    name: "SketchfabHeader",

    components: {
        Icon,
        ButtonPrimary,
        LoadingIndicator,
    },

    data() {
        return {
            sketchfabService: inject(sketchfabServiceKey),
        }
    },

    mounted() {
        this.authenticate(true);
    },

    computed: {
        isLoading() {
            return this.sketchfabService.isAuthenticating || this.sketchfabService.isLoadingUserInfo;
        },

        loggedInAsMessage() {
            return trans('assets.marketplaces.logged_in_as', {
                user: this.sketchfabService.user.displayName
            });
        },
    },

    methods: {

        async authenticate(withoutUserInteraction = false) {
            try {
                await this.sketchfabService.authenticate(withoutUserInteraction);
            } catch (error) {
                this.onAuthenticationError(error);
            }
        },

        /**
         * @param {Error} error
         */
        onAuthenticationError(error) {
            if (error instanceof RequestError) {
                this.$root.showErrorDialog(trans('errors.server_communication_failed'));
            } else {
                // it is fine if this failed - authorization has most likely been cancelled by the user
            }
        }
    },
}

</script>

<style scoped lang="scss">

header {
    margin-top: 10px;
    background-color: var(--background-color-white);
    border-radius: var(--card-border-radius-small);
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 33px;

    .logo {
        min-width: 120px;
        align-self: flex-start;
    }

    .text {
        margin: 0 auto 0 0;
        max-width: 750px;
        display: flex;
        gap: 10px;

        .icon {
            flex-basis: 16px;
            height: 20px;
        }
    }

    .user-info, .login {
        flex-basis: 150px;
        justify-content: end;
    }

    .user-info {
        text-align: end;
    }

    .avatar img {
        border-radius: 50%;
        width: 32px;
    }

    .login {
        display: flex;
    }
}

</style>
