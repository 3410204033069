import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import CoursePermissionPolicy, {CoursePermissionPolicySample} from '@/Models/Course/CoursePermissionPolicy';
import {TenantRole} from "@/Models/Tenant/TenantRole";

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.CourseUpdate() = 'courses:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('Course', new CoursePolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.CourseUpdate()), course);
 *
 */
export default class CoursePolicy {

    /**
     * Determine whether the user can delete the training.
     *
     * @param  {User}  user
     * @param  {Course}  course
     * @return {boolean}
     */
    delete(user, course) {
        return (
            this.courseIsInScopeForWriting(user, course) &&
            permission(Permission.CoursesDelete())
        );
    }

    /**
     * Determine whether the user can read the course.
     *
     * @param  {User}  user
     * @param  {Course} course
     * @return {boolean}
     */
    read(user, course) {
        return (
            this.courseIsInScopeForReading(user, course) &&
            permission(Permission.CoursesRead())
        );
    }

    /**
     * Determine whether the user can access the unit based on his course enrollments.
     * The unit must be referenced in the course.
     * A user must be enrolled in the course that uses the unit revision.
     *
     * @param  {User}  user
     * @param  {Course}  course
     * @param  {Unit}  unit
     * @return {boolean}
     */
    launch_unit(user, course, unit) {
        const unitIsReferencedInCourse = course.unit_uids.includes(unit.uid);

        return (
            unitIsReferencedInCourse
            && unit.latestReleasedRevision.isCompatible
            && permission(Permission.CoursesLaunchUnit())
            && this.courseIsInScopeForReading(user, course)
        );
    }

    /**
     * Determine whether the user can update the course.
     *
     * @param  {User}  user
     * @param  {Course}  course
     * @return {boolean}
     */
    update(user, course) {
        return (
            this.courseIsInScopeForWriting(user, course) &&
            permission(Permission.CoursesUpdate())
        );
    }

    /**
     * Determine whether the user can enroll users in the course.
     *
     * @param  {User}  user
     * @param  {Course}  course
     * @return {boolean}
     */
    enroll_users(user, course) {
        // Users cannot be enrolled in sample courses
        if (CoursePermissionPolicySample.type === course.policy) {
            return false;
        }

        return (
            (
                user.uid === course.owned_by &&
                permission(Permission.CoursesEnrollUsersInOwn())
            ) ||
            permission(Permission.CoursesEnrollUsersInAny())
        );
    }

    /**
     * @param  {User}  user
     * @param  {Course}  course
     * @return {boolean}
     */
    courseIsInScopeForWriting(user, course) {
        // Deny access to the course if it is not owned by the current tenant
        if (course.owned_by_tenant !== user.tenant.uid) {
            return false;
        }

        const availablePolicies = CoursePermissionPolicy
            .allAvailablePoliciesForUser(user)
            .map(permissionPolicyMapping => permissionPolicyMapping[1]);

        const userHasAccessToPolicy = availablePolicies.some(policy => policy.constructor.type === course.policy);

        if (userHasAccessToPolicy === false) {
            return false;
        }

        // TODO: Replace these tenant-Role checks with a proper Permission
        // Tenant admins and authors can access all courses on the tenant
        if (user.tenant_role?.isAnyOf(TenantRole.Name.Author, TenantRole.Name.Admin)) {
            return true;
        }

        return false;
    }

    /**
     * @param  {User}  user
     * @param  {Course}  course
     * @return {boolean}
     */
    courseIsInScopeForReading(user, course) {
        // Everyone can read sample courses
        if (course.policy === CoursePermissionPolicySample.type) {
            return true;
        }

        // TODO: Replace these tenant-Role checks with a proper Permission
        // Tenant admins and authors can access all courses on the tenant
        if (user.tenant_role?.isAnyOf(TenantRole.Name.Author, TenantRole.Name.Admin)) {
            return true;
        }

        // Is enrolled?
        return course.isEnrolled(user);
    }
}
