import {trans} from '@/Utility/Helpers';
import AbstractError from '@/Errors/AbstractError';

export default class RequestError extends AbstractError {
    /**
     * Constructor
     *
     * @NOTE: Allows multiple different argument signatures
     */
    constructor() {
        super(...arguments);
        this.name = 'RequestError';
        this.message = this.message || trans('errors.server_communication_failed');
        Object.setPrototypeOf(this, RequestError.prototype);
    }

    get isValidationError() {
        return this.response?.status === 422;
    }

    /**
     * @return {Record<string, Array<string>>} Laravel validation error object.
     * @NOTE: Use only when isValidationError returns true!
     */
    get validationErrors() {
        return this.response.data?.errors || this.response.errors || {};
    }
}
