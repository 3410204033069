<template>
    <div
        :data-loading="isLoading"
        class="container">
        <ArtisanCommandListItem
            v-for="(command,) in commands"
            :command="command"
        />

        <DialogLoading/>
        <DialogNotification/>
    </div>
</template>

<script>
import ArtisanCommandListItem from "@/Vue/Admin/ArtisanCommandListItem";
import {inject} from "vue";
import {manageServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import EventType from "@/Utility/EventType";
import DialogLoading from "@/Vue/Modals/DialogLoading.vue";
import DialogNotification from "@/Vue/Modals/DialogNotification.vue";

export default {
    name: "ArtisanCommands",
    components: {
        DialogNotification,
        DialogLoading,
        ArtisanCommandListItem,
    },

    props: {
        'commands': {
            type: Object,
        }
    },

    data() {
        return {
            manageService: inject(manageServiceKey),
        }

    },
    mounted() {
    },

    computed: {

        isLoading() {
            if (this.manageService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
            return false;
        }

    },

    methods: {},
}
</script>

<style scoped>

</style>
