<template>
    <div class="property trigger-running">
        <Dropdown
            :initial-value="condition.object"
            @select="onSelectObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />
        <Dropdown
            :disabled="!targetObjectIsSet"
            :initial-value="condition.trigger"
            @select="onSelectTrigger"
            :options="targetOptionsForTrigger"
            :required="true"
            :deselected-caption="trans('authoring.select_trigger')"
        />
    </div>
</template>

<script>
import
{
    getSceneObjectTargetDropdownOptionsForCommand,
    getCaptionForSceneObjectTrigger
}                                           from '@/Models/UnitData/Commands/CommandHelpers';
import Command                              from '@/Models/UnitData/Commands/Command';
import { TriggerIsRunningCondition }        from '@/Models/UnitData/Conditions/Condition';
import DropdownOption                       from '@/Utility/DropdownOption';

export default {
    name: 'PanelConditionTriggerIsRunning',
    emits: [
        'change',
    ],
    props: {
        command: Command,
        condition: TriggerIsRunningCondition,
    },

    beforeMount() {
        this.command.cleanUpData();
    },

    computed: {

        targetObjectIsSet() {
            return this.condition.object !== null;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

        /**
         * Get options for target trigger selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        targetOptionsForTrigger() {
            const options = [];
            let targetObject = null;
            let triggers = [];

            // Get triggers for the selected object:
            const targetObjectPath = this.condition.object;
            if (targetObjectPath === Command.TargetSelf)
            {
                targetObject = this.command.parentSceneObject;
            }
            else
            {
                targetObject = this.command.parentUnitData.findSceneObjectByUid(targetObjectPath);
            }
            if (targetObject !== null)
            {
                triggers = targetObject.triggers || [];
            }

            if (triggers.length >= 1)
            {
                triggers.forEach(t => {
                    options[options.length] = new DropdownOption({
                        caption: getCaptionForSceneObjectTrigger(targetObject, t, false, false),
                        icon: t.triggerType.icon,
                        disabled: false,
                        value: t.uid
                    });
                });
            }
            return options;
        },
    },
    methods: {

        /**
         * Select handler for target object
         *
         * @param {String} value
         */
        onSelectObject(value) {
            if (value !== this.condition.object)
            {
                this.condition.object = value;
                this.condition.trigger = null;
                this.$emit('change', this.condition);
            }
            return this;
        },

        /**
         * Select handler for trigger
         *
         * @param {String} value
         */
        onSelectTrigger(value) {
            if (value !== this.condition.trigger)
            {
                this.condition.trigger = value;
                this.$emit('change', this.condition);
            }
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
