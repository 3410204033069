<template>
    <section
        v-if="revisionToDisplay"
        :class="classNames"
        @focus="$emit('focus', unit)"
        @click="$emit('click', unit)"
        @dblclick="$emit('dblclick', unit)"
    >

        <img :src=revisionToDisplay.preview_thumbnail
             :alt="revisionToDisplay.title"
             class="unit-preview">

        <div class="unit-content">
            <h2 class="unit-title">{{ revisionToDisplay.title }}
                <time class="unit-author" :datetime="moment(unit.updated_at).format()">{{ dateAndAuthor }}</time>
            </h2>
            <p class="unit-description">{{ revisionToDisplay.description }}</p>
        </div>

        <footer>
            <div class="unit-labels">
                <StatusLabel
                    v-for="labelConfig in labels"
                    :key="'statuslabel'+unit.uid+(labelConfig.type || labelConfig.caption)"
                    :config="labelConfig"
                />
            </div>
            <template v-if="unitHasActions">
                <div class="unit-actions">

                    <!-- Btn: Edit -->
                    <ButtonCircular
                        v-if="unitIsEditable"
                        v-tooltip="'buttons.units.edit'"
                        :href="this.$root.route('units.edit', {'unit': unit.uid})"
                        icon="icon_edit"
                        @trigger="onEditClick"
                    />

                    <!-- Btn: Duplicate -->
                    <ButtonCircular
                        v-if="unitIsDuplicatable"
                        v-tooltip="'buttons.units.duplicate'"
                        icon="icon_duplicate"
                        @trigger="$emit('click-duplicate', unit)"
                    />

                    <!-- Btn: Import template -->
                    <ButtonCircular
                        v-if="unitIsImportableAsTemplate"
                        v-tooltip="'buttons.units.import_template'"
                        icon="icon_duplicate"
                        @trigger="$emit('click-import-template', unit)"
                    />

                    <!-- Btn: Delete -->
                    <ButtonCircular
                        v-if="unitIsDeletable"
                        v-tooltip="'buttons.units.delete'"
                        icon="icon_delete"
                        @trigger="$emit('click-delete', unit)"
                    />
                </div>
                <Icon class="icon-more" name="icon_more_horizontal" />
            </template>
        </footer>

    </section>
</template>

<script>
    import moment from 'moment';
    import Unit from '@/Models/Unit/Unit';
    import {Permission} from '@/Models/User/Permission';
    import {UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
    import StatusLabelConfig from '@/Utility/StatusLabelConfig';
    import {trans} from '@/Utility/Helpers';

    export default {
        name: 'UnitListItem',
        emits: [
            'click',
            'dblclick',
            'focus',
            'click-delete',
            'click-duplicate',
            'click-import-template',
            'click-edit',
        ],
        props: {
            unit: {
                type: Unit,
            },
            selected: {
                type: Boolean,
                default: false,
            },
        },

        computed: {

            /**
             * @returns {Array<String>}
             */
            classNames() {
                return [
                    'unit-list-item',
                    this.selected ? 'selected' : '',
                    this.unit.is_new ? 'is-new' : '',
                ];
            },

            /**
             * @returns {Array<StatusLabelConfig>}
             */
            labels() {
                const labels = [];

                // Policy (if non-standard)
                if (this.unit.policy !== UnitPermissionPolicyStandard.type)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'policy',
                            caption: this.unit.policy
                        })
                    );
                }

                // Released or draft
                if (this.unit.isReleased)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'status',
                            caption: 'released'
                        })
                    );
                }
                else if (this.unit.isDraft)
                {
                    labels.push(
                        new StatusLabelConfig({
                            caption: 'draft'
                        })
                    );
                }

                // Unreleased changes
                if (this.unit.hasUnreleasedChanges)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'notification',
                            caption: 'unreleased_changes'
                        })
                    );
                }

                // Incompatible
                if (!this.unit.latestRevision.isCompatible)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'warning',
                            caption: 'incompatible_unit_open',
                            tooltip: 'incompatible_unit_open_tooltip'
                        })
                    );
                }

                return labels;
            },

            /**
             * @returns {UnitRevision|null}
             */
            revisionToDisplay() {
                // For now, we always display the latest revision
                return this.unit.latestRevision;
            },

            /**
             * @returns {string}
             */
            dateAndAuthor() {
                const revision = this.revisionToDisplay;
                const authorName = revision.owner ? revision.owner.fullName : trans('labels.unknown_author');
                return `${moment(this.unit.updated_at).format(trans('courses.unit_list.item_date_format'))} ${trans('labels.by')} ${authorName}`;
            },

            /**
             * @returns {boolean}
             */
            unitHasActions() {
                return this.unitIsEditable
                    || this.unitIsDeletable
                    || this.unitIsDuplicatable
                    || this.unitIsImportableAsTemplate;
            },

            /**
             * @returns {boolean}
             */
            unitIsDeletable() {
                return this.$gate.allows(Permission.ability(Permission.UnitsDelete()), this.unit);
            },

            /**
             * @returns {boolean}
             */
            unitIsDuplicatable() {
                return this.$gate.allows(Permission.ability(Permission.UnitsDuplicate()), this.unit);
            },

            /**
             * @returns {boolean}
             */
            unitIsImportableAsTemplate() {
                return this.$gate.allows(Permission.ability(Permission.UnitsImportTemplate()), this.unit);
            },

            /**
             * @returns {boolean}
             */
            unitIsEditable() {
                return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.unit);
            },
        },

        methods: {

            /**
             * @param {MouseEvent} e
             */
            onEditClick(e) {
                e.preventDefault();
                this.$emit('click-edit', this.unit);
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>
