/*jshint esversion: 6 */

import {trans} from "@/Utility/Helpers/trans";
import {isUUID} from "@/Utility/Helpers/isUUID";

export {sleep} from "@/Utility/Helpers/sleep";
export {downloadFileFromUrl} from "@/Utility/Helpers/downloadFileFromUrl";
export {validateImageDimensions} from "@/Utility/Helpers/validateImageDimensions";
export {isUUID, trans};

/**
 * Publicly exposed global methods for Vue <template> tags (registered in bootstrap.js)
 *
 * @var {Object<Function>}
 */
export const VueTemplateMethods = {
    permission,
    trans,
    formatBytes,
    br2nl,
    nl2br,
    route
};

/**
 * Regular expressions
 *
 * @var {RegExp}
 */
export const RegExp_Color_Hex_RGB = /^[\da-f]{6}$/i;        // HEX RGB, e.g. 'FF0000'
export const RegExp_Color_Hex_RGBA = /^[\da-f]{8}$/i;       // HEX RGBA, e.g. 'FF0000FF'

/**
 * Get the type of any object
 *
 * @see https://javascriptweblog.wordpress.com/2011/08/08/fixing-the-javascript-typeof-operator/
 * @param {*} obj
 * @returns {String}
 */
export function getTypeOf(obj) {return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase();}

/**
 * Check whether a given user has a specific permission
 *
 * @param {String} p
 * @param {User} user  // Optional User model (window.currentUser will be used if none is provided)
 * @returns {Boolean}
 */
export function permission(p, user = null) {user = user || window.currentUser || null; return (user !== null && typeof user.hasPermission === 'function') ? user.hasPermission(p) : false;}

/**
 * uuid4 (https://gist.github.com/jed/982883)
 *
 * @param {String|undefined} a
 * @returns {String}
 */
export function uuid4(a = undefined) {return a?(a^Math.random()*16>>a/4).toString(16):([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,uuid4);}

/**
 * Create a shortcut method for generating short unique IDs (with optional prefix)
 *
 * @param {String} prefix
 * @returns {String}
 */
export function shortId(prefix=null) {return (typeof prefix === 'string' ? prefix+'-' : '') + uuid4().replace(/-.*$/, '');}

/**
 * Format file size in bytes as a human-readable string
 *
 * @param {String} bytes
 * @param {Number} decimals
 * @returns {String}
 */
export function formatBytes(bytes, decimals) {
    if (bytes == 0) {return '0 Bytes';}
    let k = 1024,
        dm = decimals <= 0 ? 0 : decimals || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

/**
 * Convert <br> tags to \n
 *
 * @param {String} text
 * @returns {String}
 */
export function br2nl(text) {return text.replace(/<br[^>]*>/gi, '\n');}

/**
 * PHP nl2br() implementation
 *
 * @param {String} text
 * @returns {String}
 */
export function nl2br(text) {return text.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2');}

/**
 * Parse string into a valid color format
 *
 * @param {String} value
 * @param {RegExp} format
 * @returns {String}
 */
export function parseColor(value = null, format = RegExp_Color_Hex_RGBA) {
    if (value === null) { return null; }
    // Convert from RGBA to RGB:
    if (format.toString() === RegExp_Color_Hex_RGB.toString() && RegExp_Color_Hex_RGBA.test(value) === true)
    {
        return value.substr(0, 6).toUpperCase();
    }
    // Convert from RGB to RGBA:
    else if (format.toString() === RegExp_Color_Hex_RGBA.toString() && RegExp_Color_Hex_RGB.test(value) === true)
    {
        return value.toUpperCase() + 'FF';
    }
    return (value === null || format.test(value) === false) ? null : value.toUpperCase();
}

/**
 * Parse string to new Date instance
 *
 * @param {String|Null} value
 * @returns {Date|Null}
 */
export function parseDate(value) {
    return (value === null || value === undefined) ? null : ((value instanceof Date) ? new Date(value.getTime()) : new Date(value));
}

/**
 * Pick a random value from an array
 *
 * @param {Array<Mixed>} array
 * @returns {Mixed|Null}
 */
export function pickRandomFromArray(array) {return (array instanceof Array && array.length >= 1) ? array[Math.floor(Math.random() * array.length)] : null;}

/**
 * Route helper (ported from Laravel)
 *
 * @param {String} routeName        // Name of the Laravel route (e.g. 'units.index')
 * @param {Object} params           // Optional route parameters (e.g. {unit: 00000000-0000-0000-0000-000000000000})
 * @returns {String}
 */
export function route(routeName, params=null) {
    if (typeof routeName !== 'string' || typeof window.appRoutes[routeName] !== 'string')
    {
        console.error('route() -> Unknown route: ' + routeName);
        throw new Error('route() -> Unknown route: ' + routeName);
    }
    let route = window.appRoutes[routeName];
    // Replace route parameters:
    if (params !== null)
    {
        if (!(params instanceof Object))
        {
            console.error('route() -> Route parameters must be an object');
            throw new TypeError('route() -> Route parameters must be an object');
        }
        for (let index in params)
        {
            if (params.hasOwnProperty(index) === true)
            {
                route = route.replace('{' + index + '?}', params[index]);
                route = route.replace('{' + index + '}', params[index]);
            }
        }
    }
    // Replace optional parameters:
    route = route.replace(/\{.*?\?\}/g, '');
    // Throw an error for any required missing parameters:
    let requiredParams = route.match(/(\{.*?\})/g);
    if (requiredParams !== null)
    {
        console.error('route() -> Missing required parameters: ' + requiredParams.join(', '));
        throw new Error('route() -> Missing required parameters: ' + requiredParams.join(', '));
    }
    return route;
}

/**
 * Compare method for sorting strings alphabetically without case sensitivity
 *
 * @returns {Function}
 */
export const compareAlphabetical = new Intl.Collator('en').compare;

/**
 * Map an array of objects by a given key
 *
 * @param {Array<Object>} objects
 * @param {String} key
 * @returns {Map<String, Array<Object>}
 */
export function mapObjectsByKey(objects, key) {
    if (objects === null || objects === undefined)
    {
        return new Map();
    }
    if (typeof key !== 'string' || key.length === 0)
    {
        throw new TypeError('mapObjectsByKey(): Key parameter must be of type string. Type of "' + typeof key + '" given.');
    }
    return objects.reduce(
        (entryMap, e) => entryMap.set(e[key], [...entryMap.get(e[key]) || [], e]),
        new Map()
    );
}

/**
 * Array sorting by date method
 *
 * @param {Array<Mixed>} arr        // Array to be sorted
 * @param {String} property         // Property name (e.g. 'updated_at')
 * @param {Boolean} descending      // Optional descending order (e.g. false)
 * @returns {Array<Mixed>}
 */
export function sortArrayByDate(arr, property, descending = false) {
    property = (typeof property === 'string') ? property : 'created_at';
    descending = (typeof descending === 'boolean') ? descending : false;
    const compare = function(a, b) {
        let aDate = (a instanceof Object && typeof a[property] !== 'undefined') ? new Date(a[property]) : null;
        let bDate = (b instanceof Object && typeof b[property] !== 'undefined') ? new Date(b[property]) : null;
        if (aDate instanceof Date && isNaN(aDate.getTime())) {aDate = null;}
        if (bDate instanceof Date && isNaN(bDate.getTime())) {bDate = null;}
        if ( aDate > bDate ) { return 1; }
        if ( aDate < bDate ) { return -1; }
        return 0;
    };
    arr.sort(compare);
    if (descending === true) {arr.reverse();}
    return arr;
}

/**
 * Array sorting by property method
 *
 * @template T
 * @param {Array<T>} arr        array to be sorted
 * @param {String} property     property name (e.g. 'title')
 * @param {Boolean} descending  optional descending order (e.g. false)
 * @returns {Array<T>}
 */
export function sortArrayByProperty(arr, property, descending = false) {
    if (!(arr instanceof Array)) {
        throw new TypeError('sortArrayByProperty(): Parameter must be an instance of Array. Type of "' + typeof arr + '" given.');
    }

    if (arr.length < 2) {
        return arr;
    }

    descending = (typeof descending === 'boolean') ? descending : false;

    const compare = function (a, b) {
        // Use either the property of the given objects or the objects themselves:
        let aProp = (a instanceof Object && a.hasOwnProperty(property)) ? a[property] : a;
        let bProp = (b instanceof Object && b.hasOwnProperty(property)) ? b[property] : b;

        // Convert valid numbers to float unless they have spaces in the beginning:
        if (!isNaN(aProp) && typeof aProp === 'string' && aProp.indexOf(' ') !== 0) {
            aProp = parseFloat(aProp);
        }
        if (!isNaN(bProp) && typeof bProp === 'string' && bProp.indexOf(' ') !== 0) {
            bProp = parseFloat(bProp);
        }

        // Numbers can just be compared by subtracting:
        const aIsNumber = (typeof aProp === 'number' && aProp !== Infinity);
        const bIsNumber = (typeof bProp === 'number' && bProp !== Infinity);

        if (aIsNumber && bIsNumber) {
            return aProp - bProp;
        }

        // Make sure non-strings and non-numbers are sorted at the end of the list:
        const aIsComparable = aIsNumber || typeof aProp === 'string';
        const bIsComparable = bIsNumber || typeof bProp === 'string';

        if (aIsComparable && !bIsComparable) {
            return -1;
        } else if (!aIsComparable && bIsComparable) {
            return 1;
        }

        return compareAlphabetical(aProp, bProp);
    };
    arr.sort(compare);

    if (descending === true) {
        arr.reverse();
    }

    return arr;
}

/**
 * Find all referenced UIDs (pointing to other objects) in an object (except those being used in "uid" properties)
 *
 * @param {object|array<*>} obj
 * @returns {array<string>}
 */
export function getUidReferencesFromObject(obj)
{
    let uids = [];
    if (obj instanceof Object && !(obj instanceof Array))
    {
        Object.keys(obj).filter(k => k !== 'uid').forEach(key => {
            const value = obj[key];
            if (isUUID(value))
            {
                // Do not use "originalUid" value if it's the same as the object's uid:
                if (key === 'originalUid' && obj.uid && obj.uid === value)
                {
                    return;
                }
                uids[uids.length] = value;
            }
            else if (value instanceof Object)
            {
                uids = [...new Set([...uids, ...getUidReferencesFromObject(value)])];
            }
        });
    }
    else if (obj instanceof Array && obj.length >= 1)
    {
        // Look recursively:
        obj.filter(o => o instanceof Object && Object.keys(o).length >= 1).forEach(o => uids = [...new Set([...uids, ...getUidReferencesFromObject(o)])]);
    }
    return uids;
}

/**
 * Get a list of all object UIDs from an object or array recursively (only from "uid" properties!)
 *
 * @recursive
 * @param {Object|Array<Mixed>} obj
 * @returns {Array<String>>}
 */
export function getObjectUids(obj)
{
    let uids = [];
    if (obj instanceof Object && !(obj instanceof Array))
    {
        // Only use objects that have a UID property:
        if (isUUID(obj.uid || null) === true)
        {
            if (uids.includes(obj.uid))
            {
                console.warn('Helpers->getObjectUids(): Found UID being used by multiple objects', obj.uid);
            }
            uids[uids.length] = obj.uid;
        }
        // Look recursively:
        for (const value of Object.values(obj).filter(o => o instanceof Object && Object.keys(o).length >= 1))
        {
            uids = [...new Set([...uids, ...getObjectUids(value)])];
        }
    }
    else if (obj instanceof Array && obj.length >= 1)
    {
        // Look recursively:
        obj.filter(o => o instanceof Object && Object.keys(o).length >= 1).forEach(o => uids = [...new Set([...uids, ...getObjectUids(o)])]);
    }
    return uids;
}

/**
 * Get a map of all UIDs and OriginalUIDs from an object or array recursively (only from "uid" and "originalUid" properties!)
 *
 * @recursive
 * @param {Object|Array<*>} obj
 * @returns {Map<OriginalUid, Uid>}
 */
export function getUidMappingFromDuplicatedObjects(obj)
{
    let mapping = new Map();
    if (obj instanceof Object && !(obj instanceof Array))
    {
        // Only use duplicated objects where the UID !== the original UID:
        if (isUUID(obj.uid || null) === true && isUUID(obj.originalUid || null) === true && obj.uid !== obj.originalUid)
        {
            mapping.set(obj.originalUid, obj.uid);
        }
        // Look recursively:
        for (const value of Object.values(obj).filter(o => o instanceof Object && Object.keys(o).length >= 1))
        {
            mapping = new Map([...mapping, ...getUidMappingFromDuplicatedObjects(value)]);
        }
    }
    else if (obj instanceof Array && obj.length >= 1)
    {
        // Look recursively:
        obj.filter(o => o instanceof Object && Object.keys(o).length >= 1).forEach(o => mapping = new Map([...mapping, ...getUidMappingFromDuplicatedObjects(o)]));
    }
    return mapping;
}

/**
 * Replace/update UID references for duplicated objects recursively
 *
 * When an object is duplicated it gets assigned a new UID and its children usually get assigned new UIDs as well.
 * For the references to those duplicated objects to still work they have to be updated with the new UIDs. For example,
 * there's a Trigger with two Commands of which one refers to the other one. If the Trigger gets duplicated the duplicated
 * Command should still point to the other Command within the duplicated Trigger.
 *
 * @NOTE: Must be implemented at the end of duplicate() methods whenever UIDs are changing on the object's children!
 * @NOTE: Since this is recursive, the UID mapping must only be updated from the parent-most object after duplicating of all child elements is completed!
 *
 * @recursive
 * @param {Object|Array<*>|String} obj
 * @param {?Map<String,String|null>} mapping     // Map of <originalUid, newUid> for replacing UIDs (newUid can be null to "remove" a value)
 * @returns {Object|Array<*>}
 */
export function updateUidReferences(obj, mapping = null)
{
    // Get mapping from the object itself if no map was specified:
    mapping = mapping || getUidMappingFromDuplicatedObjects(obj);
    if (mapping.size === 0) return obj;
    if (obj instanceof Object && !(obj instanceof Array))
    {
        // Replace (or remove) any object keys:
        for (const key of Object.keys(obj).filter(k => isUUID(k) === true && mapping.has(k) === true))
        {
            const replacement = mapping.get(key);
            if (replacement)
            {
                obj[replacement] = obj[key];
            }
            delete obj[key];
        }

        // Replace UID strings:
        for (const [key, value] of Object.entries(obj).filter(([k, o]) => isUUID(o) === true && mapping.has(o) === true))
        {
            obj[key] = mapping.get(value);
        }

        // Replace recursively:
        for (const [key, value] of Object.entries(obj).filter(([k, o]) => o instanceof Object && Object.keys(o).length >= 1))
        {
            obj[key] = updateUidReferences(value, mapping);
        }
    }
    else if (obj instanceof Array && obj.length >= 1)
    {
        // Replace recursively:
        obj.filter(o => o instanceof Object && Object.keys(o).length >= 1).forEach(o => updateUidReferences(o, mapping));
        // Replace string values:
        obj = obj.map(o => isUUID(o) === true && mapping.has(o) ? mapping.get(o) : o);
    }
    else if (isUUID(obj) === true && mapping.has(obj) === true)
    {
        // Replace string values:
        obj = mapping.get(obj);
    }
    return obj;
}

/**
 * Delete any UID references on an object or array recursively
 *
 * @NOTE: Since this is recursive, it must only be called from the parent-most object!
 *
 * @recursive
 * @param {Object|Array<*>} obj
 * @param {?Array<String>} uids
 * @param {Array<String>} keepUids
 * @returns {Object|Array<*>}
 */
export function deleteUidReferences(obj, uids = null, keepUids = [])
{
    // Get all UIDs from child objects:
    uids = uids || getObjectUids(obj);
    if (uids.length === 0) return obj;
    if (obj instanceof Object && !(obj instanceof Array))
    {
        // Remove any object keys:
        for (const key of Object.keys(obj).filter(k => isUUID(k) === true && uids.includes(k) && !keepUids.includes(k)))
        {
            delete obj[key];
        }

        // Delete UID strings (set them to null, except for "uid" properties):
        // @NOTE: Ignoring "uid" keys since there should never be an object without a UID, only references to that object should be deleted
        for (const [key, value] of Object.entries(obj).filter(([k, v]) => k !== 'uid' && isUUID(v) === true && uids.includes(v) && !keepUids.includes(v)))
        {
            obj[key] = null;
        }

        // Delete recursively:
        for (const [key, value] of Object.entries(obj).filter(([k, o]) => o instanceof Object && Object.keys(o).length >= 1))
        {
            obj[key] = deleteUidReferences(value, uids, keepUids);
        }
    }
    else if (obj instanceof Array && obj.length >= 1)
    {
        // Delete recursively:
        obj.filter(o => o instanceof Object && Object.keys(o).length >= 1).forEach(o => deleteUidReferences(o, uids, keepUids));
        // Exclude deleted UIDs from array:
        obj = obj.filter(o => isUUID(o) === false || !uids.includes(o) || keepUids.includes(o));
    }
    else if (isUUID(obj) === true && uids.includes(obj) && !keepUids.includes(obj))
    {
        // Delete string values:
        obj = null;
    }
    return obj;
}
