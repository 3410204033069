import UserFile from "@/Utility/Helpers/UserFile";
import BlobUrlLoadingManager from "@/Utility/Threejs/BlobUrlLoadingManager";
import {Group, LoadingManager, Mesh} from "three";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {TGALoader} from "three/examples/jsm/loaders/TGALoader";
import {ThreeObject3DUserData} from "@/Utility/Threejs/ThreeObject3DUserData";
import {OBJLoader} from "three/examples/jsm/loaders/OBJLoader";
import {MTLLoader} from "three/examples/jsm/loaders/MTLLoader";

/**
 * Loads and parses different kinds of local 3d files.
 *
 * Supported types:
 * - GLB
 * - GLTF
 * - FBX
 */
export default class ThreejsModelLoader {

    /**
     * @param {UserFile} fileToLoad content to load
     * @param {Array<UserFile>} files other related files that might be relevant for loading
     */
    constructor(private readonly fileToLoad: UserFile, private readonly files: Array<UserFile>) {
    }

    async load(): Promise<Group> {
        const loadingManager = new BlobUrlLoadingManager(this.files);
        loadingManager.addHandler(/\.tga$/i, new TGALoader());

        const fileContent = await this.fileToLoad.arrayBuffer();

        let model: Group;
        if (this.fileToLoad.isGlbFile || this.fileToLoad.isGltfFile) {
            model = await this.parseGltf(fileContent, loadingManager);
        } else if (this.fileToLoad.isFbxFile) {
            model = await this.parseFbx(fileContent, loadingManager);
        } else if (this.fileToLoad.isObjFile) {
            model = await this.parseObj(fileContent, loadingManager);
        } else {
            throw new Error(`Cannot load file type "${this.fileToLoad.file.name}".`);
        }

        loadingManager.revokeBlobUrls();

        return model;
    }

    private async parseGltf(data: ArrayBuffer, loadingManager: LoadingManager): Promise<Group> {
        const loader = new GLTFLoader(loadingManager);
        const gltf = await loader.parseAsync(data, "");
        gltf.scene.animations.push(...gltf.animations);

        const group = gltf.scene;

        group.userData = {
            animationsDisabled: false,
            flipTextureYBeforeExport: false,
        } as ThreeObject3DUserData;

        return group;
    }

    private async parseFbx(data: ArrayBuffer, loadingManager: LoadingManager): Promise<Group> {
        const loader = new FBXLoader(loadingManager);
        const fbx = loader.parse(data, "");

        fbx.animations = [];
        fbx.traverse(child => {
            child.animations = [];
        });

        fbx.userData = {
            animationsDisabled: true,
            flipTextureYBeforeExport: true,
        } as ThreeObject3DUserData;

        return fbx;
    }

    private async parseObj(data: ArrayBuffer, loadingManager: LoadingManager): Promise<Group> {
        const loader = new OBJLoader(loadingManager);

        // parse materials file if present
        const mtlFile = this.files.find(file => file.isMtlFile);

        if (mtlFile) {
            const mtlLoader = new MTLLoader(loadingManager);
            const mtlString = await mtlFile.file.text();
            const materials = mtlLoader.parse(mtlString, "");
            materials.preload();
            loader.setMaterials(materials);
        }

        // parse model
        const decoder = new TextDecoder("utf-8");
        const text = decoder.decode(data);
        const obj = loader.parse(text);

        obj.userData = {
            animationsDisabled: false,
            flipTextureYBeforeExport: false,
        } as ThreeObject3DUserData;

        return obj;
    }
}
