import {parseDate, uuid4} from '@/Utility/Helpers';

export default class Tenant {

    get constructorName() { return 'Tenant'; }

    constructor(attributes = {}) {
        this.uid = attributes.uid || uuid4();
        this.name = attributes.name || null;
        this.logo = attributes.logo || null;
        this.logo_thumbnail = attributes.logo_thumbnail || null;
        this.hubspot_company_id = attributes.hubspot_company_id || null;
        this.is_default_tenant = attributes.is_default_tenant || false;
        this.is_default_admin_tenant = attributes.is_default_admin_tenant || false;
        this.is_default_asset_tenant = attributes.is_default_asset_tenant || false;
        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);
    }

    get isAnyDefaultTenant() {
        return this.is_default_tenant || this.is_default_admin_tenant || this.is_default_asset_tenant;
    }
}
