<template>
    <div class="behaviour-properties" v-shortcuts.stop>
        <div v-if="shouldShowPropertyEnabled" class="property behaviour-property behaviour-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeProperty('enabled')" />
            <Dropdown
                :label="trans('authoring.running_state')"
                :key="'runningstatebehaviourphysics'+command.uid+command.properties.enabled"
                :model="command.properties"
                property="enabled"
                @select="onChangePropertyEnabled"
                :options="targetOptionsForPropertyEnabled"
                :required="true"
                class="no-wrap"
            />
        </div>

        <div v-if="shouldShowPropertyMaterialCharacteristics" class="property behaviour-property behaviour-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeProperty('material_characteristics')" />
            <Dropdown
                :label="trans('labels.material_characteristics')"
                :model="command.properties"
                property="material_characteristics"
                @select="onChangePropertyMaterialCharacteristics"
                :options="targetOptionsForPropertyMaterialCharacteristics"
                :required="true"
                :deselected-caption="trans('labels.material_characteristics')"
                class="no-wrap"
            />
        </div>

        <div v-if="shouldShowProperty('mass')" class="property behaviour-property behaviour-property-input number-input-wrapper">
            <Icon name="icon_close" class="icon-delete" @click="removeProperty('mass')" />
            <label>
                {{ trans('labels.weight') }} <span class="wait-unit">(kg)</span>
            </label>
            <NumberInput
                :model="command.properties"
                property="mass"
                @change="onChangePropertyMass"
                :minValue="0.001"
                :maxValue="999999"
                :step="0.001"
                :precision="3"
                :placeholder="trans('labels.weight')"
            />
        </div>

        <!-- Dropdown for new/unassigned property -->
        <div v-if="getPropertyOptionsForPhysicsBehaviour.length > 0" class="btn-add-with-dropdown">
            <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
            <ButtonCustom v-not-focusable icon="icon_add" :class="(!command.isValid ? 'required' : '')" caption="labels.add_property" :disabled="getPropertyOptionsForPhysicsBehaviour.length === 0" />
            <Dropdown
                :key="keyForAddNewPropertyDropdown"
                @select="onSelectNewProperty"
                :options="getPropertyOptionsForPhysicsBehaviour"
                :required="true"
                :deselected-caption="trans('authoring.select_property')"
                :disabled="getPropertyOptionsForPhysicsBehaviour.length === 0"
            />
        </div>
    </div>
</template>

<script>
// Import helpers and functions:
import { targetOptionsForPropertyEnabled, targetOptionsForPropertyMaterialCharacteristics, getPhysicsMaterialCharacteristicsProperties } from '@/Models/UnitData/Behaviours/BehaviourHelpers';

// Import classes:
import { PhysicsBehaviour }     from '@/Models/UnitData/Behaviours/Behaviour';
import {BehaviourChangeCommand}   from '@/Models/UnitData/Commands/Command';
import DropdownOption           from '@/Utility/DropdownOption';
import { trans }                from '@/Utility/Helpers';

export default {
    name: 'PanelCommandBehaviourChangePhysics',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: BehaviourChangeCommand,
            default: null
        }
    },

    data() {
        return {
            shortcuts: new Map([
                ['Backspace', null],                    // Allow backspace key
            ])
        }
    },

    computed: {

        /**
         * @returns {String}
         */
        keyForAddNewPropertyDropdown() {
            return 'dropdown-'+this.command.uid+(this.command.properties ? Object.keys(this.command.properties).length : '');
        },

        /**
         * Get property options for the dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getPropertyOptionsForPhysicsBehaviour() {
            const addedProperties = this.command.properties !== null ? Object.keys(this.command.properties) : [];
            return [
                'enabled',
                'material_characteristics',
                'mass',
            ].filter(property => !addedProperties.includes(property))
            .map(property =>
                new DropdownOption({
                    caption: trans('authoring.behaviours.properties.physics.' + property),
                    disabled: false,
                    value: property,
                })
            );
        },

        /**
         * @returns {Boolean}
         */
        hasPropertyEnabled() {
            return this.command.properties !== null && (this.command.properties.hasOwnProperty('enabled') && typeof this.command.properties.enabled === 'boolean');
        },

        /**
         * @returns {Boolean}
         */
        hasPropertyMaterialCharacteristics() {
            return this.command.properties !== null && (this.command.properties.hasOwnProperty('material_characteristics') && typeof this.command.properties.material_characteristics === 'string');
        },

        /**
         * @returns {Boolean}
         */
        shouldShowPropertyEnabled() {
            return (this.command.object !== null && this.command.behaviour !== null && this.hasPropertyEnabled);
        },

        /**
         * @returns {Boolean}
         */
        shouldShowPropertyMaterialCharacteristics() {
            return (this.command.object !== null && this.command.behaviour !== null && this.hasPropertyMaterialCharacteristics);
        },

        /**
         * @returns {Array<DropdownOption>}
         */
        targetOptionsForPropertyEnabled() {
            return targetOptionsForPropertyEnabled();
        },

        /**
         * @returns {Array<DropdownOption>}
         */
        targetOptionsForPropertyMaterialCharacteristics() {
            return targetOptionsForPropertyMaterialCharacteristics();
        },
    },

    methods: {

        /**
         *  Change handler for the enabled property
         */
        onChangePropertyEnabled() {
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Change handler for the behaviour
         *
         * @param {Number} mass
         */
        onChangePropertyMass(mass) {
            this.$emit('change', this.command);
            return this;
        },

        /**
         *  Change handler for the enabled property
         */
        onChangePropertyMaterialCharacteristics(value) {
            const properties = getPhysicsMaterialCharacteristicsProperties(value);

            if (properties === null)
            {
                return this;
            }

            Object.keys(properties).forEach((property) => {
                this.command.properties[property] = properties[property];
            });

            this.$emit('change', this.command);

            return this;
        },

        /**
         * Select handler for new properties
         *
         * @param {String} property
         */
        onSelectNewProperty(property) {

            // Make sure command.properties is an object before adding a new property
            if (this.command.properties === null) {
                this.command.properties = new Object(null);
            }

            if (PhysicsBehaviour.propertyDefaults.hasOwnProperty(property))
            {
                this.command.properties[property] = PhysicsBehaviour.propertyDefaults[property];
            }
            else
            {
                this.command.properties[property] = null;
            }

            if (property === 'material_characteristics')
            {
                this.onChangePropertyMaterialCharacteristics(this.command.properties[property]);
            }

            this.$emit('change', this.command);

            return this;
        },

        /**
         * Get visibility state for a given property name
         *
         * @param {String} property
         * @returns {Boolean}
         */
        shouldShowProperty(property) {
            return this.command.properties !== null && this.command.properties.hasOwnProperty(property);
        },

        /**
         * Remove a property by its name
         *
         * @param {String} property
         */
        removeProperty(property) {

            // The material property has associated properties that need to be removed as well
            if (property === 'material_characteristics')
            {
                const properties = getPhysicsMaterialCharacteristicsProperties(this.command.properties[property]);

                Object.keys(properties).forEach((property) => {
                    delete this.command.properties[property];
                });
            }

            delete this.command.properties[property];

            // Reset properties to null if it is empty
            if (Object.keys(this.command.properties).length === 0) {
                this.command.properties = null;
            }

            this.$emit('change', this.command);
            return this;
        },
    },
}
</script>
