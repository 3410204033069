<template>
    <div id="inspector" class="inspector-content" v-not-focusable v-shortcuts>
        <header :class="'inspector-header hotspot hotspot-' + sceneObject.subtype">
            <Icon :name="sceneObject.icon" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.hotspot_properties') }}
        </header>
        <div class="panels" v-not-focusable :key="'InspectorPanels' + refreshUI + sceneObject.uid">
            <div :class="'inspector-panel panel-hotspot hotspot-' + sceneObject.subtype">

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :model="sceneObject"
                        property="title"
                        @change="onChangeProperty"
                        :required="true"
                        :maxlength="50"
                        :placeholder="trans('authoring.hotspot_title_placeholder')"
                        :error-msg="trans('errors.hotspot.title')"
                    />
                </div>

                <Collapsible :key="'hotspot_basics_' + sceneObject.uid + '_collapsible'">
                    <template #header>
                        {{ trans('labels.basics') }}
                    </template>
                    <template #body>
                        <!-- Visibility state -->
                        <div v-if="!sceneObject.isGlobal" class="property property-visibility">
                            <Checkbox
                                :model="sceneObject"
                                property="visible"
                                layout="checkbox-right"
                                @change="onChangeProperty"
                                :caption="trans('authoring.hotspot_initially_visible')"
                            />
                        </div>

                        <!-- Style Type -->
                        <div class="property property-style">
                            <Dropdown
                                class="no-wrap"
                                :key="'dropdown-style-'+sceneObject.uid"
                                :label="trans('labels.hotspot_style')"
                                :model="sceneObject"
                                property="style"
                                @select="onChangeProperty"
                                :options="hotspotStyleOptions"
                                :deselected-caption="trans('labels.select_style')"
                            />
                        </div>

                        <!-- Tag list -->
                        <div v-if="hasPropertyTags" class="property property-tags">
                            <TagList
                                class="no-wrap"
                                :suggestions="sceneObject.parentUnitData.getTagsFromSceneObjects()"
                                :model="sceneObject"
                                property="tags"
                                @change="onChangeProperty"
                            />
                        </div>

                        <!-- Label -->
                        <div v-if="showPropertyLabel" class="property property-label">
                            <TextInput
                                v-for="component in sceneObject.getComponentsByType(classLabelComponent.type)"
                                class="no-wrap"
                                :key="'labelComponentText'+component.uid"
                                :label="trans('labels.label')"
                                :placeholder="trans('labels.label_placeholder')"
                                :model="component"
                                property="text"
                                :maxlength="30"
                                @change="onChangeProperty"
                            />
                        </div>
                    </template>
                </Collapsible>

            </div>

            <!-- Object transform -->
            <template v-if="sceneObject.supportsTransform">
                <PanelPlaceableTransform
                    v-if="shouldShowPlaceableTransformComponent"
                    class="panel-transform"
                    :sceneObject="sceneObject"
                    @change="onChangeProperty"
                />
                <PanelTransform
                    v-else
                    class="panel-transform"
                    :transform="sceneObject.transform"
                    @change="onChangeProperty"
                />
            </template>

            <PanelBehaviours
                :sceneObject="sceneObject"
                @change="onChangeProperty"
            />

            <PanelTriggers
                :key="'panel-triggers-'+sceneObject.uid"
                :sceneObject="sceneObject"
                @change="onChangeTriggers"
            />
        </div>

        <div class="buttons">
            <!-- Btn: Duplicate -->
            <ButtonCustom :disabled="!canDuplicateSceneObject" v-tooltip="'buttons.authoring.hotspot.duplicate'" icon="icon_duplicate" caption="labels.duplicate" @trigger="onClickDuplicateSceneObject" />

            <!-- Btn: Replace -->
            <ButtonCustom v-tooltip="'buttons.authoring.hotspot.replace'" icon="icon_replace" caption="labels.replace" @trigger="onClickReplace" />

            <!-- Btn: Remove -->
            <ButtonCustom v-tooltip="'buttons.authoring.hotspot.delete'" icon="icon_delete" caption="labels.remove" @trigger="onClickRemoveFromSelectedScene" />
        </div>

        <!-- Modal dialog for hotspot remove confirmation -->
        <DialogApplyCancel
            event-type="MODAL_REMOVE_HOTSPOT_FROM_SCENE"
            :title="trans('modals.remove_hotspot_from_scene.title')"
            :description="trans('modals.remove_hotspot_from_scene.description')"
        />
    </div>
</template>

<script>
// Import VueJS components:
import DialogApplyCancel        from '@/Vue/Modals/DialogApplyCancel.vue';
import PanelBehaviours          from '@/Vue/Inspector/PanelBehaviours.vue';
import PanelPlaceableTransform  from "@/Vue/Inspector/PanelPlaceableTransform.vue";
import PanelTransform           from "@/Vue/Inspector/PanelTransform.vue";
import PanelTriggers            from '@/Vue/Inspector/PanelTriggers.vue';

// Import classes:
import { shortId, sortArrayByProperty } from '@/Utility/Helpers';
import DropdownOption           from '@/Utility/DropdownOption';
import EventType                from '@/Utility/EventType';
import LabelComponent           from '@/Models/Unity/Components/LabelComponent';
import { BaseSceneObjectHotspot } from '@/Models/UnitData/SceneObjects/SceneObject';
import SceneObjectFilters       from '@/Filters/SceneObjectFilters';
import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';
import PlaceableComponent from "@/Models/Unity/Components/PlaceableComponent";

export default {
    name: 'InspectorSceneObjectHotspot',
    emits: [
        'change',
    ],
    components: {
        DialogApplyCancel,
        PanelBehaviours,
        PanelPlaceableTransform,
        PanelTransform,
        PanelTriggers,
    },
    props: {
        sceneObject: {              // Selected SceneObject that the asset is assigned to
            type: BaseSceneObjectHotspot,
            default: null
        }
    },
    data() {
        return {
            classLabelComponent: LabelComponent,     // Helper for using the class in the template
            refreshUI: shortId(),                   // Helper for refreshing UI elements
            shortcuts: new Map([
                ['Duplicate.global.prevent', this.onShortcutDuplicate],
                ['Delete.global', this.onShortcutDelete],
                ['Replace.global.prevent', this.onShortcutReplace],
            ])
        }
    },
    beforeMount() {
        // Make sure there's always at least one label component for hotspots:
        // @NOTE: Needed here since cleanUpData() is now being called not only when saving the unit!
        if (this.showPropertyLabel && this.sceneObject.getComponentsByType(LabelComponent.type).length === 0)
        {
            this.sceneObject.components.push(new LabelComponent());
        }
    },
    computed: {

        canDuplicateSceneObject() {
            return !this.sceneObject.hasReachedMaxCount;
        },

        hasPropertyTags() {
            return this.sceneObject.supportsTags;
        },

        /**
         * Whether to show the label property
         *
         * @returns {Boolean}
         */
        showPropertyLabel() {
            return ([SceneObjectType.Hotspots.Generic.subtype].includes(this.sceneObject.subtype));
        },

        shouldShowPlaceableTransformComponent() {
            return this.sceneObject && this.sceneObject.supportedComponents.includes(PlaceableComponent.type);
        },

        /**
         * Get the dropdown options for hotspot icon type
         *
         * @returns {Array<DropdownOption>}
         */
        hotspotStyleOptions() {
            const options = [];
            const availableStyles = this.sceneObject.supportedStyleTypes || null;
            if (availableStyles instanceof Array)
            {
                availableStyles.forEach(s => options[options.length] = new DropdownOption({
                    caption: s.title,
                    disabled: !s.enabled,
                    value: s.type,
                    icon: s.icon
                }));
            }
            return sortArrayByProperty(options, 'caption', false);
        },
    },
    methods: {

        /**
         * Common property change handler
         */
        onChangeProperty() {
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Update the triggers on the scene object whenever a change happens
         *
         * @param {Array<Trigger>} triggers      // List of Trigger objects
         */
        onChangeTriggers(triggers) {
            //console.log('Inspector: Triggers have been updated on SceneObject', this.sceneObject);
            this.$globalEvents.emit(EventType.INSPECTOR_SCENE_OBJECT_UPDATED, this.sceneObject);

            // Update scene objectives:
            this.$globalEvents.emit(EventType.INSPECTOR_TRIGGERS_UPDATED, this.sceneObject, triggers);
            return this;
        },

        /**
         * Click handler for scene object duplicate button
         */
        onClickDuplicateSceneObject() {
            this.$globalEvents.emit(EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.sceneObject);
            return this;
        },

        /**
         * Click handler for hotspot replace button
         */
        onClickReplace() {
            // Set filter title dynamically:
            let filter = null;

            switch (this.sceneObject.subtype) {
                case SceneObjectType.Hotspots.Transparent.subtype:
                    filter = SceneObjectFilters.Hotspot.setActive(true);
                    break;
                default:
                    filter = SceneObjectFilters.TransparentShape.setActive(true);
            }

            // Set filters:
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, [filter], this.sceneObject);
            // Show asset selection side panel for adding a new object:
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, {
                callback: this.sceneObject,
                clearWordFilter: true
            });
            return this;
        },

        /**
         * Click handler for asset remove button
         */
        onClickRemoveFromSelectedScene() {
            // Show modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_HOTSPOT_FROM_SCENE_SHOW, this.sceneObject);
            return this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            return this.onClickRemoveFromSelectedScene();
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDuplicate(e) {
            // Duplicate selected scene object if allowed:
            return this.canDuplicateSceneObject ? this.onClickDuplicateSceneObject() : this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutReplace(e) {
            return this.onClickReplace();
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
