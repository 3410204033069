import { trans } from '@/Utility/Helpers';

export default class KeyboardKey
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Attributes must always be an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Check for mandatory properties:
        if (typeof attributes.enum !== 'string' || attributes.enum === '')
        {
            console.warn('KeyboardKey->constructor(): Invalid data.', attributes);
            throw new TypeError('KeyboardKey->constructor(): Property "enum" has to be set on KeyboardKey. Must be a valid "UnityEngine.InputSystem.Key" enum string.');
        }
        else if (typeof KeyDefinitions[attributes.enum] === 'undefined' || KeyDefinitions[attributes.enum] instanceof Object === false)
        {
            console.warn('KeyboardKey->constructor(): Invalid data.', attributes);
            throw new TypeError('KeyboardKey->constructor(): Invalid key enum definition. Enum "' + attributes.enum + '" is unknown.');
        }
        // Use attributes from the actual key definitions to make sure they always match with the key enum:
        else if (attributes.code !== KeyDefinitions[attributes.enum].code)
        {
            console.warn('KeyboardKey->constructor(): Found incorrect "code" definition:', attributes.code, 'Changing to:', KeyDefinitions[attributes.enum].code);
            attributes.code = KeyDefinitions[attributes.enum].code;
        }
        if (typeof attributes.code !== 'string' || attributes.code === '')
        {
            console.warn('KeyboardKey->constructor(): Invalid data.', attributes);
            throw new TypeError('KeyboardKey->constructor(): Property "code" has to be set on KeyboardKey. Must be a valid "event.code" string.');
        }

        // Populate the model:
        this.code = attributes.code;                                            // Event key code (event.code, @see https://keycode.info/)
        this.enum = attributes.enum;                                            // Unity enum value (@see UnityEngine.InputSystem.Key enum)
        this.key = this.getKeyName(attributes.key || this.code);                // Localized key character representation defined by the user's keyboard layout
    }

    /**
     * Get localized key display name
     *
     * @param {String} keyOrEnumOrCode
     * @param {KeyboardEvent} e
     * @returns {String}
     */
    getKeyName(keyOrEnumOrCode = null, e = null) {

        let name = keyOrEnumOrCode || this.code;

        // Look for translation of the code or name at first:
        name = trans('keyboard.keys')[this.code] || trans('keyboard.keys')[name] || name;

        // Use uppercase letter for A-Z if there was no translation:
        name = ((this.code.indexOf('Key') === 0) ? this.enum : name);

        // Use the plain digit:
        name = ((this.code.indexOf('Digit') === 0) ? this.enum.replace('Digit', '') : name);

        // Remove unwanted characters at the beginning:
        name = (name.length >= 2) ? name.replace(/^[\^´`]?/, '') : name;

        // Make uppercase:
        name = (['ä', 'ö', 'ü'].indexOf(name) >= 0) ? name.toUpperCase() : name;

        // Add a suffix for numpad keys:
// @NOTE: Disabled since the app treats numpad keys like regular keys #PRDA-4874
//        if (name.indexOf('Numpad') === 0 || this.code.indexOf('Numpad') === 0 || this.enum.indexOf('Numpad') === 0 || (e instanceof KeyboardEvent && e.location === KeyboardEvent.DOM_KEY_LOCATION_NUMPAD))
//        {
//            const numpadSuffix = ' (' + trans('keyboard.locations.numpad_short') + ')';
//            name = name.replace('Numpad', '').replace(numpadSuffix, '') + numpadSuffix;
//        }
        return name;
    }

    /**
     * Whether the key is unidentified or dead
     *
     * @returns {Boolean}
     */
    get isUnidentified() {
        return (this.key === null || this.key === 'Dead' || this.key === 'Unidentified');
    }

    /**
     * Get all keys
     *
     * @returns {Object<KeyboardKey>}
     */
    static get all() {
        return Keys;
    }

    /**
     * Find a specific key by a given event
     *
     * @param {KeyboardEvent}
     * @returns {KeyboardKey|null}
     */
    static findByEvent(e) {
        let key = KeyboardKey.findByCode(e.key || e.code || e.keyCode || e.which || null) || KeyboardKey.findByCode(e.code || null);

        // Return if no key was found:
        if (key === null) return null;

        // Fix Numpad mapping for Edge:
        if (e instanceof KeyboardEvent && e.location === KeyboardEvent.DOM_KEY_LOCATION_NUMPAD && key.enum.indexOf('Numpad') === -1)
        {
            const enumMapping = {};
            enumMapping[KeyboardKey.Enter.enum]      = KeyboardKey.NumpadEnter;
            enumMapping[KeyboardKey.Delete.enum]     = KeyboardKey.NumpadPeriod;
            enumMapping[KeyboardKey.Insert.enum]     = KeyboardKey.Numpad0;
            enumMapping[KeyboardKey.End.enum]        = KeyboardKey.Numpad1;
            enumMapping[KeyboardKey.DownArrow.enum]  = KeyboardKey.Numpad2;
            enumMapping[KeyboardKey.PageDown.enum]   = KeyboardKey.Numpad3;
            enumMapping[KeyboardKey.LeftArrow.enum]  = KeyboardKey.Numpad4;
            //enumMapping[KeyboardKey.Clear.enum]      = KeyboardKey.Numpad5; // The Clear key is not supported
            enumMapping[KeyboardKey.RightArrow.enum] = KeyboardKey.Numpad6;
            enumMapping[KeyboardKey.Home.enum]       = KeyboardKey.Numpad7;
            enumMapping[KeyboardKey.UpArrow.enum]    = KeyboardKey.Numpad8;
            enumMapping[KeyboardKey.PageUp.enum]     = KeyboardKey.Numpad9;

            if (enumMapping[key.enum] instanceof KeyboardKey)
            {
                key = new KeyboardKey(enumMapping[key.enum]);
            }
        }

        // Get the display name for the given key:
        key.key = key.getKeyName(e.key || e.code || null, e);

        //console.log(e.key, e.keyCode, e.code, e.charCode, e.location, String.fromCharCode(e.charCode), e, key);
        return key;
    }

    /**
     * Find a specific key by a given name or key code
     *
     * @param {String|Number}
     * @returns {KeyboardKey|null}
     */
    static findByCode(code) {
        if (code === null) return null;
        const keyIndex = Object.keys(Keys).find(k => { return (k === code || k === code.toString().toUpperCase() || Keys[k].code === code || Keys[k].enum === code || Keys[k].keyCode === code); }) || null;
        return (keyIndex !== null) ? Keys[keyIndex] : null;
    }

    /**
     * Key sets
     *
     * @returns {Object<Array<KeyboardKey>>}
     */
    static get KeySets() {
        return {

            // Controls:
            Controls: [
                KeyboardKey.Enter,
                KeyboardKey.Escape,
                KeyboardKey.Tab,
                KeyboardKey.Backspace,

                KeyboardKey.LeftShift,
                KeyboardKey.RightShift,
                KeyboardKey.LeftAlt,
                KeyboardKey.AltGr,
                KeyboardKey.RightAlt,
                KeyboardKey.LeftCtrl,
                KeyboardKey.RightCtrl,
                KeyboardKey.LeftMeta,
                KeyboardKey.LeftCommand,
                KeyboardKey.LeftApple,
                KeyboardKey.LeftWindows,
                KeyboardKey.RightMeta,
                KeyboardKey.RightCommand,
                KeyboardKey.RightApple,
                KeyboardKey.RightWindows,
                KeyboardKey.ContextMenu,

                KeyboardKey.LeftArrow,
                KeyboardKey.RightArrow,
                KeyboardKey.UpArrow,
                KeyboardKey.DownArrow,

                KeyboardKey.PageDown,
                KeyboardKey.PageUp,
                KeyboardKey.Home,
                KeyboardKey.End,
                KeyboardKey.Insert,
                KeyboardKey.Delete,
                KeyboardKey.CapsLock,
                KeyboardKey.NumLock,
                KeyboardKey.PrintScreen,
                KeyboardKey.ScrollLock,
                KeyboardKey.Pause,

                KeyboardKey.NumpadEnter,
            ],

            // Digits:
            Digits: [
                KeyboardKey.Digit0,
                KeyboardKey.Digit1,
                KeyboardKey.Digit2,
                KeyboardKey.Digit3,
                KeyboardKey.Digit4,
                KeyboardKey.Digit5,
                KeyboardKey.Digit6,
                KeyboardKey.Digit7,
                KeyboardKey.Digit8,
                KeyboardKey.Digit9,
                KeyboardKey.Numpad0,
                KeyboardKey.Numpad1,
                KeyboardKey.Numpad2,
                KeyboardKey.Numpad3,
                KeyboardKey.Numpad4,
                KeyboardKey.Numpad5,
                KeyboardKey.Numpad6,
                KeyboardKey.Numpad7,
                KeyboardKey.Numpad8,
                KeyboardKey.Numpad9,
            ],

            // Color HEX:
            ColorHEX: [
                KeyboardKey.Digit0,
                KeyboardKey.Digit1,
                KeyboardKey.Digit2,
                KeyboardKey.Digit3,
                KeyboardKey.Digit4,
                KeyboardKey.Digit5,
                KeyboardKey.Digit6,
                KeyboardKey.Digit7,
                KeyboardKey.Digit8,
                KeyboardKey.Digit9,
                KeyboardKey.Numpad0,
                KeyboardKey.Numpad1,
                KeyboardKey.Numpad2,
                KeyboardKey.Numpad3,
                KeyboardKey.Numpad4,
                KeyboardKey.Numpad5,
                KeyboardKey.Numpad6,
                KeyboardKey.Numpad7,
                KeyboardKey.Numpad8,
                KeyboardKey.Numpad9,
                KeyboardKey.A,
                KeyboardKey.B,
                KeyboardKey.C,
                KeyboardKey.D,
                KeyboardKey.E,
                KeyboardKey.F,
            ],

            // Trigger OnKeyPress:
            TriggerKeyPress: [
                KeyboardKey.A,
                KeyboardKey.B,
                KeyboardKey.C,
                KeyboardKey.D,
                KeyboardKey.E,
                KeyboardKey.F,
                KeyboardKey.G,
                KeyboardKey.H,
                KeyboardKey.I,
                KeyboardKey.J,
                KeyboardKey.K,
                KeyboardKey.L,
                KeyboardKey.M,
                KeyboardKey.N,
                KeyboardKey.O,
                KeyboardKey.P,
                KeyboardKey.Q,
                KeyboardKey.R,
                KeyboardKey.S,
                KeyboardKey.T,
                KeyboardKey.U,
                KeyboardKey.V,
                KeyboardKey.W,
                KeyboardKey.X,
                KeyboardKey.Y,
                KeyboardKey.Z,
                KeyboardKey.Digit0,
                KeyboardKey.Digit1,
                KeyboardKey.Digit2,
                KeyboardKey.Digit3,
                KeyboardKey.Digit4,
                KeyboardKey.Digit5,
                KeyboardKey.Digit6,
                KeyboardKey.Digit7,
                KeyboardKey.Digit8,
                KeyboardKey.Digit9,
                KeyboardKey.Numpad0,
                KeyboardKey.Numpad1,
                KeyboardKey.Numpad2,
                KeyboardKey.Numpad3,
                KeyboardKey.Numpad4,
                KeyboardKey.Numpad5,
                KeyboardKey.Numpad6,
                KeyboardKey.Numpad7,
                KeyboardKey.Numpad8,
                KeyboardKey.Numpad9,
                KeyboardKey.NumpadEnter,
                KeyboardKey.NumpadDivide,
                KeyboardKey.NumpadMultiply,
                KeyboardKey.NumpadPlus,
                KeyboardKey.NumpadMinus,
                KeyboardKey.NumpadPeriod,
                KeyboardKey.NumpadEquals,
                KeyboardKey.Quote,
                KeyboardKey.Semicolon,
                KeyboardKey.Comma,
                KeyboardKey.Period,
                KeyboardKey.Slash,
                KeyboardKey.Backslash,
                KeyboardKey.LeftBracket,
                KeyboardKey.RightBracket,
                KeyboardKey.Minus,
                KeyboardKey.Equals,
                KeyboardKey.Enter,
                KeyboardKey.Space,
            ]
        };
    }
}

// @NOTE: Object keys are the representations from the UnityEngine.InputSystem.Key enum
// @see: https://www.w3.org/TR/uievents-code/
// @see: https://developer.mozilla.org/de/docs/Web/API/KeyboardEvent/keyCode
// @see: https://developer.mozilla.org/de/docs/Web/API/KeyboardEvent/key/Key_Values
const KeyDefinitions = {
    'None':             {   keyCode:        0,              code:       'None',                         },
    'Space':            {   keyCode:       32,              code:       'Space',                        },
    'Enter':            {   keyCode:       13,              code:       'Enter',                        },
    'Tab':              {   keyCode:        9,              code:       'Tab',                          },
    'Backquote':        {   keyCode:      220,              code:       'Backquote',                    },
    'Quote':            {   keyCode:      222,              code:       'Quote',                        },
    'Semicolon':        {   keyCode:      192,              code:       'Semicolon',                    },
    'Comma':            {   keyCode:      188,              code:       'Comma',                        },
    'Period':           {   keyCode:      190,              code:       'Period',                       },
    'Slash':            {   keyCode:      189,              code:       'Slash',                        },
    'Backslash':        {   keyCode:      191,              code:       'Backslash',                    },
    'LeftBracket':      {   keyCode:      186,              code:       'BracketLeft',                  },
    'RightBracket':     {   keyCode:      187,              code:       'BracketRight',                 },
    'Minus':            {   keyCode:      219,              code:       'Minus',                        },
    'Equals':           {   keyCode:      221,              code:       'Equal',                        },
    'A':                {   keyCode:       65,              code:       'KeyA',                         },
    'B':                {   keyCode:       66,              code:       'KeyB',                         },
    'C':                {   keyCode:       67,              code:       'KeyC',                         },
    'D':                {   keyCode:       68,              code:       'KeyD',                         },
    'E':                {   keyCode:       69,              code:       'KeyE',                         },
    'F':                {   keyCode:       70,              code:       'KeyF',                         },
    'G':                {   keyCode:       71,              code:       'KeyG',                         },
    'H':                {   keyCode:       72,              code:       'KeyH',                         },
    'I':                {   keyCode:       73,              code:       'KeyI',                         },
    'J':                {   keyCode:       74,              code:       'KeyJ',                         },
    'K':                {   keyCode:       75,              code:       'KeyK',                         },
    'L':                {   keyCode:       76,              code:       'KeyL',                         },
    'M':                {   keyCode:       77,              code:       'KeyM',                         },
    'N':                {   keyCode:       78,              code:       'KeyN',                         },
    'O':                {   keyCode:       79,              code:       'KeyO',                         },
    'P':                {   keyCode:       80,              code:       'KeyP',                         },
    'Q':                {   keyCode:       81,              code:       'KeyQ',                         },
    'R':                {   keyCode:       82,              code:       'KeyR',                         },
    'S':                {   keyCode:       83,              code:       'KeyS',                         },
    'T':                {   keyCode:       84,              code:       'KeyT',                         },
    'U':                {   keyCode:       85,              code:       'KeyU',                         },
    'V':                {   keyCode:       86,              code:       'KeyV',                         },
    'W':                {   keyCode:       87,              code:       'KeyW',                         },
    'X':                {   keyCode:       88,              code:       'KeyX',                         },
    'Y':                {   keyCode:       89,              code:       'KeyY',                         },
    'Z':                {   keyCode:       90,              code:       'KeyZ',                         },
    'Digit1':           {   keyCode:       49,              code:       'Digit1',                       },
    'Digit2':           {   keyCode:       50,              code:       'Digit2',                       },
    'Digit3':           {   keyCode:       51,              code:       'Digit3',                       },
    'Digit4':           {   keyCode:       52,              code:       'Digit4',                       },
    'Digit5':           {   keyCode:       53,              code:       'Digit5',                       },
    'Digit6':           {   keyCode:       54,              code:       'Digit6',                       },
    'Digit7':           {   keyCode:       55,              code:       'Digit7',                       },
    'Digit8':           {   keyCode:       56,              code:       'Digit8',                       },
    'Digit9':           {   keyCode:       57,              code:       'Digit9',                       },
    'Digit0':           {   keyCode:       48,              code:       'Digit0',                       },
    'LeftShift':        {   keyCode:       16,              code:       'ShiftLeft',                    },
    'RightShift':       {   keyCode:       16,              code:       'ShiftRight',                   },
    'LeftAlt':          {   keyCode:       18,              code:       'AltLeft',                      },
    'AltGr':            {   keyCode:       18,              code:       'AltRight',                     },
    'RightAlt':         {   keyCode:       18,              code:       'AltRight',                     },
    'LeftCtrl':         {   keyCode:       17,              code:       'ControlLeft',                  },
    'RightCtrl':        {   keyCode:       17,              code:       'ControlRight',                 },
    'LeftMeta':         {   keyCode:       91,              code:       'MetaLeft',                     },
    'LeftCommand':      {   keyCode:      224,              code:       'OSLeft',                       },
    'LeftApple':        {   keyCode:      224,              code:       'OSLeft',                       },
    'LeftWindows':      {   keyCode:       91,              code:       'OSLeft',                       },
    'RightMeta':        {   keyCode:       93,              code:       'MetaRight',                    },
    'RightCommand':     {   keyCode:      224,              code:       'OSRight',                      },
    'RightApple':       {   keyCode:      224,              code:       'OSRight',                      },
    'RightWindows':     {   keyCode:       91,              code:       'OSRight',                      },
    'ContextMenu':      {   keyCode:       93,              code:       'ContextMenu',                  },
    'Escape':           {   keyCode:       27,              code:       'Escape',                       },
    'LeftArrow':        {   keyCode:       37,              code:       'ArrowLeft',                    },
    'RightArrow':       {   keyCode:       39,              code:       'ArrowRight',                   },
    'UpArrow':          {   keyCode:       38,              code:       'ArrowUp',                      },
    'DownArrow':        {   keyCode:       40,              code:       'ArrowDown',                    },
    'Backspace':        {   keyCode:        8,              code:       'Backspace',                    },
    'PageDown':         {   keyCode:       34,              code:       'PageDown',                     },
    'PageUp':           {   keyCode:       33,              code:       'PageUp',                       },
    'Home':             {   keyCode:       36,              code:       'Home',                         },
    'End':              {   keyCode:       35,              code:       'End',                          },
    'Insert':           {   keyCode:       45,              code:       'Insert',                       },
    'Delete':           {   keyCode:       46,              code:       'Delete',                       },
    'CapsLock':         {   keyCode:       20,              code:       'CapsLock',                     },
    'NumLock':          {   keyCode:      144,              code:       'NumLock',                      },
    'PrintScreen':      {   keyCode:       44,              code:       'PrintScreen',                  },
    'ScrollLock':       {   keyCode:      145,              code:       'ScrollLock',                   },
    'Pause':            {   keyCode:       19,              code:       'Pause',                        },
    'NumpadEnter':      {   keyCode:       13,              code:       'NumpadEnter',                  },
    'NumpadDivide':     {   keyCode:      111,              code:       'NumpadDivide',                 },
    'NumpadMultiply':   {   keyCode:      106,              code:       'NumpadMultiply',               },
    'NumpadPlus':       {   keyCode:      107,              code:       'NumpadAdd',                    },
    'NumpadMinus':      {   keyCode:      109,              code:       'NumpadSubtract',               },
    'NumpadPeriod':     {   keyCode:      110,              code:       'NumpadDecimal',                },
    'NumpadEquals':     {   keyCode:       61,              code:       'NumpadEqual',                  },
    'Numpad0':          {   keyCode:       96,              code:       'Numpad0',                      },
    'Numpad1':          {   keyCode:       97,              code:       'Numpad1',                      },
    'Numpad2':          {   keyCode:       98,              code:       'Numpad2',                      },
    'Numpad3':          {   keyCode:       99,              code:       'Numpad3',                      },
    'Numpad4':          {   keyCode:      100,              code:       'Numpad4',                      },
    'Numpad5':          {   keyCode:      101,              code:       'Numpad5',                      },
    'Numpad6':          {   keyCode:      102,              code:       'Numpad6',                      },
    'Numpad7':          {   keyCode:      103,              code:       'Numpad7',                      },
    'Numpad8':          {   keyCode:      104,              code:       'Numpad8',                      },
    'Numpad9':          {   keyCode:      105,              code:       'Numpad9',                      },
    'F1':               {   keyCode:      112,              code:       'F1',                           },
    'F2':               {   keyCode:      113,              code:       'F2',                           },
    'F3':               {   keyCode:      114,              code:       'F3',                           },
    'F4':               {   keyCode:      115,              code:       'F4',                           },
    'F5':               {   keyCode:      116,              code:       'F5',                           },
    'F6':               {   keyCode:      117,              code:       'F6',                           },
    'F7':               {   keyCode:      118,              code:       'F7',                           },
    'F8':               {   keyCode:      119,              code:       'F8',                           },
    'F9':               {   keyCode:      120,              code:       'F9',                           },
    'F10':              {   keyCode:      121,              code:       'F10',                          },
    'F11':              {   keyCode:      122,              code:       'F11',                          },
    'F12':              {   keyCode:      123,              code:       'F12',                          },
//    'F13':              {   keyCode:      124,              code:       'F13',                          }, // @NOTE: not supported in Unity
//    'F14':              {   keyCode:      125,              code:       'F14',                          }, // @NOTE: not supported in Unity
//    'F15':              {   keyCode:      126,              code:       'F15',                          }, // @NOTE: not supported in Unity
//    'F16':              {   keyCode:      127,              code:       'F16',                          }, // @NOTE: not supported in Unity
//    'F17':              {   keyCode:      128,              code:       'F17',                          }, // @NOTE: not supported in Unity
//    'F18':              {   keyCode:      129,              code:       'F18',                          }, // @NOTE: not supported in Unity
//    'F19':              {   keyCode:      130,              code:       'F19',                          }, // @NOTE: not supported in Unity
//    'F20':              {   keyCode:      131,              code:       'F20',                          }, // @NOTE: not supported in Unity
//    'F21':              {   keyCode:      132,              code:       'F21',                          }, // @NOTE: not supported in Unity
//    'F22':              {   keyCode:      133,              code:       'F22',                          }, // @NOTE: not supported in Unity
//    'F23':              {   keyCode:      134,              code:       'F23',                          }, // @NOTE: not supported in Unity
//    'F24':              {   keyCode:      135,              code:       'F24',                          }, // @NOTE: not supported in Unity
//    'OEM1':             {   keyCode:      null,             code:       null,                           },
//    'OEM2':             {   keyCode:      null,             code:       null,                           },
//    'OEM3':             {   keyCode:      null,             code:       null,                           },
//    'OEM4':             {   keyCode:      null,             code:       null,                           },
//    'OEM5':             {   keyCode:      null,             code:       null,                           },
//    'IMESelected':      {   keyCode:      null,             code:       null,                           },
//    '':                 {   keyCode:      173,              code:       'AudioVolumeMute',              }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:      174,              code:       'AudioVolumeDown',              }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:      175,              code:       'AudioVolumeUp',                }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:       12,              code:       'Clear',                        }, // @NOTE: not supported in Unity
//    '':                 {   keyCode:      226,              code:       'IntlBackslash',                }, // @NOTE: not supported in Unity
};

const Keys = {};
Object.keys(KeyDefinitions).forEach(k => {
    const keyDef = KeyDefinitions[k];
    Keys[k] = new KeyboardKey({
        code: keyDef.code,
        enum: k,
        keyCode: keyDef.keyCode
    });

    // Expose each key as a class property:
    KeyboardKey[k] = Keys[k];
});
