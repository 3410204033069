<template>
    <div v-if="isVisible" id="dialog-create-unit-from-history" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <h2 class="title">{{ trans('modals.confirm_model_conversion_issues.title') }}</h2>
            <p class="description" v-html="trans('modals.confirm_model_conversion_issues.description')"></p>
            <br>
            <Checkbox
                layout="checkbox-right"
                :caption="trans('modals.confirm_model_conversion_issues.remember')"
                :model="this"
                property="remember"
            />
            <div class="buttons">
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="modals.confirm_model_conversion_issues.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    import Checkbox from "@/Vue/Common/Checkbox.vue";
    import ButtonPrimary from "@/Vue/Common/ButtonPrimary.vue";

    // Import classes:
    import {getCookie, setCookie} from 'typescript-cookie';
    import { trans }                from '@/Utility/Helpers';
    import EventType                from '@/Utility/EventType';

    const cookieNameIncompatibilityNotification = 'converter3d_dont_show_incompatibility_notification';

    const getUserPreferenceFromCookie = () => {
        const cookie = JSON.parse(getCookie(cookieNameIncompatibilityNotification) || '[]');
        return (cookie instanceof Array && cookie.includes(window.currentUser.uid));
    };

    const setUserPreferenceToCookie = (value) => {
      let cookie = JSON.parse(getCookie(cookieNameIncompatibilityNotification) || '[]');
      if (!(cookie instanceof Array)) {
        cookie = [window.currentUser.uid];
      }else if (!cookie.includes(window.currentUser.uid)) {
        cookie.push(window.currentUser.uid);
      }
      setCookie(cookieNameIncompatibilityNotification, JSON.stringify(cookie));
    };

    export default {
        name: 'DialogConfirmModelConversionIssues',
        components: {
            ButtonPrimary,
            Checkbox
        },
        data() {
            return {
                isVisible: false,
                remember: getUserPreferenceFromCookie(),
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ]),
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_HIDE, this.hide);
        },
        methods: {
            trans,

            /**
             * Show the dialog
             */
            show() {
              // Don't show the dialog if the checkbox is checked already:
                    if (this.remember) {
                      this.isVisible = false;
                      return this;
                    }

                this.previousFocusElement = document.activeElement;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {

                    // Save remember state in session:
                if (this.remember) {
                          setUserPreferenceToCookie(this.remember);
                }

                this.$globalEvents.emit(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_APPLY);
                this.hide();
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

.modal-dialog .dialog {
    width: 500px;
}

</style>
