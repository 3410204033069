<template>

    <div v-if="courseIsSample" class="property property-users">
        <header>{{ trans('labels.enrolled_for_learning') }}</header>
        <p class="info-changes-visible-immediately">{{ trans('courses.index.enrolled_users_sample') }}</p>
    </div>

    <div v-else class="property property-users">
        <header>{{ trans('labels.enrolled_for_learning') }}</header>

        <ul v-if="enrolledUsers && enrolledUsers.length >= 1">
            <li v-for="(user, index) in enrolledUsers"
                :key="'enrolledUsers'+index+'_'+course.uid+'_'+user.uid"
                class="assigned-user">
                <span class="image">
                    <img :src="user.image" alt=""/>
                </span>
                <section class="info">
                    <h4 class="name">{{ user.firstname }} {{ user.lastname }}</h4>
                    <p class="email">{{ user.email }}</p>
                </section>
                <span v-if="canDisenrollUsers" class="remove-btn">
                    <Icon class="icon-delete" name="icon_delete" @click="onClickRemoveUser(user)"/>
                </span>

                <!-- Delete confirm overlay -->
                <OverlayConfirmRemove
                    v-if="isRemoveConfirmDialogVisible[user.uid] === true"
                    @cancel="onCancelUserRemove(user)"
                    @confirm="onConfirmUserRemove(user)"
                />
            </li>
        </ul>

        <p v-else-if="!showButtonGroup" class="no-users">{{ trans('courses.index.no_users_to_enroll') }}</p>

        <div
            v-if="showButtonGroup"
            class="buttons"
        >
            <ButtonPrimary
                v-if="shouldShowEnrollUsersButton"
                caption="labels.enroll_users"
                class="assign-users"
                icon="icon_add"
                @trigger="onClickBtnEnrollUser"
            />

            <ButtonSecondary
                v-if="shouldShowDisenrollUsersButton"
                caption="labels.remove_all"
                class="assign-users"
                @trigger="onClickBtnRemoveAllUsers"
            />
        </div>

    </div>
</template>

<script>

    // Import VueJS components:
    import OverlayConfirmRemove from '@/Vue/Inspector/OverlayConfirmRemove.vue';

    // Import classes:
    import {Permission} from '@/Models/User/Permission';
    import EventType from '@/Utility/EventType';
    import Course from '@/Models/Course/Course';
    import { CoursePermissionPolicySample } from '@/Models/Course/CoursePermissionPolicy';
    import {inject} from "vue";
    import {userServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

    export default {
        name: 'PanelUserEnrollment',
        components: {
            OverlayConfirmRemove,
        },
        props: {
            course: {
                type: Course,
                default: null
            },
        },
        data() {
            return {
                // Global CourseService instance
                courseService: this.$courseService,

                // Global UserService instance
                userService: inject(userServiceKey),

                // Whether the remove confirmation dialog is visible for a specific user
                isRemoveConfirmDialogVisible: {},
            }
        },
        computed: {

            canEnrollUsers() {
                return this.$gate.allows(Permission.ability(Permission.CoursesEnrollUsers()), this.course);
            },

            courseIsSample() {
                return CoursePermissionPolicySample.type === this.course.policy;
            },

            canDisenrollUsers() {
                return this.canEnrollUsers;
            },

            showButtonGroup() {
                return this.shouldShowEnrollUsersButton || this.shouldShowDisenrollUsersButton;
            },

            shouldShowEnrollUsersButton() {
                return  this.canEnrollUsers && this.unenrolledUsers.length >= 1
            },

            shouldShowDisenrollUsersButton() {
                return this.canEnrollUsers && this.hasEnrolledUsers;
            },

            /**
             * Does the training have any assigned users?
             *
             * @returns {Boolean}
             */
            hasEnrolledUsers() {
                return this.course.hasEnrolledUsers;
            },

            /**
             * Get assigned users for the training
             *
             * @returns {Array<User>}
             */
            enrolledUsers() {
                if (this.course !== null) {
                    return this.userService.getEnrolledUsersForCourse(this.course);
                }
                return [];
            },

            /**
             * Get unenrolled users for the course
             *
             * @returns {Array<User>}
             */
            unenrolledUsers() {
                if (this.course !== null) {
                    return this.userService.getUnenrolledUsersForCourse(this.course);
                }
                return [];
            },

        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_APPLY, this.onApplyRemoveEnrollmentsFromCourse);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_ASSIGN, this.onEnrollUsersToCourse);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_APPLY, this.onApplyRemoveEnrollmentsFromCourse);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_ASSIGN, this.onEnrollUsersToCourse);
        },
        methods: {

            /**
             * Click handler for assigning users button
             *
             * @param {MouseEvent} e
             */
            onClickBtnEnrollUser(e) {
                // Hide any remove confirm dialogs:
                this.isRemoveConfirmDialogVisible = {};
                // Clear the selected state:
                this.userService.users.forEach(u => u.selected = false);
                // Show the sidepanel:
                this.$globalEvents.emit(EventType.SIDEPANEL_USERS_SHOW);
                return this;
            },

            /**
             * Click handler for remove all users button
             *
             * @param {MouseEvent} e
             */
            onClickBtnRemoveAllUsers(e) {
                // Hide any remove confirm dialogs:
                this.isRemoveConfirmDialogVisible = {};
                // Show the modal dialog:
                this.$globalEvents.emit(EventType.MODAL_REMOVE_ENROLLMENTS_FROM_COURSE_SHOW);
                return this;
            },

            /**
             * Click handler for user remove buttons
             *
             * @param {User} user User object reference
             */
            onClickRemoveUser(user) {
                this.isRemoveConfirmDialogVisible = {};    // Only allow one open dialog at the same time
                this.isRemoveConfirmDialogVisible[user.uid] = true;
                return this;
            },

            /**
             * Confirm handler for user remove confirmation dialog
             *
             * @param {User} user User object reference
             */
            onConfirmUserRemove(user) {
                delete this.isRemoveConfirmDialogVisible[user.uid];

                this.sendEnrolledUsersToApi(null, [user]);
            },

            /**
             * Cancel handler for asset remove confirmation dialog
             *
             * @param {User} user Trigger object reference
             */
            onCancelUserRemove(user) {
                delete this.isRemoveConfirmDialogVisible[user.uid];
                return this;
            },

            /**
             * Click handler for remove all users dialog apply button
             */
            onApplyRemoveEnrollmentsFromCourse() {
                this.sendEnrolledUsersToApi(null, this.enrolledUsers);
            },

            /**
             * Assign users to selected training
             *
             * @param {Array<User>} users
             */
            onEnrollUsersToCourse(users) {
                this.sendEnrolledUsersToApi(users);
            },

            /**
             * Syncs the given user assignments with the API and handles errors in the process.
             * User enrollments will be synced to the course property and propagated on success.
             *
             * @param {?Array<User>} usersToEnroll
             * @param {?Array<User>} usersToDisenroll
             * @returns {Promise<T>}
             */
            async sendEnrolledUsersToApi(usersToEnroll = null, usersToDisenroll = null) {
                usersToEnroll = usersToEnroll || [];
                usersToDisenroll = usersToDisenroll || [];

                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);

                return this.courseService
                    .changeUserEnrollments(this.course, usersToEnroll, usersToDisenroll)
                    .then(() => {
                        this.$globalEvents.emit(EventType.USER_ASSIGNMENT_USERS_CHANGED, this.course);
                    })
                    .catch(error => {
                        this.$root.showErrorDialog(error);
                    })
                    .finally(() => {
                        this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                    });
            }
        }
    }

</script>


<style lang="scss" scoped>

</style>
