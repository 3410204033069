import {InjectionKey} from "vue";
import FeatureRepository from "@/Features/FeatureRepository";
import TrackingService from "@/Services/TrackingService";
import ManageService from "@/Services/ManageService";
import SketchfabService from "@/Services/SketchfabService";
import StatisticsService from "@/Services/StatisticsService";
import TenantService from "@/Services/TenantService";
import UnitLinkService from "@/Services/UnitLinkService";
import LearningRecordsService from "@/Services/LearningRecordsService";
import LearningRecordsStatisticsService from "@/Services/LearningRecordsStatisticsService";
import LocalFeatureService from "@/Services/LocalFeatureService";
import UserService from "@/Services/UserService";

export const featureRepositoryKey: InjectionKey<FeatureRepository> = Symbol();
export const localFeatureServiceKey: InjectionKey<LocalFeatureService> = Symbol();
export const trackingServiceKey: InjectionKey<TrackingService> = Symbol();
export const userServiceKey: InjectionKey<UserService> = Symbol();
export const manageServiceKey: InjectionKey<ManageService> = Symbol();
export const sketchfabServiceKey: InjectionKey<SketchfabService> = Symbol();
export const statisticsServiceKey: InjectionKey<StatisticsService> = Symbol();
export const learningRecordsServiceKey: InjectionKey<LearningRecordsService> = Symbol();
export const learningRecordsStatisticsServiceKey: InjectionKey<LearningRecordsStatisticsService> = Symbol();
export const tenantServiceKey: InjectionKey<TenantService> = Symbol();
export const unitLinkServiceKey: InjectionKey<UnitLinkService> = Symbol();
