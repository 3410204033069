<template>
    <div ref="threejs-canvas-container" class="threejs-canvas-container">
        <canvas ref="threejs-canvas"/>
    </div>
</template>

<script type="module" lang="ts">

// Helpers
import {defineComponent, PropType, toRaw} from 'vue';

// Three.js
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import {GLTFExporter} from 'three/examples/jsm/exporters/GLTFExporter';
import {
    ACESFilmicToneMapping,
    AmbientLight,
    AnimationClip,
    AnimationMixer,
    Box3,
    CanvasTexture,
    Clock,
    Color,
    DirectionalLight,
    DoubleSide, EquirectangularReflectionMapping,
    Group,
    Mesh,
    MeshBasicMaterial,
    MultiplyBlending,
    Object3D,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    sRGBEncoding, TextureLoader,
    Vector3,
    WebGLRenderer
} from "three";
import {clone} from "three/examples/jsm/utils/SkeletonUtils";
import {ThreeObject3DUserData} from "@/Utility/Threejs/ThreeObject3DUserData";

export default defineComponent({
    name: "ThreejsModelRenderer",

    props: {
        model: {
            type: Object as PropType<Group | null>,
            default: null,
        },
        highlightedObject: {
            type: Object as PropType<Object3D | null>,
            default: null,
        },
        highlightedAnimation: {
            type: Object as PropType<AnimationClip | null>,
            default: null,
        },
        autoRotateOnStart: {
            type: Boolean,
            default: false,
        },
        backgroundColor: {
            type: String,
            default: '#F2F4F5',
        },
        previewWidth: {
            type: Number,
            default: 1280,
        },
        previewHeight: {
            type: Number,
            default: 720,
        },
        previewBackgroundColor: {
            type: String,
            default: '#E8EDEF',
        },
    },

    data() {
        const camera = new PerspectiveCamera();

        return {
            camera: camera,
            scene: new Scene(),
            renderer: null as WebGLRenderer | null,
            controls: new OrbitControls(camera, document.body),
            clock: new Clock(),
            mixer: null as AnimationMixer | null,
            mainLoopId: 0,
            highlightMaterial: new MeshBasicMaterial({
                color: 0x00B9C9,
                side: DoubleSide,
            }),
            autoRotate: false,
            shadowMesh: new Mesh<PlaneGeometry, MeshBasicMaterial>(),
        };
    },

    mounted() {
        this.initThree();

        window.addEventListener('resize', this.onWindowResize);
    },

    beforeUnmount() {
        this.autoRotate = false;

        window.removeEventListener('resize', this.onWindowResize);
    },

    computed: {
        container() {
            return this.$refs['threejs-canvas-container'] as HTMLElement;
        },
        canvas() {
            return this.$refs['threejs-canvas'] as HTMLCanvasElement;
        },
        executeLoop() {
            return this.autoRotate || this.highlightedAnimation !== null;
        },
        userData() {
            return this.model?.userData as ThreeObject3DUserData | null
        }
    },

    methods: {
        initThree() {
            // Set up renderer & scene
            this.camera = new PerspectiveCamera(75, this.rendererAspect(), 0.01, 10000);
            this.scene = new Scene();

            this.renderer = new WebGLRenderer({
                canvas: this.canvas as HTMLCanvasElement,
                antialias: true
            });
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(this.rendererSize().width, this.rendererSize().height);
            this.renderer.toneMapping = ACESFilmicToneMapping;
            this.renderer.toneMappingExposure = 1;
            this.renderer.outputEncoding = sRGBEncoding;

            // Add controls
            this.controls.dispose();
            this.controls = new OrbitControls(this.camera, this.container as HTMLElement);
            this.controls.enablePan = false;
            this.controls.target.set(0, 0, 0);
            this.controls.addEventListener('change', this.render);
            this.controls.addEventListener('start', () => this.onControlInteractionStarted());
            this.controls.update();
            // Save initial state for the controls so that we can reset them later
            this.controls.saveState();

            // Add solid color background
            this.scene.background = new Color(this.backgroundColor);

            // Add environment for reflective stuff
            this.scene.environment = new TextureLoader().load('/images/3d/room_environment_equi.jpg');
            this.scene.environment.mapping = EquirectangularReflectionMapping;

            // Add light
            const ambientLight = new AmbientLight(0xcccccc, 0.5);
            this.scene.add(ambientLight);
            const light = new DirectionalLight(0xffffff, 1.0);
            light.position.set(0.5, 1, 0);
            this.scene.add(light);

            // Add 'floor'
            this.shadowMesh = this.createSpotShadowMesh();
            this.scene.add(this.shadowMesh);
        },

        adjustSceneToObject(obj) {
            const bbox = new Box3().setFromObject(obj);
            const objectCenter = new Vector3();
            const objectSize = new Vector3();
            bbox.getCenter(objectCenter);
            bbox.getSize(objectSize);

            // adjust camera
            const cameraZ = objectSize.y / 4 / Math.tan(this.camera.fov / 2);
            this.camera.position.set(objectCenter.x, objectCenter.y, objectCenter.z - cameraZ + objectSize.z / 2);
            this.camera.lookAt(objectCenter);

            // adjust shadow
            this.shadowMesh.position.x = objectCenter.x;
            this.shadowMesh.position.y = objectCenter.y - (objectSize.y / 2);
            this.shadowMesh.position.z = objectCenter.z;
            const shadowSize = Math.max(objectSize.x, objectSize.z) * 1.6;
            this.shadowMesh.scale.setScalar(shadowSize);

            // adjust controls
            this.controls.target.set(objectCenter.x, objectCenter.y, objectCenter.z);
            this.controls.update();
        },

        createSpotShadowMesh(): Mesh<PlaneGeometry, MeshBasicMaterial> {
            const canvas = document.createElement('canvas');
            canvas.width = 128;
            canvas.height = 128;

            const context = canvas.getContext('2d');

            if (context === null) {
                throw new Error('Cannot get context to create shadow texture.')
            }

            const gradient = context.createRadialGradient(
                canvas.width / 2,
                canvas.height / 2, 0,
                canvas.width / 2,
                canvas.height / 2,
                canvas.width / 2
            );
            gradient.addColorStop(0.1, 'rgba(180,180,180,1)');
            gradient.addColorStop(1, 'rgba(255,255,255,1)');

            context.fillStyle = gradient;
            context.fillRect(0, 0, canvas.width, canvas.height);

            const shadowTexture = new CanvasTexture(canvas);

            const geometry = new PlaneGeometry();
            const material = new MeshBasicMaterial({
                map: shadowTexture, blending: MultiplyBlending, toneMapped: false
            });

            const mesh = new Mesh(geometry, material);
            mesh.receiveShadow = true;
            mesh.rotation.x = -Math.PI / 2;

            return mesh;
        },

        onControlInteractionStarted() {
            // stop autorotation once the user has interacted with the model
            this.autoRotate = false;
        },

        onWindowResize() {
            this.camera.aspect = this.rendererAspect();
            this.camera?.updateProjectionMatrix();
            this.renderer?.setSize(this.rendererSize().width, this.rendererSize().height);
            this.render();
        },

        startAutoRotationIfEnabled() {
            this.autoRotate = this.autoRotateOnStart;
            this.controls.autoRotate = this.autoRotate;
        },

        loop() {
            if (this.executeLoop) {
                this.mainLoopId = requestAnimationFrame(this.loop);
            }

            if (this.highlightedAnimation !== null) {
                this.mixer?.update(this.clock.getDelta());
            }

            if (this.autoRotate) {
                this.controls?.update();
            }

            this.render();
        },

        resetView() {
            this.controls?.reset();
            this.shadowMesh.scale.setScalar(0);

            if (this.model) {
                this.adjustSceneToObject(this.model);
            }
        },

        render() {
            if (!this.scene || !this.camera) {
                return;
            }

            this.renderer?.render(toRaw(this.scene), this.camera);
        },

        rendererAspect() {
            return this.rendererSize().width / this.rendererSize().height;
        },

        rendererSize() {
            const container = this.container as HTMLElement;
            return {
                width: container.offsetWidth,
                height: container.offsetHeight,
            };
        },

        highlight(obj) {
            obj.children.forEach(this.highlight);

            if (obj.isMesh) {
                obj.userData.oldMaterial = obj.material;
                obj.material = this.highlightMaterial;
            }
        },

        unHighlight(obj) {
            obj.children.forEach(this.unHighlight);

            if (obj.isMesh) {
                obj.material = obj.userData.oldMaterial;
            }
        },

        /**
         * @param {string} fileName
         * @return {Promise<File>}
         */
        async getGlbFile(fileName) {
            if (!this.model) {
                throw new Error('No model to export file from.');
            }

            const exporter = new GLTFExporter();

            const data = await exporter.parseAsync(
                this.getSceneToExport(),
                {
                    binary: true,
                    animations: this.model.animations,
                }
            );

            if (!(data instanceof ArrayBuffer)) {
                throw new Error('Error parsing model to file.');
            }

            return new File([data], fileName, {type: 'model/gltf-binary'});
        },

        /**
         * Repackages the loaded gltf into a new, correct scene,
         * This call might block the browsers UI for large models. As sending rendering to background
         * requires a lot of work, we'll have to live with this performance hit right now.
         *
         * @return {string} Data url of a preview image. Dimensions can be passed in this component's properties.
         */
        getPreviewImageUrl() {

            const canvas = document.createElement('canvas');

            const previewRenderer = new WebGLRenderer({
                canvas: canvas,
                antialias: true,
            });

            previewRenderer.setSize(this.previewWidth, this.previewHeight);
            previewRenderer.toneMapping = this.renderer!.toneMapping;
            previewRenderer.toneMappingExposure = this.renderer!.toneMappingExposure;
            previewRenderer.outputEncoding = this.renderer!.outputEncoding;

            this.camera.aspect = this.previewWidth / this.previewHeight;
            this.camera.updateProjectionMatrix();

            this.scene.background = new Color(this.previewBackgroundColor);

            previewRenderer.render(toRaw(this.scene), this.camera);

            this.scene.background = new Color(this.backgroundColor);

            // reset camera aspect
            this.onWindowResize();

            return canvas.toDataURL('image/jpeg', 0.8);
        },

        /**
         * Repackages the loaded model into a new, correct scene,
         * so the exporter does not generate an 'AuxScene' parent object.
         * @return {Scene}
         */
        getSceneToExport() {
            if (!this.model) {
                throw new Error('No model loaded to export');
            }

            const scene = new Scene();
            scene.name = this.model.name;
            scene.add(...this.model.children.map(clone));

            if (this.userData?.flipTextureYBeforeExport) {
                this.flipTextureY(this.model);
            }

            return scene;
        },

        flipTextureY(model: Group) {
            model.traverse(child => {
                if (child.type === 'Mesh') {
                    const mesh = child as Mesh;
                    // Check if the mesh has a "uv" attribute and skip if not
                    if (!mesh.geometry.hasAttribute('uv')) {
                        return;
                    }
                    const uvAttribute = mesh.geometry.getAttribute('uv');

                    for (let i = 0; i < uvAttribute.count; i++) {
                        const val = uvAttribute.getY(i);
                        uvAttribute.setY(i, 1 - val);
                    }

                    uvAttribute.needsUpdate = true;
                }
            });
        }
    },

    watch: {

        model(newModel, oldModel) {
            // Stop rotation
            this.autoRotate = false;

            // Remove old model from scene
            if (oldModel !== null && this.scene) {
                this.scene.remove(oldModel);
            }

            // Add new model to scene
            if (newModel !== null && this.scene) {
                const rawModel = toRaw(newModel);
                this.scene.add(rawModel);

                // set up animations
                this.mixer = new AnimationMixer(rawModel);

                // Restart rotation if needed
                this.startAutoRotationIfEnabled();
            }

            // Reset camera & controls
            this.resetView();

            // Render newly added model
            this.render();

            // Sometimes with FBX files the textures seem to be not
            // completely loaded. A delayed re-render fixes this black
            // texture problem and does no harm.
            setTimeout(() => {
                this.render();
            }, 500);
        },

        highlightedObject: function (newObject, oldObject) {
            if (oldObject !== null) {
                this.unHighlight(oldObject);
            }

            if (newObject !== null) {
                this.highlight(newObject);
            }

            this.render();
        },

        highlightedAnimation: function (newAnimation) {
            this.mixer?.stopAllAction();
            this.render();

            const action = this.mixer?.clipAction(newAnimation);
            action?.play();
        },

        executeLoop: function (newExecuteLoop, oldExecuteLoop) {
            if (oldExecuteLoop) {
                cancelAnimationFrame(this.mainLoopId);
            } else {
                if (newExecuteLoop) {
                    this.loop();
                }
            }
        }
    },
});
</script>

<style scoped lang="scss">

.threejs-canvas-container {
    width: 100%;
    height: 100%;
}

</style>
