<template>
    <div class="trigger-property trigger-property-commands" v-shortcuts.prevent>

        <header>{{ trans('labels.actions') }}</header>

        <!-- Commands Sequence -->
        <div v-if="shouldShowSequenceConfiguration" class="property trigger-commands-sequence">
            <Dropdown
                :key="'commandsSequence'+trigger.execution_type"
                class="no-wrap"
                :label="trans('authoring.execute_actions')"
                :model="trigger"
                property="execution_type"
                :options="getCommandSequenceOptions"
                @select="onChangeExecutionType"
            />
            <Dropdown
                v-if="hasTriggerExecutionParams"
                class="no-wrap"
                :label="trans('authoring.execute_actions_repeat')"
                :key="'commandsSequenceParams'+trigger.execution_type"
                :model="trigger.execution_params"
                property="repeat"
                :options="getExecutionRepeatOptions"
                @select="onChangeTrigger"
            />
        </div>

        <!-- Command selection -->
        <div v-if="hasTriggerCommands" class="trigger-action-selection">

            <!-- Empty draggable when no commands are available -->
            <Draggable
                v-if="trigger.commandsCount === 0"
                groups="trigger-command"
                :value="{trigger: trigger, command: null, index: 0}"
                :drop-only="true"
                @drop="onDropCommand"
                @dragenter="onDragEnterCommand"
                class="trigger-command trigger-no-commands-droptarget"
                :data-trigger-uid="trigger.uid"
                :data-command-uid="null"
            />

            <!-- Commands -->
            <Draggable
                v-if="trigger.commandsCount > 0"
                v-for="(command, cmdIndex) in trigger.commands"
                groups="trigger-command"
                v-focusable
                :disabled="false"
                :value="{trigger: trigger, command: command, index: cmdIndex}"
                :draggable-selector="'header[draggable]'"
                @drop="onDropCommand"
                @dragenter="onDragEnterCommand"
                :class="'panel-card trigger-command' + (!command.isValid ? ' is-invalid' : '')"
                :key="'command_settings'+command.uid+command.type+cmdIndex+'_'+trigger.commandsCount"
                :id="'command'+command.uid"
                :data-trigger-uid="trigger.uid"
                :data-command-uid="command.uid"
                :data-ref-uid="command.referencedObjectUid || 'no-ref'"
            >
                <header draggable="true">
                    <Icon v-if="!command.isValid" name="icon_invalid" class="icon-invalid" />
                    <label>{{ getCommandPrefix(trigger, cmdIndex) }}{{ command.commandType.title }}</label>
                    <Icon name="icon_close" class="icon-delete" @click="onClickRemoveCommand(command)" />
                </header>

                <PanelCommand
                    :command="command"
                    @change="onChangeTriggerAction"
                />

            </Draggable>

            <!-- Dropdown for new/unassigned command -->
            <div class="btn-add-with-dropdown">
                <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
                <ButtonSecondary
                    v-not-focusable
                    icon="icon_add"
                    caption="labels.add_action"
                />

                <Dropdown
                    :key="'dropdown-'+trigger.uid+'_'+trigger.commandsCount"
                    @select="onSelectCommand"
                    :options="getActionOptionsForTriggerObject"
                    :deselected-caption="trans('authoring.select_action')"
                />
            </div>
            <div class="trigger-command-droptarget"></div>
        </div>
    </div>
</template>

<script>
    import Clipboard                from '@/Utility/Clipboard';
    import { sortArrayByProperty, trans } from '@/Utility/Helpers';
    import DropdownOption           from '@/Utility/DropdownOption';
    import DropdownOptionGroup      from '@/Utility/DropdownOptionGroup';
    import Command                  from '@/Models/UnitData/Commands/Command';
    import CommandType              from '@/Models/UnitData/Commands/CommandType';
    import ExecutionType            from '@/Models/UnitData/Triggers/ExecutionType';
    import ExecutionRepeatType      from '@/Models/UnitData/Triggers/ExecutionRepeatType';
    import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';
    import Trigger                  from '@/Models/UnitData/Triggers/Trigger';

    export default {
        name: 'PanelTriggerPropertyCommands',
        emits: [
            'change',
        ],
        props: {
            trigger: {                  // Selected SceneObject that the triggers are assigned to
                type: Trigger,
                default: null
            }
        },

        data() {
            return {
                assetService: this.$assetService,                   // Global AssetService instance
                shortcuts: new Map([
                    ['Copy.stop', this.onShortcutCopy],             // Copy the selected command to the clipboard
                    ['Duplicate.stop', this.onShortcutDuplicate],   // Duplicate the selected command
                    // #PRDA-8481 Disabled until we decide how to handle references between objects when cutting
                    //['Cut.stop', this.onShortcutCut],               // Cut the selected command (e.g. delete and duplicate to the clipboard)
                    ['Paste.global', this.onShortcutPaste],         // Paste command from clipboard
                    ['Delete.stop', this.onShortcutDelete],         // Delete command
                ]),
            }
        },

        computed: {

            /**
             * @returns {SceneObject|null}
             */
            sceneObject() {
                return this.trigger.parentSceneObject || null;
            },

            /**
             * @returns {Boolean}
             */
            shouldShowSequenceConfiguration() {
                return this.trigger.commandsCount >= 2;
            },

            /**
             * Does a trigger have supported commands (dependent on the type of SceneObject and TriggerType)?
             *
             * @returns {Boolean}
             */
            hasTriggerCommands() {
                return this.trigger.supportedCommandTypes.length >= 1;
            },

            /**
             * Does the trigger have any configurable parameters for its execution type?
             *
             * @returns {Boolean}
             */
            hasTriggerExecutionParams() {
                const type = this.trigger.executionType;
                return (type !== null && type.defaultParams !== null);
            },

            /**
             * Get execution parameter options for repeatable execution types
             *
             * @returns {Array<DropdownOption>}
             */
            getExecutionRepeatOptions() {
                const options = [];
                const types = ExecutionRepeatType.all;
                types.forEach(t => {
                    options[options.length] = new DropdownOption({
                        caption: t.title,
                        disabled: false,
                        value: t.type
                    });
                });
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Get execution type options for command sequences
             *
             * @returns {Array<DropdownOption>}
             */
            getCommandSequenceOptions() {
                const options = [];
                const triggerType = this.trigger.triggerType;

                if (triggerType === null || triggerType.executionTypes === null) {
                    return options;
                }

                triggerType.executionTypes.forEach(t => {
                    options[options.length] = new DropdownOption({
                        caption: t.title,
                        disabled: false,
                        value: t.type
                    });
                });
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Transform the available commands to options for the dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            getActionOptionsForTriggerObject() {
                const commandsCountByType = this.trigger.commandsCountByType;
                const options = [];
                CommandType.GroupsAsArray.forEach(g => {
                    const group = new DropdownOptionGroup({
                        caption: trans('commands.groups.' + g),
                        isSeparator: true,
                        showGroupNameInCaption: false
                    });

                    let supportedCommandTypes = this.trigger.supportedCommandTypes;

                    // #PRDA-8454: Remove obsolete dropdown options for hotspots
                    if (this.trigger.parentSceneObject !== null && this.trigger.parentSceneObject.type === SceneObjectType.TypeOfHotspot)
                    {
                        const obsoleteCommandTypes = [
                            CommandType.ImageShow,
                            CommandType.TextShow,
                            CommandType.VideoPlay
                        ].map(ct => ct.type);
                        supportedCommandTypes = supportedCommandTypes.filter(ct => !obsoleteCommandTypes.includes(ct.type));
                    }

                    supportedCommandTypes.filter(c => c.group === g).forEach(c => group.options[group.options.length] = new DropdownOption({
                        caption: c.titleGrouped,
                        disabled: !c.enabled || (c.maxCountPerTrigger !== null && commandsCountByType.get(c.type) >= c.maxCountPerTrigger),
                        value: c.type
                    }));

                    sortArrayByProperty(group.options, 'caption', false);

                    if (group.options.length >= 1) {
                        options[options.length] = group;
                    }
                });

                return sortArrayByProperty(options, 'caption', false);
            },

        },
        methods: {

            /**
             * Get command prefix indexing numbers
             *
             * @param {Trigger} trigger
             * @param {Number} index
             * @returns {String}
             */
            getCommandPrefix(trigger, index) {
                const executionType = trigger.executionType;
                let prefix = '';

                switch (executionType.type) {
                    case ExecutionType.Linear.type:
                    case ExecutionType.Steps.type:
                        prefix = (index + 1) + '. ';
                        break;
                    case ExecutionType.RandomSequence.type:
                    case ExecutionType.Random.type:
                        break;
                    default:
                        break;
                }

                return prefix;
            },

            /**
             * Change handler or the execution type
             *
             */
            onChangeExecutionType() {
                // Apply default parameters:
                const defaultParams = this.trigger.executionType.defaultParams;
                this.trigger.execution_params = (defaultParams !== null) ? Object.assign({}, defaultParams) : null;
                return this.onChangeTrigger();
            },

            /**
             * Select handler for the command dropdown
             *
             * @param {String} value
             */
            onSelectCommand(value) {
                const commandType = CommandType.getByTypeName(value);
                if (commandType === null) {
                    throw new TypeError('PanelTriggers->onSelectCommand(): Invalid CommandType "' + value + '"');
                }

                // Add the new command:
                if (this.trigger.mergeCommand(
                    Command.createWithType(
                        commandType,
                        null,
                        this.trigger
                    )
                ))
                {
                    this.$emit('change', this.trigger);
                }
                return this;
            },

            /**
             * Click handler for command remove button
             *
             * @param {Command} command   // Command object reference
             */
            onClickRemoveCommand(command) {
                const cmdIndex = this.trigger.commands.findIndex(c => c.uid === command.uid);
                // Remove the command:
                this.trigger.removeCommand(command);
                // Set focus:
                if (this.trigger.hasCommands === true) {
                    // Focus on the previous (or next) command:
                    this.setFocus(this.trigger.commands[Math.max(0, cmdIndex - 1)]);
                } else {
                    // Focus on the collapsible trigger header if there are no other commands:
                    this.setFocus(this.trigger);
                }
                this.$emit('change', this.trigger);
                return this;
            },

            /**
             * Set focus on a given object
             *
             * @param {Object} obj
             */
            setFocus(obj) {
                if (obj instanceof Command) {
                    this.$nextTick(() => {
                        document.getElementById('command'+obj.uid).focus();
                    });
                }
                else if (obj instanceof Trigger) {
                    this.$nextTick(() => {
                        document.getElementById('trigger_'+obj.uid).firstElementChild.focus();
                    });
                }
                return this;
            },

            /**
             * Change handler for trigger properties
             *
             * @param {Trigger} trigger   // Trigger object reference
             */
            onChangeTrigger() {
                this.$emit('change', this.trigger);
                return this;
            },

            /**
             * Change handler for the action settings
             *
             * @param {Command} command
             */
            onChangeTriggerAction(command) {
                this.$emit('change', this.trigger);
                return this;
            },

            /*
            |--------------------------------------------------------------------------
            | Drag & Drop
            |--------------------------------------------------------------------------
            */

            /**
             * Drop handler for commands
             *
             * @param {MouseEvent} e
             */
            onDropCommand(e) {
                e.preventDefault();
                const sourceTrigger = e.dataDraggable?.value?.trigger || null;
                const sourceTriggerIndex = sourceTrigger?.parent?.triggers?.indexOf(sourceTrigger) || -1;
                const targetTrigger = e.dataDropTarget?.value?.trigger || null;
                const targetTriggerIndex = sourceTrigger?.parent?.triggers?.indexOf(targetTrigger) || -1;
                const currentIndex = e.dataDraggable?.value?.index || 0;
                const targetIndex = e.dataDropTarget?.value?.index || 0;

                if (sourceTrigger === null || targetTrigger === null) {
                    console.warn('PanelTriggerPropertyCommands->onDropCommand(): Invalid dragging data.', e);
                    return this;
                }

                const dropCommand = sourceTrigger.commands[currentIndex];
                let hasChanged = false;

                // Filter allowed command types for this scene object:
                const maxCountPerTrigger = dropCommand.commandType.maxCountPerTrigger;
                if (
                    !targetTrigger.supportedCommandTypes.map(ct => ct.type).includes(dropCommand.type)
                    || (
                        maxCountPerTrigger !== null
                        && sourceTrigger.uid !== targetTrigger.uid
                        && targetTrigger.commands.filter(c => c.type === dropCommand.type).length >= maxCountPerTrigger
                    )
                ) {
                    return this;
                }

                // Source and target triggers are the same:
                if (sourceTrigger.uid === targetTrigger.uid) {
                    if (targetIndex > currentIndex) {
                        targetTrigger.commands.splice(targetIndex + 1, 0, dropCommand);
                        targetTrigger.commands.splice(currentIndex, 1);
                        hasChanged = true;
                    } else if (targetIndex < currentIndex) {
                        targetTrigger.commands.splice(targetIndex, 0, dropCommand);
                        targetTrigger.commands.splice(currentIndex + 1, 1);
                        hasChanged = true;
                    }
                    //console.log('onDrop', e, targetIndex, currentIndex, dropCommand);
                }
                // Different trigger targets:
                else {
                    sourceTrigger.commands.splice(currentIndex, 1);

                    // Match the position the drag & drop indicator uses when dragging items between triggers.
                    // It matters whether the source trigger is listed before or after the target trigger.
                    if (targetTriggerIndex <= sourceTriggerIndex) {
                        targetTrigger.commands.splice(targetIndex, 0, dropCommand);
                    } else {
                        targetTrigger.commands.splice(targetIndex + 1, 0, dropCommand);
                    }

                    hasChanged = true;
                }

                if (hasChanged === true) {
                    // Update parent:
                    dropCommand.parent = targetTrigger;

                    // Clean up data:
                    this.trigger.cleanUpData();
                    targetTrigger.cleanUpData();

                    this.$emit('change', this.trigger);
                }
                return this;
            },

            /**
             * DragEnter handler for commands
             *
             * @param {MouseEvent} e
             */
            onDragEnterCommand(e) {
                const sourceData = e.dataDraggable?.value || null;
                const targetData = e.dataDropTarget?.value || null;
                if (sourceData === null || targetData === null) {
                    console.warn('PanelTriggerPropertyCommands->onDragEnterCommand(): Invalid dragging data.');
                    return this;
                }
                const dropCommand = sourceData.trigger.commands[e.dataDraggable.value.index];

                // Filter allowed command types for this scene object:
                const maxCountPerTrigger = dropCommand.commandType.maxCountPerTrigger;
                if (
                    !this.trigger.supportedCommandTypes.map(ct => ct.type).includes(dropCommand.type)
                    || (
                        maxCountPerTrigger !== null
                        && sourceData.trigger.uid !== targetData.trigger.uid
                        && targetData.trigger.commands.filter(c => c.type === dropCommand.type).length >= maxCountPerTrigger
                    )
                ) {
                    e.currentTarget.classList.remove('can-drop', 'drop-before', 'drop-after');
                    return this;
                }

                return this;
            },


            /*
            |--------------------------------------------------------------------------
            | Shortcut Handlers
            |--------------------------------------------------------------------------
            */

            /**
             * Shortcut handler: Copy
             *
             * @param {CustomEvent} e
             */
            onShortcutCopy(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Copy the selected command:
                if (command && trigger)
                {
                    // Copy a new instance of the selected command to the clipboard:
                    Clipboard.setClipboardDataAsync(command);
                    return this;
                }

                return this;
            },

            /**
             * Shortcut handler: Duplicate
             *
             * @param {CustomEvent} e
             */
            onShortcutDuplicate(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Duplicate the selected command:
                if (command && trigger)
                {
                    // Insert a duplicated instance of the command:
                    const duplicatedCommand = trigger.mergeCommand(command, command);
                    if (duplicatedCommand !== null) {
                        this.setFocus(duplicatedCommand);
                        this.$emit('change', this.trigger);
                    }
                    return this;
                }

                return this;
            },

            /**
             * Shortcut handler: Cut
             *
             * @param {CustomEvent} e
             */
            onShortcutCut(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Cut the selected command:
                if (command && trigger)
                {
                    // Copy a new instance of the selected command to the clipboard:
                    Clipboard.setClipboardDataAsync(command);
                    this.onClickRemoveCommand(command);
                    return this;
                }

                return this;
            },

            /**
             * Shortcut handler: Paste
             *
             * @param {CustomEvent} e
             */
            onShortcutPaste(e) {
                // Do nothing if the clipboard is empty:
                const clipboardData = Clipboard.getDataFromClipboardEvent(e.detail.clipboardEvent);
                if (clipboardData === null || clipboardData.isEmpty || !clipboardData.isModel)
                {
                    return this;
                }

                // Get data from the clipboard (and use new instances so we're not modifying the clipboard data):
                const commandsToPaste = (clipboardData.isInstanceOf(Command)) ? [Command.fromClipboardData(clipboardData)].filter(c => c !== null) : [];

                // Get target command and trigger from the shortcut event:
                const { command, trigger } = this.getDataByEvent(e);
                if (commandsToPaste.length === 0 || command === null || trigger === null)
                {
                    return this;
                }

                // Stop bubbling to parent components:
                e.stopShortcutPropagation();

                // Add referenced assets to the unit (if they are really being used is determined by the backend when saving):
                if (commandsToPaste.length > 0)
                {
                    // @TODO: Consider asset policies once we have restrictions for them #PRDA-8075
                    this.trigger.parentUnitRevision.addAssets(this.assetService.getReferencedAssetsFromObject(commandsToPaste));
                }

                // Paste commands into the selected trigger:
                const mergedCommands = trigger.mergeCommands(commandsToPaste, command);
                if (mergedCommands.length === 0)
                {
                    return this;
                }

                // Set focus on the last of the newly inserted commands:
                this.setFocus(mergedCommands[mergedCommands.length - 1]);
                this.$emit('change', this.trigger);
                return this;
            },

            /**
             * Shortcut handler: Delete
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Delete the selected command:
                if (command && trigger)
                {
                    return this.onClickRemoveCommand(command);
                }

                // Delete the selected trigger:
                else if (trigger)
                {
                    return this.onClickRemoveTrigger(trigger);
                }
                return this;
            },

            /**
             * Get models by event
             *
             * @param {CustomEvent} e
             * @returns {Object}
             */
            getDataByEvent(e) {
                // @NOTE: Using parentNode for collapsible elements since the data will be on the parent of the slot!
                const commandUid = e.target.dataset.commandUid || e.target.parentNode.dataset.commandUid || null;
                const triggerUid = e.target.dataset.triggerUid || e.target.parentNode.dataset.triggerUid || null;
                const result = {
                    trigger: null,
                    command: null
                };
                if (triggerUid !== null) {
                    result.trigger = this.sceneObject.triggers.find(t => t.uid === triggerUid) || null;
                    if (result.trigger !== null && commandUid !== null) {
                        result.command = result.trigger.commands.find(c => c.uid === commandUid) || null;
                    }
                }
                return result;
            },

            expandTrigger() {
                // @TODO: Bubble event up
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
