import {CreativeCommonsLicense, CreativeCommonsLicenses, LicenseId} from "@/Models/Asset/CreativeCommonsLicenses";
import {trans} from "@/Utility/Helpers/trans";

export default class SketchfabModel {

    public readonly uid: string;
    public readonly name: string;
    public readonly description: string | null;
    public readonly url: string;
    public readonly fileSize: number;
    public readonly faceCount: number;
    public readonly vertexCount: number;
    public readonly textureCount: number;
    public readonly license: CreativeCommonsLicense;
    public readonly imageUrlMini: string;
    public readonly imageUrl720p: string | null;
    public readonly tags: string[];
    public readonly userName: string;
    public readonly userProfileUrl: string;

    public get creditsPlainText() {
        return trans('assets.create.credits_plain', this.creditsTransReplacementObject, false);
    }

    private get creditsTransReplacementObject(): Record<string, string> | {} {
        return {
            modelUrl: this.url,
            modelName: this.name,
            userUrl: this.userProfileUrl,
            userName: this.userName,
            licenseUrl: trans(this.license.urlTransKey),
            licenseName: trans(this.license.nameTransKey),
        };
    }

    constructor(apiModel: any) {
        this.uid = apiModel.uid;
        this.name = apiModel.name;
        this.description = apiModel.description;
        this.url = apiModel.viewerUrl;

        this.license = this.parseLicense(apiModel.license.label);

        const downloadGlbArchive = this.getDownloadGlbArchive(apiModel.archives.glb);
        this.fileSize = downloadGlbArchive.size;
        this.faceCount = downloadGlbArchive.faceCount;
        this.vertexCount = downloadGlbArchive.vertexCount;
        this.textureCount = downloadGlbArchive.textureCount;

        this.tags = apiModel.tags.map(tag => tag.name);

        this.userName = apiModel.user.displayName;
        this.userProfileUrl = apiModel.user.profileUrl;

        this.imageUrlMini = this.getImageUrlBySize(86, 48, apiModel.thumbnails.images) || '';
        this.imageUrl720p = this.getImageUrlBySize(1280, 720, apiModel.thumbnails.images);
    }

    private getImageUrlBySize(minWidth: Number, minHeight: Number, images: Array<any>): string | null {
        const image = images.reverse().find(image => image.width >= minWidth && image.height >= minHeight);
        return image?.url;
    }

    /**
     * We cannot choose a specific quality when downloading GLBs.
     * Sketchfab always chooses the 1024px texture resolution version or lower, if the source textures are lower.
     */
    private getDownloadGlbArchive(archives: Array<any>): any {
        if (archives.length === 1) {
            return archives[0];
        }

        return archives.find(archive => archive.textureMaxResolution <= 1024);
    }

    private parseLicense(licenseLabel: string): CreativeCommonsLicense {
        switch (licenseLabel) {
            case 'CC Attribution':
                return CreativeCommonsLicenses[LicenseId.CC_BY];
            default:
                throw new Error(`Unknown license '${licenseLabel}'.`);
        }
    }
}
