<template>

    <main id="layout-main">

        <PageHeader
            :page-title="trans('tenants.create.headline')"
        />

        <TenantCreateForm/>

        <DialogLoading/>
        <DialogSaving/>
        <DialogNotification/>
    </main>

</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {trans} from "@/Utility/Helpers/trans";
import PageHeader from "@/Vue/Common/PageHeader.vue";
import DialogSaving from "@/Vue/Modals/DialogSaving.vue";
import DialogLoading from "@/Vue/Modals/DialogLoading.vue";
import DialogNotification from "@/Vue/Modals/DialogNotification.vue";
import TenantUpdateForm from "@/Vue/Tenants/TenantUpdateForm.vue";
import TenantCreateForm from "@/Vue/Tenants/TenantCreateForm.vue";

export default defineComponent({
    name: "PageTenantCreate",

    components: {
        TenantCreateForm,
        TenantUpdateForm,
        DialogNotification,
        DialogLoading,
        DialogSaving,
        PageHeader,
    },

    methods: {
        trans,
    }
});
</script>

<style scoped lang="scss">

</style>
