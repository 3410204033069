<template>
    <div class="command-settings">
        <!-- Select scene object as target -->
        <Dropdown
            :initial-value="command.target_object"
            @select="onSelectTargetObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <!-- Select trigger from selected scene object -->
        <Dropdown
            v-if="command.target_object"
            :key="'triggercancel'+command.target_object"
            :initial-value="command.trigger_to_cancel"
            @select="onSelectTargetTrigger"
            :options="dropdownOptionsForTrigger"
            :required="true"
            :deselected-caption="trans('authoring.select_trigger')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { getSceneObjectTargetDropdownOptionsForCommand, getCaptionForSceneObjectTrigger }     from '@/Models/UnitData/Commands/CommandHelpers';

// Import classes:
import DropdownOption           from '@/Utility/DropdownOption';
import {TriggerCancelCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandCancelTrigger',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: TriggerCancelCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for trigger cancel value dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        dropdownOptionsForTrigger() {
            const options = [];
            let targetObject = null;
            let triggers = [];

            // Get triggers for the selected object:
            if (this.command.target_object === TriggerCancelCommand.TargetSelf)
            {
                targetObject = this.command.parentSceneObject;
            }
            else
            {
                targetObject = this.command.parentUnitData.findSceneObjectByUid(this.command.target_object);
            }
            triggers = (targetObject !== null) ? targetObject.triggers || [] : [];

            if (triggers.length >= 1)
            {
                triggers.forEach(t => {
                    options[options.length] = new DropdownOption({
                        caption: getCaptionForSceneObjectTrigger(targetObject, t, false, false),
                        icon: t.triggerType.icon,
                        disabled: false,
                        value: t.uid
                    });
                });
            }
            return options;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

    },
    methods: {

        /**
         * Select handler for trigger cancel target dropdown
         *
         * @param {String} object
         */
        onSelectTargetObject(object) {
            if (object !== this.command.target_object)
            {
                // Reset the value:
                this.command.trigger_to_cancel = null;
                this.command.target_object = object;
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         * Select handler for trigger cancel value dropdown
         *
         * @param {String} trigger
         */
        onSelectTargetTrigger(trigger) {
            if (trigger !== this.command.trigger_to_cancel)
            {
                this.command.trigger_to_cancel = trigger;
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>
