<template>
    <div id="inspector" class="inspector-content" v-not-focusable v-shortcuts>
        <header class="inspector-header sceneobject-group">
            <Icon name="icon_group" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.group_properties') }}
        </header>
        <div class="panels" v-not-focusable :key="'InspectorPanels' + refreshUI + sceneObject.uid">
            <div class="inspector-panel panel-group">

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :model="sceneObject"
                        property="title"
                        @change="onChangeProperty"
                        :required="true"
                        :maxlength="50"
                        :placeholder="trans('authoring.group_title_placeholder')"
                        :error-msg="trans('errors.group.title')"
                    />
                </div>

                <!-- List of children -->
                <!--<div class="property property-title">
                    <div v-for="c in sceneObject.objects" :key="'groupchild'+c.uid">{{c.title}}</div>
                </div>-->
            </div>
        </div>

        <div class="buttons">
            <!-- Btn: Duplicate -->
            <ButtonCustom :disabled="!canDuplicateGroup" icon="icon_duplicate" caption="labels.duplicate" @trigger="onClickDuplicateGroup" />

            <!-- Btn: Remove -->
            <ButtonCustom icon="icon_delete" caption="labels.remove" @trigger="onClickRemoveFromSelectedScene" />
        </div>

        <!-- Modal dialog for module remove confirmation -->
        <DialogApplyCancel
            event-type="MODAL_REMOVE_GROUP_FROM_SCENE"
            :title="trans('modals.remove_group_from_scene.title')"
            :description="trans('modals.remove_group_from_scene.description')"
        />
    </div>
</template>

<script>
// Import VueJS components:
import DialogApplyCancel        from '@/Vue/Modals/DialogApplyCancel.vue';

// Import classes:
import { shortId }              from '@/Utility/Helpers';
import EventType                from '@/Utility/EventType';
import { SceneObjectGroup }     from '@/Models/UnitData/SceneObjects/SceneObject';

export default {
    name: 'InspectorSceneObjectGroup',
    emits: [
        'change',
    ],
    components: {
        DialogApplyCancel,
    },
    props: {
        sceneObject: {              // Selected SceneObjectGroup
            type: SceneObjectGroup,
            default: null
        }
    },
    data() {
        return {
            refreshUI: shortId(),                   // Helper for refreshing UI elements
            shortcuts: new Map([
                ['Duplicate.global.prevent', this.onShortcutDuplicate],
                ['Delete.global', this.onShortcutDelete],
                ['Replace.global.prevent', null],
            ])
        }
    },
    computed: {

        canDuplicateGroup() {
            return true;
        },
    },
    methods: {

        /**
         * Common property change handler
         */
        onChangeProperty() {
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Click handler for scene object duplicate button
         */
        onClickDuplicateGroup() {
            this.$globalEvents.emit(EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.sceneObject);
            return this;
        },

        /**
         * Click handler for the remove button
         */
        onClickRemoveFromSelectedScene() {
            // Show modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_GROUP_FROM_SCENE_SHOW, this.sceneObject);
            return this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            return this.onClickRemoveFromSelectedScene();
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDuplicate(e) {
            // Duplicate selected scene object if allowed:
            return this.canDuplicateGroup ? this.onClickDuplicateGroup() : this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
