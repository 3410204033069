<template>
    <main id="layout-main" :data-loading="isLoading" :data-saving="isSaving" v-shortcuts>
        <PageHeader
            :key="'pageheader_'+assetHasChanged"
            :page-title="pageHeadline"
            :buttons="headerButtons"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable @scroll="onScrollList" ref="scrollList">
                <div class="container list-filters-wrapper">
                    <ListFiltersBar
                        ref="listFiltersBar"
                        :categories="filterCategories"
                        :filters="isComponentFilterActive ? [] : filtersByType"
                        :sorting-filters="isComponentFilterActive ? [] : sortingFilters"
                        :sort-descending="sortDescending"
                        :filterWords="filterWords"
                        @change="applyFilters"
                        @reset="onResetFilters"
                    >
                        <template v-slot:buttons>
                            <ButtonPrimary
                                class="btn-add-new"
                                @trigger="onClickCreateNewAsset"
                                v-tooltip="'buttons.assets.create'"
                                icon="icon_add"
                                caption="assets.index.add_asset"
                                :href="$root.route('assets.createForm')"
                            />
                        </template>
                    </ListFiltersBar>
                </div>
                <div class="container">
                    <table v-if="!isComponentFilterActive && filteredList.length > 0" class="asset-list">
                        <template v-for="asset in filteredList.slice(0, renderItemsLoaded)" :key="'AssetListItem_asset_' + asset.uid">
                            <AssetListItem
                                :asset="asset"
                                :selected="(selectedAsset && selectedAsset.uid === asset.uid)"
                                :showAddToLibraryButton="shouldShowAddToLibraryButton"
                                @click="selectAsset"
                                @add-to-library="onAddToLibraryClicked"
                            />
                        </template>

                    </table>

                    <template v-if="!isLoading && !isComponentFilterActive">
                        <NoItemsAvailable v-if="assets.length === 0 && !isSearchActive" />
                        <NoItemsFound v-else-if="filteredList.length === 0" />
                    </template>

                    <component
                        ref="filterComponent"
                        :key="'filterComponent_' + activeTabFilterCategory.componentName"
                        v-if="isComponentFilterActive"
                        :is="activeTabFilterCategory.componentName"
                        :filterWords="filterWords">
                    </component>

                </div>
            </div>

            <aside id="layout-inspector" :class="{ 'open': selectedAsset !== null }">
                <SidepanelAsset
                    v-if="selectedAsset"
                    :asset="selectedAsset"
                    :key="'SidepanelAsset_' + selectedAsset.uid"
                    @change="onChangeInspectorPanelAsset"
                />
            </aside>

            <!-- Asset create dialog -->
            <DialogCreateAsset
                @success="onAssetImported"
            />

            <DialogLoading />
            <DialogSaving />
            <DialogNotification />
            <ModalAssetPreview />
        </div>
    </main>
</template>

<script>

// Import VueJS components:
import DialogApplyCancel        from '@/Vue/Modals/DialogApplyCancel.vue';
import DialogNotification       from '@/Vue/Modals/DialogNotification.vue';
import DialogLoading            from '@/Vue/Modals/DialogLoading.vue';
import DialogSaving             from '@/Vue/Modals/DialogSaving.vue';
import ListFiltersBar           from '@/Vue/Common/ListFiltersBar.vue';
import AssetListItem            from '@/Vue/Assets/AssetListItem.vue';
import SidepanelAsset           from '@/Vue/Assets/Sidepanel/SidepanelAsset.vue';
import SketchfabList            from '@/Vue/Assets/Sketchfab/SketchfabList.vue';
import ModalAssetPreview        from '@/Vue/Modals/ModalAssetPreview.vue';
import NoItemsAvailable         from '@/Vue/Search/NoItemsAvailable';
import NoItemsFound             from '@/Vue/Search/NoItemsFound';

// Import classes:
import {inject}                 from 'vue';
import EventType                from '@/Utility/EventType';
import PageHeaderButton         from '@/Utility/PageHeaderButton';
import {permission, route, sortArrayByProperty, trans} from '@/Utility/Helpers';
import { Permission }           from '@/Models/User/Permission';
import Search                   from '@/Utility/Search';
import Asset                    from '@/Models/Asset/Asset';
import AssetType                from '@/Models/Asset/AssetType';
import SceneObjectFilters       from '@/Filters/SceneObjectFilters';
import VueComponentCategory     from '@/Filters/VueComponentCategory';
import {featureRepositoryKey}   from '@/Vue/Bootstrap/InjectionKeys';
import {Feature}                from '@/Models/Features/Feature';
import DialogCreateAsset        from "@/Vue/Modals/DialogCreateAsset.vue";
import MultiFilterCategory      from "@/Filters/MultiFilterCategory";
import FilterSection            from "@/Filters/FilterSection";
import AssetPolicy, {AssetPolicySample, AssetPolicyTemplate} from "@/Models/Asset/AssetPolicy";
import SortingFilters           from "@/Filters/SortingFilters.js";

export default {
    name: 'PageAssetList',
    components: {
        DialogCreateAsset,
        DialogApplyCancel,
        AssetListItem,
        ListFiltersBar,
        DialogNotification,
        DialogLoading,
        DialogSaving,
        ModalAssetPreview,
        SidepanelAsset,
        SketchfabList,
        NoItemsAvailable,
        NoItemsFound,
    },
    data() {
        return {
            assetService: this.$assetService,       // Global AssetService instance
            featureRepository: inject(featureRepositoryKey),
            pageTitle: '',                          // Page title to be displayed in the header
            headerButtons: {                        // List of button configurations
                modelViewer: new PageHeaderButton({
                    disabled: false,
                    visible: true,
                    caption: trans('assets.model_viewer.title'),
                    tooltip: 'assets.model_viewer.subtitle',
                    icon: 'icon_3d-converter',
                    href: route('tools.model-viewer.index'),
                }),
                deleteAssets: new PageHeaderButton({
                    disabled: false,
                    visible: true,
                    caption: trans('assets.index.delete_unused'),
                    tooltip: 'buttons.assets.delete_unused',
                    icon: 'icon_delete',
                    callback: this.onClickDeleteUnusedAssets,
                }),
                saveAsset: new PageHeaderButton({
                    visible: false,
                    caption: trans('labels.save'),
                    tooltip: 'buttons.assets.save',
                    icon: 'icon_save',
                    callback: this.saveAsset
                }),
                savedAsset: new PageHeaderButton({
                    disabled: true,
                    visible: false,
                    caption: trans('labels.save'),
                    tooltip: 'buttons.assets.save',
                    icon: 'icon_saved',
                    callback: null
                }),

            },
            selectedAsset: null,            // The selected asset that is being edited
            assetHasChanged: false,         // Whether the asset has unsaved changes
            afterSaveCallback: null,        // Callback method for the save changes dialog's apply/dismiss buttons (after the asset has been saved)
            shortcuts: new Map([            // Shortcut mapping to methods
                ['Save.global.prevent', this.saveAsset],
                ['Backspace.global.prevent', null],    // Prevent going back in browser history
                ['Reload.global', this.onShortcutReload], // Catch page reloading
            ]),
            filterCategories: [             // List of FilterCategory items for tabs navigation
                SceneObjectFilters.AssetsFromMyLibrary.setActive(true, true),
                SceneObjectFilters.AssetsFromFreeLibrary,
                SceneObjectFilters.AssetsArchived,
            ],
            filtersByType: [
                new MultiFilterCategory(
                    trans('labels.asset_type'),
                    this.filterSectionsByType(),
                    'icon_asset'
                ).setActive(true, true),
                new MultiFilterCategory(
                    trans('labels.policy'),
                    this.filterSectionsByPolicy(),
                    null
                ).setActive(true, true)
            ],
            sortingFilters: [
                SortingFilters.Alphabetical.setActive(true, true),
                SortingFilters.UpdatedAt,
            ],
            sortDescending: false,
            filteredList: [],
            filterWords: null,              // Filter words for the text input
            renderItemsLoaded: 32,          // How many items to display initially
            renderItemsPerPage: 32,         // How many items to add at once when scrolling
            events: new Map([
                [EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav],
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],

                // Asset Events
                [EventType.INSPECTOR_ASSET_UPDATED, this.onUpdateInspectorAsset],
                [EventType.MODAL_ARCHIVE_ASSET_SHOW, this.onShowArchiveAsset],
                [EventType.MODAL_ARCHIVE_ASSET_APPLY, this.onApplyArchiveAsset],
                [EventType.MODAL_RESTORE_ASSET_APPLY, this.onApplyRestoreAsset],
                [EventType.MODAL_DELETE_ASSET_SHOW, this.onShowDeleteAsset],
                [EventType.MODAL_DELETE_ASSET_APPLY, this.onApplyDeleteAsset],

                [EventType.MODAL_SAVE_ASSET_CHANGES_CANCEL, this.onCancelSaveAssetChanges],
                [EventType.MODAL_SAVE_ASSET_CHANGES_APPLY, this.onApplySaveAssetChanges],
                [EventType.MODAL_SAVE_ASSET_CHANGES_DISMISS, this.onDismissSaveAssetChanges],

                [EventType.MODAL_REMOVE_ASSET_FROM_LIBRARY_SHOW, this.onShowRemoveAssetFromLibrary],
                [EventType.MODAL_REMOVE_ASSET_FROM_LIBRARY_APPLY, this.onApplyRemoveAssetFromLibrary],

                [EventType.MODAL_DELETE_UNUSED_ASSETS_SHOW, this.onShowDeleteUnusedAssets],
                [EventType.MODAL_DELETE_UNUSED_ASSETS_APPLY, this.onApplyDeleteUnusedAssets],
            ]),
        }
    },

    mounted() {
        // Disable UI elements:
        if (!permission(Permission.AssetsUpdate()))
        {
            this.headerButtons.saveAsset.disabled = true;
            this.headerButtons.savedAsset.disabled = true;
        }

        if (!permission(Permission.AssetsDelete()))
        {
            this.headerButtons.deleteAssets.disabled = true;
        }

        // Fetch assets for the user:
        this.fetchAssets();

        // Add global events:
        this.$globalEvents.addEvent('click.global.asset-list', this.onClickGlobal);
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });

        // Add optional filters:
        if (this.featureRepository.active(Feature.FeatureAssetProviderSketchfab)) {
            const position = this.filterCategories.length - 1;
            this.filterCategories.splice(position, 0, new VueComponentCategory({
                title: trans('labels.marketplaces'),
                componentName: 'sketchfab-list',
            }));
        }
    },

    beforeUnmount() {
        // Remove global events:
        this.$globalEvents.removeEvent('click.global.asset-list', this.onClickGlobal);
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    computed: {
        /**
         * Get the list of assets from the service
         *
         * @returns {Array<Asset>}
         */
        assets() {
            return this.assetService.assets;
        },

        /**
         * Whether to show the add-to-library buttons
         *
         * @returns {Boolean}
         */
        shouldShowAddToLibraryButton() {
            return (this.activeTabFilterCategory.title === SceneObjectFilters.AssetsFromFreeLibrary.title);
        },

        /**
         * Loading state
         *
         * @returns {Boolean}
         */
        isLoading() {
            if (this.assetService.isLoading)
            {
                this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
            return false;
        },

        /**
         * Saving state
         *
         * @returns {Boolean}
         */
        isSaving() {
            if (this.assetService.isSaving)
            {
                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
            return false;
        },

        /**
         * Header page title
         *
         * @returns {String}
         */
        pageHeadline() {
            return (this.pageTitle !== '' && this.pageTitle !== null) ? this.pageTitle : trans('assets.index.headline');
        },

        /**
         * @return {FilterCategory | VueComponentCategory}
         */
        activeTabFilterCategory() {
            return this.filterCategories.find(fc => fc.isActive);
        },

        /**
         * @return {boolean}
         */
        isComponentFilterActive() {
            return typeof this.activeTabFilterCategory?.componentName === 'string';
        },

        /**
         * @return {boolean}
         */
        isSearchActive() {
            return (this.filterWords !== null || this.filtersByType.some(mf => mf.isActive));
        }
    },
    methods: {

        /**
         * @param {Asset} asset
         */
        onAssetImported(asset) {
            this.$toast.success(trans('assets.modals.import_success', {
                title: asset.title
            }));
        },

        /**
         * @param {Event} e
         */
        onScrollList(e) {
            if (this.isComponentFilterActive) {
                // pass scroll event to dynamic component
                this.$refs.filterComponent.onScroll(e);
                return;
            }

            // Maximum number of items showing already
            if (this.renderItemsLoaded >= this.filteredList.length) {
                return this;
            }
            const list = e.target;
            if (list.scrollTop >= (list.scrollHeight - list.offsetHeight - (list.offsetHeight * 0.85))) {
                this.renderItemsLoaded += this.renderItemsPerPage;
            }
            return this;
        },

        /**
         * Apply filters
         *
         * @param {FilterCategory|String|Object|Null} filterObj
         * @param {boolean} resetScrollPosition
         */
        applyFilters(filterObj = undefined, resetScrollPosition = true) {
            // Store filter keywords text:
            if ((filterObj === null || typeof filterObj === 'string') && filterObj !== this.filterWords)
            {
                this.filterWords = filterObj;
            }

            // Order by ascending/descending:
            if (typeof filterObj?.descending === 'boolean') {
                this.sortDescending = filterObj.descending;
            }

            // Hide all assets if any of the multi filter categories is NOT active (e.g. has no checkbox ticked)
            if (this.filtersByType.some(f => !f.isActive)) {
                this.filteredList = [];
                this.renderItemsLoaded = this.renderItemsPerPage;
                this.$refs.scrollList.scrollTop = 0;
                return this;
            }

            // Start filtering with a list of all assets:
            this.filteredList = Array.from(this.assets);

            // Apply filter category from selected tab:
            if (typeof this.activeTabFilterCategory.callback === 'function') {
                this.filteredList = this.activeTabFilterCategory.callback(this.filteredList) || [];
            }

            // Apply type filters:
            for (const multiFilter of this.filtersByType) {
                const activeFilterSections = multiFilter.sections.filter(fs => fs.category?.isActive);
                if (activeFilterSections.length === 0) {
                    continue;
                }
                this.filteredList = this.filteredList.filter(e => {
                    for (const activeFilterSection of activeFilterSections) {
                        if (activeFilterSection.category.callback([e]).length >= 1) {
                            return true;
                        }
                    }
                    return false;
                });
            }

            // Apply keyword filter:
            if (this.filterWords !== null)
            {
                this.filteredList = Search.filterObjects(this.filteredList, ['title', 'description', 'assetTypeTitle'], this.filterWords);
            }

            // Sort objects:
            this.filteredList = this.sortingFilters.find(sf => sf.isActive).callback(this.filteredList, {descending: this.sortDescending});

            // Reset scrolling list
            if (resetScrollPosition) {
                this.renderItemsLoaded = this.renderItemsPerPage;
                this.$refs.scrollList.scrollTop = 0;
            } else {
                this.renderItemsLoaded = Math.min(this.renderItemsLoaded, this.filteredList.length);
            }

            this.validateSelectionOrPerformDefaultSelection();
            return this;
        },

        onResetFilters() {
            this.sortDescending = false;
            this.applyFilters(null);
            this.$refs.listFiltersBar.setFocus();
            return this;
        },

        filterSectionsByType() {
            return sortArrayByProperty(
                AssetType.all.filter(
                    a => !['unknown', 'text'].includes(a.type)
                ).map(
                    a => new FilterSection(a.title, a.filterCategory.setActive(true, true))
                ),
                'title'
            );
        },

        filterSectionsByPolicy() {
            const policiesDisabledInFilterByDefault = [AssetPolicySample.type, AssetPolicyTemplate.type];

            return sortArrayByProperty(
                AssetPolicy.all
                    .filter(policy => {
                        // hide policies this tenant is not able to index/create them
                        return window.currentUser.tenant.is_default_asset_tenant ||
                            !policy.hasToExistInsideAssetDefaultTenant ||
                            policy.canBeIndexedByForeignTenants;
                    })
                    .map(policy => {
                        let disabledByDefault = policiesDisabledInFilterByDefault.includes(policy.type);

                        return new FilterSection(
                            trans('asset_policies.' + policy.type),
                            policy.filterCategory.setActive(!disabledByDefault, !disabledByDefault)
                        )
                    }
                ),
                'title'
            );
        },

        /**
         * Validates if a previous selection is still valid.
         * Otherwise select the first item in filterList if it is not empty.
         */
        validateSelectionOrPerformDefaultSelection() {
            if (this.selectedAsset === null) {
                this.selectFirstListItemOrDeselect();
                return this;
            }

            if (!this.filteredList.some(asset => asset.uid === this.selectedAsset.uid)) {
                this.selectFirstListItemOrDeselect();
            }
            return this;
        },

        /**
         * Select the first asset in the current filterList, or deselect if no asset is available in it.
         */
        selectFirstListItemOrDeselect() {
            this.selectAsset(this.filteredList[0] || null);
            return this;
        },

        /**
         * Fetch assets for the current user from API
         */
        fetchAssets() {
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;
            this.assetService
                .fetchAssets()
                .then(this.onSuccessFetchAssets)
                .catch(this.onErrorApi);
            return this;
        },

        /**
         * Success handler for loading assets
         *
         * @param {Array<Asset>} assets
         */
        onSuccessFetchAssets(assets) {
            this.applyFilters();

            return this;
        },

        /**
         * Error handler for API errors
         *
         * @param {String} error
         */
        onErrorApi(error) {
            this.$root.showErrorDialog(error, () => {
                this.afterSaveCallback = null;
                this.assetHasChanged = false;
                this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
            });

            return this;
        },

        /**
         * Select a specific asset
         *
         * @param {Asset} asset
         * @param {Boolean} force           // Whether to force the selection
         */
        selectAsset(asset, force = false) {
            // Only select an asset if it's not already selected:
            if (this.selectedAsset === null ||
                asset === null ||
                this.selectedAsset.uid !== asset.uid ||
                force === true)
            {
                // Prevent losing unsaved changes:
                if (this.assetHasChanged === true && permission(Permission.AssetsUpdate())) {
                    this.afterSaveCallback = function() {
                        this.selectAsset(asset, force);
                    }.bind(this);
                    this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, asset);
                    return this;
                }

                this.selectedAsset = (asset !== null) ? Asset.createFromAttributes(asset) : null;    // @NOTE: Creating a new instance so we can later revert any changes
                this.headerButtons.saveAsset.visible = false;
                this.headerButtons.savedAsset.visible = true;
                this.assetHasChanged = false;
                this.afterSaveCallback = null;
            }
            return this;
        },

        /**
         * Click handler for header navigation buttons that delegates the action to the button callback method
         *
         * @param {PageHeaderButton} buttonConfig
         */
        onClickHeaderNav(buttonConfig) {
            if (buttonConfig.callback === null) {return this;}
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && buttonConfig.callback !== this.saveAsset && permission(Permission.AssetsUpdate())) {
                this.afterSaveCallback = function() {
                    this.onClickHeaderNav(buttonConfig);
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, this.selectedAsset);
                return this;
            }
            buttonConfig.callback.call(this, buttonConfig);
            return this;
        },

        /**
         * Click handler for global events
         *
         * @param {MouseEvent} e
         */
        onClickGlobal(e) {
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate()) && this.$globalEvents.isEventTargetDescendantOfSelector(e, '#layout-sidemenu a, #inspector > .buttons > .btn') === true) {
                e.preventDefault();
                e.returnValue = '';
                // Show modal dialog:
                this.afterSaveCallback = function() {
                    e.target.closest('a, .btn').click();
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, this.selectedAsset);
            }
            return this;
        },

        /**
         * Shortcut handler for reloading
         *
         * @param {CustomEvent} e
         */
        onShortcutReload(e) {
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate()))
            {
                this.afterSaveCallback = function() {
                    window.location.reload();
                }.bind(this);
                this.onBeforeUnload(e.detail.keyboardEvent);
            }
            return this;
        },

        /**
         * Before unload handler
         *
         * @param {BeforeUnloadEvent} e
         */
        onBeforeUnload(e) {
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate())) {
                e.preventDefault();
                e.returnValue = '';
                // Show modal dialog:
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, this.selectedAsset);
            }
            else
            {
                // Show loading dialog:
                this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
            }
            return this;
        },

        /**
         * Click handler for create new asset button
         */
        onClickCreateNewAsset() {
            window.location.href = this.$root.route('assets.createForm');
            return this;
        },

        /**
         * Show handler for modal dialog archive asset
         *
         * @param {Asset} asset
         */
        onShowArchiveAsset(asset) {
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate())) {
                this.afterSaveCallback = function() {
                    this.$globalEvents.emit(EventType.MODAL_ARCHIVE_ASSET_SHOW, this.selectedAsset);
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_ARCHIVE_ASSET_HIDE);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, asset);
            }
            return this;
        },

        /**
         * Apply handler for archiving an asset
         *
         * @param {Asset} asset
         */
        onApplyArchiveAsset(asset) {
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;
            this.assetService
                .archiveAsset(asset)
                .then(this.onSuccessArchiveOrRestoreAsset)
                .catch(this.onErrorApi);
            return this;
        },

        /**
         * Apply handler for restoring an asset
         *
         * @param {Asset} asset
         */
        onApplyRestoreAsset(asset) {
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;
            this.assetService
                .restoreArchivedAsset(asset)
                .then(this.onSuccessArchiveOrRestoreAsset)
                .catch(this.onErrorApi);
            return this;
        },

        /**
         * Success handler for archiving/restoring the selected asset
         *
         * @param {Asset} asset
         */
        onSuccessArchiveOrRestoreAsset(asset) {
            this.applyFilters(undefined, false);

            return this;
        },

        /**
         * Click handler for delete all unused assets
         */
        onClickDeleteUnusedAssets() {
            this.$globalEvents.emit(EventType.MODAL_DELETE_UNUSED_ASSETS_SHOW);
            return this;
        },

        /**
         * Apply handler for deleting unused assets
         *
         * @param {Asset} asset
         */
        onApplyDeleteUnusedAssets(asset) {
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;
            this.assetService
                .deleteUnusedAssets()
                .then(this.onSuccessDeleteAsset)
                .catch(this.onErrorApi);
            return this;
        },

        /**
         * Show handler for modal dialog delete asset
         *
         */
        onShowDeleteUnusedAssets() {
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate)) {
                this.afterSaveCallback = function() {
                    this.$globalEvents.emit(EventType.MODAL_DELETE_UNUSED_ASSETS_SHOW);
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_DELETE_UNUSED_ASSETS_HIDE);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, this.selectedAsset);
            }
            return this;
        },

        /**
         * Apply handler for deleting an asset
         *
         * @param {Asset} asset
         */
        onApplyDeleteAsset(asset) {
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;
            this.assetService
                .deleteAsset(asset)
                .then(this.onSuccessDeleteAsset)
                .catch(this.onErrorApi);
            return this;
        },

        /**
         * Show handler for modal dialog delete asset
         *
         * @param {Asset} asset
         */
        onShowDeleteAsset(asset) {
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate())) {
                this.afterSaveCallback = function() {
                    this.$globalEvents.emit(EventType.MODAL_DELETE_ASSET_SHOW, this.selectedAsset);
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_DELETE_ASSET_HIDE);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, asset);
            }
            return this;
        },

        /**
         * Success handler for deleting the selected asset.
         */
        onSuccessDeleteAsset() {
            this.applyFilters(undefined, false);

            return this;
        },

        /**
        * Update handler for inspector changes to the asset
        *
        * @param {Asset} asset
        */
        onUpdateInspectorAsset(asset) {
            this.selectedAsset = asset;
            this.assetHasChanged = true;
            return this;
        },

        /**
         * Save the current asset
         */
        saveAsset() {
            if (this.selectedAsset === null || this.isLoading || this.isSaving || !this.assetHasChanged || !permission(Permission.AssetsUpdate()))
            {
                return this;
            }
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;

            // Save the selected asset:
            this.assetService
                .saveAsset(this.selectedAsset)
                .then(this.onSuccessSaveAsset)
                .catch(this.onErrorSaveAsset);
            return this;
        },

        /**
         * Handler for when user wants to add a free library asset to their library.
         */
        onAddToLibraryClicked(asset) {
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate())) {
                this.afterSaveCallback = function() {
                    this.addAssetToLibrary(asset);
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, asset);
            } else {
                this.addAssetToLibrary(asset);
            }
            return this;
        },

        /**
         * Tell the assetService to add the asset from the free library.
         * @throws AuthorizationError if the user does not have the required permission
         * @param {Asset} asset
         */
        addAssetToLibrary(asset) {
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;

            this.assetService.addAssetToLibrary(asset)
                .then(this.onSuccessAddAssetToLibrary)
                .catch(this.onErrorAddAssetToLibrary);

            return this;
        },

        /**
         * Handle successfully adding an asset to the library
         * @param asset
         */
        onSuccessAddAssetToLibrary(asset) {
            // calling applyFilters here will also reload the listEntries and will "disable" the add to library button.
            this.applyFilters(undefined, false);

            if (this.selectedAsset?.uid === asset.uid) {
                this.selectAsset(asset, true);
            }

            this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
            this.onAssetImported(asset);
        },

        /**
         * Handle error that occurred when adding an asset to the library
         * @param error
         */
        onErrorAddAssetToLibrary(error) {
            this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
            this.$root.showErrorDialog(error);
        },

        /**
         * Show handler for modal dialog remove asset from library
         *
         */
        onShowRemoveAssetFromLibrary(asset) {
            // Prevent losing unsaved changes:
            if (this.assetHasChanged === true && permission(Permission.AssetsUpdate())) {
                this.afterSaveCallback = function() {
                    this.$globalEvents.emit(EventType.MODAL_REMOVE_ASSET_FROM_LIBRARY_SHOW, asset);
                }.bind(this);
                this.$globalEvents.emit(EventType.MODAL_REMOVE_ASSET_FROM_LIBRARY_HIDE);
                this.$globalEvents.emit(EventType.MODAL_SAVE_ASSET_CHANGES_SHOW, asset);
            }
            return this;
        },

        /**
         * Tell the assetService to remove the asset from the user library.
         * @throws AuthorizationError if the user does not have the required permission
         * @param {Asset} asset
         */
        onApplyRemoveAssetFromLibrary(asset) {
            // Hide the header buttons:
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = false;

            this.assetService.removeAssetFromLibrary(asset)
                .then(this.onSuccessRemoveAssetFromLibrary)
                .catch(this.onErrorRemoveAssetFromLibrary);

            return this;
        },

        /**
         * Handle successfully removing an asset from the library
         * @param asset
         */
        onSuccessRemoveAssetFromLibrary(asset) {
            // calling applyFilters here will also reload the listEntries and will "enable" the add to library button.
            this.applyFilters(undefined, false);

            if (this.selectedAsset?.uid === asset.uid) {
                this.selectAsset(asset, true);
            }

            this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
        },

        /**
         * Handle error that occurred when removing an asset from the library
         * @param error
         */
        onErrorRemoveAssetFromLibrary(error) {
            this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
            this.$root.showErrorDialog(error);
        },

        /**
         * Success handler for saving the selected asset
         *
         * @param {Asset} asset
         */
        onSuccessSaveAsset(asset) {
            this.applyFilters(undefined, false);

            this.selectedAsset = Asset.createFromAttributes(asset);    // @NOTE: Creating a new instance so we can later revert any changes
            this.assetHasChanged = false;

            // After save callback:
            this.onAfterSaveOrDismissChanges();
            this.headerButtons.saveAsset.visible = false;
            this.headerButtons.savedAsset.visible = true;

            return this;
        },

        /**
         * Error handler for saving the selected asset
         *
         * @param {String} error
         */
        onErrorSaveAsset(error) {
            this.headerButtons.saveAsset.visible = true;
            this.headerButtons.savedAsset.visible = false;
            return this.onErrorApi(error);
        },

        /**
         * Cancel click handler for save changes dialog
         */
        onCancelSaveAssetChanges() {
            // Clear the callback:
            this.afterSaveCallback = null;
            return this;
        },

        /**
         * Apply click handler for save changes dialog
         *
         * @param {Asset} asset
         */
        onApplySaveAssetChanges(asset) {
            // Save the asset changes:
            this.saveAsset();
            return this;
        },

        /**
         * Dismiss click handler for save changes dialog
         *
         * @param {Asset} asset
         */
        onDismissSaveAssetChanges(asset) {
            if (this.selectedAsset !== null) {
                const originalAsset = this.assetService.getAssetByUid(this.selectedAsset.uid);
                if (originalAsset !== null) {
                    this.selectedAsset = Asset.createFromAttributes(originalAsset); // @NOTE: Creating a new instance, so we can later revert any changes
                }
            }
            this.assetHasChanged = false;
            this.onAfterSaveOrDismissChanges();
            return this;
        },

        /**
         * Execute the callback after saving data
         */
        onAfterSaveOrDismissChanges() {
            if (typeof this.afterSaveCallback === 'function') {
                this.afterSaveCallback.call(this);
                this.afterSaveCallback = null;
            }
            return this;
        },

        /**
         * Update the asset object whenever a change happens
         */
        onChangeInspectorPanelAsset(asset) {
            //console.log('Inspector: Asset has been updated', this.selectedAsset, asset);
            this.$globalEvents.emit(EventType.INSPECTOR_ASSET_UPDATED, this.selectedAsset);
            return this;
        },

    },
    watch: {
        assetHasChanged(hasChanged) {
            // Change the save icon:
            this.headerButtons.saveAsset.visible = hasChanged;
            this.headerButtons.savedAsset.visible = !hasChanged;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
