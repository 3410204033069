<template>
    <div class="command-settings">
        <!-- Select scene object -->
        <Dropdown
            :initial-value="command.target"
            @select="onSelectTargetSceneObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { getSceneObjectTargetDropdownOptionsForCommand }     from '@/Models/UnitData/Commands/CommandHelpers';

// Import classes:
import {ActivateModuleCommand} from '@/Models/UnitData/Commands/Command';


export default {
    name: 'PanelCommandActivateModule',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: ActivateModuleCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },
    },
    methods: {
        /**
         * Select handler for target scene object dropdown
         *
         * @param {String} targetUID
         */
        onSelectTargetSceneObject(targetUID) {
            if (targetUID !== this.command.target)
            {
                this.command.target = targetUID;
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>
