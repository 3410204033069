import AbstractDataObject from '@/Models/AbstractDataObject';
import CommandTargetType from '@/Models/UnitData/Commands/CommandTargetType';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import VariableComparison from '@/Models/UnitData/Variables/VariableComparison';
// @TODO: Remove dependencies from VariableHelpers.js, available types and defaults should be on the variable class
import {getAvailableComparisonClassesForVariableOfType, defaultComparisonForVariableType} from '@/Models/UnitData/Variables/VariableHelpers';
import Command, {ConditionCommand} from "@/Models/UnitData/Commands/Command";
import SceneObject, {
    BaseSceneObjectModule,
    SceneObjectModuleVariable
} from "@/Models/UnitData/SceneObjects/SceneObject";
import Trigger from "@/Models/UnitData/Triggers/Trigger";
import Variable from "@/Models/UnitData/Variables/Variable";

/**
 * Get a proper Condition object for attributes
 *
 * @param {*} attributes
 * @param {Object} parent               // Parent object reference
 * @returns {Condition}
 */
export function getConditionForData(attributes: any, parent: any | null = null): Condition | null
{
    if (!(attributes instanceof Object))
    {
        return null;
    }

    const className = getConditionClassFromType(attributes.type);
    switch (className)
    {
        case ObjectActiveCondition:         return new ObjectActiveCondition(attributes.object, parent);
        case ObjectiveCompletedCondition:   return new ObjectiveCompletedCondition(attributes.objective, parent);
        case ScriptCondition:               return new ScriptCondition(attributes.object, attributes.code, parent);
        case TriggerIsRunningCondition:     return new TriggerIsRunningCondition(attributes.object, attributes.trigger, parent);
        case VariableCondition:             return new VariableCondition(attributes, parent);
    }

    return null;
}

export default class Condition extends AbstractDataObject
{
    static get constructorName(): string { return 'Condition'; }

    /**
     * Constructor
     *
     * @param {Object} parent               // Parent object reference
     */
    constructor(parent: any | null = null)
    {
        super(parent);

        if (new.target === Condition) {
            throw new TypeError(`Cannot construct Condition instances directly`);
        }

        // Assign the static type to this object, so that the 'type' is included in the JSON:
        Object.defineProperty(this, 'type', {
            value: this.constructor.type,
            writable: false,
            enumerable: true,
        });
    }

    static get type(): string {
        return 'undefined';
    }

    static get targetType() {
        return null;
    }

    get targetType() {
        return this.constructor.targetType;
    }

    get isValid(): boolean {
        // Force implementation on inherited classes:
        if (!this.constructor.prototype.hasOwnProperty('isValid'))
        {
            throw new Error(`Condition->isValid(): Subclass "${this.constructor.name}" must implement its own getter for isValid()`);
        }
        return this.type === this.constructor.type;
    }

    get referencedObjectUid(): string|null {
        return null;
    }

    /**
     * Clean up data (e.g. remove invalid target UIDs)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        // @NOTE: Override this method on subclasses to make sure a condition only uses valid data
        return false;
    }

    get keyForTrueActionCollapsibleLabel() {
        return 'commands.condition.labels.' + this.type + '.true_action';
    }

    get keyForFalseActionCollapsibleLabel() {
        return 'commands.condition.labels.' + this.type + '.false_action';
    }
}

export class ObjectActiveCondition extends Condition
{
    public object: string | null;

    constructor(object: string | null, parent = null) {
        super(parent);
        this.object = object || null;
    }

    static get type(): string {
        return 'object_active';
    }

    static get targetType() {
        return CommandTargetType.SceneObject;
    }

    get isValid(): boolean {
        return (this.object !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.object === Command.TargetSelf) ? this.parentSceneObject?.uid || null : this.object;
    }

    /**
     * Get possible target objects from parents
     */
    get possibleTargets(): Array<SceneObject> {
        const parentUnitData = this.parentUnitData || null;
        if (parentUnitData === null) {throw new Error('ObjectActiveCondition->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object from the unit if the condition's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.parentTrainingScene.allSceneObjects));
    }

    /**
     * Get the referenced target object
     */
    get targetObject(): SceneObject | null {
        if (this.object === 'self')
        {
            const parentSceneObject = this.parentSceneObject || null;
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    /**
     * Clean up data (e.g. remove invalid target)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.object === null) {return hasChanged;}

        // Handle target "self" (on scene objectives):
        if (this.object === 'self')
        {
            // Remove target "self" if not allowed:
            if (!CommandType.Condition.allowTargetSelf || this.parentSceneObjectives !== null)
            {
                console.info('ObjectActiveCondition->cleanUpData(): Removing forbidden target "self" from condition.', this);
                this.object = null;
                return true;
            }

            // No further checks needed:
            if (this.parentSceneObjectives === null)
            {
                return hasChanged;
            }
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.parentSceneObject || null;
        if (parentSceneObject !== null && parentSceneObject.uid === this.object && CommandType.Condition.allowTargetSelf)
        {
            console.info('ObjectActiveCondition->cleanUpData(): Changing target to "self" on condition.', this.object, this);
            this.object = 'self';
            return true;
        }

        // Check if target object exists and is allowed:
        if (!this.isValid || !this.possibleTargets.some(o => o.uid === this.object))
        {
            console.info('ObjectActiveCondition->cleanUpData(): Removing unknown or invalid target from condition.', this.object, this);
            this.object = null;
            return true;
        }

        return hasChanged;
    }
}

export class ObjectiveCompletedCondition extends Condition
{
    public objective: string | null;

    constructor(objective: string | null, parent: ConditionCommand | null = null) {
        super(parent);
        this.objective = objective || null;
    }

    static get type(): string {
        return 'objective_completed';
    }

    static get targetType() {
        return CommandTargetType.Trigger;
    }

    get isValid(): boolean {
        return (this.objective !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return this.targetObject?.uid || null;
    }

    /**
     * Get possible target triggers from parents
     */
    get possibleTargets(): Array<Trigger> {
        const parentUnitData = this.parentUnitData || null;
        if (parentUnitData === null) {throw new Error('ObjectiveCompletedCondition->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any objective trigger from the unit:
        return parentUnitData.allTriggers.filter(t => t.is_objective);
    }

    /**
     * Get the referenced target object
     */
    get targetObject(): SceneObject | null {
        const targetTrigger = (this.objective !== null) ? this.targetTrigger : null;
        return (targetTrigger !== null) ? targetTrigger.parentSceneObject : null;
    }

    /**
     * Get the referenced target trigger
     */
    get targetTrigger(): Trigger | null {
        if (this.objective === 'self')
        {
            return (this.parentTrigger !== null) ? this.possibleTargets.find(t => t.uid === this.parentTrigger.uid) || null : null;
        }
        return (this.objective !== null) ? this.possibleTargets.find(t => t.uid === this.objective) || null : null;
    }

    /**
     * Clean up data (e.g. remove invalid target)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        if (this.objective === null) {return hasChanged;}

        // Remove target "self" (since we only want to use UIDs):
        if (this.objective === 'self')
        {
            console.info('ObjectiveCompletedCondition->cleanUpData(): Removing forbidden target "self" from condition.', this);
            this.objective = null;
            return true;
        }

        // Check if target trigger exists and is allowed:
        if (!this.isValid || !this.possibleTargets.some(o => o.uid === this.objective))
        {
            console.info('ObjectiveCompletedCondition->cleanUpData(): Removing unknown or invalid target from condition.', this.objective, this);
            this.objective = null;
            return true;
        }

        return hasChanged;
    }
}

export class ScriptCondition extends Condition
{
    public object: any;
    public code: any;

    constructor(object: any, code: any, parent = null) {
        super(parent);
        this.object = object || null;
        this.code = code || null;
    }

    static get type(): string {
        return 'script';
    }

    static get targetType() {
        return CommandTargetType.SceneObject;
    }

    get isValid(): boolean {
        // @TODO: Implement proper validation for the scripted code
        return (this.object !== null && this.code !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.object === Command.TargetSelf) ? this.parentSceneObject?.uid || null : this.object;
    }

    /**
     * Get possible target objects from parents
     */
    get possibleTargets(): Array<SceneObject> {
        const parentUnitData = this.parentUnitData || null;
        if (parentUnitData === null) {throw new Error('ScriptCondition->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any module from the unit if the condition's parent is a global object or any module from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.parentTrainingScene.allSceneObjects)).filter(o => o.type === 'widget');
    }

    /**
     * Get the referenced target object
     */
    get targetObject(): BaseSceneObjectModule | null {
        if (this.object === 'self')
        {
            const parentSceneObject = this.parentSceneObject || null;
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    /**
     * Clean up data (e.g. remove invalid target)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();
        // @TODO: Check if target object exists
        return hasChanged;
    }
}

export class TriggerIsRunningCondition extends Condition
{
    public object: string | null;
    public trigger: string | null;

    constructor(object: string | null, trigger: string  |null, parent = null) {
        super(parent);
        this.object = object || null;
        this.trigger = trigger || null;
    }

    static get type(): string {
        return 'trigger_running';
    }

    static get targetType() {
        return CommandTargetType.Trigger;
    }

    get isValid(): boolean {
        return (this.object !== null && this.trigger !== null) && super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.object === Command.TargetSelf) ? this.parentSceneObject?.uid || null : this.object;
    }

    /**
     * Get possible target objects from parents
     */
    get possibleTargets(): Array<SceneObject> {
        const parentUnitData = this.parentUnitData || null;
        if (parentUnitData === null) {throw new Error('TriggerIsRunningCondition->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any object with at least one trigger from the unit if the condition's parent is a global object or any object from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.parentTrainingScene.allSceneObjects)).filter(o => o.hasTriggers);
    }

    /**
     * Get the referenced target object
     */
    get targetObject(): SceneObject | null
    {
        if ((this.object === null || (this.object === 'self' && this.isGlobal)) && this.trigger !== null)
        {
            return this.possibleTargets.find(o => o.triggers.some(t => t.uid === this.trigger)) || null;
        }
        if (this.object === 'self')
        {
            const parentSceneObject = this.parentSceneObject || null;
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    /**
     * Get the referenced target trigger
     */
    get targetTrigger(): Trigger | null {
        const targetObject = (this.trigger !== null) ? this.targetObject : null;
        return (targetObject !== null) ? targetObject.triggers.find(t => t.uid === this.trigger) || null : null;
    }

    /**
     * Clean up data (e.g. remove invalid target)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset to empty values if no target object is set:
        if (this.object === null)
        {
            if (this.trigger !== null) {
                this.trigger = null;
                hasChanged = true;
            }
            return hasChanged;
        }

        // Handle target "self" on scene objectives:
        if (this.object === 'self' && this.parentSceneObjectives !== null)
        {
            // Reset target if no trigger is set since there is no way to find out which object the condition was assigned to:
            if (this.trigger === null)
            {
                console.info('TriggerIsRunningCondition->cleanUpData(): Removing forbidden target "self" from condition on objectives.', this);
                this.object = null;
                return true;
            }

            // Check if parent scene is set:
            const parentTrainingScene = this.parentTrainingScene || null;
            if (parentTrainingScene === null) {throw new Error('TriggerIsRunningCondition->cleanUpData(): Unable to clean up data because parent scene is not set.');}

            // Reassign target "self" to original scene object when condition is inside objectives:
            const originalTargetObject = this.parentUnitData.allGlobalObjects.concat(parentTrainingScene.allSceneObjects).find(o => o.hasTriggers && o.triggers.map(t => t.uid).includes(this.trigger)) || null;
            if (originalTargetObject !== null)
            {
                console.info('TriggerIsRunningCondition->cleanUpData(): Reassigning target "self" on condition.', this);
                this.object = originalTargetObject.uid;
                return true;
            }
            console.info('TriggerIsRunningCondition->cleanUpData(): Removing forbidden target "self" from condition on objectives.', this);
            this.object = null;
            this.trigger = null;
            return true;
        }

        // Check if target object exists and is allowed:
        const targetObj = this.targetObject;
        if (targetObj === null || !targetObj.hasTriggers)
        {
            console.info('TriggerIsRunningCondition->cleanUpData(): Removing unknown or invalid target from condition.', this.object, this);
            this.object = null;
            this.trigger = null;
            return true;
        }

        // Change target to "self" if parent has the same UID as the target:
        const parentSceneObject = this.parentSceneObject || null;
        if (parentSceneObject && this.object === parentSceneObject.uid && CommandType.Condition.allowTargetSelf)
        {
            console.info('TriggerIsRunningCondition->cleanUpData(): Changing target to "self" on condition.', this.object, this);
            this.object = 'self';
            hasChanged = true;
        }

        // Check if target trigger exists and is allowed:
        if (this.trigger !== null && !targetObj.triggers.some(t => t.uid === this.trigger))
        {
            // Reassign original target UID for triggers that were duplicated into other scene objects:
            if (this.object === 'self')
            {
                const originalTargetObj = this.possibleTargets.find(o => o.hasTriggers && o.triggers.map(t => t.uid).includes(this.trigger)) || null;
                if (originalTargetObj !== null)
                {
                    console.info('TriggerIsRunningCondition->cleanUpData(): Reassigning target to original scene object on condition.', this.object, this);
                    this.object = originalTargetObj.uid;
                    return true;
                }
            }

            // Remove unknown or invalid trigger reference:
            console.info('TriggerIsRunningCondition->cleanUpData(): Removing unknown or invalid trigger target from condition.', this.trigger, this);
            this.trigger = null;
            return true;
        }

        return hasChanged;
    }
}

export class VariableCondition extends Condition
{
    public object: string | null;
    public variable: string | null;
    public comparison: VariableComparison | null;

    constructor(attributes: any = {}, parent = null) {
        super(parent);
        this.object = attributes.object || null;
        this.variable = attributes.variable || null;
        this.comparison = (attributes.comparison instanceof Object) ? VariableComparison.createFromAttributes(attributes.comparison, this) : null;
    }

    static get type(): string {
        return 'variable';
    }

    static get targetType() {
        return CommandTargetType.Variable;
    }

    get isValid(): boolean {
        return this.variable !== null &&
            this.object !== null &&
            this.comparison !== null &&
            this.comparison.isValid &&
            super.isValid;
    }

    get referencedObjectUid(): string|null {
        return (this.object === Command.TargetSelf) ? this.parentSceneObject?.uid || null : this.object;
    }

    /**
     * Get possible target objects from parents
     */
    get possibleTargets(): Array<SceneObjectModuleVariable> {
        const parentUnitData = this.parentUnitData || null;
        if (parentUnitData === null) {throw new Error('VariableCondition->possibleTargets(): Unable to get targets because parent UnitData is not set.');}
        // Target can be any variable module from the unit if the command's parent is a global object or any variable module from the same scene otherwise:
        return (this.isGlobal ? parentUnitData.allSceneObjects : parentUnitData.allGlobalObjects.concat(this.parentTrainingScene.allSceneObjects)).filter(o => o.hasVariables);
    }

    /**
     * Get the referenced target object
     *
     */
    get targetObject(): SceneObjectModuleVariable | null {
        if ((this.object === null || (this.object === 'self' && this.isGlobal)) && this.variable !== null)
        {
            return this.possibleTargets.find(o => o.hasVariable(this.variable)) || null;
        }
        if (this.object === 'self')
        {
            const parentSceneObject = this.parentSceneObject || null;
            return (parentSceneObject !== null) ? this.possibleTargets.find(o => o.uid === parentSceneObject.uid) || null : null;
        }
        return (this.object !== null) ? this.possibleTargets.find(o => o.uid === this.object) || null : null;
    }

    /**
     * Get the referenced target variable
     */
    get targetVariable(): Variable | null {
        const targetObject = (this.variable !== null) ? this.targetObject : null;
        return (targetObject !== null) ? targetObject.getVariable(this.variable) : null;
    }

    /**
     * Clean up data (e.g. remove invalid target)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData(): boolean {
        let hasChanged = super.cleanUpData();

        // Reset to empty values if no target object is set:
        if (this.object === null)
        {
            if (this.variable !== null) {this.variable = null; hasChanged = true;}
            if (this.comparison !== null) {this.comparison = null; hasChanged = true;}
            return hasChanged;
        }

        // Handle target "self" on scene objectives:
        if (this.object === 'self' && this.parentSceneObjectives !== null)
        {
            // Reset target if no variable is set since there is no way to find out which object the condition was assigned to:
            if (this.variable === null)
            {
                console.info('VariableCondition->cleanUpData(): Removing forbidden target "self" from condition on objectives.', this);
                this.object = null;
                this.variable = null;
                this.comparison = null;
                return true;
            }

            // Check if parent scene is set:
            const parentTrainingScene = this.parentTrainingScene || null;
            if (parentTrainingScene === null) {throw new Error('VariableCondition->cleanUpData(): Unable to clean up data because parent scene is not set.');}

            // Reassign target "self" to original scene object when condition is inside objectives:
            const originalTargetObject = this.parentUnitData.allGlobalObjects.concat(parentTrainingScene.allSceneObjects).find(o => o.hasVariables && o.variables.map(v => v.uid).includes(this.variable)) || null;
            if (originalTargetObject !== null)
            {
                console.info('VariableCondition->cleanUpData(): Reassigning target "self" on condition.', this);
                this.object = originalTargetObject.uid;
                return true;
            }
            console.info('VariableCondition->cleanUpData(): Removing forbidden target "self" from condition on objectives.', this);
            this.object = null;
            this.variable = null;
            this.comparison = null;
            return true;
        }

        // Check if target object exists and is allowed:
        const targetObj = this.targetObject;
        if (targetObj === null)
        {
            console.info('VariableCondition->cleanUpData(): Removing unknown or invalid target from condition.', this.object, this);
            this.object = null;
            this.variable = null;
            this.comparison = null;
            return true;
        }

        // Check if target variable exists and is allowed:
        const targetVariable = this.targetVariable;
        if (this.variable !== null && targetVariable === null)
        {
            // Reassign original target UID for variables that were duplicated into other scene objects:
            if (this.object === 'self')
            {
                const originalTargetObj = this.possibleTargets.find(o => o.hasVariables && o.hasVariable(this.variable)) || null;
                if (originalTargetObj !== null)
                {
                    console.info('VariableCondition->cleanUpData(): Reassigning target to original scene object on command.', this.object, this);
                    this.object = originalTargetObj.uid;
                    return true;
                }
            }

            // Remove unknown or invalid variable reference:
            console.info('VariableCondition->cleanUpData(): Removing unknown or invalid variable target from command.', this.variable, this);
            this.variable = null;
            this.comparison = null;
            return true;
        }

        // Check if comparison type is allowed for the given variable:
        if (targetVariable !== null && this.comparison !== null)
        {
            // @TODO: Remove dependencies from VariableHelpers.js, available types and defaults should be on the variable class
            if (!getAvailableComparisonClassesForVariableOfType(targetVariable.type).map(o => o.Type).includes(this.comparison.type))
            {
                console.info('VariableCondition->cleanUpData(): Resetting invalid comparison to default.', this.comparison, this);
                this.comparison = defaultComparisonForVariableType(targetVariable.type);
                return true;
            }
            else if (this.comparison.cleanUpData())
            {
                return true;
            }
        }

        return hasChanged;
    }
}

/**
 * Get the Condition class for the specified type
 *
 * @param {String} type
 * @returns {Condition}
 */
export function getConditionClassFromType(type: string): Condition | null {
    let conditionClass = conditionTypeToConditionMapping.get(type);
    return conditionClass !== undefined ? conditionClass : null;
}

/**
 * Condition.type to Condition mapping
 */
const conditionTypeToConditionMapping: Map<string, Condition> = new Map([
    [ObjectActiveCondition.type, ObjectActiveCondition],
    [ObjectiveCompletedCondition.type, ObjectiveCompletedCondition],
    [ScriptCondition.type, ScriptCondition],
    [TriggerIsRunningCondition.type, TriggerIsRunningCondition],
    [VariableCondition.type, VariableCondition],
]);
