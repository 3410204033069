import axios from 'axios';
import _ from 'lodash';

const Axios = axios.Axios;

export default class AxiosRequest extends Axios {

    /**
     * Constructor
     *
     * @param {Object} config
     */
    constructor(config = {})
    {
        // Merge the global axios default settings into the config:
        config = _.merge({}, axios.defaults, config);
        super(config);
        this.isBusy = false;
        this.currentRequest = null;
        this.cancelToken = axios.CancelToken.source();
    }

    /**
     * Inject cancel token
     *
     * @param {Object} config
     * @returns {Object}
     */
    injectCancelToken(config)
    {
        if (config instanceof Object === false)
        {
            throw new TypeError('AxiosRequest->injectCancelToken(): Parameter "config" must be of type Object');
        }
        return _.merge(config, {
            cancelToken: this.cancelToken.token
        });
    }

    /**
     * Cancel the current request
     *
     * @async
     * @returns {Promise}
     */
    async cancel()
    {
        if (this.currentRequest !== null)
        {
            this.cancelToken.token.promise.finally(() => {
                // Generate a new token:
                this.cancelToken = axios.CancelToken.source();
                // Clear the current request:
                this.currentRequest = null;
            }, this);
            this.cancelToken.cancel('Request canceled');
            return await this.currentRequest
                .catch((e) => {
                    // Always resolve the promise with a fulfilled state so we can use cancel().then():
                    return Promise.resolve(e);
                });
        }
        return Promise.resolve('Request canceled');
    }

    /**
     * Send request
     *
     * @async
     * @param {Object} config
     * @returns {Promise}
     */
    async request(config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->request(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.request(config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }

    /**
     * Send GET request
     *
     * @async
     * @param {String} url
     * @param {Object} config
     * @returns {Promise}
     */
    async get(url, config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->get(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.get(url, config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }

    /**
     * Send DELETE request
     *
     * @async
     * @param {String} url
     * @param {Object} config
     * @returns {Promise}
     */
    async delete(url, config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->delete(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.delete(url, config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }

    /**
     * Send HEAD request
     *
     * @async
     * @param {String} url
     * @param {Object} config
     * @returns {Promise}
     */
    async head(url, config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->head(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.head(url, config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }

    /**
     * Send OPTIONS request
     *
     * @async
     * @param {String} url
     * @param {Object} config
     * @returns {Promise}
     */
    async options(url, config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->options(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.options(url, config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }

    /**
     * Send POST request
     *
     * @template TData
     * @async
     * @param {String} url
     * @param {TData} data
     * @param {?AxiosRequestConfig<TData>} config
     * @returns {Promise<AxiosResponse<any>>}
     */
    async post(url, data = null, config = {}) {
        if (this.isBusy === true) {
            return Promise.reject('AxiosRequest->post(): The request is still busy');
        }

        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios
            .post(url, data, config)
            .finally(() => {
                this.isBusy = false;
                this.currentRequest = null;
            });

        return this.currentRequest;
    }

    /**
     * Send PUT request
     *
     * @async
     * @param {String} url
     * @param {Mixed} data
     * @param {Object} config
     * @returns {Promise}
     */
    async put(url, data = null, config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->put(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.put(url, data, config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }

    /**
     * Send PATCH request
     *
     * @async
     * @param {String} url
     * @param {Mixed} data
     * @param {Object} config
     * @returns {Promise}
     */
    async patch(url, data = null, config = {})
    {
        if (this.isBusy === true) { return Promise.reject('AxiosRequest->patch(): The request is still busy'); }
        config = this.injectCancelToken(config);
        this.isBusy = true;
        this.currentRequest = axios.patch(url, data, config).finally(() => {this.isBusy = false; this.currentRequest = null;}, this);
        return await this.currentRequest;
    }
}
