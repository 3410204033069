<template>
    <div class="dialog-installation-cell-app">
        <header>
            <span class="logo" v-html="logoImageTag"></span>
            <h2 class="title">{{ title }}</h2>
        </header>
        <p class="description" v-html="description"></p>
        <footer>
            <ButtonPrimary v-if="buttonHref" :href="buttonHref" target="_blank" class="btn-apply" :caption="buttonCaption" />
            <a v-if="linkHref" :href="linkHref" target="_blank">{{ linkCaption }}</a>
            <span v-else></span>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'DialogInstallationCellApp',
    props: {
        logoName: {
            type: String,
        },
        title: {
            type: String,
        },
        description: {
            type: String,
        },
        buttonCaption: {
            type: String,
        },
        buttonHref: {
            type: String,
        },
        linkCaption: {
            type: String,
        },
        linkHref: {
            type: String,
            default: null,
        },
    },

    computed: {
        logoImageTag() {
            return `<img src="/images/logos/${this.logoName}.png" alt="">`;
        },
    },
}
</script>


<style lang="scss">
    .dialog-installation-cell-app main .description .selectable {
        cursor: text;
    }
</style>

<style lang="scss" scoped>

    .dialog-installation-cell-app header {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .dialog-installation-cell-app header .logo {
        display: flex;
        width: 48px;
        height: 48px;
    }

    .dialog-installation-cell-app main .description {
        margin-bottom: 24px;
    }

    .dialog-installation-cell-app footer {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 12px;
    }

    footer .btn {
        flex-shrink: 0;
    }

    @media screen and (min-width: 784px) {
        .dialog-installation-cell-app:not(:last-child) {
            min-height: 150px;
        }
    }
</style>
