<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('users.create.headline') }}</div>

                    <div class="card-body">
                        <form ref="form" class="form-user-create" method="post">
                            <UserFormTextInput
                                :key="'firstname'+key"
                                :model="form"
                                property="firstname"
                                :required="true"
                                :maxlength="50"
                                :label="trans('labels.firstname')"
                                @change="removeValidationError('firstname')"
                                :validationErrors="validationErrors('firstname')"
                            />

                            <UserFormTextInput
                                :key="'lastname'+key"
                                :model="form"
                                property="lastname"
                                :required="true"
                                :maxlength="50"
                                :label="trans('labels.lastname')"
                                @change="removeValidationError('lastname')"
                            />

                            <UserFormTextInput
                                :key="'email'+key"
                                :model="form"
                                property="email"
                                type="email"
                                :required="true"
                                :maxlength="254"
                                :label="trans('labels.email')"
                                @change="removeValidationError('email')"
                                :validationErrors="validationErrors('email')"
                            />

                            <div v-if="canChangeUserRole" class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.role') }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'userrole'+key"
                                        :model="form"
                                        property="userroles_uid"
                                        :initialValue="initialUserRole"
                                        :options="userRoleOptions"
                                        :required="true"
                                        :class="(validationErrors('userroles_uid').length > 0 ? ' is-invalid' : '')"
                                        @select="removeValidationError('userroles_uid')"
                                    />

                                    <span v-if="validationErrors('userroles_uid').length > 0" class="invalid-feedback" role="alert">
                                        <strong v-html="validationErrors('userroles_uid')[0]"></strong>
                                    </span>
                                </div>
                            </div>

                            <div v-if="canChangeTenantRole" class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.tenant_role') }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'tenantrole'+key"
                                        :model="form"
                                        property="tenant_role_uid"
                                        :initialValue="initialTenantRole"
                                        :options="tenantRoleOptions"
                                        :required="true"
                                        :class="(validationErrors('tenant_role_uid').length > 0 ? ' is-invalid' : '')"
                                        @select="removeValidationError('tenant_role_uid')"
                                    />

                                    <span v-if="validationErrors('tenant_role_uid').length > 0" class="invalid-feedback" role="alert">
                                        <strong v-html="validationErrors('tenant_role_uid')[0]"></strong>
                                    </span>
                                </div>
                            </div>

                            <template v-if="canSetPassword">
                                <div class="form-group row">
                                    <div class="col-md-6 offset-md-4">
                                        <div class="form-check">
                                            <input
                                                :key="'send_welcome_email'+key"
                                                class="form-check-input"
                                                type="checkbox"
                                                name="send_welcome_email"
                                                id="send_welcome_email"
                                                v-model="form.send_welcome_email"
                                                :true-value="true"
                                                :false-value="false"
                                            >
                                            <label class="form-check-label" for="send_welcome_email">
                                                {{ trans('labels.send_welcome_email') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <UserFormTextInput
                                    v-if="!form.send_welcome_email"
                                    :key="'password'+key"
                                    :model="form"
                                    property="password"
                                    type="password"
                                    :required="true"
                                    :minlength="8"
                                    :maxlength="50"
                                    :label="trans('labels.password')"
                                    @change="removeValidationError('password')"
                                    :validationErrors="validationErrors('password')"
                                    :labelHint="trans('tooltips.form_labels.password')"
                                />

                                <UserFormTextInput
                                    v-if="!form.send_welcome_email"
                                    :key="'password_confirmation'+key"
                                    :model="form"
                                    property="password_confirmation"
                                    type="password"
                                    :required="true"
                                    :minlength="8"
                                    :maxlength="50"
                                    :label="trans('labels.password_confirm')"
                                />
                            </template>

                            <div class="form-group row mb-0">
                                <div class="buttons col-md-6 offset-md-4">
                                    <ButtonSecondary :href="route('users.index')" caption="users.create.back_to_user_list" />
                                    <button type="submit" class="btn btn-primary" @click.prevent="onSubmit" :disabled="isSubmitting">
                                        {{ trans('labels.create') }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <DialogSaving :title="trans('users.create.modals.saving.title')" />
        <DialogNotification />
    </div>
</template>

<script>
import DialogNotification       from '@/Vue/Modals/DialogNotification.vue';
import DialogSaving from '@/Vue/Modals/DialogSaving.vue';
import DropdownOption from '@/Utility/DropdownOption';
import EventType from '@/Utility/EventType';
import UserFormTextInput from '@/Vue/Users/UserFormTextInput';
import { permission, trans } from '@/Utility/Helpers';
import { Permission } from '@/Models/User/Permission';
import RequestError from "@/Errors/RequestError.js";

export default {
    name: 'CreateUserForm',
    components: {
        DialogNotification,
        DialogSaving,
        UserFormTextInput
    },
    props: {
        userRoles: {
            type: Array,
            default() {
                return [];
            }
        },
        tenantRoles: {
            type: Array,
            default() {
                return [];
            }
        },
    },
    data() {
        return {
            isSubmitting: false,
            form: {
                firstname: null,
                lastname: null,
                email: null,
                userroles_uid: null,
                tenant_role_uid: null,
                send_welcome_email: true,
            },
            defaultForm: {},
            errors: {},
            key: 0,
        }
    },

    beforeMount() {
        this.form.userroles_uid = this.initialUserRole;
        this.form.tenant_role_uid = this.initialTenantRole;

        this.defaultForm = {...this.form};
    },

    computed: {

        /**
         * Can the current user change the users' tenant role
         *
         * @returns {boolean}
         */
        canChangeTenantRole() {
            return permission(Permission.TenantsAssignRole());
        },

        /**
         * Can the current user change the users' tenant role
         *
         * @returns {boolean}
         */
        canChangeUserRole() {
            return permission(Permission.UsersAssignUserRole());
        },

        /**
         * Can the current user set the password for a new user
         *
         * @returns {boolean}
         */
        canSetPassword() {
            return permission(Permission.UsersUpdatePassword());
        },

        /**
         * Get the UID for the initial tenant role that should be selected.
         *
         * @returns {string}
         */
        initialTenantRole() {
            const role = this.tenantRoles.find(role => role.name === 'learner');

            return role.uid;
        },

        /**
         * Get the UID for the initial user role that should be selected.
         *
         * @returns {string}
         */
        initialUserRole() {
            const role = this.userRoles.find(role => role.name === 'user');

            return role !== null ? role.uid : null;
        },

        /**
         * Tenants to show in the dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        userRoleOptions() {
            const options = [];
            this.userRoles.forEach(role => options.push(new DropdownOption({
                caption: trans('userroles.' + role.name),
                disabled: false,
                value: role.uid
            })));

            return options;
        },

        /**
         * Tenants to show in the dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        tenantRoleOptions() {
            const options = [];
            this.tenantRoles.forEach(role => options.push(new DropdownOption({
                caption:trans('tenant_roles.' +  role.name),
                disabled: false,
                value: role.uid
            })));

            return options;
        },
    },
    methods: {

        /**
         * Get the validation errors for a specific field.
         *
         * @param property
         * @returns {string}
         */
        validationErrors(property) {
            return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
        },

        /**
         * Clear the validatin errors for a specific field.
         *
         * @param property
         */
        removeValidationError(property) {
            delete this.errors[property];
        },

        /**
         * Click handler fpr the submit button
         *
         * @param event
         */
        onSubmit(event) {
            event.preventDefault();

            const form = this.$refs.form;

            if (this.isSubmitting || !form.reportValidity()) {
                return;
            }

            this.isSubmitting = true;
            this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
            const formData = {...this.form};

            // Do not include passwords if we send a welcome email anyway
            if (this.form.send_welcome_email === true) {
                delete formData.password;
                delete formData.password_confirmation;
            } else {
                delete formData.send_welcome_email;
            }

            this.$userService.createUserFromFormData(formData)
                .then((user) => {
                    this.$toast.success(trans('users.create.create_success', {user: user.fullName}));
                    this.resetForm();
                })
                .catch((error) => {
                    this.errors = error.validationErrors || {};

                    // Show error dialog unless it's a validation error:
                    if (!(error instanceof RequestError && error.isValidationError)) {
                        this.$root.showErrorDialog(error);
                    }
                })
                .finally(() => {
                    this.isSubmitting = false;
                    this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                });
        },

        /**
         * Reset the inputs and dropdowns
         */
        resetForm() {
            this.form = {...this.defaultForm};
            this.errors = {};

            // Make sure the components reload
            this.key += 1;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
