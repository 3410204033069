import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from "@/Models/Tenant/TenantRole";

export default class TenantWithDetails extends Tenant {

    constructor(attributes = {}) {
        super(attributes);

        /**
         * Role of the current user for this tenant
         * @type {TenantRole|null}
         */
        this.tenant_role = (attributes.tenant_role instanceof Object) ? new TenantRole(attributes.tenant_role) : null;

        this.unit_count = isNaN(attributes.unit_count) ? null : attributes.unit_count;
        this.user_count = isNaN(attributes.user_count) ? null : attributes.user_count;
    }

}
