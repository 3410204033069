import _ from 'lodash';
import AbstractDataObject from '@/Models/AbstractDataObject';
import Operand from '@/Models/UnitData/Variables/Operand';

/* NOTE: New VariableOperation classes have to be added to mapping at the end of this file! */

/**
 * @abstract
 */
export default class VariableOperation extends AbstractDataObject
{
    static get constructorName() { return 'VariableOperation'; }
    static get Type() {
        return 'Operation';
    }

    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     * @param {Object} parent               // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        if (new.target === VariableOperation) {
            throw new TypeError(`Cannot construct Operation instances directly`);
        }

        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        this.type = attributes.type;
        this.operand = attributes.operand ? Operand.createFromAttributes(attributes.operand, this) : null;
    }

    get isValid() {
        return this.type === this.constructor.Type && this.operand instanceof Operand && this.operand.isValid;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        // @NOTE: Override this method on subclasses to make sure a variable operation only uses valid data
        let hasChanged = false;
        if (this.type !== this.constructor.Type)
        {
            // console.info('VariableOperation->cleanUpData(): Changing incorrect type.', this.type, this);
            this.type = this.constructor.Type;
            hasChanged = true;
        }

        if (this.operand !== null && this.operand.cleanUpData())
        {
            if (!this.operand.isValid) {
                this.operand = null;
            }
            hasChanged = true;
        }
        return hasChanged;
    }

    /**
     * Create a new operation with the given BehaviourType or type string
     *
     * @param {String} operationType
     * @param {Object} attributes
     * @param {Object} parent               // Parent object reference
     * @returns {VariableOperation}
     */
    static createWithType(operationType, attributes = {}, parent = null) {
        const operationClass = getOperationClassFromType(operationType);

        // Merge default attributes:
        if (operationClass !== null && operationClass.defaultAttributes instanceof Object) {
            attributes = {
                ...operationClass.defaultAttributes, ...attributes
            };
        }

        // Set the command type:
        attributes = {
            ...attributes,
            ...{
                type: operationType,
            }
        };

        return VariableOperation.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new operation from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent               // Parent object reference
     * @returns {VariableOperation}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? _.cloneDeep(attributes) : new Object(null);
        const className = getOperationClassFromType(clonedAttributes.type) || VariableOperation;
        return new className(clonedAttributes, parent);
    }
}

export class BoolSetOperation extends VariableOperation {

    static get Type() {
        return 'bool_set';
    }
}

export class NumberAddOperation extends VariableOperation {

    static get Type() {
        return 'number_add';
    }
}

export class NumberMultiplyOperation extends VariableOperation {

    static get Type() {
        return 'number_multiply';
    }
}

export class NumberSubtractOperation extends VariableOperation {

    static get Type() {
        return 'number_subtract';
    }
}

export class NumberSetOperation extends VariableOperation {

    static get Type() {
        return 'number_set';
    }
}

/**
 * OperationType to Operation subclass mapping
 * @type {Map<string, VariableOperation>}
 */
export const operationMapping = new Map([
    [BoolSetOperation.Type, BoolSetOperation],
    [NumberAddOperation.Type, NumberAddOperation],
    [NumberMultiplyOperation.Type, NumberMultiplyOperation],
    [NumberSetOperation.Type, NumberSetOperation],
    [NumberSubtractOperation.Type, NumberSubtractOperation],
]);

/**
 * OperationType to Operation subclass mapping
 *
 * @param {String} type
 * @returns {VariableOperation|null}
 */
export function getOperationClassFromType(type) {
    return operationMapping.has(type) ? operationMapping.get(type) : null;
}
