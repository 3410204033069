<template>
    <div v-if="isVisible" id="dialog-create-asset" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <CreateAssetForm
                :initial-title="initialAssetTitle"
                :initial-description="initialAssetDescription"
                :initial-asset-attribution="initialAssetAttribution"
                :initial-preview-image-url="initialPreviewImageUrl"
                :lock-asset-attribution="lockAssetAttribution"
                :asset-file-downloader="assetFileDownloader"
                :asset-types="assetTypes"
                :policies="policies"
                :asset-provider="assetProvider"
                :provider-asset-id="providerAssetId"
                :enableDialogs="false"
                @success="onSuccessAssetCreate"
                @cancel="onCancelAssetCreate"
            />
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import {AssetPolicyStandard}    from '@/Models/Asset/AssetPolicy';
    import CreateAssetForm from "@/Vue/Assets/CreateAssetForm";

    export default {
        name: 'DialogCreateAsset',
        components: {
            CreateAssetForm
        },
        emits: [
            'success',
            'cancel',
        ],
        props: {
            policies: {
                type: Array,
                default() {
                    return [AssetPolicyStandard.type];
                }
            },
        },
        data() {
            return {
                /** @type {string | null} */
                initialAssetTitle: null,

                /** @type {string | null} */
                initialAssetDescription: null,

                /** @type {string | null} */
                initialAssetAttribution: null,

                /** @type {string | null} */
                initialPreviewImageUrl: null,

                /** @type {boolean} */
                lockAssetAttribution: false,

                /** @type {AssetProvider | null} */
                assetProvider: null,

                /** @type {string | null} */
                providerAssetId: null,

                /** @type {() => Promise<Blob> | null} */
                assetFileDownloader: null,

                callback: null,                 // Reference to the target function/model/component for which this component is being shown
                assetTypes: {},                 // List of available AssetTypes for selection in the create asset form
                isVisible: false,               // Visibility state
                previousFocusElement: null,     // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Escape', this.onCancelAssetCreate],
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_CREATE_ASSET_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_CREATE_ASSET_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_CREATE_ASSET_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_CREATE_ASSET_HIDE, this.hide);
        },

        methods: {

            /**
             * Show the dialog
             *
             * @param {ModalCreateAssetShowEventParameters} options
             */
            show(options = {}) {

                // Convert list of AssetType objects into an object that the create asset form uses
                const availableAssetTypes = {};
                if (options.assetTypes && options.assetTypes instanceof Array) {
                    options.assetTypes.forEach(assetType => availableAssetTypes[assetType.type] = assetType.supportedFileExtensions);
                }else{
                    console.error('DialogCreateAsset->show(): No valid asset types given.');
                }

                this.initialAssetTitle = options.initialAssetTitle || null;
                this.initialAssetDescription = options.initialAssetDescription || null;
                this.initialAssetAttribution = options.initialAssetAttribution || null;
                this.initialPreviewImageUrl = options.initialPreviewImageUrl || null;
                this.lockAssetAttribution = options.lockAssetAttribution || false;
                this.assetFileDownloader = options.assetFileDownloader || null;
                this.callback = options.callback || null;
                this.assetTypes = availableAssetTypes;
                this.assetProvider = options.assetProvider || null;
                this.providerAssetId = options.providerAssetId || null;
                this.previousFocusElement = document.activeElement;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Success handler for promise from asset create form
             *
             * @param {Asset} asset
             */
            onSuccessAssetCreate(asset) {
                this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_HIDE);
                this.hide();
                if (this.callback instanceof Function) {
                    this.callback(asset);
                }
                this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_SUCCESS, asset, this.callback);
                this.$emit('success', asset, this.callback);
                return this;
            },

            /**
             * Cancel handler for promise from asset create form
             */
            onCancelAssetCreate() {
                this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_HIDE);
                this.hide();
                this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_CANCEL, this.callback);
                this.$emit('cancel');
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
