import { trans } from '@/Utility/Helpers';

export default class HotspotStyleType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Check for mandatory properties:
        if (typeof attributes.title !== 'string' || attributes.title.length === 0)
        {
            console.warn('HotspotStyleType->constructor(): Invalid data.', attributes);
            throw new TypeError('HotspotStyleType->constructor(): Property "title" has to be set on HotspotStyleType.');
        }
        if (typeof attributes.type !== 'string' || HotspotStyleType.isValidType(attributes.type) === false)
        {
            console.warn('HotspotStyleType->constructor(): Invalid data.', attributes);
            throw new TypeError('HotspotStyleType->constructor(): Property "type" has to be set on HotspotStyleType. Must be a valid type for hotspots icons from HotspotStyleType class.');
        }

        // Populate the model:
        this.enabled = (typeof attributes.enabled === 'boolean') ? attributes.enabled : true;   // Enabled state
        this.title = attributes.title;                                          // Translated title of this Hotspot (e.g. 'Fire Hotspot')
        this.type = attributes.type;                                            // Type identifier
        this.description = attributes.description || null;                      // Translated description of this Hotspot
        this.icon = attributes.icon || null;                                    // SVG icon identifier
        this.preview = attributes.preview || null;                              // Preview image file name (JPG file name without extension, located in /public/images/preview/)
    }

    /**
     * HotspotStyleType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {HotspotStyleType}
     */
    static get Default()                    { return StaticHotspotStyleTypes.Generic; }
    static get Generic()                    { return StaticHotspotStyleTypes.Generic; }
    static get Info()                       { return StaticHotspotStyleTypes.Info; }
    static get Small()                      { return StaticHotspotStyleTypes.Small; }
    static get Transparent()                { return StaticHotspotStyleTypes.Transparent; }
    static get TransparentCircle()          { return StaticHotspotStyleTypes.TransparentCircle; }
    static get TransparentCube()            { return StaticHotspotStyleTypes.TransparentCube; }
    static get TransparentRectangleDoor()   { return StaticHotspotStyleTypes.TransparentRectangleDoor; }
    static get TransparentRectangleLong()   { return StaticHotspotStyleTypes.TransparentRectangleLong; }
    static get TransparentRectangleShort()  { return StaticHotspotStyleTypes.TransparentRectangleShort; }
    static get TransparentRectangleThin()   { return StaticHotspotStyleTypes.TransparentRectangleThin; }
    static get Warning()                    { return StaticHotspotStyleTypes.Warning; }

    /**
     * Get all types as an array
     *
     * @returns {Array<HotspotStyleType>}
     */
    static get all()
    {
        return Object.values(StaticHotspotStyleTypes);
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in HotspotStyleTypes)
        {
            if (HotspotStyleTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }
}

/**
 * Type definitions
 */
const StaticHotspotStyleTypes = {};
const HotspotStyleTypes = {

    Generic: {
        type: 'generic',
        title: trans('hotspoticons.generic.title'),
        description: trans('hotspoticons.generic.description'),
        icon: 'icon_hotspot-default',
        preview: 'hotspot_generic'
    },

    Info: {
        type: 'info',
        title: trans('hotspoticons.info.title'),
        description: trans('hotspoticons.info.description'),
        icon: 'icon_hotspot-info',
        preview: 'hotspot_info'
    },

    Small: {
        type: 'small',
        title: trans('hotspoticons.small.title'),
        description: trans('hotspoticons.small.description'),
        icon: 'icon_hotspot-small',
        preview: 'hotspot_small'
    },

    Transparent: {
        type: 'transparent',
        title: trans('hotspoticons.transparent.title'),
        description: trans('hotspoticons.transparent.description'),
        icon: 'icon_hotspot-transparent',
        preview: 'hotspot_transparent'
    },

    TransparentCircle: {
        type: 'transparent_circle',
        title: trans('hotspoticons.transparent_circle.title'),
        description: trans('hotspoticons.transparent_circle.description'),
        icon: 'icon_transparent-circle',
        preview: 'hotspot_transparent_circle'
    },

    TransparentCube: {
        type: 'transparent_cube',
        title: trans('hotspoticons.transparent_cube.title'),
        description: trans('hotspoticons.transparent_cube.description'),
        icon: 'icon_transparent-cube',
        preview: 'hotspot_transparent_cube'
    },

    TransparentRectangleDoor: {
        type: 'transparent_rect_door',
        title: trans('hotspoticons.transparent_rect_door.title'),
        description: trans('hotspoticons.transparent_rect_door.description'),
        icon: 'icon_transparent-rect-door',
        preview: 'hotspot_transparent_rect_door'
    },

    TransparentRectangleLong: {
        type: 'transparent_rect_long',
        title: trans('hotspoticons.transparent_rect_long.title'),
        description: trans('hotspoticons.transparent_rect_long.description'),
        icon: 'icon_transparent-rect-long',
        preview: 'hotspot_transparent_rect_long'
    },

    TransparentRectangleShort: {
        type: 'transparent_rect_short',
        title: trans('hotspoticons.transparent_rect_short.title'),
        description: trans('hotspoticons.transparent_rect_short.description'),
        icon: 'icon_transparent-rect-short',
        preview: 'hotspot_transparent_rect_short'
    },

    TransparentRectangleThin: {
        type: 'transparent_rect_thin',
        title: trans('hotspoticons.transparent_rect_thin.title'),
        description: trans('hotspoticons.transparent_rect_thin.description'),
        icon: 'icon_transparent-rect-thin',
        preview: 'hotspot_transparent_rect_thin'
    },

    Warning: {
        type: 'warning',
        title: trans('hotspoticons.warning.title'),
        description: trans('hotspoticons.warning.description'),
        icon: 'icon_hotspot-warning',
        preview: 'hotspot_warning'
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in HotspotStyleTypes)
{
    StaticHotspotStyleTypes[i] = new HotspotStyleType(HotspotStyleTypes[i]);
}
