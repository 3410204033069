import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route} from '@/Utility/Helpers';
import TenantWithDetails from '@/Models/Tenant/TenantWithDetails';
import Tenant from "@/Models/Tenant/Tenant";
import User from "@/Models/User/User";

export type TenantForm = {
    name: string,
    hubspot_company_id: string | null,
    logo: File | null,
    initialLogoUrl: string | null,
};

export default class TenantService {

    public tenants: Array<Tenant> = [];
    public isLoading: Boolean = false;
    public isSaving: Boolean = false;
    public isDeleting: Boolean = false;
    private request: AxiosRequest | null = null;

    /**
     * Cancel any ongoing requests.
     */
    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        return this.request?.cancel();
    }

    /**
     * Fetch all tenants for the current user from API
     *
     * @async
     * @param {User} user user to fetch tenants for; defaults to current user
     * @returns {Promise}
     */
    async fetchTenants(user: User | null = null): Promise<Array<Tenant>> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        user = user || window.currentUser!;

        this.isLoading = true;
        this.request = new AxiosRequest();

        const indexRoute = route('api.users.tenants.index', {'user': user.uid});

        return this.request
            .get(indexRoute)
            .then(({data}) => {
                this.tenants = [];
                data.forEach(tenantData => {
                    try {
                        this.tenants.push(new TenantWithDetails(tenantData));
                    } catch (ex) {
                        console.warn('TenantService->fetchTenants(): Skipping tenant with invalid or incompatible data.', tenantData, ex);
                    }
                });
                return this.tenants;
            })
            .catch(error => {
                console.error('TenantService->fetchTenants():', error);
                throw error;
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Switches current user to work in the given tenant.
     *
     * CAUTION: window.currentUser is out of date after calling this functions.
     * Permissions might be wrong for the new tenant!
     */
    async switchTenant(tenant: Tenant): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        // TODO: This change in currentUser does not reflect to Vue components. How to fix this elegantly?
        const oldTenant = window.currentUser!.tenant;
        window.currentUser!.tenant = tenant;

        const switchTenantRoute = route('user.tenants.current.put');

        return this.request
            .put(switchTenantRoute)
            .catch(error => {
                window.currentUser!.tenant = oldTenant;
                console.error('TenantService->switchTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    async createTenant(data: TenantForm): Promise<Tenant> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const createTenantRoute = route('api.tenants.create');

        const shallowFormData = this.toFormData(data);
        const formData = new FormData();

        // nest inside tenant node
        shallowFormData.forEach((value, key) => {
            formData.set(`tenant[${key}]`, value);
        });

        return this.request
            .post(createTenantRoute, formData)
            .then(({data}) => {
                return new Tenant(data.tenant);
            })
            .catch(error => {
                console.error('TenantService->createTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    async updateTenant(uid: string, data: TenantForm): Promise<Tenant> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;
        this.request = new AxiosRequest();

        const updateTenantRoute = route('api.tenants.update', {tenant: uid});

        const formData = this.toFormData(data);
        // change method to support file uploads
        formData.append('_method', 'PATCH');

        return this.request
            .post(updateTenantRoute, formData)
            .then(({data}) => {
                return new Tenant(data.data);
            })
            .catch(error => {
                console.error('TenantService->updateTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isSaving = false;
                this.request = null;
            });
    }

    /**
     * Deletes the given tenant permanently.
     */
    async deleteTenant(tenant: Tenant): Promise<any> {
        if (this.isLoading || this.request?.isBusy) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isDeleting = true;
        this.request = new AxiosRequest();

        const deleteTenantRoute = route('api.tenants.delete', {tenant: tenant.uid});

        return this.request
            .delete(deleteTenantRoute)
            .catch(error => {
                console.error('TenantService->deleteTenant():', error);
                throw error;
            })
            .finally(() => {
                this.isDeleting = false;
                this.request = null;
            });
    }

    private toFormData(data: TenantForm): FormData {
        const formData = new FormData();
        formData.set('name', data.name);

        if (data.hubspot_company_id) {
            formData.set('hubspot_company_id', data.hubspot_company_id);
        }
        if (data.logo) {
            formData.set('logo', data.logo, data.logo.name);
        }
        return formData;
    }
}
