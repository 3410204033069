<template :data-loading="isLoading">
    <div class="learning-records-table">
        <div v-if="!isLoading" class="learning-records-filters">

            <RevealButton
                :caption="trans('labels.units')"
                icon="icon_units"
                :active="unitSelectionHasChanged"
            >
                <template #body>
                    <div
                        class="learning-records-reveal-button-scroll-wrapper"
                        style="white-space:nowrap;"
                    >
                        <Checkbox
                            v-for="(unit, index) in this.units"
                            class="no-wrap"
                            :key="'unit_' + this.units[index]['uid'] + '_' + enabledUnits.length"
                            :model="this.units[index]"
                            :property="'visible'"
                            layout="checkbox-left"
                            @change="applyFilters"
                            :caption="unit['title']"
                            @click.stop
                        />
                    </div>
                    <div class="learning-records-reveal-button-footer">
                        <ButtonSecondary
                            v-if="unitSelectionHasChanged"
                            caption="Select All"
                            @trigger="selectAllUnits"
                        />
                        <ButtonSecondary
                            v-else
                            caption="Deselect All"
                            @trigger="deselectAllUnits"
                        />
                    </div>

                </template>
            </RevealButton>

            <RevealButton
                :caption="trans('labels.users')"
                icon="icon_users"
                :active="userSelectionHasChanged"
            >
                <template #body>
                    <div
                        class="learning-records-reveal-button-scroll-wrapper"
                        style="white-space:nowrap;"
                    >
                        <Checkbox
                            v-for="(user, index) in this.users"
                            class="no-wrap"
                            :key="'unit_' + this.users[index]['uid'] + '_' + enabledUsers.length"
                            :model="this.users[index]"
                            :property="'selected'"
                            layout="checkbox-left"
                            @change="applyFilters"
                            :caption="user['firstname'] + ' ' + user['lastname'] + ' - ' + user['email']"
                            @click.stop
                        />
                    </div>
                    <div class="learning-records-reveal-button-footer">
                        <ButtonSecondary
                            v-if="userSelectionHasChanged"
                            caption="Select All"
                            @trigger="selectAllUsers"
                        />
                        <ButtonSecondary
                            v-else
                            caption="Deselect All"
                            @trigger="deselectAllUsers"
                        />
                    </div>
                </template>
            </RevealButton>

            <RevealButton
                :caption="trans('labels.created_at')"
                icon="icon_time"
                :active="createdAtSelectionHasChanged"
            >
                <template #body>
                    <div class="date-picker-wrapper has-label no-wrap">
                        <label>{{ trans('learning_records.index.labels.from') }}</label>
                        <input type="date" v-model="filters.created_at.from" @change="applyFilters">
                    </div>

                    <div class="date-picker-wrapper has-label no-wrap">
                        <label>{{ trans('learning_records.index.labels.until') }}</label>
                        <input type="date" v-model="filters.created_at.until" @change="applyFilters">
                    </div>
                </template>
            </RevealButton>


            <RevealButton
                :caption="trans('learning_records.index.labels.experience')"
                :active="experienceSelectionHasChanged"
            >
                <template #body>
                    <div class="date-picker-wrapper has-label no-wrap">
                        <label>{{ trans('learning_records.index.labels.from') }}</label>
                        <input type="date" v-model="filters.experience.timestamp.from" @change="applyFilters">
                    </div>

                    <div class="date-picker-wrapper has-label no-wrap">
                        <label>{{ trans('learning_records.index.labels.until') }}</label>
                        <input type="date" v-model="filters.experience.timestamp.until" @change="applyFilters">
                    </div>

                    <Dropdown
                        class="no-wrap"
                        :initial-value="filters.experience.verb"
                        :options="getOptionsForVerbDropdown"
                        :label="trans('learning_records.index.labels.verb')"
                        @select="onSelectVerb"
                        @click.stop
                    />

                    <Dropdown
                        class="no-wrap"
                        :initial-value="filters.experience.object"
                        :options="getOptionsForObjectDropdown"
                        :label="trans('learning_records.index.labels.object')"
                        @select="onSelectObject"
                        @click.stop
                    />

                    <Dropdown
                        class="no-wrap"
                        :initial-value="filters.experience.result.completion"
                        @select="onSelectExperienceResultCompletion"
                        :options="getOptionsForExperienceResultCompletionDropdown"
                        :label="trans('learning_records.index.labels.completion')"
                        @click.stop
                    />

                    <Dropdown
                        class="no-wrap"
                        :initial-value="filters.experience.result.success"
                        @select="onSelectExperienceResultSuccess"
                        :options="getOptionsForExperienceResultCompletionDropdown"
                        :label="trans('learning_records.index.labels.success')"
                        @click.stop
                    />

                    <div class="text-input-wrapper">
                        <TextInput
                            class="no-wrap"
                            :key="'unit.result.response'"
                            :model="filters.experience.result"
                            property="response"
                            name="experience_result_response"
                            :maxlength="100"
                            :label="trans('learning_records.index.labels.response')"
                            @change="applyFilters"
                            :placeholder="trans('learning_records.index.labels.response')"
                        />
                    </div>

                    <div class="text-input-wrapper">
                        <TextInput
                            class="no-wrap"
                            :key="'unit.experience.context.registration'"
                            :model="filters.experience.context"
                            property="registration"
                            name="experience_registration"
                            :maxlength="50"
                            :label="trans('learning_records.index.labels.registration')"
                            @change="applyFilters"
                            :placeholder="trans('learning_records.index.labels.registration')"
                        />
                    </div>

                    <div class="text-input-wrapper">
                        <TextInput
                            class="no-wrap"
                            :key="'unit.experience.context.revision'"
                            :model="filters.experience.context"
                            property="revision"
                            name="experience_revision"
                            :maxlength="50"
                            :label="trans('learning_records.index.labels.revision')"
                            @change="applyFilters"
                            :placeholder="trans('learning_records.index.labels.revision')"
                        />
                    </div>

                </template>

            </RevealButton>

            <ButtonSecondary
                :caption="trans('learning_records.index.labels.clear_all')"
                @trigger="onClickReset"
                :stop-propagation="false"
            />

            <RevealButton
                :caption="trans('learning_records.index.labels.columns')"
                class="margin-left-auto"
            >
                <template #body>
                    <div class="learning-records-reveal-button-scroll-wrapper">
                        <Checkbox
                            v-for="column in Object.keys(columnsToShow)"
                            :key="'columns_' + column + '_' + columnsToShow[column]"
                            class="no-wrap"
                            :model="columnsToShow"
                            :property="column"
                            layout="checkbox-left"
                            @change="applyFilters"
                            :caption="column"
                            @click.stop
                        />
                    </div>
                    <div class="learning-records-reveal-button-footer">
                        <ButtonSecondary
                            v-if="enabledColumns.length !== Object.keys(columnsToShow).length"
                            caption="Select All"
                            @trigger="selectAllColumns"
                        />
                        <ButtonSecondary
                            v-else
                            caption="Deselect All"
                            @trigger="deselectAllColumns"
                        />
                    </div>
                </template>
            </RevealButton>

            <RevealButton
                :caption="trans('learning_records.index.labels.sorting')"
            >
                <template #body>
                    <Dropdown
                        class="no-wrap white-space-no-wrap"
                        :initial-value="this.filters.sorting.column"
                        @select="onSelectSortColumn"
                        :options="getOptionsForSortColumnDropdown"
                        :label="trans('learning_records.index.labels.sort_by')"
                        @click.stop
                    />
                    <Dropdown
                        class="no-wrap white-space-no-wrap"
                        :initial-value="this.filters.sorting.descending"
                        @select="onSelectSortOrder"
                        :options="getOptionsForSortOrderDropdown"
                        :label="trans('learning_records.index.labels.order')"
                        @click.stop
                    />
                </template>
            </RevealButton>

            <ButtonPrimary
                v-if="canDownload"
                caption="labels.download"
                icon="icon_download"
                @trigger="onClickDownload"
                :disabled="!canExportLearningRecords"
            />

        </div>

        <div
            v-if="enabledColumns.length > 0 && !isLoading"
            class="export-table-wrapper"
        >
            <div class="grid-table" :style="gridTemplateStyle" :key="'table_'+enabledColumns.length+'_'+enabledUnits.length+'_'+key">
                <div :key="'header_enabled_columns_'+enabledColumns.length">
                    <div v-for="column in enabledColumns" :title="column">{{ column }}</div>
                </div>
                <div
                    v-for="record in records"
                    :key="key + '_' + record.uid + '_' + enabledColumns.length"
                    :title="record[column]"
                >
                    <div v-for="column in enabledColumns">{{ record[column] }}</div>
                </div>
            </div>
        </div>

        <div v-if="isLoading" class="loader centered">
            <LoadingIndicator/>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash';
    import TextInput from "@/Vue/Common/TextInput.vue";
    import PageHeader from "@/Vue/Common/PageHeader.vue";
    import ButtonPrimary from "@/Vue/Common/ButtonPrimary.vue";
    import {shortId, sortArrayByProperty, trans} from "@/Utility/Helpers";
    import UserFormTextInput from "@/Vue/Users/UserFormTextInput.vue";
    import Checkbox from "@/Vue/Common/Checkbox.vue";
    import Collapsible from "@/Vue/Common/Collapsible.vue";
    import DropdownOption from "@/Utility/DropdownOption";
    import Dropdown from "@/Vue/Common/Dropdown.vue";
    import KeyboardKey from "@/Utility/KeyboardKey";
    import RevealButton from "@/Vue/Common/RevealButton.vue";
    import ButtonSecondary from "@/Vue/Common/ButtonSecondary.vue";
    import {inject} from "vue";
    import {learningRecordsServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
    import {Permission} from "@/Models/User/Permission";

    export default {
        name: "LearningRecordsTable",
        components: {
            LoadingIndicator,
            RevealButton,
            ButtonPrimary,
            ButtonSecondary,
            Checkbox,
            Collapsible,
            Dropdown,
            PageHeader,
            TextInput,
            UserFormTextInput,
        },
        props: {
            //
        },

        data() {
            return {
                data: [],
                learningRecordsService: inject(learningRecordsServiceKey),
                /**
                 * @type {AbortController|null}
                 */
                fetchRecordsAbortController: null,
                key: shortId(),
                defaultFilters: {},
                filters: {
                    created_at: {
                        from: moment('2023-01-01').format('YYYY-MM-DD').toString(),
                        until: moment().add(1, 'days').format('YYYY-MM-DD').toString(),
                    },
                    experience: {
                        timestamp: {
                            from: moment('2023-01-01').format('YYYY-MM-DD').toString(),
                            until: moment().add(1, 'days').format('YYYY-MM-DD').toString(),
                        },
                        verb: '',
                        object: '',
                        result: {
                            completion: '',
                            success: '',
                            response: '',
                        },
                        context: {
                            registration: '',
                            revision: '',
                        },
                    },
                    sorting: {
                        column: 'created_at',
                        descending: true,
                    },
                },
                filteredData: [],
                columnsToShow: {
                    'uid': true,
                    'tenant.uid': false,
                    'tenant.name': false,
                    'unit.uid': true,
                    'unit.title': true,
                    'user.uid': true,
                    'user.firstname': false,
                    'user.lastname': false,
                    'user.email': true,
                    'experience.verb': true,
                    'experience.object': true,
                    'experience.result.completion': false,
                    'experience.result.success': false,
                    'experience.result.response': false,
                    'experience.result.duration': false,
                    'experience.result.duration_in_seconds': false,
                    'experience.result.score.min': false,
                    'experience.result.score.max': false,
                    'experience.result.score.scaled': false,
                    'experience.result.score.raw': false,
                    'experience.context.registration': false,
                    'experience.context.revision': false,
                    'experience.timestamp': true,
                    'created_at': true,
                },

                units: [],

                users: [],

                restrictedKeys: {
                    'numberInput': [
                        ...KeyboardKey.KeySets.Digits,
                        ...KeyboardKey.KeySets.Controls,
                        KeyboardKey.findByCode('Slash'),
                        KeyboardKey.findByCode('NumpadSubtract'),
                        KeyboardKey.findByCode('Period')
                    ]
                },
            }
        },

        mounted() {

            if (this.learningRecordsService.learningRecords === null) {
                this.fetchRecordsAbortController = new AbortController();
                this.learningRecordsService
                    .index(this.fetchRecordsAbortController.signal)
                    .then((learningRecords) => {
                        this.data = learningRecords.records;
                        this.units = this.getUnitsFromData();
                        this.users = this.getUsersFromData();
                        this.defaultFilters = JSON.parse(JSON.stringify(this.filters));

                        this.applyFilters();
                    })
                    .catch(() => {
                        if (this.fetchRecordsAbortController?.signal?.aborted === true) {
                            return;
                        }
                        this.$root?.showErrorDialog(trans('errors.server_communication_failed'));
                    });
            } else {
                this.data = this.learningRecordsService.learningRecords.records;
                this.units = this.getUnitsFromData();
                this.users = this.getUsersFromData();
                this.defaultFilters = JSON.parse(JSON.stringify(this.filters));

                this.applyFilters();
            }

        },

        unmounted() {
            this.fetchRecordsAbortController?.abort();
        },

        computed: {

            canDownload() {
                return this.$gate.allows(Permission.ExperiencesExport());
            },

            gridTemplateStyle() {
                return 'grid-template-columns: repeat(' + this.enabledColumns.length + ', minmax(120px, 1fr))';
            },

            isLoading() {
                return this.learningRecordsService.isLoading;
            },

            timestampFromDate() {
                return this.filters.experience.timestamp.from !== null ? new Date(this.filters.experience.timestamp.from) : null;
            },

            timestampUntilDate() {
                return this.filters.experience.timestamp.until !== null ? new Date(this.filters.experience.timestamp.until) : null;
            },

            createdAtFromDate() {
                return this.filters.created_at.from !== null ? new Date(this.filters.created_at.from) : null;
            },

            createdAtUntilDate() {
                return this.filters.created_at.until !== null ? new Date(this.filters.created_at.until) : null;
            },

            records() {
                return sortArrayByProperty(this.filteredData, this.filters.sorting.column, this.filters.sorting.descending);
            },

            /**
             * Get dropdown options for sort column
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForSortColumnDropdown() {
                return Object.keys(this.columnsToShow)
                    .map(c => new DropdownOption({
                        caption: c,
                        value: c,
                    }));
            },

            /**
             * Get dropdown options for completion column
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForExperienceResultCompletionDropdown() {
                return [
                    new DropdownOption({
                        caption: '---',
                        value: '',
                    }),
                    new DropdownOption({
                        caption: 'true',
                        value: 'true',
                    }),
                    new DropdownOption({
                        caption: 'false',
                        value: 'false',
                    })
                ];
            },

            /**
             * Get dropdown options for sort order
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForSortOrderDropdown() {
                return [
                    new DropdownOption({
                        caption: 'descending',
                        value: true,
                    }),
                    new DropdownOption({
                        caption: 'ascending',
                        value: false,
                    })
                ];
            },

            canExportLearningRecords() {
                return (
                    this.enabledColumns.length > 0
                    && this.records.length > 0
                );
            },

            enabledColumns() {
                return Object.keys(_.flow([
                    Object.entries,
                    arr => arr.filter(([key, value]) => value),
                    Object.fromEntries
                ])(this.columnsToShow));
            },

            enabledUnits() {
                return this.units.filter(unit => unit.visible).map(unit => unit.uid);
            },

            unitCountString() {
                return '(' + String(this.enabledUnits.length).padStart(String(this.units.length).length, '0')  + '/' + this.units.length + ')';
            },

            enabledUsers() {
                return this.users.filter(user => user.selected).map(user => user.uid);
            },

            userCountString() {
                return '(' + String(this.enabledUsers.length).padStart(String(this.users.length).length, '0') + '/' + this.users.length + ')';
            },

            verbs() {
                const unfilteredVerbs = this.data.map((value) => {
                    return {
                        'id': value['experience.verb'],
                        'display': value['experience.verb.display'],
                    }
                });

                return sortArrayByProperty(_.uniqWith(unfilteredVerbs, _.isEqual), 'display');
            },

            objects() {
                const unfilteredObjects = this.data.map((value) => {
                    return {
                        'id': value['experience.object'],
                        'name': value['experience.object.definition.name'],
                    }
                });

                return sortArrayByProperty(_.uniqWith(unfilteredObjects, _.isEqual), 'name');
            },

            /**
             * Get dropdown options for completion column
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForVerbDropdown() {
                return [
                    new DropdownOption({
                        caption: '---',
                        value: '',
                    }),
                    ...this.verbs.map((value) => {
                        return new DropdownOption({
                            caption: value.display ? value.display + ' - ' + value.id : value.id,
                            value: value.id,
                        })
                    })
                ];
            },

            /**
             * Get dropdown options for completion column
             *
             * @returns {Array<DropdownOption>}
             */
            getOptionsForObjectDropdown() {
                return [
                    new DropdownOption({
                        caption: '---',
                        value: '',
                    }),
                    ...this.objects.map((value) => {
                        return new DropdownOption({
                            caption: value.name ? value.name + ' - ' + value.id : value.id,
                            value: value.id,
                        })
                    })
                ];
            },

            unitSelectionHasChanged() {
                return this.enabledUnits.length !== this.units.length;
            },

            userSelectionHasChanged() {
                return this.enabledUsers.length !== this.users.length;
            },

            createdAtSelectionHasChanged() {
                return !_.isEqual(this.filters.created_at, this.defaultFilters.created_at);
            },

            experienceSelectionHasChanged() {
                return !_.isEqual(this.filters.experience, this.defaultFilters.experience);
            },
        },

        methods: {

            onClickReset() {
                this.filters = Object.assign({}, JSON.parse(JSON.stringify(this.defaultFilters)));

                this.selectAllUnits();
                this.selectAllUsers();
            },

            getUnitsFromData() {
                const unfilteredUnits = this.data.map((value) => {
                    return {
                        'uid': value['unit.uid'],
                        'title': value['unit.title'],
                        'visible': true,
                    }
                });

                return sortArrayByProperty(_.uniqWith(unfilteredUnits, _.isEqual), 'title');
            },

            getUsersFromData() {
                const unfilteredUsers = this.data.map((value) => {
                    return {
                        'uid': value['user.uid'],
                        'firstname': value['user.firstname'],
                        'lastname': value['user.lastname'],
                        'email': value['user.email'],
                        'selected': true,
                    }
                });

                return sortArrayByProperty(_.uniqWith(unfilteredUsers, _.isEqual), 'lastname');
            },

            applyFilters() {
                this.filteredData = this.data.filter(record => {
                    const timestamp = new Date(record['experience.timestamp']);

                    // Record
                    const isCreatedAtInDateRange = (timestamp >= this.createdAtFromDate && timestamp <= this.createdAtUntilDate);

                    // Experience
                    const isExperienceTimestampInDateRange = (timestamp >= this.timestampFromDate && timestamp <= this.timestampUntilDate);
                    const isIncludedInExperienceResultCompletion = (
                        ["true", "false"].includes(this.filters.experience.result.completion) === false
                        || (
                            typeof record['experience.result.completion'] === 'boolean'
                            && record['experience.result.completion'].toString() === this.filters.experience.result.completion
                        )
                    );
                    const isIncludedInExperienceResultSuccess = (
                        ["true", "false"].includes(this.filters.experience.result.success) === false
                        || (
                            typeof record['experience.result.success'] === 'boolean'
                            && record['experience.result.success'].toString() === this.filters.experience.result.success
                        )
                    );

                    const isIncludedInExperienceResultResponse = (
                        (
                            record['experience.result.response'] === null
                            && this.filters.experience.result.response.toLowerCase().trim().length === 0
                        )
                        || (
                            typeof record['experience.result.response'] === 'string'
                            && record['experience.result.response'].toLowerCase().includes(this.filters.experience.result.response.toLowerCase().trim())
                        )
                    );

                    const isIncludedInExperienceVerb = (
                        record['experience.verb'].toLowerCase() === this.filters.experience.verb.toLowerCase().trim()
                        || this.filters.experience.verb.toLowerCase().trim() === ''
                    );
                    const isIncludedInExperienceObject = (
                        record['experience.object'].toLowerCase() === this.filters.experience.object.toLowerCase().trim()
                        || this.filters.experience.object.toLowerCase().trim() === ''
                    );

                    const isIncludedInExperienceContextRegistration = (
                        (
                            record['experience.context.registration'] === null
                            && this.filters.experience.context.registration.toLowerCase().trim().length === 0
                        )
                        || (
                            typeof record['experience.context.registration'] === 'string'
                            && record['experience.context.registration'].toLowerCase().includes(this.filters.experience.context.registration.toLowerCase().trim())
                        )
                    );

                    const isIncludedInExperienceContextRevision = (
                        (
                            record['experience.context.revision'] === null
                            && this.filters.experience.context.revision.toLowerCase().trim().length === 0
                        )
                        || (
                            typeof record['experience.context.revision'] === 'string'
                            && record['experience.context.revision'].toLowerCase().includes(this.filters.experience.context.revision.toLowerCase().trim())
                        )
                    );

                    // Unit
                    const isIncludedInUnitUid = this.enabledUnits.includes(record['unit.uid'].toLowerCase());

                    // User
                    const isIncludedInUserUid = this.enabledUsers.includes(record['user.uid'].toLowerCase());

                    return (
                        isCreatedAtInDateRange
                        && isExperienceTimestampInDateRange
                        && isIncludedInExperienceResultCompletion
                        && isIncludedInExperienceResultSuccess
                        && isIncludedInExperienceResultResponse
                        && isIncludedInExperienceVerb
                        && isIncludedInExperienceObject
                        && isIncludedInExperienceContextRegistration
                        && isIncludedInExperienceContextRevision
                        && isIncludedInUnitUid
                        && isIncludedInUserUid
                    );
                })

                this.key = shortId();
            },

            onClickCopyToClipboard() {
                navigator.clipboard.writeText(this.getFilteredCSV());
            },

            onClickDownload() {
                const downloadLink = document.createElement("a");
                downloadLink.href = "data:text/csv;charset=utf-8," + escape(this.getFilteredCSV());
                downloadLink.download = "learning_records.csv";

                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            },

            getFilteredCSV() {
                return Papa.unparse(this.records, {
                    quotes: true, //or array of booleans
                    quoteChar: '"',
                    escapeChar: '"',
                    delimiter: ";",
                    header: true,
                    newline: "\r\n",
                    skipEmptyLines: false, //other option is 'greedy', meaning skip delimiters, quotes, and whitespace.
                    columns: this.enabledColumns //or null or array of strings
                });
            },

            selectAllUnits(){
                this.units.forEach(unit => {
                    unit.visible = true;
                })

                this.applyFilters();
            },

            deselectAllUnits(){
                this.units.forEach(unit => {
                    unit.visible = false;
                })

                this.applyFilters();
            },

            selectAllUsers(){
                this.users.forEach(user => {
                    user.selected = true;
                })

                this.applyFilters();
            },

            deselectAllUsers(){
                this.users.forEach(user => {
                    user.selected = false;
                })

                this.applyFilters();
            },

            selectAllColumns(){
                Object.keys(this.columnsToShow).forEach(column => {
                    this.columnsToShow[column] = true;
                });

                this.applyFilters();
            },

            deselectAllColumns(){
                Object.keys(this.columnsToShow).forEach(column => {
                    this.columnsToShow[column] = false;
                });

                this.applyFilters();
            },

            onSelectVerb(value) {
                this.filters.experience.verb = value ?? '';
                this.applyFilters();
                return this;
            },

            onSelectObject(value) {
                this.filters.experience.object = value ?? '';
                this.applyFilters();
                return this;
            },

            onSelectExperienceResultCompletion(value) {
                this.filters.experience.result.completion = value;
                this.applyFilters();
                return this;
            },

            onSelectExperienceResultSuccess(value) {
                this.filters.experience.result.success = value;
                this.applyFilters();
                return this;
            },

            onSelectSortColumn(value) {
                this.filters.sorting.column = value;
                return this;
            },

            onSelectSortOrder(value) {
                this.filters.sorting.descending = value;
                return this;
            },
        },
    }
</script>

<style lang="scss">

    .learning-records-table {

        .centered {
            position: absolute;
            left: 50%;
            top: calc(50% + var(--header-height));
            transform: translate(-50%, -50%);
        }

        .loader {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        .learning-records-reveal-button-scroll-wrapper {
            overflow-y: auto;
            max-height: 500px;
            padding-right: 40px;
            margin-right: -20px;
        }

        .learning-records-reveal-button-footer {
            margin-top: 20px;
            display: flex;
        }

        .learning-records-filters {
            display: flex;
            gap: 24px;
            align-items: flex-start;
            margin-bottom: 24px;
            flex-wrap: wrap;

            .margin-left-auto {
                margin-left: auto;
            }

            .white-space-no-wrap {
                white-space: nowrap;
            }

            .date-picker-wrapper {
                display: flex;
                gap: 12px;
                align-items: baseline;

                &:not(:last-child, :only-child) {
                    margin-bottom: var(--forminput-spacing);
                }

                label {
                    flex-grow: 1;
                    flex-basis: 60px;
                }

                input[type="date"] {
                    margin-right: auto;
                }
            }

            .text-input-wrapper {
                max-width: 500px;
                width: 310px;
                margin-bottom: var(--forminput-spacing);
            }

            .date-picker-wrapper.has-label.no-wrap,
            .textinput.type-text.has-label.no-wrap,
            .dropdown-element.has-label.no-wrap {
                display: flex;
                width: 100%;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 12px;

                > label {
                    flex-grow: 1;
                    flex-basis: 60px;
                    margin: 0;
                    padding: 0;
                    font-family: inherit;
                }

                > .dropdown,
                > .textinput,
                > input {
                    flex-grow: 2;
                    flex-basis: 200px;
                    width: 150px;
                }

                > .tags {
                    flex-basis: 100%;
                    margin: 0;
                }

                > .error-msg {
                    flex-basis: 100%;
                    padding: 0;
                }
            }
        }

        .export-table-wrapper {
            overflow-x: auto;
            width: 100%;

            .grid-table {
                display: grid;
                row-gap: 4px;

                & > div {
                    display: contents;

                    &:first-child > div {
                        background-color: var(--color-anthracite);
                        color: white;
                    }

                    & > div {
                        background-color: var(--color-white);
                        padding: 10px;
                        overflow-wrap: break-word;

                        &:first-child {
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                        }

                        &:last-child {
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
            }
        }
    }

</style>
