import {Euler, Quaternion, Vector3} from "three";
import {degToRad, radToDeg} from "three/src/math/MathUtils";

// noinspection PointlessArithmeticExpressionJS
export default class Rotation {
    x: number;
    y: number;
    z: number;
    w: number;

    constructor({x = 0, y = 0, z = 0, w = 1} = {}) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.w = w;
    }

    get isDefault(): boolean {
        return this.x === 0
            && this.y === 0
            && this.z === 0
            && this.w === 1;
    }

    isEqualTo(rotation: Rotation): boolean {
        return this.x === rotation.x
            && this.y === rotation.y
            && this.z === rotation.z
            && this.w === rotation.w;
    }

    /**
     * @return Vector3 Rotation in euler angles (in degrees) as displayed in the Unity editor
     * (left-handed coordinate system, with ZXY rotation order).
     */
    get unityVector3(): Vector3 {
        // fix difference in handedness of coordinate systems between unity and three.js by inverting "w"
        const quaternion = new Quaternion(this.x, this.y, this.z, -this.w);

        // convert to euler in Unity rotation order "ZXY"
        const euler = new Euler().setFromQuaternion(quaternion, "ZXY");

        // convert radians to degrees
        // minus sign is needed to fix difference in handedness of coordinate systems between unity and three.js
        // "+0" is to fix -0 results
        euler.x = -radToDeg(euler.x) + 0;
        euler.y = -radToDeg(euler.y) + 0;
        euler.z = -radToDeg(euler.z) + 0;

        return new Vector3(euler.x, euler.y, euler.z);
    }

    set unityVector3(unityEuler: Vector3) {
        // convert back to radians
        // minus sign is needed to fix difference in handedness of coordinate systems between three.js and unity
        const x = degToRad(-unityEuler.x);
        const y = degToRad(-unityEuler.y);
        const z = degToRad(-unityEuler.z);

        // convert to quaternion by using correct Unity rotation order "ZXY"
        const euler = new Euler(x, y, z, "ZXY");
        const quaternion = new Quaternion().setFromEuler(euler);

        // fix difference in handedness of coordinate systems between unity and three.js by inverting
        this.x = -quaternion.x;
        this.y = -quaternion.y;
        this.z = -quaternion.z;
        this.w = quaternion.w;
    }

    /**
     * @return Euler Representation of this rotation for usage in css coordinate systems
     * (left-handed, with ZYX rotation order, Y-Axis pointing down).
     */
    get cssEuler(): Euler {
        // fix difference in handedness of coordinate systems between unity and three.js by inverting "w"
        const quaternion = new Quaternion(this.x, this.y, this.z, -this.w);

        // convert to euler in Unity rotation order "ZXY" - then convert order to css transform ("ZYX")
        const euler = new Euler().setFromQuaternion(quaternion, "ZXY").reorder("ZYX");

        // why is this needed?
        euler.x = -euler.x;

        return euler;
    }

    /**
     * @return Quaternion Representation of this rotation for usage in three.js coordinate systems
     * (right-handed).
     */
    get threeJsQuaternion(): Quaternion {
        return new Quaternion(-this.x, -this.y, this.z, this.w);
    }

    set threeJsQuaternion(quaternion: Quaternion) {
        this.x = -quaternion.x;
        this.y = -quaternion.y;
        this.z = quaternion.z;
        this.w = quaternion.w;
    }
}
