import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, trans} from "@/Utility/Helpers";
import Course from "@/Models/Course/Course";
import PagingMetadata from "@/Models/PagingMetadata";
import CoursePage from "@/Models/Course/CoursePage";
import Unit from "@/Models/Unit/Unit";

export default class CourseService {

    static get NumberOfCoursesPerPage() {
        return 150;
    }

    /**
     * Constructor
     */
    constructor() {
        /**
         * Currently loaded course page.
         * @type {CoursePage}
         */
        this.coursePage = new CoursePage([], new PagingMetadata());

        this.isLoading = false;             // Loading state
        this.isSaving = false;              // Saving state
        this.request = null;                // The current request
    }

    /**
     * Cancel any ongoing requests
     *
     * @async
     * @returns {Promise}
     */
    async cancelRequests() {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null) {
            return await this.request.cancel();
        }
        return Promise.resolve('Requests canceled');
    }

    /**
     * Fetch all courses for the current user from API
     *
     * @async
     * @param {number} page current page to fetch; starting with 1
     * @param {object|null} filters e.g. {policy: ["standard", "sample", "template"]}
     * @param {string|null} search keywords
     * @param {string|null} orderBy e.g. "title", "created_at", "updated_at"
     * @param {boolean} descending
     * @returns {Promise<CoursePage>}
     */
    async fetchCourses(page = 1, filters = null, search = null, orderBy = null, descending = false) {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            page: page,
            per_page: CourseService.NumberOfCoursesPerPage,
            filter: filters,
            search: search,
            sort: orderBy,
        };

        if (descending) {
            params.sort_order = 'desc';
        }

        return await this.request.get(
            route('api.courses.index'),
            {
                params: params
            }
        ).then(({data}) => {
            const pagingMetadata = new PagingMetadata(data.meta);
            const courses = data.data.map(courseData => {
                try {
                    return new Course(courseData);
                } catch (ex) {
                    console.warn('CourseService->fetchCourses(): Skipping course with invalid or incompatible data.', courseData, ex);
                    return null;
                }
            }).filter(c => c instanceof Course);
            this.coursePage = new CoursePage(courses, pagingMetadata);
            return Promise.resolve(this.coursePage);
        }).finally(() => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Fetches the course with the given uid from API.
     * @param {String} uid
     * @returns {Promise<Course>}
     */
    async fetchCourse(uid) {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        return await this.request.get(route('api.courses.details', {'course': uid}))
            .then(({data}) => {
                try {
                    const course = new Course(data.data);
                    return Promise.resolve(course);
                } catch (ex) {
                    console.error('CourseService->fetchCourse(): API returned invalid or incompatible course data.', data, ex);
                    return Promise.reject(trans('errors.course.invalid_data'));
                }
            }).finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Fetches the course with the given uid from API.
     * @param {String} course_uid
     * @param {number} per_page
     * @param {number} page
     * @return {Promise<Unit[]>}
     */
    async fetchUnitsForCourse(course_uid, per_page = 15, page = 0) {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;
        this.request = new AxiosRequest();

        const params = {
            'per_page': Math.max(1, per_page),
            'page': Math.max(0, page),
        };

        return await this.request.get(route('api.courses.units', { 'course': course_uid }), { 'params': params })
            .then(({data}) => {
                try {
                    const units = data.data.map(unitData => {
                        try {
                            return new Unit(unitData);
                        } catch (ex) {
                            console.warn('CourseService->fetchUnitsForCourse(): Skipping unit with invalid or incompatible data.', unitData, ex);
                            return null;
                        }
                    }).filter(c => c instanceof Unit);
                    return Promise.resolve(units);
                } catch (ex) {
                    console.error('CourseService->fetchUnitsForCourse(): API returned invalid or incompatible units data.', data, ex);
                    return Promise.reject(trans('errors.course.invalid_data'));
                }
            }).finally(() => {
                this.isLoading = false;
                this.request = null;
            });
    }

    /**
     * Create a course through the API
     *
     * @async
     * @param {FormData} formData
     * @returns {Promise<Course>}
     */
    async createCourseFromFormData(formData) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }
        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.courses.create'),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then(({data}) => {
            try {
                const course = new Course(data.data);
                //console.info('CourseService->createCourseFromFormData(): Course created.', course, data);
                return Promise.resolve(course);
            } catch (ex) {
                console.error('CourseService->createCourseFromFormData(): API returned invalid or incompatible course data.', data, ex);
                return Promise.reject(trans('errors.course.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Save a course through the API.
     * The latest draft revision is used to pull updated properties from.
     *
     * @async
     * @param {Course} course course with its latest draft revision to save
     * @returns {Promise<Course>} saved course returned from the API
     */
    async saveCourse(course) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        // Prepare form data if a preview file should be included:
        let propertiesToUpdate = {
            ...course.updateApiProperties,
        };
        let formData = propertiesToUpdate;
        let config = {};
        let method = 'patch';
        if (course.previewImageForUpload instanceof File) {
            // Send a POST request since PATCH doesn't support files:
            formData = new FormData();
            method = 'post';
            formData.append('_method', 'PATCH');
            formData.append('course', JSON.stringify(propertiesToUpdate));
            formData.append('preview_image', course.previewImageForUpload);
            config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            };
        }

        this.request = new AxiosRequest();
        return await this.request[method](
            route('api.courses.update', {course: course.uid}),
            formData,
            config
        ).then(({data}) => {
            try {
                const course = new Course(data.data);
                return Promise.resolve(course);
            } catch (ex) {
                console.error('CourseService->saveCourse(): API returned invalid or incompatible course data.', data, ex);
                return Promise.reject(trans('errors.course.invalid_data'));
            }
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Delete a course through the API
     *
     * @async
     * @param {Course} course
     * @returns {Promise<Course>}
     */
    async deleteCourse(course) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true)
        {
            throw new ServiceIsBusyError();
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.delete(
            route('api.courses.delete', {course: course.uid})
        ).then(() => {
            return Promise.resolve(course);
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Adds and/or removes user enrollments through the API.
     * All given users must be members of the logged in user's current tenant.
     * On success the user enrollments of the given course will be updated.
     *
     * @param {Course} course
     * Course that users should be enrolled in / disenrolled from.
     * @param {Array<User>} usersToEnroll
     * List users that should be enrolled to the course.
     * Users that are already enrolled will not be added again.
     * @param {Array<User>} usersToDisenroll
     * List of users that should be disenrolled from the course.
     * Users that aren't enrolled will not throw any errors.
     * @returns {Promise<void>}
     */
    async changeUserEnrollments(course, usersToEnroll, usersToDisenroll) {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError();
        }

        const usersToEnrollUids = usersToEnroll.map(user => user.uid);
        const usersToDisenrollUids = usersToDisenroll.map(user => user.uid);

        this.isSaving = true;
        this.request = new AxiosRequest();

        return await this.request.post(
            route('api.courses.enrollments.update', {course: course.uid}),
            {
                users_to_enroll: usersToEnrollUids,
                users_to_disenroll: usersToDisenrollUids,
            }
        ).then(({ data }) => {
            // Reflect user changes in given course reference
            if (data.hasOwnProperty('enrollments')) {
                // The response might already have newer data because the users are cached on page load
                course.setEnrollments(data.enrollments);
            } else {
                // Fallback if the response does not include an updated user list
                course.addEnrollments(usersToEnroll);
                course.removeEnrollments(usersToDisenroll);
            }

            return Promise.resolve(course);
        }).finally(() => {
            this.isSaving = false;
            this.request = null;
        });
    }
}
