<template>
    <StatisticsCard class="statistics-card-most-used-units" :is-loading="isLoading">
        <h4 v-if="hasUnits">{{ trans('statistics.index.most_used_units.title') }}</h4>
        <p class="empty" v-else>
            {{ trans('statistics.index.most_used_units.empty') }}
        </p>
        <ul>
            <li v-for="unit in units">
                <Icon name="icon_units"/>
                {{ unit.title }}
            </li>
        </ul>
    </StatisticsCard>
</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import Icon from "@/Vue/Common/Icon.vue";
import {MostUsedUnitsInCourses} from "@/Models/Statistics/Statistics";
import {trans} from "@/Utility/Helpers/trans";

export default defineComponent({
    name: "StatisticsCardMostUsedUnits",

    components: {
        Icon,
        StatisticsCard
    },

    props: {
        units: {
            type: Object as PropType<Array<MostUsedUnitsInCourses>>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    computed: {
        hasUnits() {
            return this.units.length > 0;
        }
    },

    methods: {
        trans
    },
});

</script>

<style lang="scss" scoped>

.statistics-card-most-used-units {
    padding: 24px 28px;

    .empty {
        font-family: var(--font-family-condensed-demibold);
        color: var(--color-anthracite40);
    }

    ul {
        list-style-type: none;

        li {
            margin: 4px 0;
            padding: 6px 8px;

            border: var(--forminput-border);
            border-radius: 8px;

            font-family: var(--font-family-condensed);
            line-height: 20px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            .icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
        }
    }
}

</style>
