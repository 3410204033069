<template>
    <ButtonCustom
        class="btn-circular"
    />
</template>

<script>
    export default {
        name: 'ButtonCircular',
    }
</script>

<style lang="scss" scoped>

</style>
