<template>
    <div class="command-settings">
        <Dropdown
            :key="'helpertriggerinvoke'+command.target"
            :initial-value="command.value"
            @select="onSelectTriggerInvokeValue"
            :options="dropdownOptionsForTriggerInvokeValues"
            :required="true"
            :deselected-caption="trans('authoring.select_reaction')"
        />

        <Checkbox
            :key="'awaitcommandcompletion'"
            :model="command"
            property="await_completion"
            layout="checkbox-right"
            @change="onChangeCommand"
            :caption="trans('authoring.await_completion')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { getSceneObjectTargetDropdownOptionsForCommand }     from '@/Models/UnitData/Commands/CommandHelpers';
import { trans } from '@/Utility/Helpers';

// Import classes:
import DropdownOption           from '@/Utility/DropdownOption';
import {HelperTriggerInvokeCommand} from '@/Models/UnitData/Commands/Command';
import TriggerType              from '@/Models/UnitData/Triggers/TriggerType';

export default {
    name: 'PanelCommandHelperInvokeTrigger',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: HelperTriggerInvokeCommand,
            default: null
        }
    },

    beforeMount() {
        this.command.cleanUpData();
    },

    computed: {
        /**
         * Get options for trigger invoke value dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        dropdownOptionsForTriggerInvokeValues() {
            const options = [];
            const helperModule = this.command.targetObject;

            if (helperModule === null || !helperModule.hasTriggers)
            {
                return [];
            }

            // Get triggers from the first Helper module:
            helperModule.triggers.filter(t => t.typeOf(TriggerType.OnCue)).forEach(t => {
                options[options.length] = new DropdownOption({
                    caption: t.title || trans('labels.reaction'),
                    disabled: false,
                    value: t.uid
                });
            });

            return options;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

    },
    methods: {

        /**
         * Select handler for trigger invoke value dropdown
         *
         * @param {String} value
         */
        onSelectTriggerInvokeValue(value) {
            if (value !== this.command.value)
            {
                this.command.value = value;
                this.$emit('change', this.command);
            }

            return this;
        },

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>
