<template>
    <div class="container" :data-loading="isLoading">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('assets.create.headline') }}</div>

                    <div class="card-body">
                        <form ref="form" class="form-asset-create" method="post" enctype="multipart/form-data" @submit.prevent="onSubmit">

                            <!-- Asset Type -->
                            <div v-show="assetTypeOptions.length > 1" class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.asset_type') }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'assetType'+key"
                                        :model="form"
                                        property="type"
                                        name="type"
                                        :options="assetTypeOptions"
                                        :required="true"
                                        :class="(validationErrors('type').length > 0 ? ' is-invalid' : '')"
                                        @select="onTypeChanged"
                                    />

                                    <span v-if="validationErrors('type').length > 0" class="error-msg" role="alert">
                                        <strong v-html="validationErrors('type')[0]"></strong>
                                    </span>
                                </div>
                            </div>

                            <!-- Title -->
                            <UserFormTextInput
                                ref="formInputTitle"
                                :key="'title'+key"
                                :model="form"
                                property="title"
                                name="title"
                                :required="true"
                                :maxlength="50"
                                :label="trans('labels.asset_title')"
                                @change="handleTitleChanged"
                                :placeholder="trans('assets.forms.title_placeholder')"
                                :validationErrors="validationErrors('title')"
                            />

                            <!-- Voice -->
                            <div class="form-group row" v-if="isGeneratedTtsSound">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.voice')  }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'voiceType'+key"
                                        :model="form"
                                        property="ttsVoice"
                                        :options="voiceTypeOptions"
                                        :required="true"
                                        @select="onVoiceChanged"
                                    />
                                </div>
                            </div>

                            <!-- Content description -->
                            <div class="form-group row" v-if="shouldShowContentDescription">
                                <label for="create-asset-content-description" class="col-md-4 col-form-label text-md-right">{{ trans('labels.content_description') }}</label>

                                <div class="col-md-6 ">
                                    <TextInput
                                        :key="'content_description'+key"
                                        ref="createAssetContentDescription"
                                        id="create-asset-content-description"
                                        type="textarea"
                                        name="content_description"
                                        :initial-value="form.content_description"
                                        :validation-errors="validationErrors('content_description')"
                                        :maxlength="600"
                                        :required="isGeneratedTtsSound"
                                        :placeholder="trans('assets.forms.content_description_placeholder')"
                                        @change="handleContentDescriptionChanged"
                                    />
                                </div>
                            </div>

                            <!-- Sound synthesis -->
                            <div class="form-group row" v-if="isGeneratedTtsSound">
                                <div class="col-md-4"></div>
                                <div class="col-md-6">
                                    <audio
                                        ref="audio"
                                        :class="{ disabled: !form.content_description }"
                                        controls
                                        :src="ttsAudioSource"
                                        controlslist="nodownload"
                                        @contextmenu.prevent
                                        @play="synthesizeIfNeeded(true, true)"
                                    >
                                    </audio>
                                </div>
                            </div>

                            <!-- Description -->
                            <div class="form-group row">
                                <label for="create-asset-description" class="col-md-4 col-form-label text-md-right">{{ trans('labels.description') }} / {{ trans('labels.keywords') }}</label>

                                <div class="col-md-6">
                                    <TextInput
                                        :key="'description'+key"
                                        id="create-asset-description"
                                        type="textarea"
                                        name="description"
                                        :model="form"
                                        property="description"
                                        :validation-errors="validationErrors('description')"
                                        :maxlength="600"
                                        :placeholder="trans('assets.forms.description_placeholder')"
                                        @change="removeValidationError('description')"
                                    />
                                </div>
                            </div>

                            <!-- Attribution -->
                            <div class="form-group row">
                                <label for="create-asset-attribution" class="col-md-4 col-form-label text-md-right">{{ trans('labels.attribution') }} / {{ trans('labels.credits') }}</label>

                                <div class="col-md-6">
                                    <TextInput
                                        :key="'attribution'+key"
                                        id="create-asset-attribution"
                                        type="textarea"
                                        name="attribution"
                                        :model="form"
                                        property="attribution"
                                        :validation-errors="validationErrors('attribution')"
                                        :maxlength="600"
                                        :placeholder="trans('assets.forms.attribution_placeholder')"
                                        :disabled="lockAssetAttribution"
                                        @change="removeValidationError('attribution')"
                                    />
                                </div>
                            </div>

                            <!-- Policy -->
                            <div class="form-group row" v-if="showPolicySelection">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('labels.policy') }}</label>
                                <div class="col-md-6">
                                    <Dropdown
                                        :key="'policy'+key"
                                        :model="form"
                                        property="policy"
                                        name="policy"
                                        :options="policyOptions"
                                        :required="true"
                                        :class="(validationErrors('policy').length > 0 ? ' is-invalid' : '')"
                                        @select="removeValidationError('policy')"
                                    />

                                    <span v-if="validationErrors('policy').length > 0" class="error-msg" role="alert">
                                        <strong v-html="validationErrors('policy')[0]"></strong>
                                    </span>
                                </div>
                            </div>

                            <!-- 3D Asset Format -->
                            <div v-if="show3dAssetFormatSelection" class="form-group row">
                                <div class="col-md-6 offset-md-4">
                                    <label class="radio-inline mb-0">
                                        <input
                                            type="radio"
                                            name="bundle"
                                            :value="false"
                                            v-model="form.bundle"
                                            @change="handleBundleChanged"
                                        > {{ trans('labels.glb') }}
                                    </label>
                                    <label class="radio-inline mb-0 ml-2">
                                        <input
                                            type="radio"
                                            name="bundle"
                                            :value="true"
                                            v-model="form.bundle"
                                            @change="handleBundleChanged"
                                        > {{ trans('labels.assetbundle') }}
                                    </label>
                                    <Transition>
                                        <a v-show="!form.bundle"
                                           class="link-external mb-0 ml-4"
                                           :href="route('tools.model-viewer.index')">
                                            {{ trans('assets.create.convert_to_other_formats') }}
                                        </a>
                                    </Transition>
                                    <Transition>
                                        <div v-show="form.bundle" class="asset-bundle-support-end">
                                            <Icon name="icon_error" />
                                            <p>{{ trans('assets.create.asset_bundle_support_end') }}</p>
                                        </div>
                                    </Transition>
                                </div>
                            </div>

                            <!-- Image Files -->
                            <div v-if="shouldShowFileInputAsImage" id="group-image-files" class="form-group row">
                                <label for="image-files-0" class="col-md-4 col-form-label text-md-right">{{ trans('labels.image') }}</label>

                                <div class="row col-md-8 align-items-center">
                                    <div class="col-auto">
                                        <ImageInputField
                                            field-id="image-files-0"
                                            field-name="files[]"
                                            ref="imageFileInput"
                                            :validation-rules="imageValidationRules"
                                            @file-changed="handleFileInputChanged"
                                            @validation-error="handleImageValidationError"
                                        />
                                    </div>

                                    <div class="col-auto">
                                        <span v-if="validationErrors('files').length > 0 || validationErrors('files.0').length > 0" class="error-msg" role="alert">
                                            <strong v-if="validationErrors('files').length > 0" v-html="validationErrors('files')[0]"></strong>
                                            <strong v-if="validationErrors('files.0').length > 0" v-html="validationErrors('files.0')[0]"></strong>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div v-else-if="shouldShowReadyPlayerMeCreator" id="group-readyplayerme" class="form-group row row-avatar-preview">
                                <label class="col-md-4 col-form-label text-md-right">{{ trans('assets.forms.character_label') }}</label>
                                <div class="col-md-6">
                                    <ReadyPlayerMeCharacterCreator
                                        :key="key"
                                        @character-model-url-changed="onChangeAvatarFileDownloader"
                                        @character-model-preview-changed="handlePreviewImageChanged"
                                        field-id="preview_image"
                                        field-name="preview_image"
                                        ref="previewImage"
                                        :validation-rules="{
                                            minWidth: 1280,
                                            minHeight: 720,
                                            aspectRatio: 16 / 9,
                                            allowedTypes: ['PNG', 'JPG'],
                                        }"
                                    />
                                    <div class="col-auto">
                                        <span
                                            v-if="(
                                                validationErrors('files').length > 0
                                                || validationErrors('files.0').length > 0
                                                || validationErrors('preview_image').length > 0
                                                || validationErrors('character_gender').length > 0
                                            )"
                                            class="error-msg"
                                            role="alert"
                                        >
                                            <strong v-if="validationErrors('files').length > 0" v-html="validationErrors('files')[0]"></strong>
                                            <strong v-if="validationErrors('files.0').length > 0" v-html="validationErrors('files.0')[0]"></strong>
                                            <strong v-if="validationErrors('preview_image').length > 0" v-html="validationErrors('preview_image')[0]"></strong>
                                            <strong v-if="validationErrors('character_gender').length > 0" v-html="validationErrors('character_gender')[0]"></strong>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Generic Files -->
                            <div v-else-if="shouldShowFileInput" id="group-files" class="form-group row">
                                <label for="files-0" class="col-md-4 col-form-label text-md-right">{{ trans('labels.file') }}</label>

                                <div class="col-md-6">
                                    <input
                                        id="files-0"
                                        type="file"
                                        ref="filesInput"
                                        @change="e => handleFileInputChanged(e.target.files[0])"
                                        :accept="fileInputAcceptAttribute"
                                        :class="(validationErrors('files').length > 0 || validationErrors('files.0').length > 0 ? 'form-control is-invalid' : 'form-control')"
                                        name="files[]"
                                        required
                                    >

                                    <span v-if="validationErrors('files').length > 0 || validationErrors('files.0').length > 0" class="error-msg" role="alert">
                                        <strong v-if="validationErrors('files').length > 0" v-html="validationErrors('files')[0]"></strong>
                                        <strong v-if="validationErrors('files.0').length > 0" v-html="validationErrors('files.0')[0]"></strong>
                                    </span>
                                    <p v-if="fileInputRequirements" class="preview-requirements mb-0">
                                        <Icon name="icon_info"/>
                                        {{ fileInputRequirements }}
                                    </p>
                                </div>
                            </div>

                            <!-- AssetBundle Files -->
                            <div v-if="shouldShowBundleInput" id="group-bundlefiles" class="form-group row" >
                                <label for="bundlefiles-0" class="col-md-4 col-form-label text-md-right">{{ trans('labels.bundle_files') }}</label>

                                <div class="col-md-6">
                                    <input
                                        id="bundlefiles-0"
                                        ref="bundleFileInput"
                                        type="file"
                                        accept=".bundleinfo,.winbundle,.uwpbundle,.androidbundle"
                                        :class="(validationErrors('bundlefiles').length > 0 || validationErrors('bundlefiles.0').length > 0 ? 'form-control is-invalid' : 'form-control')"
                                        name="bundlefiles[]"
                                        @change="handleBundleFileInputChanged"
                                        required
                                        multiple
                                    >

                                    <span v-if="validationErrors('bundlefiles').length > 0 || validationErrors('bundlefiles.0').length > 0" class="error-msg" role="alert">
                                        <strong v-if="validationErrors('bundlefiles').length > 0" v-html="validationErrors('bundlefiles')[0]"></strong>
                                        <strong v-if="validationErrors('bundlefiles.0').length > 0" v-html="validationErrors('bundlefiles.0')[0]"></strong>
                                    </span>
                                    <p v-if="fileInputRequirements" class="preview-requirements mb-0">
                                        <Icon name="icon_info"/>
                                        {{ fileInputRequirements }}
                                    </p>
                                </div>
                            </div>

                            <!-- Preview Image -->
                            <div v-if="canBeAssetBundle" id="group-preview-image" class="form-group row">
                                <label for="preview_image" class="col-md-4 col-form-label text-md-right">{{
                                        trans('labels.preview_image')
                                    }}</label>

                                <div class="row col-md-8 align-items-center">
                                    <div class="col-auto">
                                        <ImageInputField
                                            field-id="preview_image"
                                            field-name="preview_image"
                                            ref="previewImage"
                                            :initial-image-url="initialPreviewImageUrl"
                                            :validation-rules="{
                                                minWidth: 1280,
                                                minHeight: 720,
                                                aspectRatio: 16 / 9,
                                                allowedTypes: ['PNG', 'JPG'],
                                            }"
                                            @file-changed="handlePreviewImageChanged"
                                            @validation-error="handlePreviewValidationError"
                                        />
                                    </div>

                                    <div class="col-auto">
                                        <span v-if="validationErrors('preview_image').length > 0"
                                              class="error-msg" role="alert">
                                            <strong v-html="validationErrors('preview_image')[0]"></strong>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <!-- Buttons -->
                            <div class="form-group row mb-0">
                                <div class="buttons col-md-6 offset-md-4">
                                    <ButtonSecondary
                                        v-if="!enableDialogs"
                                        caption="labels.cancel"
                                        @trigger="onClickCancel"
                                    />
                                    <ButtonSecondary
                                        v-else
                                        :href="route('assets.index')"
                                        caption="labels.back_to_my_library"
                                    />
                                    <ButtonPrimary
                                        type="submit"
                                        :disabled="isSubmitting"
                                        caption="labels.create"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <DialogSaving v-if="enableDialogs" :title="trans('assets.create.modals.saving.title')" />
        <DialogLoading v-if="enableDialogs" />
        <DialogReadyPlayerMe avatar-type="npc" :clear-cache="(this.readyPlayerMe.avatarUrl === null)" />
        <DialogNotification v-if="enableDialogs" />
    </div>
</template>

<script>
import DialogNotification from '@/Vue/Modals/DialogNotification.vue';
import DialogSaving from '@/Vue/Modals/DialogSaving.vue';
import DropdownOption from '@/Utility/DropdownOption';
import EventType from '@/Utility/EventType';
import UserFormTextInput from "@/Vue/Users/UserFormTextInput";
import {route, trans} from '@/Utility/Helpers';
import AssetType from '@/Models/Asset/AssetType';
import TextToSpeechService from "@/Services/CognitiveServices/TextToSpeechService";
import DialogLoading from "@/Vue/Modals/DialogLoading.vue";
import TextToSpeechVoiceConfig from "@/Services/CognitiveServices/TextToSpeechVoiceConfig";
import {kebabCase} from "lodash";
import ImageInputField from "@/Vue/Common/ImageInputField.vue";
import ReadyPlayerMeAvatarPreview from "@/Vue/Users/ReadyPlayerMeAvatarPreview.vue";
import ReadyPlayerMeCharacterCreator from "@/Vue/Assets/ReadyPlayerMe/ReadyPlayerMeCharacterCreator.vue";
import DialogReadyPlayerMe from "@/Vue/Modals/DialogReadyPlayerMe.vue";
import {AssetPolicyStandard} from "@/Models/Asset/AssetPolicy";
import {AssetProvider} from "@/Models/Asset/AssetProvider";
import RequestError from "@/Errors/RequestError.js";

export default {
        name: "CreateAssetForm",
        emits: [
            'success',
            'cancel',
        ],
        components: {
            DialogReadyPlayerMe,
            ReadyPlayerMeAvatarPreview,
            ReadyPlayerMeCharacterCreator,
            ImageInputField,
            DialogLoading,
            DialogNotification,
            DialogSaving,
            UserFormTextInput
        },
        props: {
            assetTypes: {
                type: Object,
                default() {
                    return {};
                }
            },
            policies: {
                type: Array,
                default() {
                    return [];
                }
            },
            assetProvider: {
                type: String,
                required: false,
                default: null,
            },
            providerAssetId: {
                type: String,
                required: false,
                default: null,
            },
            initialTitle: {
                type: String,
                required: false,
                default: null,
            },
            initialDescription: {
                type: String,
                required: false,
                default: null,
            },
            initialAssetAttribution: {
                type: String,
                required: false,
                default: null,
            },
            initialPreviewImageUrl: {
                type: String,
                required: false,
                default: null,
            },
            lockAssetAttribution: {
                type: Boolean,
                required: false,
                default: false,
            },
            /**
             * @type {() => Promise<Blob>}
             */
            assetFileDownloader: {
                type: Function,
                required: false,
                default: null,
            },
            enableDialogs: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                /**
                 * @type AssetService
                 */
                assetService: this.$assetService,
                ttsService: new TextToSpeechService(),

                isSubmitting: false,
                form: {
                    description: this.initialDescription?.slice(0, 600) || null,
                    attribution: this.initialAssetAttribution,
                    content_description: null,
                    title: this.initialTitle,
                    type: Object.keys(this.assetTypes).includes(AssetType.Image.type) ? AssetType.Image.type : Object.keys(this.assetTypes)[0] ?? null,
                    bundle: false,
                    files: null,
                    policy: this.policies.length === 0 ? null : this.policies[0],
                    bundlefiles: null,

                    /**
                     @type TextToSpeechVoiceConfig
                     */
                    ttsVoice: TextToSpeechVoiceConfig.All[0],

                    /**
                     * @type TextToSpeechResult
                     */
                    ttsResult: null,

                    assetProvider: this.assetProvider,
                    providerAssetId: this.providerAssetId,
                },
                titleAutofilledFromFile: false,
                titleAutofilledFromContentDescription: false,
                defaultForm: {},
                errors: {},
                key: 0,
                readyPlayerMe: {
                    assetFileDownloader: null,
                    avatarUrl: null,
                    metaDataUrl: null,
                },

            }
        },

        beforeMount() {
            this.defaultForm = { ...this.form };
        },

        computed: {

            /**
             * Tenants to show in the dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            assetTypeOptions() {
                return Object.keys(this.assetTypes)
                    .map(
                        assetType => new DropdownOption({
                            caption: trans('assets.types.' + assetType),
                            icon: AssetType.getByTypeName(assetType).icon,
                            disabled: false,
                            value: assetType
                        })
                    );
            },

            /**
             * Voice/language options to show in the dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            voiceTypeOptions() {
                return TextToSpeechVoiceConfig.All
                    .map(voiceConfig => new DropdownOption({
                        caption: voiceConfig.label,
                        value: voiceConfig
                    }));
            },

            /**
             * Can the current user change the users' tenant role
             *
             * @returns {boolean}
             */
            canBeAssetBundle() {
                return [AssetType.EnvironmentModel3D.type, AssetType.Model3D.type].includes(this.form.type);
            },

            show3dAssetFormatSelection() {
              return this.canBeAssetBundle && this.assetFileDownloader === null;
            },

            fileInputAcceptAttribute() {
                return this.assetTypes[this.form.type];
            },

            showPolicySelection() {
                return this.policies.length > 1 || (this.policies.length === 1 && this.policies[0] !== AssetPolicyStandard.type);
            },

            /**
             * Tenants to show in the dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            policyOptions() {
                const options = [];

                for (let [key, value] of Object.entries(this.policies)) {
                    options.push(new DropdownOption({
                        caption:trans('asset_policies.' + value),
                        disabled: false,
                        value: value
                    }))
                }

                return options;
            },

            shouldShowContentDescription() {
                return this.form.type === AssetType.SoundTts.type;
            },

            shouldShowBundleInput() {
                return this.canBeAssetBundle && !this.isGeneratedTtsSound && !this.shouldShowFileInput && this.form.bundle === true;
            },

            shouldShowFileInput() {
                if (this.assetFileDownloader !== null) {
                    return false;
                }

                return (!this.canBeAssetBundle && !this.isGeneratedTtsSound) || (this.canBeAssetBundle && this.form.bundle === false);
            },

            shouldShowReadyPlayerMeCreator() {
                return this.form.type === AssetType.CharacterModel3D.type;
            },

            shouldShowFileInputAsImage() {
                return this.shouldShowFileInput && AssetType.isImageType(this.form.type);
            },

            fileInputRequirements() {
                switch (this.form.type) {
                    case AssetType.Model3D.type:
                    case AssetType.EnvironmentModel3D.type:
                        if (this.form.bundle) {
                            return trans('assets.create.requirements_file_asset_bundle');
                        } else {
                            return trans('assets.create.requirements_file_glb');
                        }
                    default:
                        return null;
                }
            },

            isGeneratedTtsSound() {
                return this.form.type === AssetType.SoundTts.type;
            },

            /**
             * @returns {Boolean}
             */
            isLoading() {
                if (this.ttsService.isSynthesizing) {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
                return false;
            },

            isTtsResultUpToDate() {
                return this.form.ttsResult &&
                    this.form.ttsResult.plainTextInput === this.form.content_description &&
                    this.form.ttsResult.voiceConfig === this.form.ttsVoice;
            },

            ttsAudioSource() {
                if (this.isTtsResultUpToDate) {
                    return window.URL.createObjectURL(this.form.ttsResult.blob);
                } else {
                    // silent audio as fallback to enable play button in html audio element
                    return 'data:audio/x-wav;base64,UklGRooWAABXQVZFZm10IBAAAAABAAEAIlYAAESsAAACABAAZGF0YWYWAAAAAA';
                }
            },

            /**
             * @return {ImageValidationRules}
             */
            imageValidationRules() {
                switch (this.form.type) {
                    case AssetType.Image.type:
                        return {
                            maxWidth: 2048,
                            maxHeight: 2048,
                            allowedTypes: ['PNG', 'JPG'],
                        };
                    case AssetType.EnvironmentImage.type:
                        return {
                            minWidth: 2160,
                            minHeight: 1080,
                            maxWidth: 8192,
                            maxHeight: 4096,
                            aspectRatio: 2,
                            allowedTypes: ['PNG', 'JPG'],
                        };
                    default:
                        return {};
                }
            },
        },
        methods: {
            route,

            onChangeAvatarFileDownloader(url) {
                const urlWithMorphTargets = new URL(url);
                urlWithMorphTargets.searchParams.set('morphTargets', 'ARKit,Oculus Visemes');
                const urlString = urlWithMorphTargets.toString();

                const metaDataUrl = new URL(url.replace('.glb', '.json'));
                metaDataUrl.search = '';

                this.readyPlayerMe.assetFileDownloader = () => this.downloadModel(urlString);
                this.readyPlayerMe.avatarUrl = urlString;
                this.readyPlayerMe.metaDataUrl = metaDataUrl.toString();
            },

            /**
             * @param {String} filename
             * @return {String}
             */
            getFilenameWithoutExtension(filename) {
                return filename.replace(/\.[a-z\d]+$/i, '');
            },

            handleTitleChanged() {
                this.removeValidationError('title');
                this.titleAutofilledFromFile = false;
                this.titleAutofilledFromContentDescription = false;
            },

            /**
             * @param {String} newValue
             * @param {InputEvent} e
             */
            handleContentDescriptionChanged(newValue, e) {

                newValue = newValue?.trim();

                this.removeValidationError('content_description');

                if (newValue !== null && (this.isTitleEmpty() || this.titleAutofilledFromContentDescription)) {
                    this.titleAutofilledFromContentDescription = true;
                    this.overrideTitle(this.getTitleFromContentDescription(newValue));
                }
                if (this.form.content_description !== newValue) {
                    this.form.content_description = newValue;
                    this.form.ttsResult = null;
                }
            },

            handleBundleChanged() {
                this.resetFileInputs();
            },

            handleBundleFileInputChanged(event) {
                const files = event.target.files
                const newFiles = [];

                for (let i = 0; i < files.length; i++) {
                    newFiles.push(files[i]);
                }

                this.form.bundlefiles = newFiles;

                // Reset validation errors
                const bundleFileInput = this.$refs.bundleFileInput;

                if (bundleFileInput === undefined || bundleFileInput === null) {
                    return;
                }

                bundleFileInput.setCustomValidity('');
                this.removeValidationError('bundlefiles');
                this.removeValidationError('bundlefiles.0');
            },

            /**
             * @param {File|null} file
             */
            handleFileInputChanged(file) {
                // Set title from file name if title is empty or from previously selected file
                if (this.isTitleEmpty() || this.titleAutofilledFromFile) {
                    this.titleAutofilledFromFile = true;
                    this.overrideTitle(this.getTitleFromFile(file));
                }

                this.form.files = [file];
                this.removeValidationError('files');
                this.removeValidationError('files.0');
            },

            /**
             * @param {Error} e
             */
            handleImageValidationError(e) {
                this.$root.showErrorDialog(e.message);
            },

            handlePreviewImageChanged() {
                this.removeValidationError('preview_image');
            },

            /**
             * @param {Error} e
             */
            handlePreviewValidationError(e) {
                this.$root.showErrorDialog(e.message);
            },

            async synthesize(playAfterSynthesis = false) {
                this.form.ttsResult = await this.ttsService.synthesize(this.form.content_description, this.form.ttsVoice);

                if (playAfterSynthesis) {
                    // delay, so new source is already set on the element
                    setTimeout(() => this.$refs.audio.play(), 100);
                }
            },

            /**
             * @param {boolean} playAfterSynthesis
             * @param {boolean} showErrorOverlay when true, errors during synthesis will be caught and an error dialog shown
             * @return {Promise<void>}
             */
            async synthesizeIfNeeded(playAfterSynthesis = false, showErrorOverlay = true) {
                if (!this.isGeneratedTtsSound) {
                    // we do not need to synthesize for this asset type
                    return;
                }
                if (this.form.content_description === null) {
                    // nothing to synthesize without content description
                    return;
                }
                if (this.isTtsResultUpToDate) {
                    // synthesis is up-to-date
                    return;
                }

                try {
                    await this.synthesize(playAfterSynthesis);
                } catch (e) {
                    if (showErrorOverlay) {
                        this.$root.showErrorDialog(e);
                    } else {
                        throw e;
                    }
                }
            },

            /**
             * @param {String} url
             * @returns {Promise<Blob>}
             */
            async downloadModel(url) {
                const response = await fetch(url);
                return await response.blob();
            },

            /**
             * @param {FormData} formData
             * @return {Promise<void>}
             */
            async downloadAssetFileIfNeeded(formData) {
                if (this.assetFileDownloader !== null) {
                    const blob = await this.assetFileDownloader();
                    formData.set('files[]', blob, kebabCase(this.initialTitle) + '.glb');
                }

                if (this.readyPlayerMe.assetFileDownloader !== null) {
                    const blob = await this.readyPlayerMe.assetFileDownloader();
                    // Get file name
                    const pathname = new URL(this.readyPlayerMe.avatarUrl).pathname;
                    const index = pathname.lastIndexOf('/');
                    const filename = pathname.substring(index + 1)

                    formData.set('files[]', blob, filename);
                }
            },

            async downloadCharacterMetaDataIfNeeded(formData) {
                if (this.readyPlayerMe.metaDataUrl !== null) {
                    const metaData = await fetch(this.readyPlayerMe.metaDataUrl);
                    const jsonMetaData = await metaData.json();

                    const pathname = new URL(this.readyPlayerMe.metaDataUrl).pathname;
                    const index = pathname.lastIndexOf('/');
                    const providerAssetID = pathname.substring(index + 1).replace('.json', '');

                    formData.set('character_gender', jsonMetaData['outfitGender']);
                    formData.set('asset_provider', AssetProvider.ReadyPlayerMe);
                    formData.set('provider_asset_id', providerAssetID);
                }
            },

            onClickCancel() {
                this.$emit('cancel');
                return this;
            },

            /**
             * Click handler fpr the submit button
             *
             * @param event
             */
            onSubmit(event) {
                event.preventDefault();

                const form = this.$refs.form;

                if (!this.validateBundleFiles()) {
                    event.preventDefault();
                    return;
                }

                if (this.isSubmitting || !form.reportValidity()) {
                    return;
                }

                this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
                const formData = new FormData(event.target);
                formData.append('type', this.form.type);
                formData.append('policy', this.form.policy);
                formData.delete('bundle');
                formData.append('bundle', this.form.bundle === true ? '1' : '0');

                // because the attribution field can be disabled, we need to set this explicitly
                // @NOTE: formData.set() converts null to "null" so it must be an empty string
                formData.set('attribution', this.form.attribution || '');

                // set hidden fields
                if (this.form.assetProvider) {
                    formData.set('asset_provider', this.form.assetProvider);
                    formData.set('provider_asset_id', this.form.providerAssetId);
                }

                this.synthesizeIfNeeded(false, false)
                    .then(() => this.downloadAssetFileIfNeeded(formData))
                    .then(() => {
                        if (this.isGeneratedTtsSound) {
                            this.form.ttsResult.applyToCreateAssetFormData(formData);
                        }
                    })
                    .then(() => this.downloadCharacterMetaDataIfNeeded(formData))
                    .then(() => {
                        this.isSubmitting = true;
                        return this.assetService.createAssetFromFormData(formData, this.onUploadProgress);
                    })
                    .then((asset) => {
                        if (this.enableDialogs) {
                            this.$toast.success(trans('assets.modals.create_success', {
                                title: asset.title,
                                type: asset.assetType.title,
                            }));
                        }
                        this.resetForm();
                        this.$emit('success', asset);
                    })
                    .catch((error) => {
                        this.errors = error.validationErrors || {};

                        // Show error dialog unless it's a validation error:
                        if (!(error instanceof RequestError && error.isValidationError)) {
                            this.$root.showErrorDialog(error);
                        }
                    })
                    .finally(() => {
                        this.isSubmitting = false;
                        this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                    });
            },

            /**
             * @param {AxiosProgressEvent} progressEvent
             */
            onUploadProgress(progressEvent) {
                if (!progressEvent.total) {
                    return;
                }

                const progressPercent = progressEvent.loaded / progressEvent.total;
                this.$globalEvents.emit(EventType.MODAL_SAVING_PROGRESS, progressPercent);
            },

            onTypeChanged() {
                this.removeValidationError('type');
                this.form.bundle = false;

                // Reset title if it was auto-filled from selected file
                if (this.titleAutofilledFromFile || this.titleAutofilledFromContentDescription) {
                    this.clearTitle();
                }

                this.resetFileInputs();

                this.resetCharacterSettings();

                // Reset preview image
                this.removeValidationError('preview_image');
                if (this.$refs.previewImage) {
                    this.$refs.previewImage.reset();
                }

                // Reset content description
                this.removeValidationError('content_description');
                this.form.content_description = null;
            },

            onVoiceChanged() {
                this.form.ttsResult = null;
            },

            /**
             * Clear the validatin errors for a specific field.
             *
             * @param property
             */
            removeValidationError(property) {
                delete this.errors[property];
            },

            resetFileInputs() {
                // Reset files
                this.removeValidationError('files');
                this.removeValidationError('files.0');
                this.form.files = null;

                // Reset generic files
                if (this.$refs.filesInput) {
                    this.$refs.filesInput.value = '';
                }

                // Reset image files
                if (this.$refs.imageFileInput) {
                    this.$refs.imageFileInput.reset();
                }

                // Reset bundle files
                this.removeValidationError('bundlefiles');
                this.removeValidationError('bundlefiles.0');
                if (this.$refs.bundleFileInput) {
                    this.$refs.bundleFileInput.value = '';
                }
                this.form.bundlefiles = null;
            },

            resetCharacterSettings() {
                this.readyPlayerMe.assetFileDownloader = null;
                this.readyPlayerMe.avatarUrl = null;
                this.readyPlayerMe.metaDataUrl = null;
            },

            /**
             * Reset the inputs and dropdowns
             */
            resetForm() {
                this.form = {
                    ...this.defaultForm,

                    // Keep current selection
                    type: this.form.type,
                    bundle: this.form.bundle,
                    policy: this.form.policy,
                    ttsVoice: this.form.ttsVoice,
                };

                this.errors = {};

                // Reset files
                if (this.$refs.filesInput) {
                    this.$refs.filesInput.value = '';
                }

                this.resetCharacterSettings();

                // Reset image files
                if (this.$refs.imageFileInput) {
                    this.$refs.imageFileInput.reset();
                }

                // Reset bundle files
                if (this.$refs.bundleFileInput) {
                    this.$refs.bundleFileInput.value = '';
                }

                // Reset preview image
                if (this.$refs.previewImage) {
                    this.$refs.previewImage.reset();
                }

                // Make sure the components reload
                this.key += 1;
            },

            validateBundleFiles() {
                const requiredFileExtensions = [
                    'winbundle',
                    'uwpbundle',
                    'androidbundle'
                ];
                const optionalFileExtension = 'bundleinfo';
                const attributeName = window.i18n.validation.attributes.bundlefiles;

                const bundleFileInput = this.$refs.bundleFileInput;

                if (bundleFileInput === undefined || bundleFileInput === null) {
                    return true;
                }

                bundleFileInput.setCustomValidity('');

                // no bundle -> no bundle error
                if (this.form.bundle === false) {
                    return true;
                }

                // const fileNames = Array.from(bundleFileInput.files).map(file => file.name);
                const fileNames = Array.from(this.form.bundlefiles).map(file => file.name);

                // validate all required extensions are there
                for (const requiredExtension of requiredFileExtensions) {
                    const isExtensionPresent = fileNames.some(fileName => fileName.endsWith(requiredExtension));

                    if (!isExtensionPresent) {
                        const message = window.i18n.validation.all_bundle_file_extensions_present
                            .replace(':attribute', attributeName)
                            .replace(':extension', requiredExtension);

                        bundleFileInput.setCustomValidity(message);
                    }
                }

                // validate max file count not exceeded
                const maxFileCount = requiredFileExtensions.length + 1;

                if (fileNames.length > maxFileCount) {
                    // too many files
                    const message = window.i18n.validation.max.array
                        .replace(':attribute', attributeName)
                        .replace(':max', `${maxFileCount}`);

                    bundleFileInput.setCustomValidity(message);
                }

                // validate optional file has correct extension
                if (fileNames.length === maxFileCount) {
                    const isOptionalExtensionPresent = fileNames.some(fileName => fileName.endsWith(optionalFileExtension));

                    if (!isOptionalExtensionPresent) {
                        // optional file is missing based on file count
                        const message = window.i18n.validation.all_bundle_file_extensions_present
                            .replace(':attribute', attributeName)
                            .replace(':extension', optionalFileExtension);

                        bundleFileInput.setCustomValidity(message);
                    }
                }

                return bundleFileInput.reportValidity();
            },

            /**
             * Get the validation errors for a specific field.
             *
             * @param property
             * @returns {string}
             */
            validationErrors(property) {
                return this.errors && this.errors.hasOwnProperty(property) ? this.errors[property] : [];
            },

            isTitleEmpty() {
                return this.form.title === null || this.form.title.trim() === '';
            },

            clearTitle() {
                this.overrideTitle('');
                this.titleAutofilledFromFile = false;
                this.titleAutofilledFromContentDescription = false;
            },

            /**
             * @param {String} title
             * @return void
             */
            overrideTitle(title) {
                if (!this.$refs.formInputTitle) {
                    return;
                }

                this.removeValidationError('title');
                this.form.title = title;
                this.$refs.formInputTitle.text = title;
                this.$refs.formInputTitle.$refs.domElement.value = title;
            },

            /**
             * @param {File|null} file
             * @return string
             */
            getTitleFromFile(file) {
                if (file) {
                    return this.getTitleFromText(this.getFilenameWithoutExtension(file.name));
                } else {
                    return '';
                }
            },

            /**
             * @param {string} contentDescription
             * @return string
             */
            getTitleFromContentDescription(contentDescription) {
                return this.getTitleFromText(contentDescription);
            },

            /**
             * @param {String} text
             * @return string
             */
            getTitleFromText(text) {
                text = text.replace('\n', ' ');

                if (text.length <= this.$refs.formInputTitle.maxlength) {
                    return text;
                }

                return text.substring(0, this.$refs.formInputTitle.maxlength - 1) + '…';
            }
        },
    }
</script>

<style lang="scss" scoped>
    .form-asset-create .row-avatar-preview > div {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
    }
</style>
