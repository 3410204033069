<template>
    <div
        class="modal-asset-preview-canvas-wrapper"
        v-shortcuts.global
    >
        <ThreejsModelRenderer
            :model="gltf?.scene"
            :highlighted-object="highlightedObject"
            :highlighted-animation="highlightedAnimation"
            :auto-rotate-on-start="true"
        />

        <WidgetModelLayers
            v-show="shouldShowLayerWidget"
            :scene="gltf?.scene"
            @mouseOverNode="onMouseOverNode"
            @mouseOutNode="onMouseOutNode"
            @hide="onClickHideWidget"
        />
        <ModelAnimationsWidget
            v-show="shouldShowAnimationWidget"
            :animations="gltf?.animations"
            @mouseOverAnimation="onMouseOverAnimation"
            @mouseOutAnimation="onMouseOutAnimation"
            @hide="onClickHideWidget"
        />

        <div class="modal-preview-buttons"
             v-show="!shouldShowLayerWidget && !shouldShowAnimationWidget && gltf !== null">
            <ButtonCircular
                class="button-toggle-widget"
                v-tooltip="'tooltips.buttons.assets.show_layers'"
                icon="icon_layers"
                @trigger="onToggleLayerWidget"
            />
            <ButtonCircular
                :disabled="!modelHasAnimations"
                class="button-toggle-widget"
                v-tooltip="'tooltips.buttons.assets.show_animations'"
                icon="icon_animation"
                @trigger="onToggleAnimationWidget"
            />
        </div>

        <AssetPreviewUsageOverlay
            v-if="gltf !== null"
            :can-zoom="true"
            :can-rotate="true"/>

        <div
            v-if="showLoadingIndicator"
            class="loader">
            <LoadingIndicator/>
        </div>
    </div>
</template>

<script type="module">
// Three.js
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';

// Components
import LoadingIndicator from "@/Vue/Common/LoadingIndicator";
import WidgetModelLayers from "@/Vue/Modals/AssetPreview/WidgetModelLayers";
import ModelAnimationsWidget from "@/Vue/Modals/AssetPreview/WidgetModelAnimations.vue";
import AssetPreviewUsageOverlay from "@/Vue/Modals/AssetPreview/AssetPreviewUsageOverlay.vue";
import ThreejsModelRenderer from "@/Vue/Modals/AssetPreview/ThreejsModelRenderer.vue";

export default {
        name: "ThreeDObjectRenderer",

        components: {
            ThreejsModelRenderer,
            AssetPreviewUsageOverlay,
            ModelAnimationsWidget,
            LoadingIndicator,
            WidgetModelLayers,
        },

        props: {
            src: {
                type: String,
                default: null,
            }
        },

        data() {
            return {
                layerWidgetShouldBeVisible: false,
                animationWidgetShouldBeVisible: false,
                isLoading: true,
                gltf: null,
                highlightedObject: null,
                highlightedAnimation: null,

                /**
                 * Shortcut mapping to methods
                 * @type Map
                 */
                shortcuts: new Map([
                    ['OS+L.prevent', this.onToggleLayerWidget],
                    ['Ctrl+L.prevent', this.onToggleLayerWidget],
                    ['OS+I.prevent', this.onToggleAnimationWidget],
                    ['Ctrl+I.prevent', this.onToggleAnimationWidget],
                    ['Escape.prevent', this.onCloseWidgets],
                ]),
            }
        },

        mounted() {
            if (this.src !== null) {
                this.loadModelFromUrl(this.src, this.onGlbParseSuccess, null);
            }
        },

        computed: {

            shouldShowLayerWidget() {
                return this.gltf !== null && this.layerWidgetShouldBeVisible === true;
            },

            shouldShowAnimationWidget() {
                return this.gltf !== null && this.animationWidgetShouldBeVisible === true;
            },

            showLoadingIndicator() {
                return this.isLoading;
            },

            modelHasAnimations() {
                return this.gltf && this.gltf.animations && Array.isArray(this.gltf.animations) && this.gltf.animations.length > 0;
            },

            animationsButtonTooltip() {
                return this.modelHasAnimations ? "labels.animations" : "tooltips.buttons.assets.does_not_support_animations";
            },
        },

        methods: {

            loadModelFromUrl(url, successCallback, errorCallback) {
                new GLTFLoader().load(url, successCallback, errorCallback);
                return this;
            },

            onClickHideWidget() {
                this.layerWidgetShouldBeVisible = false;
                this.animationWidgetShouldBeVisible = false;
            },

            onGlbParseSuccess(gltf) {
                this.gltf = gltf;
                this.onLoadingEnded();
            },

            onLoadingEnded() {
                this.isLoading = false;
            },

            onMouseOutNode() {
                this.highlightedObject = null;
            },

            onMouseOverNode(obj) {
                this.highlightedObject = obj;
            },

            onMouseOutAnimation() {
                this.highlightedAnimation = null;
            },

            onMouseOverAnimation(animation) {
                this.highlightedAnimation = animation;
            },

            onToggleLayerWidget() {
                this.layerWidgetShouldBeVisible = !this.layerWidgetShouldBeVisible;

                if (this.layerWidgetShouldBeVisible) {
                    this.animationWidgetShouldBeVisible = false;
                }
            },

            onToggleAnimationWidget() {
                this.animationWidgetShouldBeVisible = !this.animationWidgetShouldBeVisible;

                if (this.animationWidgetShouldBeVisible) {
                    this.layerWidgetShouldBeVisible = false;
                }
            },

            onCloseWidgets(e) {
                if (this.layerWidgetShouldBeVisible || this.animationWidgetShouldBeVisible) {
                    e.stopPropagation();
                }

                this.layerWidgetShouldBeVisible = false;
                this.animationWidgetShouldBeVisible = false;
            },
        },

        watch: {
            src() {
                if (this.src !== null) {
                    this.loadModelFromUrl(this.src, this.onGlbParseSuccess, null);
                }
                return this;
            },
        }
    }
</script>

<style lang="scss">

    .modal-asset-preview-canvas-wrapper {
        height: 60vh;
        overflow: hidden;
        display: flex;
        position: relative;
        border-radius: var(--card-border-radius);
        aspect-ratio: 16/9;
    }

    .modal-asset-preview-canvas-wrapper {
        .loader {
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
        }
    }

    .modal-asset-preview-canvas-wrapper {
        .modal-preview-buttons {
            position: absolute;
            top: 20px;
            left: 20px;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .button-toggle-widget {
                background-color: var(--background-color-white);
            }
        }
    }

</style>
