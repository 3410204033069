<template>
    <div v-if="isVisible" id="dialog-duplicate-unit" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <h2 class="title">{{ trans('modals.duplicate_unit.title') }}</h2>
            <p class="description" v-html="trans('modals.duplicate_unit.description', {title: unit.latestRevision.title})"></p>
            <TextInput
                :initial-value="newTitle"
                @change="onChangeTitle"
                :required="true"
                :maxlength="50"
                :placeholder="trans('units.create.unit_title_placeholder')"
                :error-msg="trans('errors.unit.title')"
            />
            <Checkbox
                :initialValue="keepAssignedAuthors"
                layout="checkbox-right"
                :caption="trans('modals.duplicate_unit.keep_authors')"
                @change="onChangeKeepAssignedAuthors"
            />
            <div class="buttons">
                <ButtonSecondary class="btn-cancel" @trigger="onClickCancel" caption="modals.duplicate_unit.cancel" />
                <ButtonPrimary :disabled="!isValid" class="btn-apply" @trigger="onClickApply" caption="modals.duplicate_unit.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    //

    // Import classes:
    import { trans }                from '@/Utility/Helpers';
    import EventType                from '@/Utility/EventType';

    export default {
        name: 'DialogDuplicateUnit',
        data() {
            return {
                isVisible: false,                       // Visibility state
                unit: null,                             // Reference to the Unit for which this dialog is being shown
                newTitle: '',                           // Title of the duplicated unit
                keepAssignedAuthors: false,             // Checkbox state for whether the assigned authors should be duplicated as well
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([                // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        computed: {

            /**
             * Validation state
             *
             * @returns {Boolean}
             */
            isValid() {
                return this.newTitle.length > 0 && this.newTitle.length <= 50;
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_DUPLICATE_UNIT_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_DUPLICATE_UNIT_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_DUPLICATE_UNIT_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_DUPLICATE_UNIT_HIDE, this.hide);
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {Unit} unit
             */
            show(unit) {
                this.previousFocusElement = document.activeElement;
                this.unit = unit;
                this.newTitle = this.unit.latestRevision.title + ' ' + trans('modals.duplicate_unit.copy_suffix');
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.unit = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                if (this.isValid === true)
                {
                    this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_APPLY, this.unit, this.newTitle, this.keepAssignedAuthors);
                    this.hide();
                }
                return this;
            },

            /**
             * Change handler for the title
             *
             * @param {String} newTitle
             */
            onChangeTitle(newTitle) {
                this.newTitle = newTitle;
                return this;
            },

            /**
             * Change handler for keep authors checkbox
             */
            onChangeKeepAssignedAuthors(checked) {
                this.keepAssignedAuthors = checked;
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
