<template>
    <section
        v-if="course"
        :class="classNames"
        @focus="$emit('focus', course)"
        @click="$emit('click', course)"
        @dblclick="onDoubleClick">

        <div v-if="labels.length > 0" class="wrapper course-labels">
            <StatusLabel
                v-for="labelConfig in labels"
                :key="'statuslabel'+course.uid+(labelConfig.type || labelConfig.caption)"
                :config="labelConfig"
            />
        </div>

        <img :src=course.preview_thumbnail
             alt=""
             class="course-preview">

        <div class="metadata">
            <span class="unit-count">{{ unitCountHeadline }}</span>
        </div>

        <div class="content">
            <h2 class="title">{{ course.title }}</h2>
            <p class="description">{{ course.description }}</p>
        </div>

        <footer>
            <template v-if="courseHasActions">
                <div class="wrapper actions">
                    <ButtonCircular
                        v-if="courseIsViewable"
                        v-tooltip="'buttons.courses.view'"
                        :href="this.$root.route('courses.view', {'course': course.uid})"
                        icon="icon_arrow_right"
                    />
                    <ButtonCircular
                        v-if="courseIsEditable"
                        v-tooltip="'buttons.courses.edit'"
                        :href="this.$root.route('courses.edit', {'course': course.uid})"
                        icon="icon_edit"
                    />
                    <ButtonCircular
                        v-if="courseIsDeletable"
                        v-tooltip="'buttons.courses.delete'"
                        icon="icon_delete"
                        @trigger="onClickDelete"
                    />
                </div>
                <Icon class="icon-more" name="icon_more_horizontal" />
            </template>
        </footer>

    </section>
</template>

<script>
    import Course from "@/Models/Course/Course";
    import {trans} from '@/Utility/Helpers';
    import {Permission} from "@/Models/User/Permission";
    import EventType from "@/Utility/EventType";
    import StatusLabelConfig from "@/Utility/StatusLabelConfig";
    import {CoursePermissionPolicyStandard} from "@/Models/Course/CoursePermissionPolicy";
    import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";

    export default {
        name: "CourseListItem",
        components: {ButtonCircular},
        emits: [
            'focus',
            'click',
            'dblclick',
        ],
        props: {
            course: {
                type: Course,
            },
            selected: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            classNames() {
                return [
                    'course-item',
                    this.selected ? 'selected' : '',
                ];
            },

            unitCountHeadline() {
                switch (this.course.numberOfUnits) {
                    case 0:
                        return trans('courses.index.number_of_units_zero');
                    case 1:
                        return trans('courses.index.number_of_units_one');
                    default:
                        return trans('courses.index.number_of_units_many', {count: this.course.numberOfUnits});
                }
            },

            /**
             * @returns {Array<StatusLabelConfig>}
             */
            labels() {
                const labels = [];

                // Policy (if non-standard)
                if (this.course.policy !== CoursePermissionPolicyStandard.type)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'policy',
                            caption: this.course.policy
                        })
                    );
                }

                return labels;
            },

            courseIsDeletable() {
                return this.$gate.allows(Permission.ability(Permission.CoursesDelete()), this.course);
            },

            courseIsEditable() {
                return this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), this.course);
            },

            courseIsViewable() {
                return this.$gate.allows(Permission.ability(Permission.CoursesRead()), this.course);
            },

            /**
             * @returns {boolean}
             */
            courseHasActions() {
                return this.courseIsDeletable || this.courseIsEditable || this.courseIsViewable;
            },
        },

        methods: {

            onDoubleClick() {
                this.$emit('dblclick', this.course);

                return this;
            },

            onClickDelete() {
                if (this.courseIsDeletable) {
                    this.$globalEvents.emit(EventType.MODAL_DELETE_COURSE_SHOW, this.course);
                }
                return this;
            },

        }
    }
</script>

<style lang="scss" scoped>
</style>
