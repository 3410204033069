<template>
    <div class="hint-assignment panel-card">
        <header>
            <Icon :name="trigger.triggerType.icon" />
            <span class="caption" :title="getHintAssignmentTitleForTooltip">{{ getHintAssignmentTitle }}</span>
        </header>

        <Dropdown
            :initial-value="hintValue"
            @select="onSelectAssignment"
            :options="getOptionsForAssignmentDropdown"
            :deselected-caption="trans('authoring.no_hint')"
        />
    </div>
</template>

<script>
// Import classes:
import { getCaptionForSceneObjectTrigger }  from '@/Models/UnitData/Commands/CommandHelpers';
import DropdownOption                       from '@/Utility/DropdownOption';
import { sortArrayByProperty, trans }       from '@/Utility/Helpers';
import { SceneObjectModuleHelper }          from '@/Models/UnitData/SceneObjects/SceneObject';
import Trigger                              from '@/Models/UnitData/Triggers/Trigger';

export default {
    name: 'PanelHintAssignmentItem',
    emits: [
        'change',
    ],
    props: {
        trigger: {
            type: Trigger,
            default: null
        },
        sceneObject: {
            type: SceneObjectModuleHelper,
        }
    },
    computed: {

        /**
         * Get the reactions configured on the sceneObject
         *
         * @returns {Array<Trigger>}
         */
        reactions() {
            return this.sceneObject.reactions;
        },

        /**
         * Get title by trigger
         *
         * @returns {String|null}
         */
        getHintAssignmentTitle() {
            const so = this.getSceneObjectAndTrigger;

            if (so === null) {
                return null;
            }

            return getCaptionForSceneObjectTrigger(so.sceneObject, so.trigger);
        },

        /**
         * Get title for tooltip
         *
         * @returns {String|null}
         */
        getHintAssignmentTitleForTooltip() {
            return this.getHintAssignmentTitle !== null && this.getHintAssignmentTitle.length >= 28 ? this.getHintAssignmentTitle : null;
        },

        /**
         * Get the hint assignment
         *
         * @returns {HintObjectiveAssignment|null}
         */
        hint() {
            return (this.sceneObject.hints || []).find(h => h.objective === this.trigger.uid) || null;
        },

        /**
         * Get the cue trigger value
         *
         * @returns {String}
         */
        hintValue() {
            const hint = this.hint;
            return (hint !== null) ? hint.cue : '';
        },

        /**
         * Get dropdown options for hints
         *
         * @returns {Array<DropdownOption>}
         */
        getOptionsForAssignmentDropdown() {
            return [
                new DropdownOption({
                    caption: trans('authoring.no_hint'),
                    value: null
                }),
                ...sortArrayByProperty(
                    this.reactions.map(
                        t => new DropdownOption({
                            caption: t.title || t.triggerType.title,
                            value: t.uid
                        })
                    ),
                    'caption',
                    false
                )
            ];
        },

        /**
         * Get scene object and trigger
         *
         * @returns {Object|null}
         */
        getSceneObjectAndTrigger() {
            const parentTrainingScene = this.trigger?.parentTrainingScene || null;
            if (parentTrainingScene === null) {
                return null;
            }
            const sceneObject = parentTrainingScene.allSceneObjects.find(o => o.hasTrigger(this.trigger)) || null;
            return (sceneObject === null) ? null : {
                sceneObject: sceneObject,
                trigger: sceneObject.triggers.find(t => t.uid === this.trigger.uid) || null,
            };
        },
    },
    methods: {

        /**
         * Change handler for a specific hint assignment
         *
         * @param {String} value // The reaction cue trigger UID
         */
        onSelectAssignment(value) {
            this.$emit('change', value, this);
            return this;
        },
    }
}
</script>
