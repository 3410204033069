<template>
    <div
        v-if="objectives"
        class="inspector-panel panel-objectives"
        v-shortcuts.prevent
    >

        <!-- Sequence mode selection -->
        <div class="property property-sequence">
            <Icon v-show="objectives.sequence === classSceneObjectivesSequenceType.Linear.type" name="icon_sequence_linear" class="icon-sequence-linear" />
            <Icon v-show="objectives.sequence === classSceneObjectivesSequenceType.Free.type" name="icon_sequence_free" class="icon-sequence-free" />
            <RadioButtons
                :model="objectives"
                property="sequence"
                :required="true"
                :buttons="getSequenceButtons()"
                @change="onChangeSequence"
            />
        </div>

        <!-- Order of objectives -->
        <Collapsible :class="'property property-order sequence-' + objectives.sequence">
            <template #header>
                {{ trans('labels.objectives') }}
            </template>
            <template #body>
                <Draggable
                    v-for="(uid, index) in objectives.order"
                    :key="'objectivesOrder_'+index"
                    groups="scene-objectives"
                    :disabled="(objectives.order.length < 2)"
                    :value="index"
                    @drop="onDropObjectiveOrder"
                    class="objective">
                        <PanelObjectivesListItem
                            :triggerUid="uid"
                            :objectives="objectives"
                            :positionInSequence="index + 1"
                        />
                </Draggable>
            </template>
        </Collapsible>

        <!-- Commands -->
        <Collapsible ref="trigger-when-all-completed" class="objectives-commands trigger" id="panel-objectives-commands">

            <template #header>
                <Icon name="icon_objectives-completed" class="round bg icon-trigger" />
                <span class="caption">{{ trans('triggers.objectives_completed') }}</span>
            </template>

            <template #body>

                <!-- Commands Sequence -->
                <div v-if="objectives.commandsCount >= 2" class="property trigger-commands-sequence">
                    <Dropdown
                        class="no-wrap"
                        :key="'commandsSequence'+objectives.execution_type"
                        :label="trans('authoring.execute_actions')"
                        :model="objectives"
                        property="execution_type"
                        :options="getCommandSequenceOptions()"
                        @select="onChangeCommandsSequence"
                    />
                </div>

                <header>{{ trans('labels.actions') }}</header>

                <!-- Settings for each command -->
                <Draggable
                    v-for="(command, cmdIndex) in objectives.commands"
                    groups="objectives-command"
                    v-focusable
                    :drop-only="(objectives.commandsCount < 2)"
                    :value="cmdIndex"
                    :draggable-selector="'header[draggable]'"
                    @drop="onDropCommand"
                    :class="'panel-card objectives-command trigger-command' + (!command.isValid ? ' is-invalid' : '')"
                    :key="'objectivecommand_'+command.uid+command.type+cmdIndex+'_'+objectives.commandsCount"
                    :id="'command'+command.uid"
                    :data-command-uid="command.uid"
                    :data-uid="command.uid"
                    :data-ref-uid="command.referencedObjectUid || 'no-ref'"
                >
                    <header :draggable="(objectives.commandsCount >= 2)">
                        <Icon v-if="!command.isValid" name="icon_invalid" class="icon-invalid" />
                        <label>{{ getCommandPrefix(cmdIndex) }}{{ command.commandType.title }}</label>
                        <Icon name="icon_close" class="icon-delete" @click="onClickRemoveCommand(command)" />
                    </header>

                    <PanelCommand
                        :command="command"
                        @change="onChangeCommand"
                    />
                </Draggable>

                <!-- Dropdown for new/unassigned command -->
                <div class="btn-add-with-dropdown">
                    <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
                    <ButtonSecondary v-not-focusable icon="icon_add" caption="labels.add_action" />
                    <Dropdown
                        :key="'dropdown-objectives_'+objectives.commandsCount"
                        @select="onSelectCommand"
                        :options="getCommandOptions()"
                        :deselected-caption="trans('authoring.select_action')"
                    />
                </div>
                <div class="trigger-command-droptarget"></div>

            </template>
        </Collapsible>

    </div>
</template>

<script>
    // Import classes:
    import DropdownOption           from '@/Utility/DropdownOption';
    import DropdownOptionGroup      from '@/Utility/DropdownOptionGroup';
    import RadioButtonConfig        from '@/Utility/RadioButtonConfig';
    import Clipboard                from '@/Utility/Clipboard';
    import { sortArrayByProperty, trans } from '@/Utility/Helpers';
    import Command                  from '@/Models/UnitData/Commands/Command';
    import CommandType              from '@/Models/UnitData/Commands/CommandType';
    import ExecutionType            from '@/Models/UnitData/Triggers/ExecutionType';
    import SceneObjectivesSequenceType from '@/Models/UnitData/Scenes/Objectives/SceneObjectivesSequenceType';
    import SceneObjectives          from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
    import PanelObjectivesListItem  from '@/Vue/Inspector/PanelObjectivesListItem';

    export default {
        name: 'PanelObjectives',
        emits: [
            'change',
        ],
        props: {
            objectives: {
                type: SceneObjectives,
                default: null
            }
        },
        components: {
            PanelObjectivesListItem,
        },
        data() {
            return {
                classSceneObjectivesSequenceType: SceneObjectivesSequenceType,
                shortcuts: new Map([
                    ['Copy.stop', this.onShortcutCopy],             // Copy (duplicate) the selected trigger/command to the clipboard
                    ['Duplicate.stop', this.onShortcutDuplicate],   // Duplicate the selected trigger/command
                    // #PRDA-8481 Disabled until we decide how to handle references between objects when cutting
                    //['Cut.stop', this.onShortcutCut],                // Cut the selected trigger/command (e.g. delete and duplicate to the clipboard)
                    ['Paste.global', this.onShortcutPaste],         // Paste trigger/command from clipboard
                    ['Delete.stop', this.onShortcutDelete],         // Delete trigger/command
                ])
            }
        },
        methods: {

            /**
             * Get execution type options for command sequences
             *
             * @returns {Array<DropdownOption>}
             */
            getCommandSequenceOptions() {
                const options = [];
                [
                    ExecutionType.Linear,
                    ExecutionType.Parallel
                ].forEach(t => {
                    options[options.length] = new DropdownOption({
                        caption: t.title,
                        disabled: false,
                        value: t.type
                    });
                });
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Transform the commands into options for the dropdown
             *
             * @returns {Array<DropdownOption>}
             */
            getCommandOptions() {
                const commands = CommandType.forObjectivesCompleted;
                const options = [];
                CommandType.GroupsAsArray.forEach(g => {
                    const group = new DropdownOptionGroup({
                        caption: trans('commands.groups.' + g),
                        isSeparator: true,
                        showGroupNameInCaption: false
                    });

                    commands.filter(c => c.group === g).forEach(c => group.options[group.options.length] = new DropdownOption({
                        caption: c.titleGrouped,
                        disabled: !c.enabled,
                        value: c.type
                    }));

                    sortArrayByProperty(group.options, 'caption', false);

                    if (group.options.length >= 1)
                    {
                        options[options.length] = group;
                    }
                });
                return sortArrayByProperty(options, 'caption', false);
            },

            /**
             * Get command prefix indexing numbers
             *
             * @param {Number} index
             * @returns {String}
             */
            getCommandPrefix(index) {
                const executionType = this.objectives.executionType;
                return (executionType.type === ExecutionType.Linear.type || executionType.type === ExecutionType.Steps.type) ? (index + 1) + '. ' : (executionType.type === ExecutionType.RandomSequence.type || executionType.type === ExecutionType.Random.type) ? '?. ' : '';
            },

            /**
             * Select handler for the command dropdown
             *
             * @param {String} value
             */
            onSelectCommand(value) {
                const commandType = CommandType.getByTypeName(value);
                if (commandType === null)
                {
                    throw new TypeError('PanelObjectives->onSelectCommand(): Invalid CommandType "' + value + '"');
                }

                // Add the new command:
                if (this.objectives.mergeCommand(
                    Command.createWithType(
                        commandType,
                        null,
                        this.objectives
                    )))
                {
                    this.$emit('change', this.objectives);
                }

                return this;
            },

            /**
             * Change handler for the command settings
             *
             * @param {Command} command
             */
            onChangeCommand(command) {
                this.$emit('change', this.objectives);
                return this;
            },

            /**
             * Change handler for the commands sequence
             *
             * @param {Command} command
             */
            onChangeCommandsSequence() {
                this.$emit('change', this.objectives);
                return this;
            },

            /**
             * Drop handler for objectives order
             *
             * @param {MouseEvent} e
             */
            onDropObjectiveOrder(e) {
                e.preventDefault();
                if (!(e.dataDraggable instanceof Object) || !(e.dataDropTarget instanceof Object)) {
                    console.warn('PanelObjectives->onDropObjectiveOrder(): Invalid dragging data.', e);
                    return this;
                }

                const currentIndex = e.dataDraggable?.value || 0;
                const targetIndex = e.dataDropTarget?.value || 0;
                const order = this.objectives.order;
                const uid = this.objectives.order[currentIndex];
                let hasChanged = false;
                if (targetIndex > currentIndex)
                {
                    order.splice(targetIndex + 1, 0, uid);
                    order.splice(currentIndex, 1);
                    hasChanged = true;
                }
                else if (targetIndex < currentIndex)
                {
                    order.splice(targetIndex, 0, uid);
                    order.splice(currentIndex + 1, 1);
                    hasChanged = true;
                }
                //console.log('onDrop', e, targetIndex, uid, order, currentIndex);
                if (hasChanged === true)
                {
                    this.$emit('change', this.objectives);
                }
                return this;
            },

            /**
             * Get radiobutton configurations for sequence
             *
             * @returns {Array<RadioButtonConfig>}
             */
            getSequenceButtons() {
                const buttons = [];
                SceneObjectivesSequenceType.all.forEach(st => {
                    buttons.push(new RadioButtonConfig({
                        value: st.type,
                        caption: st.title
                    }));
                });
                return buttons;
            },

            /**
             * Change handler for the objectives sequence
             */
            onChangeSequence() {
                this.$emit('change', this.objectives);
                return this;
            },

            /**
             * Click handler for command remove button
             *
             * @param {Command} command   // Command object reference
             */
            onClickRemoveCommand(command) {
                const cmdIndex = this.objectives.commands.findIndex(c => c.uid === command.uid);
                // Remove the command:
                this.objectives.removeCommand(command);
                // Set focus:
                if (this.objectives.hasCommands === true)
                {
                    // Focus on the previous (or next) command:
                    this.setFocus(this.objectives.commands[Math.max(0, cmdIndex - 1)]);
                }
                else
                {
                    // Focus on the collapsible trigger header if there are no other commands:
                    this.setFocus('when-all-completed');
                }
                this.$emit('change', this.objectives);
                return this;
            },

            /**
             * Drop handler for commands
             *
             * @param {MouseEvent} e
             */
            onDropCommand(e) {
                e.preventDefault();
                if (!(e.dataDraggable instanceof Object) || !(e.dataDropTarget instanceof Object)) {
                    console.warn('PanelObjectives->onDropCommand(): Invalid dragging data.', e);
                    return this;
                }

                const currentIndex = e.dataDraggable?.value || 0;
                const targetIndex = e.dataDropTarget?.value || 0;
                const commands = this.objectives.commands;
                let hasChanged = false;

                // Source and target contents must not be the same:
                if (currentIndex !== targetIndex)
                {
                    if (targetIndex > currentIndex)
                    {
                        commands.splice(targetIndex + 1, 0, commands[currentIndex]);
                        commands.splice(currentIndex, 1);
                        hasChanged = true;
                    }
                    else if (targetIndex < currentIndex)
                    {
                        commands.splice(targetIndex, 0, commands[currentIndex]);
                        commands.splice(currentIndex + 1, 1);
                        hasChanged = true;
                    }
                    //console.log('onDrop', e, currentIndex, targetIndex);
                }
                if (hasChanged === true)
                {
                    this.$emit('change', this.objectives);
                }
                return this;
            },

            /**
             * Shortcut handler: Copy
             *
             * @param {CustomEvent} e
             */
            onShortcutCopy(e) {
                const { command } = this.getDataByEvent(e);

                // Copy the selected command:
                if (command)
                {
                    // Copy a new instance of the selected command to the clipboard:
                    Clipboard.setClipboardDataAsync(command);
                    return this;
                }
                return this;
            },

            /**
             * Shortcut handler: Duplicate
             *
             * @param {CustomEvent} e
             */
            onShortcutDuplicate(e) {
                const { command } = this.getDataByEvent(e);

                // Duplicate the selected command:
                if (command)
                {
                    // Insert a duplicated instance of the command:
                    const duplicatedCommand = this.objectives.mergeCommand(command, command);
                    if (duplicatedCommand !== null)
                    {
                        this.setFocus(duplicatedCommand);
                        this.$emit('change', this.objectives);
                    }
                    return this;
                }
                return this;
            },

            /**
             * Shortcut handler: Cut
             *
             * @param {CustomEvent} e
             */
            onShortcutCut(e) {
                const { command } = this.getDataByEvent(e);

                // Cut the selected command:
                if (command)
                {
                    // Copy a new instance of the selected command to the clipboard:
                    Clipboard.setClipboardDataAsync(command);
                    this.onClickRemoveCommand(command);
                    return this;
                }
                return this;
            },

            /**
             * Shortcut handler: Paste
             *
             * @param {CustomEvent} e
             */
            onShortcutPaste(e) {
                // Do nothing if the clipboard is empty:
                const clipboardData = Clipboard.getDataFromClipboardEvent(e.detail.clipboardEvent);
                if (clipboardData === null || clipboardData.isEmpty || !clipboardData.isModel)
                {
                    return this;
                }

                // Get data from the clipboard:
                let commandsToPaste = (clipboardData.isInstanceOf(Command)) ? [Command.fromClipboardData(clipboardData)].filter(c => c !== null) : [];
                if (commandsToPaste.length === 0)
                {
                    return this;
                }

                // Get source command from the event:
                const { command } = this.getDataByEvent(e);

                // Paste commands into the objectives:
                const mergedCommands = this.objectives.mergeCommands(commandsToPaste, command);
                if (mergedCommands.length >= 1)
                {
                    // Expand the trigger if it is collapsed:
                    if (this.$refs['trigger-when-all-completed'].collapsed === true)
                    {
                        this.$refs['trigger-when-all-completed'].expand(e);
                    }
                    // Set focus on the last of the newly inserted commands:
                    this.setFocus(mergedCommands[mergedCommands.length - 1]);
                    this.$emit('change', this.objectives);
                }
                return this;
            },

            /**
             * Shortcut handler: Delete
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                const { command } = this.getDataByEvent(e);

                // Delete the selected command:
                if (command)
                {
                    return this.onClickRemoveCommand(command);
                }
                return this;
            },

            /**
             * Get models by event
             *
             * @param {CustomEvent} e
             * @returns {Object}
             */
            getDataByEvent(e) {
                // @NOTE: Using parentNode for collapsible elements since the data will be on the parent of the slot!
                const commandUid = e.target.dataset.commandUid || e.target.parentNode.dataset.commandUid || null;
                const result = {
                    command: null
                };
                if (commandUid !== null)
                {
                    result.command = this.objectives.commands.find(c => c.uid === commandUid) || null;
                }
                return result;
            },

            /**
             * Set focus on a given object
             *
             * @param {Object} obj
             */
            setFocus(obj) {
                if (obj instanceof Command)
                {
                    this.$nextTick(() => {
                        document.getElementById('command'+obj.uid).focus();
                    });
                }
                else if (obj === 'when-all-completed')
                {
                    this.$nextTick(() => {
                        document.getElementById('panel-objectives-commands').firstElementChild.focus();
                    });
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .property-sequence {
        display: flex;
        flex-direction: row;
        align-items: center;

        .icon {
            flex-shrink: 0;
            flex-grow: 0;
            flex-basis: 50px;
        }

        .radiobutton-group {
            flex-grow: 1;
            text-align: right;
        }
    }

    .property-order {
        .objective {
            display: flex;
            flex-direction: row;
            max-width: 100%;

            &:only-child {
                cursor: default;
            }
        }
    }

</style>
