// Register global components:
import ButtonCustom from "@/Vue/Common/ButtonCustom";
import ButtonCircular from "@/Vue/Common/ButtonCircular";
import ButtonPrimary from "@/Vue/Common/ButtonPrimary";
import ButtonSecondary from "@/Vue/Common/ButtonSecondary";
import Checkbox from "@/Vue/Common/Checkbox";
import Collapsible from "@/Vue/Common/Collapsible";
import ColorHexInput from "@/Vue/Common/ColorHexInput";
import Draggable from "@/Vue/Common/Draggable";
import Dropdown from "@/Vue/Common/Dropdown";
import Icon from "@/Vue/Common/Icon";
import NumberInput from "@/Vue/Common/NumberInput";
import PageHeader from "@/Vue/Common/PageHeader";
import RadioButtons from "@/Vue/Common/RadioButtons";
import StatusLabel from "@/Vue/Common/StatusLabel";
import Tabs from "@/Vue/Common/Tabs.vue";
import TagList from "@/Vue/Common/TagList";
import TextInput from "@/Vue/Common/TextInput";
import TextOutputField from "@/Vue/Common/TextOutputField";
import Pagination from "@/Vue/Common/Pagination";
import DialogAbout from "@/Vue/Modals/DialogAbout";
import DialogInstallation from "@/Vue/Modals/Installation/DialogInstallation";

// Register components for Laravel views:
import ArtisanCommands from "@/Vue/Admin/ArtisanCommands";
import Authoring from "@/Vue/Authoring/Authoring";
import PageAssetList from "@/Vue/Assets/PageAssetList";
import SidemenuItem from "@/Vue/Sidemenu/SidemenuItem.vue";
import SidemenuItemUserGuiding from "@/Vue/Sidemenu/SidemenuItemUserGuiding";
import PageTenantCreate from "@/Vue/Tenants/PageTenantCreate";
import PageTenantList from "@/Vue/Tenants/PageTenantList";
import PageTenantUpdate from "@/Vue/Tenants/PageTenantUpdate";
import ApiTokenPage from "@/Vue/Tokens/ApiTokenPage";
import PageUserList from "@/Vue/Users/PageUserList.vue";
import LogoutUsersForm from "@/Vue/Tokens/LogoutUsersForm";
import CreateUserForm from "@/Vue/Users/CreateUserForm";
import DeleteUserForm from "@/Vue/Users/DeleteUserForm";
import UpdateUserForm from "@/Vue/Users/UpdateUserForm";
import Userguiding from "@/Vue/Bootstrap/Userguiding.vue";
import UserNavigationItem from "@/Vue/Users/UserNavigationItem";
import SetInitialPasswordPage from "@/Vue/Users/SetInitialPasswordPage";
import PageCourseCreate from "@/Vue/Courses/PageCourseCreate";
import PageCourseEdit from "@/Vue/Courses/PageCourseEdit";
import PageCourseList from "@/Vue/Courses/PageCourseList";
import PageCourseView from "@/Vue/Courses/PageCourseView";
import CreateAssetForm from "@/Vue/Assets/CreateAssetForm";
import PageImportList from "@/Vue/Import/PageImportList";
import PageUnitList from "@/Vue/Units/PageUnitList";
import PageUnitCreate from "@/Vue/Units/PageUnitCreate";
import PageUnitLaunch from "@/Vue/Units/PageUnitLaunch";
import PageModelViewer from "@/Vue/Tools/ModelViewer/PageModelViewer";
import PageLmsXApiLaunch from "@/Vue/Lms/xapi/PageLmsXApiLaunch";
import PageLmsXApiLaunchError from "@/Vue/Lms/xapi/PageLmsXApiLaunchError";
import ManageLocalFeaturesForm from "@/Vue/Features/Local/ManageLocalFeaturesForm.vue";
import PageStatistics from "@/Vue/Statistics/PageStatistics.vue";
import PermissionsTable from "@/Vue/Admin/PermissionsTable.vue";
import TranslationsTable from "@/Vue/Admin/TranslationsTable.vue";
import FeaturesTable from "@/Vue/Admin/FeaturesTable.vue";

// Command Panels
import PanelCommandActivateModule from "@/Vue/Inspector/Commands/PanelCommandActivateModule";
import PanelCommandAIKnowledge from "@/Vue/Inspector/Commands/PanelCommandAIKnowledge";
import PanelCommandAIPrompt from "@/Vue/Inspector/Commands/PanelCommandAIPrompt";
import PanelCommandBehaviourChange from "@/Vue/Inspector/Commands/PanelCommandBehaviourChange";
import PanelCommandBehaviourChangeCollidable from "@/Vue/Inspector/Commands/PanelCommandBehaviourChangeCollidable";
import PanelCommandBehaviourChangeMovable from "@/Vue/Inspector/Commands/PanelCommandBehaviourChangeMovable";
import PanelCommandBehaviourChangePhysics from "@/Vue/Inspector/Commands/PanelCommandBehaviourChangePhysics";
import PanelCommandBehaviourChangeTeleportable from "@/Vue/Inspector/Commands/PanelCommandBehaviourChangeTeleportable";
import PanelCommandChangeScene from "@/Vue/Inspector/Commands/PanelCommandChangeScene";
import PanelCommandCondition from "@/Vue/Inspector/Commands/PanelCommandCondition";
import PanelCommandControl3dAnimation from "@/Vue/Inspector/Commands/PanelCommandControl3dAnimation";
import PanelCommandControlCharacterAnimation from "@/Vue/Inspector/Commands/PanelCommandControlCharacterAnimation";
import PanelCommandDeactivateModule from "@/Vue/Inspector/Commands/PanelCommandDeactivateModule";
import PanelCommandFade from "@/Vue/Inspector/Commands/PanelCommandFade";
import PanelCommandFeedback from "@/Vue/Inspector/Commands/PanelCommandFeedback";
import PanelCommandHelperAnimationPlay from "@/Vue/Inspector/Commands/PanelCommandHelperAnimationPlay";
import PanelCommandHelperGlowChange from "@/Vue/Inspector/Commands/PanelCommandHelperGlowChange";
import PanelCommandHelperKnowledge from "@/Vue/Inspector/Commands/PanelCommandHelperKnowledge";
import PanelCommandHelperPrompt from "@/Vue/Inspector/Commands/PanelCommandHelperPrompt";
import PanelCommandHelperSpeak from "@/Vue/Inspector/Commands/PanelCommandHelperSpeak";
import PanelCommandHelperTriggerInvoke from "@/Vue/Inspector/Commands/PanelCommandHelperTriggerInvoke";
import PanelCommandHelperWaypointGoTo from "@/Vue/Inspector/Commands/PanelCommandHelperWaypointGoTo";
import PanelCommandImageShow from "@/Vue/Inspector/Commands/PanelCommandImageShow";
import PanelCommandInputStyle from "@/Vue/Inspector/Commands/PanelCommandInputStyle";
import PanelCommandLearningRecord from "@/Vue/Inspector/Commands/PanelCommandLearningRecord";
import PanelCommandScript from "@/Vue/Inspector/Commands/PanelCommandScript";
import PanelCommandTriggerCancel from "@/Vue/Inspector/Commands/PanelCommandTriggerCancel";
import PanelCommandTriggerInvoke from "@/Vue/Inspector/Commands/PanelCommandTriggerInvoke";
import PanelCommandShowHide from "@/Vue/Inspector/Commands/PanelCommandShowHide";
import PanelCommandShowText from "@/Vue/Inspector/Commands/PanelCommandShowText";
import PanelCommandSoundPlay from "@/Vue/Inspector/Commands/PanelCommandSoundPlay";
import PanelCommandUnitExit from "@/Vue/Inspector/Commands/PanelCommandUnitExit";
import PanelCommandUnitReset from "@/Vue/Inspector/Commands/PanelCommandUnitReset";
import PanelCommandVariableOperation from "@/Vue/Inspector/Commands/PanelCommandVariableOperation";
import PanelCommandVideoPlay from "@/Vue/Inspector/Commands/PanelCommandVideoPlay";
import PanelCommandWait from "@/Vue/Inspector/Commands/PanelCommandWait";
import PanelCommandWorldAnchorReset from "@/Vue/Inspector/Commands/PanelCommandWorldAnchorReset";
import PanelVariableOperandNumber from "@/Vue/Inspector/Variables/PanelVariableOperandNumber";

// Wrapper for commands
import PanelCommand from "@/Vue/Inspector/PanelCommand";

// Behaviour Panels
import PanelBehaviourCollidable from "@/Vue/Inspector/Behaviours/PanelBehaviourCollidable";
import PanelBehaviourMovable from "@/Vue/Inspector/Behaviours/PanelBehaviourMovable";
import PanelBehaviourPhysics from "@/Vue/Inspector/Behaviours/PanelBehaviourPhysics";
import PanelBehaviourTeleportable from "@/Vue/Inspector/Behaviours/PanelBehaviourTeleportable";

// Wrapper for behaviours
import PanelBehaviour from "@/Vue/Inspector/Behaviours/PanelBehaviour";

// Variable Panels
import PanelVariableBool from "@/Vue/Inspector/Variables/PanelVariableBool";
import PanelVariableNumber from "@/Vue/Inspector/Variables/PanelVariableNumber";

// Wrapper for variables
import PanelVariable from "@/Vue/Inspector/Variables/PanelVariable";

// register components
export function registerComponents(app) {
    // Register global components:
    app.component('ButtonCustom', ButtonCustom);
    app.component('ButtonCircular', ButtonCircular);
    app.component('ButtonPrimary',  ButtonPrimary);
    app.component('ButtonSecondary', ButtonSecondary);
    app.component('Checkbox', Checkbox);
    app.component('Collapsible', Collapsible);
    app.component('ColorHexInput', ColorHexInput);
    app.component('Draggable', Draggable);
    app.component('Dropdown', Dropdown);
    app.component('Icon', Icon);
    app.component('NumberInput', NumberInput);
    app.component('PageHeader', PageHeader);
    app.component('RadioButtons',RadioButtons);
    app.component('StatusLabel', StatusLabel);
    app.component('Tabs', Tabs);
    app.component('TagList', TagList);
    app.component('TextInput', TextInput);
    app.component('TextOutputField', TextOutputField);
    app.component('Pagination', Pagination);
    app.component('DialogAbout', DialogAbout);
    app.component('DialogInstallation', DialogInstallation);

    // Register components for Laravel views:
    // @NOTE: Only kebab-case is working here since these components are loaded in Laravel views!
    app.component('artisan-commands', ArtisanCommands);
    app.component('authoring', Authoring);
    app.component('page-asset-list', PageAssetList);
    app.component('sidemenu-item', SidemenuItem);
    app.component('sidemenu-item-userguiding', SidemenuItemUserGuiding);
    app.component('page-tenant-create', PageTenantCreate);
    app.component('page-tenant-list', PageTenantList);
    app.component('page-tenant-update', PageTenantUpdate);
    app.component('api-token-page', ApiTokenPage);
    app.component('page-user-list', PageUserList);
    app.component('logout-users-form', LogoutUsersForm);
    app.component('create-user-form', CreateUserForm);
    app.component('delete-user-form', DeleteUserForm);
    app.component('update-user-form', UpdateUserForm);
    app.component('manage-local-features-form', ManageLocalFeaturesForm);
    app.component('user-navigation-item', UserNavigationItem);
    app.component('userguiding', Userguiding);
    app.component('set-initial-password-page', SetInitialPasswordPage);
    app.component('page-course-create', PageCourseCreate);
    app.component('page-course-edit', PageCourseEdit);
    app.component('page-course-list', PageCourseList);
    app.component('page-course-view', PageCourseView);
    app.component('create-asset-form', CreateAssetForm);
    app.component('page-import-list', PageImportList);
    app.component('page-unit-list', PageUnitList);
    app.component('page-unit-create', PageUnitCreate);
    app.component('page-unit-launch', PageUnitLaunch);
    app.component('page-model-viewer', PageModelViewer);
    app.component('page-lms-xapi-launch', PageLmsXApiLaunch);
    app.component('page-lms-xapi-launch-error', PageLmsXApiLaunchError);
    app.component('page-statistics', PageStatistics);
    app.component('permissions-table', PermissionsTable);
    app.component('translations-table', TranslationsTable);
    app.component('features-table', FeaturesTable);

    // Command Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelCommandActivateModule', PanelCommandActivateModule);
    app.component('PanelCommandAIKnowledge', PanelCommandAIKnowledge);
    app.component('PanelCommandAIPrompt', PanelCommandAIPrompt);
    app.component('PanelCommandBehaviourChange', PanelCommandBehaviourChange);
    app.component('PanelCommandBehaviourChangeCollidable', PanelCommandBehaviourChangeCollidable);
    app.component('PanelCommandBehaviourChangeMovable', PanelCommandBehaviourChangeMovable);
    app.component('PanelCommandBehaviourChangePhysics', PanelCommandBehaviourChangePhysics);
    app.component('PanelCommandBehaviourChangeTeleportable', PanelCommandBehaviourChangeTeleportable);
    app.component('PanelCommandChangeScene', PanelCommandChangeScene);
    app.component('PanelCommandCondition', PanelCommandCondition);
    app.component('PanelCommandControl3dAnimation', PanelCommandControl3dAnimation);
    app.component('PanelCommandControlCharacterAnimation', PanelCommandControlCharacterAnimation);
    app.component('PanelCommandDeactivateModule', PanelCommandDeactivateModule);
    app.component('PanelCommandFade', PanelCommandFade);
    app.component('PanelCommandFeedback', PanelCommandFeedback);
    app.component('PanelCommandHelperAnimationPlay', PanelCommandHelperAnimationPlay);
    app.component('PanelCommandHelperGlowChange', PanelCommandHelperGlowChange);
    app.component('PanelCommandHelperKnowledge', PanelCommandHelperKnowledge);
    app.component('PanelCommandHelperPrompt', PanelCommandHelperPrompt);
    app.component('PanelCommandHelperSpeak', PanelCommandHelperSpeak);
    app.component('PanelCommandHelperTriggerInvoke', PanelCommandHelperTriggerInvoke);
    app.component('PanelCommandHelperWaypointGoTo', PanelCommandHelperWaypointGoTo);
    app.component('PanelCommandImageShow', PanelCommandImageShow);
    app.component('PanelCommandInputStyle', PanelCommandInputStyle);
    app.component('PanelCommandLearningRecord', PanelCommandLearningRecord);
    app.component('PanelCommandScript', PanelCommandScript);
    app.component('PanelCommandTriggerCancel', PanelCommandTriggerCancel);
    app.component('PanelCommandTriggerInvoke', PanelCommandTriggerInvoke);
    app.component('PanelCommandShowHide', PanelCommandShowHide);
    app.component('PanelCommandShowText', PanelCommandShowText);
    app.component('PanelCommandSoundPlay', PanelCommandSoundPlay);
    app.component('PanelCommandUnitExit', PanelCommandUnitExit);
    app.component('PanelCommandUnitReset', PanelCommandUnitReset);
    app.component('PanelCommandVariableOperation', PanelCommandVariableOperation);
    app.component('PanelCommandVideoPlay', PanelCommandVideoPlay);
    app.component('PanelCommandWait', PanelCommandWait);
    app.component('PanelCommandWorldAnchorReset', PanelCommandWorldAnchorReset);
    app.component('PanelVariableOperandNumber', PanelVariableOperandNumber);

    // Wrapper for commands
    // This should be used instead of the individual command panels.
    app.component('PanelCommand', PanelCommand);

    // Behaviour Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelBehaviourCollidable', PanelBehaviourCollidable);
    app.component('PanelBehaviourMovable', PanelBehaviourMovable);
    app.component('PanelBehaviourPhysics', PanelBehaviourPhysics);
    app.component('PanelBehaviourTeleportable', PanelBehaviourTeleportable);

    // Wrapper for behaviours
    // This should be used instead of the individual behaviour panels.
    app.component('PanelBehaviour', PanelBehaviour);

    // Variable Panels
    // Because of circular dependencies we have to register them globally for now
    app.component('PanelVariableBool', PanelVariableBool);
    app.component('PanelVariableNumber', PanelVariableNumber);

    // Wrapper for variables
    // This should be used instead of the individual variable panels.
    app.component('PanelVariable', PanelVariable);
}
