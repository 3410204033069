<template>
    <ButtonCustom
        class="btn-primary"
    />
</template>

<script>
    export default {
        name: 'ButtonPrimary',
    }
</script>

<style lang="scss" scoped>

</style>
