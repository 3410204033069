import {uuid4} from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';

/* NOTE: New Variable classes have to be added to mapping at the end of this file! */

/**
 * @abstract
 */
export default class Variable extends AbstractDataObject
{
    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     * @param {Object} parent               // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        if (new.target === Variable) {
            throw new TypeError('Cannot construct Variable instances directly');
        }

        if (new.target && new.target.Type !== attributes.type) {
            throw new TypeError(`"${new.target.name}" cannot be instantiated with type attribute "${attributes.type}". "${new.target.Type}" expected instead.`);
        }

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        this.uid = attributes.uid || uuid4();                               // Unique ID
        this.originalUid = this.uid;                                        // Original unique ID from which the object was duplicated (hidden)
        this.value = (attributes.value !== undefined) ? attributes.value : this.defaultValue;
        this.name = attributes.name || '';
        this.type = attributes.type;
    }

    static get Type() {
        return 'undefined';
    }

    get defaultValue() {
        return undefined;
    }

    get isValid() {
        return this.type === this.constructor.Type && typeof this.name === 'string' && this.name.trim() !== '';
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        // @NOTE: Override this method on subclasses to make sure a variable only uses valid data
        let hasChanged = false;
        if (this.type !== this.constructor.Type)
        {
            console.info('Variable->cleanUpData(): Changing incorrect type.', this.type, this);
            this.type = this.constructor.Type;
            hasChanged = true;
        }
        if (typeof this.name !== 'string')
        {
            console.info('Variable->cleanUpData(): Setting default name.', this.name, this);
            this.name = '';
            hasChanged = true;
        }
        else if (this.name !== this.name.trim())
        {
            console.info('Variable->cleanUpData(): Removing whitespace from name.', this.name, this);
            this.name = this.name.trim();
            hasChanged = true;
        }
        return hasChanged;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {Variable}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = getVariableForData(this, this.parent);
        duplicated.uid = uuid4();

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Create a new variable with the given variableType and filled with default values.
     *
     * @param {String} variableType
     * @param {Object} parent
     * @returns {Variable}
     */
    static createDefaultWithType(variableType, parent = null) {
        return getVariableForData({type: variableType}, parent);
    }
}

export class BoolVariable extends Variable
{
    constructor(attributes, parent) {
        super(attributes, parent);
    }

    static get Type() {
        return 'bool';
    }

    get defaultValue() {
        return false;
    }

    get isValid() {
        return typeof this.value === 'boolean' && super.isValid;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (typeof this.value !== 'boolean')
        {
            console.info('BoolVariable->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            hasChanged = true;
        }
        return hasChanged;
    }
}

export class NumberVariable extends Variable
{
    constructor(attributes, parent) {
        super(attributes, parent);
    }

    static get Type() {
        return 'number';
    }

    get defaultValue() {
        return 0;
    }

    static get MinValue() {
        return -999999;
    }

    static get MaxValue() {
        return 999999;
    }

    get isValid() {
        return !isNaN(this.value)
            && this.value >= NumberVariable.MinValue
            && this.value <= NumberVariable.MaxValue
            && this.value % 1 === 0
            && super.isValid;
    }

    /**
     * Clean up data
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();
        if (isNaN(this.value))
        {
            console.info('NumberVariable->cleanUpData(): Resetting invalid value to default.', this.value, this);
            this.value = this.defaultValue;
            hasChanged = true;
        }
        else if (this.value !== parseInt(this.value, 10))
        {
            console.info('NumberVariable->cleanUpData(): Changing float or string value to integer.', this.value, this);
            this.value = parseInt(this.value, 10);
            hasChanged = true;
        }
        if (this.value > NumberVariable.MaxValue)
        {
            console.info('NumberVariable->cleanUpData(): Resetting out-of-range value to maximum.', this.value, this);
            this.value = NumberVariable.MaxValue;
            hasChanged = true;
        }
        else if (this.value < NumberVariable.MinValue)
        {
            console.info('NumberVariable->cleanUpData(): Resetting out-of-range value to minimum.', this.value, this);
            this.value = NumberVariable.MinValue;
            hasChanged = true;
        }
        return hasChanged;
    }
}

/**
 * Variable.type to Variable mapping
 *
 * @type {Map<string|*, Variable>}
 */
export const variableTypeToVariableMapping = new Map([
    [BoolVariable.Type, BoolVariable],
    [NumberVariable.Type, NumberVariable],
]);

/**
 * Get the Variable class for the specified type
 *
 * @param {String} type
 * @returns {Variable}
 */
export function getVariableClassFromType(type) {
    return variableTypeToVariableMapping.has(type) ? variableTypeToVariableMapping.get(type) : null;
}

/**
 * Get a proper Variable object for attributes
 *
 * @param {Object} attributes
 * @param parent
 * @returns {Variable}
 */
export function getVariableForData(attributes, parent = null) {
    if (!(attributes instanceof Object)) {
        return null;
    }

    const className = getVariableClassFromType(attributes.type);
    return className === null ? null : new className(attributes, parent);
}
