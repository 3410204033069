<template>
    <div class="dialog-installation-cell-app">
        <header>
            <span class="logo" v-html="logoImageTag"></span>
            <h2 class="title">{{ title }}</h2>
        </header>
        <p class="description" v-html="description"></p>
        <div v-if="isLoggedIn" class="dialog-installation-cell-app-submit">
            <form>
                <TextInput
                    :key="'device_manager_id'+key"
                    :model="model"
                    property="device_manager_id"
                    :maxlength="10000"
                    :required="true"
                    :placeholder="br2nl(trans('modals.install_app.apps.oculus_business.input.placeholder'))"
                    :error-msg="trans('modals.install_app.apps.oculus_business.input.errors.generic')"
                    @change="onChangeDeviceManagerId"
                    :validationErrors="validationErrors('device_manager_id')"
                    :disabled="isInputDisabled"
                />
            </form>
        </div>
        <footer>
            <ButtonPrimary v-if="isLoggedIn" class="btn-apply" :caption="buttonCaption" @trigger.prevent="onSubmit" :disabled="isSubmitButtonDisabled"/>
            <a v-if="linkHref" :href="linkHref" target="_blank">{{ linkCaption }}</a>
            <span v-else></span>
        </footer>
        <span class="success-message" v-if="showSuccessMessage">{{ trans('modals.install_app.apps.oculus_business.input.success_message') }}</span>
    </div>
</template>

<script>
    import {route, trans} from '@/Utility/Helpers';

    export default {
    name: 'DialogInstallationCellAppQuestBusiness',
    props: {
        logoName: {
            type: String,
        },
        title: {
            type: String,
        },
        buttonCaption: {
            type: String,
        },
        buttonHref: {
            type: String,
        },
        linkCaption: {
            type: String,
        },
        linkHref: {
            type: String,
        },
        url: {
            type: String,
            default: route('api.installer.quest.business.submit'),
        }
    },

    data() {
        return {
            isSubmitting: false,
            model: {
                device_manager_id: null,
            },
            errors: {},
            key: 0,
            submitted: false,
        }
    },

    computed: {
        logoImageTag() {
            return `<img src="/images/logos/${this.logoName}.png" alt="">`;
        },
        isInputDisabled() {
            return this.isSubmitting;
        },
        isSubmitButtonDisabled() {
            return this.isSubmitting || this.model.device_manager_id === null || this.model.device_manager_id === '';
        },
        showSuccessMessage() {
            return this.isSubmitting === false && this.submitted === true;
        },
        description() {
            return this.isLoggedIn ?
                trans('modals.install_app.apps.oculus_business.description') :
                trans('modals.install_app.apps.oculus_business.description_unauthorized');
        },
        isLoggedIn() {
          return window.currentUser !== null;
        },
    },

    methods: {
        /**
         * @param {String} text
         */
        onChangeDeviceManagerId(text) {
            this.model.device_manager_id = text;
            this.removeValidationError('device_manager_id');
            this.submitted = false;
        },

        validate() {
            this.removeValidationError('device_manager_id');
            this.key++;

            return this.validationErrors('device_manager_id').length === 0;
        },

        /**
         * Get the validation errors for a specific field.
         *
         * @param property
         * @returns {string}
         */
        validationErrors(property) {
            return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
        },

        /**
         * Clear the validatin errors for a specific field.
         *
         * @param property
         */
        removeValidationError(property) {
            delete this.errors[property];
        },

        /**
         * Click handler fpr the submit button
         *
         * @param event
         */
        onSubmit(event) {
            event.preventDefault();

            if (this.isSubmitting || !this.validate()) {
                return;
            }

            this.isSubmitting = true;
            const formData = this.model;

            axios({
                url: this.url,
                method: "post",
                data: formData,
            })
                .then(() => {
                    this.model.device_manager_id = null;
                    this.submitted = true;
                })
                .catch((error) => {
                    this.submitted = false;
                    this.errors = error.validationErrors || {};
                })
                .finally(() => {
                    this.isSubmitting = false;
                    this.key++;
                });
        },
    },
}
</script>

<style lang="scss" scoped>

    .dialog-installation-cell-app header {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .dialog-installation-cell-app header .logo {
        display: flex;
        width: 48px;
        height: 48px;
    }

    .dialog-installation-cell-app main .description {
        margin-bottom: 24px;
    }

    .dialog-installation-cell-app footer {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-top: 12px;
    }

    footer .btn {
        flex-shrink: 0;
    }

    .dialog-installation-cell-app-submit {
        margin-bottom: 12px;
    }

    .dialog-installation-cell-app-submit label {
        font-family: var(--font-family-condensed-demibold);
    }

    .dialog-installation-cell-app .success-message {
        display: block;
        margin-top: 10px;
    }

    @media screen and (min-width: 784px) {
        .dialog-installation-cell-app:not(:last-child) {
            min-height: 150px;
        }
    }
</style>
