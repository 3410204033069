import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route} from '@/Utility/Helpers';
import Token from '@/Models/User/Token';
import User from "@/Models/User/User";

export default class TokenService {

    public isLoading: Boolean = false;
    public isSaving: Boolean = false;
    public tokens: Token[] = [];
    private request: AxiosRequest | null = null;

    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null) {
            return await this.request.cancel();
        }
        return Promise.resolve('Requests canceled');
    }

    async fetchTokens(user: User): Promise<Token[]> {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        user = user || window.currentUser;

        this.isLoading = true;

        this.request = new AxiosRequest();
        return await this.request.get(
            route('api.users.tokens.index', {'user': user.uid})
        ).then(({data}: any) => {
            this.tokens = [];
            Object.keys(data).map((key: string): void => {
                try {
                    this.tokens.push(new Token(data[key]));
                } catch (ex) {
                    console.warn('TokenService->fetchTokens(): Skipping token with invalid or incompatible data.', data[key], ex);
                }
            });
            return Promise.resolve(this.tokens);
        }).finally((): void => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Revokes all login tokens of all users on this instance, so they need to re-authenticate.
     */
    async revokeAllLoginTokens(): Promise<void> {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }
        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.delete(
            route('api.tokens.deleteAll'), {
                params: {
                    token_type: 'login'
                }
            }
        ).then(() => {
            return Promise.resolve();
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Revokes the given token, so it cannot be used for API calls any longer.
     */
    async revokeToken(token: Token): Promise<any> {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }
        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.delete(
            route('api.tokens.delete', {token: token.id})
        ).then(() => {
            token.revoked = true;
            return Promise.resolve(token);
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Creates a new token for the given user.
     */
    async createToken(name: string, scopes: string[], user: User|null = null): Promise<Token> {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        user = user || window.currentUser;

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.users.tokens.create', {'user': user?.uid}), {
                token_type: 'api',
                name: name,
                scopes: scopes
            }
        ).then(({data}: any): Promise<Token> => {
            data.token.access_token = data.accessToken;
            const token = new Token(data.token);
            this.tokens.push(token);
            return Promise.resolve(token);
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }
}
