<template>
    <Collapsible class="inspector-panel panel-ai" :initial-collapsed="true">
        <template #header>
            {{ trans('labels.ai') }}
        </template>
        <template #body>
            <div class="property property-feature-not-available">
                <FeatureNotAvailable :data-object="sceneObject" />
            </div>

            <div class="property property-voice" :key="key">
                <header>{{ trans('authoring.ai.voice.label') }}</header>
                <Dropdown
                    :class="'no-wrap'"
                    :label="trans('authoring.ai.voice.language.label')"
                    :model="this"
                    :property="'selectedLocale'"
                    :options="dropdownOptionsForLanguage"
                    @select="onSelectLocale"
                    @click.stop
                />
                <Dropdown
                    :class="'no-wrap'"
                    :label="trans('authoring.ai.voice.label')"
                    :initial-value="sceneObject.ai.voice.name"
                    :options="dropdownOptionsForVoiceName"
                    @select="onSelectVoice"
                    @click.stop
                />

                <Dropdown
                    :disabled="dropdownOptionsForSpeakingStyle.length === 0"
                    :class="'no-wrap text-capitalize'"
                    :label="trans('authoring.ai.voice.speaking_style.label')"
                    :model="sceneObject.ai.voice"
                    :property="'speaking_style'"
                    :options="dropdownOptionsForSpeakingStyle"
                    @select="onSelectSpeakingStyle"
                    @click.stop
                />

                <TextInput
                    class="no-wrap"
                    type="textarea"
                    :label="trans('authoring.ai.voice.preview.label')"
                    :model="voice"
                    :property="'preview'"
                    :required="true"
                    :maxlength="800"
                    :placeholder="trans('authoring.ai.voice.preview.placeholder')"
                    :class="{ disabled: isPreviewPlayerDisabled }"
                />
                <div class="property property-preview-player">
                    <audio
                        ref="audio"
                        controls
                        :src="ttsAudioSource"
                        controlslist="nodownload noplaybackrate"
                        @contextmenu.prevent
                        @play="synthesizeIfNeeded(true, true)"
                        :class="{ disabled: isPreviewPlayerDisabled }"
                    />
                    <LoadingIndicator v-if="ttsService.isSynthesizing"/>
                </div>

            </div>

            <div class="property property-knowledge">
                <TextInput
                    type="textarea"
                    :label="trans('authoring.ai.knowledge.label')"
                    :model="sceneObject.ai"
                    property="knowledge"
                    @change="onChangeKnowledge"
                    :required="false"
                    :maxlength="800"
                    :placeholder="trans('authoring.ai.knowledge.placeholder')"
                />
            </div>
        </template>
    </Collapsible>
</template>

<script lang="ts">
    import {trans, shortId} from "@/Utility/Helpers";
    import {defineComponent} from "vue";
    import Collapsible from "@/Vue/Common/Collapsible.vue";
    import {SceneObjectAssetCharacterModel3D} from "@/Models/UnitData/SceneObjects/SceneObject";
    import TextInput from "@/Vue/Common/TextInput.vue";
    import FeatureNotAvailable from "@/Vue/Features/FeatureNotAvailable.vue";
    import TextToSpeechVoice from "@/Services/CognitiveServices/TextToSpeechVoice";
    import { Dictionary } from "lodash";
    import DropdownOption from "@/Utility/DropdownOption";
    import Dropdown from "@/Vue/Common/Dropdown.vue";
    import DropdownOptionGroup from "@/Utility/DropdownOptionGroup";
    import TextToSpeechService from "@/Services/CognitiveServices/TextToSpeechService";
    import TextToSpeechVoiceConfig from "@/Services/CognitiveServices/TextToSpeechVoiceConfig";
    import TextToSpeechResult from "@/Services/CognitiveServices/TextToSpeechResult";
    import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";

    export default defineComponent({
        name: "PanelAI",

        components: {
            LoadingIndicator,
            Dropdown,
            FeatureNotAvailable,
            TextInput,
            Collapsible
        },

        props: {
            sceneObject: {
                type: SceneObjectAssetCharacterModel3D,
                required: true
            }
        },

        data() {
            return {
                key: shortId(),
                selectedLocale: 'en-US',

                ttsService: new TextToSpeechService(),
                voice: {
                    preview: 'The quick brown fox jumps over the lazy dog',
                    ttsResult: null as TextToSpeechResult | null,
                },
            };
        },

        computed: {
            allowedLocales(): Array<string> {
                return ['en-US', 'en-GB'];
            },

            isPreviewPlayerDisabled(): boolean {
                return this.sceneObject.ai.voice.name === null ||
                    this.sceneObject.ai.voice.speaking_style === null ||
                    this.ttsService.isSynthesizing;
            },

            voicesByLocaleFiltered(): Dictionary<TextToSpeechVoice[]> {
                const filteredByLocale = Object.entries(TextToSpeechVoice.allByLocale)
                    .filter(([locale, _]) => this.allowedLocales.includes(locale));

                return Object.fromEntries(filteredByLocale);
            },

            speakingStyleForSelectedVoice(): Array<string> {
                const speakingStylesForVoice = this.speakingStylesForVoice(this.sceneObject.ai.voice.name);

                return speakingStylesForVoice || [];
            },

            dropdownOptionsForLanguage(): Array<DropdownOption> {
                return [
                    ...this.allowedLocales.map((value) => {
                        return new DropdownOption({
                            caption: trans('authoring.ai.voice.language.languages.' + value),
                            value: value,
                        })
                    })
                ];
            },

            dropdownOptionsForVoiceName(): Array<DropdownOption> {

                const groups = new Array<DropdownOptionGroup>;
                const options = {
                    'with_speaking_styles': this.voicesByLocaleFiltered[this.selectedLocale].filter(voice => voice.hasMultipleStyles),
                    'without_speaking_styles': this.voicesByLocaleFiltered[this.selectedLocale].filter(voice => !voice.hasMultipleStyles),
                };

                for (const optionsKey in options) {
                    groups.push(new DropdownOptionGroup({
                        caption: trans('authoring.ai.voice.speaking_style.option_groups.' + optionsKey),
                        isSeparator: true,
                        collapsible: false,
                        showGroupNameInCaption: false,
                        options: options[optionsKey].map((voice: TextToSpeechVoice) => {
                            const caption = voice.displayName ? voice.displayName : voice.shortName;
                            const styles = voice.hasMultipleStyles ? ' (' + voice.styleList?.length + ')' : '';
                            return new DropdownOption({
                                caption: caption + styles,
                                value: voice.shortName,
                            })
                        })
                    }));
                }

                return groups;
            },

            dropdownOptionsForSpeakingStyle(): Array<DropdownOption> {
                const speakingStylesForVoice = this.speakingStyleForSelectedVoice;

                const options = [
                    ...speakingStylesForVoice.map((value) => {
                        return new DropdownOption({
                            caption: trans('authoring.ai.voice.speaking_style.styles.' + value, {}, false, false) || value,
                            value: value,
                        })
                    })
                ];

                // If there is more than one option the first option is "automatic" which should be separated from the
                // regular speaking styles.
                if (options.length > 1) {
                    const separator = new DropdownOption({
                        isSeparator: true,
                    });

                    options.splice(1, 0, separator);
                }

                return options;
            },

            isTtsResultUpToDate() {
                return this.voice.ttsResult &&
                    this.voice.ttsResult.plainTextInput === this.voice.preview &&
                    this.voice.ttsResult.voiceConfig.voiceName === this.sceneObject.ai.voice.name &&
                    this.voice.ttsResult.voiceConfig.style === this.sceneObject.ai.voice.speaking_style;
            },

            ttsVoice(): TextToSpeechVoiceConfig {
                return new TextToSpeechVoiceConfig(
                    this.selectedLocale,
                    this.sceneObject.ai.voice.name,
                    true,
                    0,
                    0,
                    this.sceneObject.ai.voice.speaking_style
                );
            },

            ttsAudioSource() {
                if (this.isTtsResultUpToDate) {
                    return window.URL.createObjectURL(this.voice.ttsResult.blob);
                } else {
                    // silent audio as fallback to enable play button in html audio element
                    return 'data:audio/x-wav;base64,UklGRooWAABXQVZFZm10IBAAAAABAAEAIlYAAESsAAACABAAZGF0YWYWAAAAAA';
                }
            },
        },

        beforeMount() {
            if (this.sceneObject.ai.voice.name !== null) {
                this.selectedLocale = this.sceneObject.ai.voice.name.substring(0, 5);
            }
        },

        methods: {
            trans,

            onChangeKnowledge(text: string) {
                this.sceneObject.ai.knowledge = text;
                this.$emit('change', this.sceneObject);
                return this;
            },

            onSelectLocale(locale: string) {
                const defaultVoice = this.sceneObject.getDefaultVoiceForCharacter(locale)!;

                this.sceneObject.ai.voice.name = defaultVoice?.shortName || null;
                this.sceneObject.ai.voice.speaking_style = defaultVoice?.styleList[0] || null;

                this.key = shortId();
            },

            onSelectVoice(value: string) {
                this.sceneObject.ai.voice.name = value;

                // If the new voice supports the current speaking style keep it else reset to default
                if (!this.speakingStylesForVoice(this.sceneObject.ai.voice.name)?.includes(this.sceneObject.ai.voice.speaking_style)) {
                    this.sceneObject.ai.voice.speaking_style = this.speakingStyleForSelectedVoice[0];
                }

                this.$emit('change', this.sceneObject);
                this.key = shortId();
                return this;
            },

            onSelectSpeakingStyle(value: string) {
                this.sceneObject.ai.voice.speaking_style = value;
                this.$emit('change', this.sceneObject);
                this.key = shortId();
                return this;
            },

            speakingStylesForVoice(voiceName: string) {
                return this.voicesByLocaleFiltered[this.selectedLocale]?.find(voice => voice.shortName == voiceName)?.styleList;
            },

            async synthesize(playAfterSynthesis = false) {
                this.voice.ttsResult = await this.ttsService.synthesize(this.voice.preview, this.ttsVoice);

                if (playAfterSynthesis) {
                    // delay, so new source is already set on the element
                    setTimeout(() => this.$refs.audio.play(), 100);
                }
            },

            /**
             * @param {boolean} playAfterSynthesis
             * @param {boolean} showErrorOverlay when true, errors during synthesis will be caught and an error dialog shown
             * @return {Promise<void>}
             */
            async synthesizeIfNeeded(playAfterSynthesis = false, showErrorOverlay = true) {
                if (this.isTtsResultUpToDate) {
                    // synthesis is up-to-date
                    return;
                }

                try {
                    await this.synthesize(playAfterSynthesis);
                } catch (e) {
                    if (showErrorOverlay) {
                        this.$root.showErrorDialog(e);
                    } else {
                        throw e;
                    }
                }
            },
        },
    });
</script>

<style scoped lang="scss">
    .panel-ai {
        .property.property-feature-not-available:empty {
            display: none;
        }

        .property-voice {
            & > header {
                font-family: var(--font-family-condensed-demibold);
            }
        }

        .textinput.type-textarea.disabled {
            pointer-events: none;

            :deep(textarea) {
                opacity: 0.5;
            }
        }

        .property.property-preview-player {
            display: flex;
            align-items: flex-end;
            flex-direction: column;

            audio {
                width: 200px;
                border-radius: var(--btn-border-radius);
                transition: opacity 250ms ease;
            }

            audio.disabled {
                pointer-events: none;
                opacity: 0.2;
            }

            .loading-indicator {
                position: absolute;
                top: 0;
                right: 80px;
            }
        }
    }
</style>
