import {
    parseDate,
    uuid4
} from '@/Utility/Helpers';
import AbstractDataObject from '@/Models/AbstractDataObject';
import Asset from '@/Models/Asset/Asset';
import User from '@/Models/User/User';
import UnitData from '@/Models/UnitData/UnitData';
import UnitType from '@/Models/UnitData/UnitType';
import TrainingConfiguration from '@/Models/UnitData/Configuration/TrainingConfiguration';

export default class UnitRevision extends AbstractDataObject
{
    static get constructorName() { return 'UnitRevision'; }

    /**
     * Constructor
     *
     * @param {Object} attributes           // Properties data
     * @param {Object} parent               // Parent object reference
     */
    constructor(attributes = {}, parent = null)
    {
        super(parent);

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        [
            'selected',
            'assets_count',
            'preview_upload_file',
        ].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Populate the model:
        this.uid = attributes.uid || uuid4();                                   // Unique ID
        this.preview = attributes.preview || null;                              // Preview image URL
        this.preview_thumbnail = attributes.preview_thumbnail || null;          // Preview image thumbnail URL
        this.owned_by = attributes.owned_by || null;                            // ID of the associated owner
        this.owner = (attributes.owner) ? new User(attributes.owner) : null;    // Associated owner User model
        this.created_at = parseDate(attributes.created_at || null);         // Created date
        this.updated_at = parseDate(attributes.updated_at || null);         // Updated date
        this.fetched_at = parseDate(attributes.fetched_at || null);         // Last fetched from API date
        this.released_at = parseDate(attributes.released_at || null);       // Released date

        this.unit_uid = attributes.unit_uid || null;                            // Unique ID of the parent unit
        this.type = attributes.type || UnitType.VR.type;                        // Unit type (e.g. 'VR', 'AR')
        this.title = attributes.title || null;                                  // Title text
        this.description = attributes.description || null;                      // Description text
        this.version = attributes.version || null;                              // Version
        this.compatible = (typeof attributes.compatible === 'boolean') ? attributes.compatible : false; // Compatible with the requested WMS?
        this.platform = attributes.platform || null;                            // Platform from which this revision was created

        this.assets = Object.keys(attributes.assets || {}).reduce((assets, assetUid) => ({ ...assets, [assetUid]: Asset.createFromAttributes(attributes.assets[assetUid])}), {});
        this.unit_data = new UnitData(attributes.unit_data || {}, this);        // Unit data with scenes and objects (@NOTE: Has to be initialized AFTER the assets!)

        // Obsolete since part of UnitData now
        // @TODO: Remove these (also from UnitRevisionListResource.php)
        this.configuration = new TrainingConfiguration(attributes.configuration || null);   // Additional configuration object (TrainingConfiguration)
        this.spatial_positioning = (this.type === UnitType.AR.type) ? true : (typeof attributes.spatial_positioning === 'boolean') ? attributes.spatial_positioning : false;  // Spatial positioning
        this.network_enabled = (typeof attributes.network_enabled === 'boolean') ? attributes.network_enabled : false;  // Networking state

        // Hidden and UI-related
        this.selected = (typeof attributes.selected === 'boolean') ? attributes.selected : false;  // Selected state (hidden)
        this.assets_count = attributes.assets_count || this.assetsCount;        // Count of assets provided by the API (hidden)
    }

    /**
     * Create a new UnitRevision from given attributes
     *
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {UnitRevision}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        return new (this)(...arguments);
    }

    /**
     * Clean up data (e.g. remove empty components from objects)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        return this.unit_data.cleanUpData();
    }

    /**
     * Is the unit revision compatible with the current WMS and LearningApp?
     *
     * @returns {Boolean}
     */
    get isCompatible() {
        return this.compatible === true;
    }

    /**
     * Is the unit revision in a draft state?
     *
     * @returns {Boolean}
     */
    get isDraft() {
        return (this.released_at === null);
    }

    /**
     * Is this unit revision the latest revision of the parent unit?
     *
     * @throws {Error}
     * @returns {Boolean}
     */
    get isLatestRevision() {
        const parentUnit = this.parentUnit;
        if (parentUnit === null) {
            throw new Error('UnitRevision->isLatestRevision(): Parent unit is not set.');
        }
        return parentUnit.latest_revision_uid === this.uid;
    }

    /**
     * Is this unit revision the latest released revision of the parent unit?
     *
     * @throws {Error}
     * @returns {Boolean}
     */
    get isLatestReleasedRevision() {
        const parentUnit = this.parentUnit;
        if (parentUnit === null) {
            throw new Error('UnitRevision->isLatestReleasedRevision(): Parent unit is not set.');
        }
        return parentUnit.latest_released_revision_uid === this.uid;
    }

    /**
     * Has the unit revision been released yet?
     *
     * @returns {Boolean}
     */
    get isReleased() {
        return (this.released_at !== null);
    }

    /**
     * Check if the revision is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // The unit data must be valid
        return this.unit_data.isValid;
    }

    /**
     * Does the training have any assets?
     *
     * @returns {Boolean}
     */
    get hasAssets() {
        return (this.assetsCount > 0);
    }

    /**
     * Does the training have a specific asset assigned?
     *
     * @returns {Boolean}
     */
    hasAssetWithUid(uid) {
        return (this.assets[uid] instanceof Asset);
    }

    /**
     * Get the count of training scenes
     *
     * @returns {number}
     */
    get assetsCount() {
        return (this.assets instanceof Object) ? Object.keys(this.assets).length : 0;
    }

    /**
     * Add assets
     *
     * @param {Asset|Array<Asset>} assets
     */
    addAssets(assets) {
        if (assets instanceof Asset) {return this.addAssets([assets]);}
        assets.filter(asset => typeof this.assets[asset.uid] === 'undefined' || this.assets[asset.uid].updated_at !== asset.updated_at).forEach(asset => {
            //console.info('UnitRevision->addAssets(): Adding new asset reference', asset.title);
            this.assets[asset.uid] = Asset.createFromAttributes(asset);    // @NOTE: Creating a new instance to prevent accidentally modifying the reference
        });
        this.assets_count = this.assetsCount;
        return this;
    }

    /**
     * Get preview image for uploading
     * @returns {File|Null}
     */
    get previewImageForUpload() {
        return this.preview_upload_file || null;
    }

    /**
     * Set preview image for uploading
     * @param {File} file
     */
    set previewImageForUpload(file) {
        this.preview_upload_file = (file instanceof File) ? file : null;
    }
}
