<template>
    <Dropdown
        :initial-value="condition.object"
        @select="onSelectObject"
        :options="getSceneObjectTargetDropdownOptionsForCommand"
        :required="true"
        :deselected-caption="trans('authoring.select_sceneobject')"
    />
</template>

<script>
import { getSceneObjectTargetDropdownOptionsForCommand }     from '@/Models/UnitData/Commands/CommandHelpers';
import Command                              from '@/Models/UnitData/Commands/Command';
import { ObjectActiveCondition }            from '@/Models/UnitData/Conditions/Condition';
import DropdownOption                       from '@/Utility/DropdownOption';

export default {
    name: 'PanelConditionObjectActive',
    emits: [
        'change',
    ],
    props: {
        command: Command,
        condition: ObjectActiveCondition,
    },
    computed: {

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

    },
    methods: {

        /**
         * Select handler for target object
         *
         * @param {String} value
         */
        onSelectObject(value) {
            if (value !== this.condition.object)
            {
                this.condition.object = value;
                this.$emit('change', this.condition);
            }
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
