<template>
    <Collapsible class="inspector-panel panel-hint-assignment">
        <template #header>
            {{ trans('labels.hint_assignment') }}
        </template>
        <template #body>

            <!-- Grouped by scenes -->
            <Collapsible
                v-for="group in getObjectivesByScenes"
                :key="'hintsgroupedbyscene'+group.scene.uid"
                :data-ref-uid="group.scene.uid"
                class="hints-group"
            >
                <template #header>
                    <Icon name="icon_objectives" />
                    <span class="caption">{{ trans('labels.scene') }} {{ group.scene.indexAndTitleFormatted }}</span>
                </template>
                <template #body>

                    <!-- Hint assignment for every objective trigger from each scene -->
                    <template v-for="(objective, objectiveIndex) in group.objectives">
                        <PanelHintAssignmentItem
                            v-for="trigger in objective.triggers"
                            :key="'scenehint'+group.scene.uid+objectiveIndex+trigger.uid"
                            :trigger="trigger"
                            :sceneObject="sceneObject"
                            :data-ref-uid="objective.object.uid"
                            @change="onChangeAssignment"
                        />
                    </template>

                    <!-- When all completed -->
                    <div class="hint-assignment panel-card">
                        <header>
                            <Icon name="icon_objectives-completed" />
                            <span class="caption">{{ trans('authoring.when_all_completed_hint') }}</span>
                        </header>

                        <Dropdown
                            :initial-value="getHintValueForScene(group.scene)"
                            @select="onSelectWhenAllCompletedForScene"
                            :options="getOptionsForWhenAllCompletedDropdown(group.scene)"
                            :deselected-caption="trans('authoring.no_hint')"
                        />

                    </div>

                </template>
            </Collapsible>

        </template>
    </Collapsible>
</template>

<script>
    // Import VueJS components:
    import PanelHintAssignmentItem     from '@/Vue/Inspector/PanelHintAssignmentItem.vue';

    // Import classes:
    import DropdownOption           from '@/Utility/DropdownOption';
    import { sortArrayByProperty, trans }  from '@/Utility/Helpers';
    import SceneObject              from '@/Models/UnitData/SceneObjects/SceneObject';
    import TriggerType              from '@/Models/UnitData/Triggers/TriggerType';
    import HintObjectiveAssignment  from '@/Models/UnitData/Triggers/Hints/HintObjectiveAssignment';
    import HintSceneAssignment      from '@/Models/UnitData/Triggers/Hints/HintSceneAssignment';

    export default {
        name: 'PanelHintAssignment',
        emits: [
            'change',
        ],
        components: {
            PanelHintAssignmentItem
        },
        props: {
            sceneObject: {                  // Selected SceneObject that the waypoints are assigned to
                type: SceneObject,
                default: null
            }
        },
        computed: {

            /**
             * Get the hints grouped by scenes
             *
             * @returns {Array<Object>}
             */
            getObjectivesByScenes() {
                const scenes = [];
                this.sceneObject.parentUnitData.scenes.forEach(s => {
                    // Get all triggers for each scene:
                    const sceneTriggers = s.allTriggers;
                    // Add a group object for each scene with reference to the scene, its objects that have objective triggers and the hints:
                    scenes.push({
                        scene: s,
                        objectives: s.allSceneObjects.filter(o => o instanceof SceneObject).map(o => {
                            return {
                                object: o,
                                triggers: o.triggers.filter(t => t.is_objective === true)
                            };
                        }).filter(o => o.triggers.length >= 1),
                        hints: this.sceneObject.hints.filter(h => sceneTriggers.find(t => t.uid === h.objective))
                    });
                });
                return scenes.filter(s => s.objectives.length >= 1);
            },

            /**
             * Get the configured reactions. Reactions are cue triggers!
             *
             * @returns {Array<CueTrigger>}
             */
            reactions() {
                return this.sceneObject.triggers.filter(t => t.typeOf(TriggerType.OnCue));
            },
        },
        methods: {

            /**
             * Get the hint assignment from the scene object for a given trigger
             *
             * @param {Trigger} trigger
             * @returns {HintObjectiveAssignment|null}
             */
            getHintForTrigger(trigger) {
                return this.sceneObject.hints.find(h => h.objective === trigger.uid) || null;
            },

            /**
             * Get the hint assignment for the "When All Completed" trigger
             *
             * @param {TrainingScene} scene
             * @returns {HintSceneAssignment|null}
             */
            getHintForScene(scene) {
                return this.sceneObject.when_all_completed_hints.find(h => h.scene_uid === scene.uid) || null;
            },

            /**
             * Get the cue trigger value for a given scene's "When All Completed" trigger
             *
             * @param {TrainingScene} scene
             * @returns {Object}
             */
            getHintValueForScene(scene) {
                const hint = this.getHintForScene(scene);
                return {
                    cue: (hint !== null) ? hint.cue : null,
                    scene: scene
                };
            },

            /**
             * Get the dropdown options for selecting a hint on a specific scene
             *
             * @param {TrainingScene} scene
             * @returns {Array<DropdownOption>}
             */
            getOptionsForWhenAllCompletedDropdown(scene) {
                return [
                    new DropdownOption({
                        caption: trans('authoring.no_hint'),
                        value: { cue: null, scene: scene }
                    }),
                    ...sortArrayByProperty(
                        this.reactions.map(
                            t => new DropdownOption({
                                caption: t.title || t.triggerType.title,
                                value: { cue: t.uid, scene: scene }
                            })
                        ),
                        'caption',
                        false
                    )
                ];
            },

            /**
             * Change handler for a specific hint assignment
             *
             * @param {String} value        UID of the cue / reaction
             * @param {Object} component    Vue component
             */
            onChangeAssignment(value, component) {
                const trigger = component.$props.trigger;
                const hint = this.getHintForTrigger(trigger);

                if (hint !== null) {
                    hint.cue = value;
                } else {
                    this.sceneObject.hints.push(new HintObjectiveAssignment({
                        objective: trigger.uid,
                        cue: value
                    }));
                }

                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             * Change handler for "When All Completed" hint assignment
             *
             * @param {Object} value    // { cue: reaction trigger UID, scene: TrainingScene }
             */
            onSelectWhenAllCompletedForScene(value) {
                const cue = value.cue;
                const scene = value.scene;
                const hint = this.getHintForScene(scene);

                if (hint !== null) {
                    // Remove existing cue
                    if (cue === null) {
                        this.sceneObject.when_all_completed_hints = this.sceneObject.when_all_completed_hints.filter(c => c.scene_uid !== scene.uid)
                    } else {
                        hint.cue = cue;
                    }
                } else {
                    // Add new when_all_completed_cue
                    this.sceneObject.when_all_completed_hints.push(new HintSceneAssignment({
                        scene_uid: scene.uid,
                        cue: cue
                    }));
                }
                this.$emit('change', this.sceneObject);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
