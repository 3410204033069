<template>
    <div class="command-settings">
        <Dropdown
            :initial-value="command.value"
            @select="onSelectScene"
            :options="getSceneTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_scene')"
        />
        <Checkbox
            :model="command"
            property="fade"
            layout="checkbox-right"
            @change="onChangeFade"
            :caption="trans('commands.scene.change.fade')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { getSceneTargetDropdownOptionsForCommand }   from '@/Models/UnitData/Commands/CommandHelpers';

// Import classes:
import {ChangeSceneCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandChangeScene',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: ChangeSceneCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for scene selection dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getSceneTargetDropdownOptionsForCommand() {
            return getSceneTargetDropdownOptionsForCommand(this.command);
        },
    },
    methods: {
        /**
         * Select handler for scene dropdown
         *
         * @param {String} sceneUidOrIndex
         */
        onSelectScene(sceneUidOrIndex) {
            if (sceneUidOrIndex !== this.command.value)
            {
                this.command.value = sceneUidOrIndex;
                this.$emit('change', this.command);
            }

            return this;
        },

        onChangeFade() {
            this.$emit('change', this.command);
            return this;
        }
    }
}
</script>
