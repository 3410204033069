import AssetType from "@/Models/Asset/AssetType";

export interface MostUsedUnitsInCourses {
    title: string;
    count: number;
}

export interface AssetTypeWithCount {
    type: AssetType;
    count: number;
}

export interface TenantRoleWithCount {
    role: string;
    count: number;
}

export interface Activity {
    objectName: string;
    time: Date;
    objectType: 'course' | 'asset' | 'user' | 'unit';
    operation: 'updated' | 'created' | 'released';
}

export default class Statistics {

    readonly courseCount: number;
    readonly unitCount: number;
    readonly mostUsedUnitsInCourses: Array<MostUsedUnitsInCourses>;
    readonly usersByRole: Array<TenantRoleWithCount>;
    readonly assetTypes: Array<AssetTypeWithCount>;
    readonly activities: Array<Activity>;

    constructor(apiModel: any) {
        this.courseCount = apiModel.course_count;
        this.unitCount = apiModel.unit_count;

        this.mostUsedUnitsInCourses = apiModel.most_used_units_in_courses as Array<MostUsedUnitsInCourses>;

        this.usersByRole = apiModel.users_by_role.map(entry => {
            return {
                role: entry.tenant_role,
                count: entry.count,
            }
        });

        this.assetTypes = apiModel.asset_types.map(entry => {
            return {
                count: entry.count,
                type: AssetType.getByTypeName(entry.type)
            }
        });

        this.activities = apiModel.activities.map(entry => {
            return {
                objectName: entry.object_name,
                time: new Date(entry.time),
                objectType: entry.object_type,
                operation: entry.operation,
            }
        });
    }

}
