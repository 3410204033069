<template>
    <Collapsible
        ref="collapsible"
        :initial-collapsed="showAsReactions"
        :key="'trigger_' + trigger.uid + '_collapsible'"
        :class="getTriggerClasses"
        :dragValue="dragValue"
        :dragGroups="dragGroups"
        :draggable-selector="'.trigger > header[draggable], .trigger > header[draggable] *'"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
    >
        <template #header>
            <PanelTriggerHeader
                :trigger="trigger"
                :isHelperModule="isHelperModule"
                :showAsReactions="showAsReactions"
                @remove="onConfirmRemove"
                :showConfirmRemovalDialog="showConfirmRemovalDialog"
                @showConfirmRemovalDialogChanged="onShowConfirmRemovalDialogChanged"
                @change="onTriggerChanged"
            />
        </template>

        <template #body>
            <!-- Add properties for the trigger -->
            <component
                v-for="(configuration, index) in getPropertyComponentsForTrigger"
                :key="getPropertyKey(index, configuration.component)"
                :is="configuration.component"
                :trigger="trigger"
                @change="onTriggerChanged"
            />

            <!-- This is a Greyed-out overlay that will be displayed while the delete-confirm overlay is present. -->
            <span v-if="showConfirmRemovalDialog" class="confirm-remove"></span>
        </template>
    </Collapsible>
</template>

<script>

import {getPropertyComponentsForTrigger}            from '@/Models/UnitData/Triggers/TriggerHelpers';
import Trigger                                      from '@/Models/UnitData/Triggers/Trigger';
import PanelTriggerHeader                           from '@/Vue/Inspector/Triggers/Headers/PanelTriggerHeader.vue';
import PanelTriggerPropertyCancelWhenActivated      from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyCancelWhenActivated.vue';
import PanelTriggerPropertyCancelWhenDeactivated    from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyCancelWhenDeactivated.vue';
import PanelTriggerPropertyCommands                 from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyCommands.vue';
import PanelTriggerPropertyRetrigger                from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyRetrigger.vue';
import PanelTriggerPropertyDistance                 from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyDistance.vue';
import PanelTriggerPropertyEnterLeaveSubtype        from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyEnterLeaveSubtype.vue';
import PanelTriggerPropertyIsObjective              from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyIsObjective.vue';
import PanelTriggerPropertyTags                     from '@/Vue/Inspector/Triggers/Properties/PanelTriggerPropertyTags.vue';

export default {
    name: 'PanelTrigger',
    emits: [
        'change',
        'remove',
        'showConfirmRemovalDialogChanged',
    ],
    components: {
        PanelTriggerHeader,
        PanelTriggerPropertyCancelWhenActivated,
        PanelTriggerPropertyCancelWhenDeactivated,
        PanelTriggerPropertyCommands,
        PanelTriggerPropertyEnterLeaveSubtype,
        PanelTriggerPropertyRetrigger,
        PanelTriggerPropertyDistance,
        PanelTriggerPropertyIsObjective,
        PanelTriggerPropertyTags,
    },
    props: {
        trigger: Trigger,
        isHelperModule: Boolean,
        showAsReactions: {
            type: Boolean,
            default() {
                return false;
            }
        },
        showConfirmRemovalDialog: {
            type: Boolean,
            default: false
        },
        dragGroups: {
            type: [String, Array],
            default: null
        },
        dragValue: {
            type: [String, Number, Object, Array, Boolean],
            default: null
        }
    },
    computed: {

        getPropertyComponentsForTrigger() {
            return getPropertyComponentsForTrigger(this.trigger.event).filter(c => this.showComponent(c) && c.component);
        },

        /**
         * Get trigger CSS classes
         *
         * @returns {String}
         */
        getTriggerClasses() {
            const classes = ['trigger'];
            classes.push('trigger-' + this.trigger.event.replace('on_', '').replace('_', '-'));
            if (this.trigger.triggerType.canUserEditTitle) {classes.push('can-user-edit-title');}
            if (!this.trigger.isValid) {classes.push('is-invalid');}
            return classes.join(' ');
        },

        sceneObject() {
            return this.trigger.parentSceneObject;
        },
    },
    methods: {

        showComponent(component) {
            return (!this.sceneObject.isGlobal || component.allowedOnGlobalObjects);
        },

        /**
         *
         * @param  {String} component
         * @param  {Number} index
         * @return {String}
         */
        getPropertyKey(component, index) {
            return index + '_' + this.trigger.uid + '_' + component;
        },

        onConfirmRemove() {
            this.$emit('remove', this.trigger);
            return this;
        },

        /**
         * Event handler for the changed event
         *
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);
            return this;
        },

        onShowConfirmRemovalDialogChanged(value) {
            this.$emit('showConfirmRemovalDialogChanged', value);
            return this;
        },

        onDragStart(e) {
            window.document.body.classList.add('is-dragging-trigger');
            return this;
        },

        onDragEnd(e) {
            window.document.body.classList.remove('is-dragging-trigger');
            return this;
        }
    },
}
</script>

<style lang="scss" scoped>

</style>


<style lang="scss">
    body.is-dragging-trigger .trigger {

        // Prevent dragenter and dragleave events from child elements (e.g. commands)
        .collapsible-body,
        .collapsible-body * {
            pointer-events: none !important;
        }
    }
</style>
