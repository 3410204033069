import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.UnitLinksCreate() = 'unit_links:create' => create() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('UnitLink', new UnitLinkPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.UnitLinksCreate()), UnitLink.constructorName, unit);
 *
 */
export default class UnitLinkPolicy {

    /**
     * Determine whether the user can read the unit.
     *
     * @param  {User}  user
     * @param  {Unit}  unit
     * @return {boolean}
     */
    create(user, unit) {
        return (
            unit.isReleased
            && this.unitIsInScopeForReading(user, unit)
            && permission(Permission.UnitLinksCreate())
        );
    }


    /**
     * Determine whether the user can read the unit.
     *
     * @param  {User}  user
     * @param  {Unit}  unit
     * @return {boolean}
     */
    read(user, unit) {
        if (!this.unitIsInScopeForReading(user, unit)) {
            return false;
        }

        if (permission(Permission.UnitLinksRead())) {
            return true;
        }

        return false;
    }

    /**
     * Determine whether the user can update the unit.
     *
     * @param  {User}  user
     * @param  {UnitLink}  unitLink
     * @return {boolean}
     */
    update(user, unitLink) {
        const tenant = user.tenant;
        const unitLinkTenantIsCurrentTenant = tenant.uid === unitLink.tenant_uid;

        if (
            unitLinkTenantIsCurrentTenant
            && permission(Permission.UnitLinksUpdateAny())
        ) {
            return true;
        }

        if (
            unitLinkTenantIsCurrentTenant
            && unitLink.owned_by === user.uid
            && permission(Permission.UnitLinksUpdateOwn())
        ) {
            return true;
        }

        if (
            unitLinkTenantIsCurrentTenant
            && permission(Permission.UnitLinksUpdate())
        ) {
            return true;
        }

        return false;
    }

    /**
     * Determine whether the user can delete the unit.
     *
     * @param  {User}  user
     * @param  {UnitLink}  unitLink
     * @return {boolean}
     */
    delete(user, unitLink) {
        const tenant = user.tenant;
        const unitLinkTenantIsCurrentTenant = tenant.uid === unitLink.tenant_uid;

        if (
            unitLinkTenantIsCurrentTenant
            && permission(Permission.UnitLinksDeleteAny())
        ) {
            return true;
        }

        if (
            unitLinkTenantIsCurrentTenant
            && permission(Permission.UnitLinksDelete())
        ) {
            return true;
        }

        return false;
    }

    /**
     * @param  {User}  user
     * @param  {Unit}  unit
     * @return {boolean}
     */
    unitIsInScopeForReading(user, unit) {
        // Every unit passed here comes from the backend and is validated
        // to be readable by the user.

        // Unreleased units cannot handle public links
        if (!unit.isReleased) {
            return false;
        }

        const tenant = user.tenant;
        const unitOwnerIsCurrentTenant = tenant.uid === unit.owned_by_tenant;

        // Some policies do not allow for public links in foreign tenants
        if (!unitOwnerIsCurrentTenant && !unit.parsedPolicy.canHavePublicLinksInForeignTenant) {
            return false;
        }

        return true;
    }
}
