<template>
    <div class="command-settings">
        <Dropdown
            :model="command"
            property="subtype"
            @select="onChangeCommand"
            :options="dropdownOptionsForFeedbackValues"
            :required="true"
            :deselected-caption="trans('authoring.select_feedback')"
        />
        <TextInput
            v-if="shouldShowMessageInput"
            :model="command"
            property="message"
            :required="!command.isValid"
            :maxlength="100"
            :placeholder="trans('labels.text')"
            @change="onChangeCommand"
            error-msg="Error"
        />
    </div>
</template>

<script>
// Import classes:
import DropdownOption                   from '@/Utility/DropdownOption';
import { sortArrayByProperty }          from '@/Utility/Helpers';
import {FeedbackCommand}                  from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandFeedback',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: FeedbackCommand,
            default: null
        }
    },
    computed: {
        /**
         * Does the command have a set value?
         *
         * @returns {Boolean}
         */
        hasValue() {
            return this.command.subtype !== undefined && this.command.subtype !== null;
        },

        /**
         * Get options for feedback value dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        dropdownOptionsForFeedbackValues() {
            const options = [];
            const commandType = this.command.commandType;
            if (commandType !== null && commandType.possibleValues !== null)
            {
                for (const key in commandType.possibleValues)
                {
                    if (commandType.possibleValues.hasOwnProperty(key) === true)
                    {
                        options[options.length] = new DropdownOption({
                            caption: commandType.possibleValues[key],
                            disabled: false,
                            value: key
                        });
                    }
                }
            }
            return sortArrayByProperty(options, 'caption', false);
        },

        shouldShowMessageInput() {
            return this.command.subtype === 'message';
        },
    },
    methods: {
        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>
