<template>
    <div class="command-settings" v-shortcuts.stop>
        <!-- Select scene object as target (only for non-helper modules) -->
        <Dropdown
            :initial-value="command.condition.type"
            @select="onSelectConditionType"
            :options="dropDownOptionsForConditionType"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <!-- Additional component for condition -->
        <component
            v-if="componentForCondition"
            :is="componentForCondition"
            :command="command"
            :condition="command.condition"
            @change="onChangeTriggerCondition"
        />

        <!-- True Action / When Completed -->
        <Collapsible
            :key="keyForTrueActionCommand"
            class="condition-command-collapsible"
            :data-ref-uid="command.true_action?.referencedObjectUid || null"
        >
            <template #header>
                <!-- True Action -->
                {{ trans(keyForTrueActionCollapsibleLabel) }}
            </template>

            <template #body>
                <Dropdown
                    :model="command.true_action"
                    property="type"
                    @select="onSelectTrueActionCommand"
                    :options="getActionOptionsForTriggerObject()"
                    :required="(command.true_action === null && command.false_action === null)"
                    :deselected-caption="trans('authoring.no_action')"
                />

                <PanelCommand
                    v-if="command.true_action !== null && componentForCommand(command.true_action)"
                    :is="componentForCommand(command.true_action)"
                    :command="command.true_action"
                    @change="onChangeTriggerAction"
                />
            </template>
        </Collapsible>

        <!-- False Action / When Not Completed -->
        <Collapsible
            :key="keyForFalseActionCommand"
            class="condition-command-collapsible"
            :data-ref-uid="command.false_action?.referencedObjectUid || null"
        >
            <template #header>
                <!-- False Action -->
                {{ trans(keyForFalseActionCollapsibleLabel) }}
            </template>

            <template #body>
                <Dropdown
                    :model="command.false_action"
                    property="type"
                    @select="onSelectFalseActionCommand"
                    :options="getActionOptionsForTriggerObject()"
                    :required="(command.true_action === null && command.false_action === null)"
                    :deselected-caption="trans('authoring.no_action')"
                />

                <PanelCommand
                    v-if="command.false_action !== null && componentForCommand(command.false_action)"
                    :is="componentForCommand(command.false_action)"
                    :command="command.false_action"
                    @change="onChangeTriggerAction"
                />
            </template>
        </Collapsible>
    </div>
</template>

<script>
// Import helpers and functions:
import { getPanelComponentName, getCaptionForSceneObjectTrigger }     from '@/Models/UnitData/Commands/CommandHelpers';
import { getPropertyComponentForCondition }                           from '@/Models/UnitData/Conditions/ConditionHelpers';

// Import classes:
import DropdownOption                   from '@/Utility/DropdownOption';
import DropdownOptionGroup              from '@/Utility/DropdownOptionGroup';
import { sortArrayByProperty, trans }   from '@/Utility/Helpers';
import Command                          from '@/Models/UnitData/Commands/Command';
import {ConditionCommand}                 from '@/Models/UnitData/Commands/Command';
import CommandType                      from '@/Models/UnitData/Commands/CommandType';
import SceneObject                      from '@/Models/UnitData/SceneObjects/SceneObject';
import { getConditionForData }          from '@/Models/UnitData/Conditions/Condition';
import PanelConditionObjectiveCompleted from '@/Vue/Inspector/Conditions/PanelConditionObjectiveCompleted';
import PanelConditionObjectActive       from '@/Vue/Inspector/Conditions/PanelConditionObjectActive';
import PanelConditionTriggerIsRunning   from '@/Vue/Inspector/Conditions/PanelConditionTriggerIsRunning';
import PanelConditionScript             from '@/Vue/Inspector/Conditions/PanelConditionScript';
import PanelConditionVariable           from '@/Vue/Inspector/Conditions/PanelConditionVariable';

export default {
    name: 'PanelCommandCondition',
    emits: [
        'change',
    ],
    components: {
        PanelConditionObjectiveCompleted,
        PanelConditionObjectActive,
        PanelConditionTriggerIsRunning,
        PanelConditionScript,
        PanelConditionVariable
    },
    props: {
        command: {                      // The command object to be edited
            type: ConditionCommand,
            default: null
        }
    },
    data() {
        return {
            shortcuts: new Map([
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ])
        }
    },
    computed: {

        componentForCondition() {
            return getPropertyComponentForCondition(this.command.condition);
        },

        /**
         * @returns {string} Key for the true action collapsible label for translation.
         */
        keyForTrueActionCollapsibleLabel() {
            return this.command.condition.keyForTrueActionCollapsibleLabel;
        },

        /**
         * @returns {string} Key for the false action collapsible label for translation.
         */
        keyForFalseActionCollapsibleLabel() {
            return this.command.condition.keyForFalseActionCollapsibleLabel;
        },

        keyForTrueActionCommand() {
            return 'true_action_command-' + (this.command?.true_action?.uid || '');
        },

        keyForFalseActionCommand() {
            return 'false_action_command-' + (this.command?.false_action?.uid || '');
        },

        /**
         * Get options for condition type dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        dropDownOptionsForConditionType() {
            const options = [];
            const commandType = this.command.commandType;
            if (commandType !== null && commandType.possibleValues !== null)
            {
                for (const key in commandType.possibleValues)
                {
                    if (commandType.possibleValues.hasOwnProperty(key) === true)
                    {
                        options[options.length] = new DropdownOption({
                            caption: commandType.possibleValues[key],
                            disabled: false,
                            value: key
                        });
                    }
                }
            }

            return sortArrayByProperty(options, 'caption', false);
        },

        /**
         * Get options for condition type dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        dropDownOptionsForConditionObjective() {
            const options = [];
            const currentScene = this.command.parentTrainingScene;

            // Get all triggers grouped by scenes (current scene being first):
            [currentScene].concat(this.command.parentUnitData.scenes).forEach((s, si) => {
                if (s === null || (currentScene !== null && si >= 1 && s.uid === currentScene.uid))
                {
                    return;
                }
                const group = new DropdownOptionGroup({
                    caption: (currentScene !== null && currentScene.uid === s.uid) ? trans('authoring.target_this_scene') : s.indexAndTitleFormatted,
                    showGroupNameInCaption: true,
                    isSeparator: true,
                    collapsible: true,
                    referenceUid: s.uid,
                });
                s.triggers.filter(t => t.is_objective === true).forEach(t => {
                    group.options.push(new DropdownOption({
                        caption: t.title || t.triggerType.title,
                        disabled: false,
                        value: t.uid,
                        icon: t.icon,
                        referenceUid: s.uid,
                    }));
                });
                s.allSceneObjects.filter(o => o instanceof SceneObject && o.hasTriggers).forEach(o => {
                    o.triggers.filter(t => t.is_objective === true).forEach(t => {
                        group.options.push(new DropdownOption({
                            caption: getCaptionForSceneObjectTrigger(o, t, true),
                            disabled: false,
                            value: t.uid,
                            icon: t.icon,
                            referenceUid: o.uid,
                        }));
                    });
                });
                if (group.options.length >= 1)
                {
                    options.push(group);
                }
            });

            return options;}

    },
    methods: {
        /**
         * Get the component name for the panel
         *
         * @param {Command} command
         * @returns {String|null}
         */
        componentForCommand(command) {
            return getPanelComponentName(command);
        },

        /**
         * Change handler for the condition settings
         *
         * @param {Condition} condition
         */
        onChangeTriggerCondition(condition) {
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Change handler for the action settings
         *
         * @param {Command} command
         */
        onChangeTriggerAction(command) {
            this.$emit('change', command);
            return this;
        },

        /**
         * Select handler for the command dropdown
         *
         * @param {String} value
         * @param {string} action           // Options: 'true_action', 'false_action'
         */
        onSelectActionCommand(value, action) {
            // Handle reset
            // The dropdown uses a custom option that uses "null" as the value.
            if (value === null) {
                this.command[action] = null;
                this.$emit('change', this.command);
                return this;
            }

            const commandType = CommandType.getByTypeName(value);
            if (commandType === null)
            {
                throw new TypeError('PanelTriggers->onSelectCommand(): Invalid CommandType "' + value + '"');
            }

            const actionCommand = Command.createWithType(
                commandType,
                null,
                this.command.parentTrigger
            );
            actionCommand.cleanUpData();

            this.command[action] = actionCommand;
            this.$emit('change', this.command);

            return this;
        },

        /**
         * Select handler for the true action command dropdown
         *
         * @param {String} value
         */
        onSelectTrueActionCommand(value) {
            this.onSelectActionCommand(value, 'true_action');
            return this;
        },

        /**
         * Select handler for the false action command dropdown
         *
         * @param {String} value
         */
        onSelectFalseActionCommand(value) {
            this.onSelectActionCommand(value, 'false_action');
            return this;
        },

        /**
         * Select handler for condition type value dropdown
         *
         * @param {String} value
         */
        onSelectConditionType(value) {
            if (value !== this.command.condition.type)
            {
                // Deconstruct the condition into an object of attributes:
                const {...attributes} = this.command.condition;
                attributes.type = value;

                // Create a new condition from attributes:
                this.command.condition = getConditionForData(attributes, this.command);
                this.command.cleanUpData();
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         * Transform the commands to options for the dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        getActionOptionsForTriggerObject() {
            const options = [];
            CommandType.GroupsAsArray.forEach(g => {
                const group = new DropdownOptionGroup({
                    caption: trans('commands.groups.' + g),
                    isSeparator: true,
                    showGroupNameInCaption: false
                });

                this.command.supportedCommandTypes.filter(c => c.group === g).forEach(c => group.options[group.options.length] = new DropdownOption({
                    caption: c.titleGrouped,
                    disabled: !c.enabled,
                    value: c.type
                }));

                sortArrayByProperty(group.options, 'caption', false);

                if (group.options.length >= 1)
                {
                    options[options.length] = group;
                }
            });

            // Create an option that allows us to reset the dropdown
            const deselectOption = new DropdownOption({
                caption: trans('authoring.no_action'),
                disabled: false,
                value: null
            });

            return [deselectOption].concat(sortArrayByProperty(options, 'caption', false));
        }
    }
}
</script>
