import _ from 'lodash';
import { trans } from '@/Utility/Helpers';
import ExecutionRepeatType from '@/Models/UnitData/Triggers/ExecutionRepeatType';

export default class ExecutionType
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        let attrs = (attributes instanceof Object) ? _.cloneDeep(attributes) : new Object(null);

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || ExecutionType.isValidType(attributes.type) === false)
        {
            console.warn('ExecutionType->constructor(): Invalid data.', attributes);
            throw new TypeError('ExecutionType->constructor(): Property "type" has to be set on ExecutionType. Must be a valid type from ExecutionType class.');
        }

        // Populate the model:
        this.type = attributes.type;                                        // Type identifier
        this.title = attributes.title;                                      // Translated title
        this.description = attributes.description || null;                  // Translated description
        this.defaultParams = attributes.defaultParams || null;              // Default parameters for this type
        this.params = attrs.params || this.defaultParams;                   // Parameters needed for this execution type
    }

    /**
     * ExecutionType "constants"
     *
     * @NOTE: Make sure to also update the all() method when adding/changing types!
     *
     * @returns {ExecutionType}
     */
    static get Linear()                 { return StaticExecutionTypes.Linear; }
    static get Parallel()               { return StaticExecutionTypes.Parallel; }
    static get Random()                 { return StaticExecutionTypes.Random; }
    static get RandomSequence()         { return StaticExecutionTypes.RandomSequence; }
    //static get RandomSteps()            { return StaticExecutionTypes.RandomSteps; } // @NOTE: Disabled until we maybe implement it in the DreamApp later
    static get Steps()                  { return StaticExecutionTypes.Steps; }

    /**
     * Get all execution types as an array
     *
     * @returns {Array<ExecutionType>}
     */
    static get all()
    {
        return Object.values(StaticExecutionTypes);
    }

    /**
     * Get ExecutionType by a given type name
     *
     * @param {String} type
     * @returns {ExecutionType|null}
     */
    static getByTypeName(type)
    {
        return this.all.find(t => t.type === type) || null;
    }

    /**
     * Check whether a given type is valid
     *
     * @param {String} type
     * @returns {Boolean}
     */
    static isValidType(type)
    {
        for (let i in ExecutionTypes)
        {
            if (ExecutionTypes[i].type === type)
            {
                return true;
            }
        }
        return false;
    }
}

/**
 * Type definitions
 */
const StaticExecutionTypes = {};
const ExecutionTypes = {

    Linear: {
        type: 'linear',
        title: trans('executiontypes.linear'),
        description: trans('executiontypes.linear_description'),
        defaultParams: null,
    },
    Parallel: {
        type: 'parallel',
        title: trans('executiontypes.parallel'),
        description: trans('executiontypes.parallel_description'),
        defaultParams: null,
    },
    Random: {
        type: 'random',
        title: trans('executiontypes.random'),
        description: trans('executiontypes.random_description'),
        defaultParams: null,
    },
    RandomSequence: {
        type: 'random_sequence',
        title: trans('executiontypes.random_sequence'),
        description: trans('executiontypes.random_sequence_description'),
        defaultParams: null,
    },
 // @NOTE: Disabled until we maybe implement it in the DreamApp later
//    RandomSteps: {
//        type: 'random_steps',
//        title: trans('executiontypes.random_steps'),
//        description: trans('executiontypes.random_steps_description'),
//        defaultParams: {
//            repeat: ExecutionRepeatType.Loop.type
//        },
//    },
    Steps: {
        type: 'steps',
        title: trans('executiontypes.steps'),
        description: trans('executiontypes.steps_description'),
        defaultParams: {
            repeat: ExecutionRepeatType.Loop.type
        },
    },
};

/**
 * Static types (we only want to have one instance for each type!)
 */
for (let i in ExecutionTypes)
{
    StaticExecutionTypes[i] = new ExecutionType(ExecutionTypes[i]);
}
