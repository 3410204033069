import {get} from "lodash";

/**
 * Pulls a translation string from the translation file at `window.i18l`.
 *
 * @param translationKey Path in dot notion of where to find the translation string, e.g. `modals.loading.title`
 * @param replacements Replacements for any placeholders inside the string.
 * Placeholders inside the translation strings are marked with a leading colon followed by the key (e.g. `:name`).
 * The replace object maps a value to this specific key.
 * Keys that do not exist inside the `replacements` object will be left
 * as is. Keys that do not exist inside the translation string will be ignored.
 * Replacement values will be stripped of their html entities to avoid XSS attacks.
 * @param sanitizeReplaceValues If true, html entities will be removed from values in the `replacements` object to
 * prevent XSS attacks.
 * @param throwErrors If true throws an error instead of returning undefined for non-existing translation keys.
 * @return The translation string with all its replacements at the given path.
 */
export function trans(translationKey: string): string
export function trans(translationKey: string, replacements: Record<string, string | number>): string
export function trans(translationKey: string, replacements: Record<string, string | number>, sanitizeReplaceValues: boolean): string
export function trans(translationKey: string, replacements: Record<string, string | number>, sanitizeReplaceValues: boolean, throwErrors: boolean): string | undefined
export function trans(translationKey: string, replacements: Record<string, string | number> = {}, sanitizeReplaceValues: boolean = true, throwErrors: boolean = true): string | undefined {
    let translated = get(window.i18n, translationKey) as string;

    // If no translation is found return early
    if (translated === undefined && throwErrors) {
        throw new Error(`Translation for key "${translationKey}" not found.`);
    } else if (translated === undefined) {
        return translated;
    }

    Object.entries(replacements)
        .forEach(([replaceKey, replaceValue]) => {
            translated = replace(translated, replaceKey, replaceValue, sanitizeReplaceValues);
        });

    return translated;
}

function replace(translation: string, key: string, value: string | number, sanitizeReplaceValue: boolean): string {
    value = value.toString();

    if (sanitizeReplaceValue) {
        value = stripHtmlEntities(value);
    }

    return translation.replace(`:${key}`, value);
}

function stripHtmlEntities(input: string): string {
    return input.toString().replace(/(<.*?\/?.*?>)|[/\\<>="']/g, '');
}
