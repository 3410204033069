<template>
    <Collapsible class="inspector-panel panel-connectables">

        <template #header>
            {{ trans('labels.connectables') }}
        </template>
        <template #body>

            <!-- List of connectables -->
            <div
                v-for="(group, groupindex) in listOfConnectablesGrouped"
                class="connectable-group"
                :key="'connectablegroup'+groupindex"
                :data-ref-uid="group.referencedObjectUid"
            >
                <h4 class="group-caption">{{group.caption}}</h4>
                <div
                    v-for="(connectable, cindex) in group.options"
                    class="connectable-object"
                    :key="'connectable'+groupindex+'_'+cindex"
                    :data-ref-uid="connectable.referencedObjectUid"
                >
                    <Icon :name="connectable.icon" />
                    <span class="connectable-caption" :title="getTitleAttributeForCaption(connectable.caption)">{{ connectable.caption }}</span>
                    <Icon name="icon_close" class="icon-delete" @click="onClickRemoveConnectable(connectable.value)" />
                </div>
            </div>

            <!-- Button to add a new connectable -->
            <div class="btn-add-with-dropdown">
                <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
                <ButtonSecondary v-if="optionsForAddConnectable.length > 0" v-not-focusable icon="icon_add" caption="labels.add_connectable" />
                <Dropdown
                    v-if="optionsForAddConnectable.length > 0"
                    :key="'dropdown-add-connectable'+sceneObject.uid"
                    @select="onSelectAddConnectable"
                    :options="optionsForAddConnectable"
                    :deselected-caption="trans('authoring.select_connectable')"
                />
                <p v-if="shouldShowNoConnectableHint" v-html="trans('authoring.no_connectable_in_scene')"></p>
            </div>

        </template>
    </Collapsible>
</template>

<script>
    // Import VueJS components:

    // Import classes:
    import { trans }                from '@/Utility/Helpers';
    import DropdownOption           from '@/Utility/DropdownOption';
    import DropdownOptionGroup      from '@/Utility/DropdownOptionGroup';
    import { SceneObjectModuleConnection } from '@/Models/UnitData/SceneObjects/SceneObject';
    import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';

    export default {
        name: 'PanelConnectables',
        emits: [
            'change',
        ],
        props: {
            sceneObject: {                  // Selected SceneObject that the contents are assigned to
                type: SceneObjectModuleConnection,
                default: null
            }
        },
        computed: {

            /**
             * @returns {UnitData}
             */
            unitData() {
                return this.sceneObject.parentUnitData;
            },

            /**
             * Get the list of connectable objects as an array for displaying them in the panel
             *
             * @returns {Array<DropdownOptionGroup>}
             */
            listOfConnectablesGrouped() {
                const options = [];

                // Global objects:
                if (this.unitData.hasObjects)
                {
                    const matchingGlobalObjects = this.unitData.allGlobalObjects.filter(o => this.sceneObject.connectables.indexOf(o.uid) >= 0);
                    const globalObjectsGroup = new DropdownOptionGroup({
                        caption: trans('labels.global_objects'),
                        isSeparator: true,
                        collapsible: true,
                        referenceUid: 'no-ref',
                    });
                    // @NOTE: Disabled since we always want to be able to collapse groups no matter how many options they have
                    //globalObjectsGroup.collapsible = (matchingGlobalObjects.length > globalObjectsGroup.autoCollapseLimit);
                    matchingGlobalObjects.forEach(o => {
                        globalObjectsGroup.options[globalObjectsGroup.options.length] = new DropdownOption({
                            caption: o.title,
                            disabled: false,
                            value: o.uid,
                            icon: o.icon,
                            referenceUid: o.uid,
                        });
                    });
                    if (globalObjectsGroup.options.length >= 1)
                    {
                        options[options.length] = globalObjectsGroup;
                    }
                }

                // Other scene objects:
                const scenes = (this.sceneObject.isGlobal === true) ? this.unitData.scenes : [this.sceneObject.parentTrainingScene];
                scenes.filter(s => s.hasObjects).forEach(s => {
                    const sceneObjects = s.allSceneObjects.filter(o => this.sceneObject.connectables.indexOf(o.uid) >= 0);
                    const optGroup = new DropdownOptionGroup({
                        caption: s.indexAndTitleFormatted,
                        isSeparator: true,
                        collapsible: true,
                        referenceUid: s.uid,
                    });
                    // @NOTE: Disabled since we always want to be able to collapse groups no matter how many options they have
                    //optGroup.collapsible = (sceneObjects.length > optGroup.autoCollapseLimit);
                    sceneObjects.forEach(so => {
                        optGroup.options[optGroup.options.length] = new DropdownOption({
                            caption: so.title,
                            disabled: false,
                            value: so.uid,
                            icon: so.icon,
                            referenceUid: so.uid,
                        });
                    });
                    if (optGroup.options.length >= 1)
                    {
                        options[options.length] = optGroup;
                    }
                });

                return options;
            },

            /**
             * Get the list of connectable objects for the dropdown
             *
             * @returns {Array<DropdownOptionGroup>}
             */
            optionsForAddConnectable() {
                const options = [];

                // Global objects:
                if (this.unitData.hasObjects)
                {
                    const matchingGlobalObjects = this.unitData.allGlobalObjects.filter(o => o.typeOf(SceneObjectType.Assets.Model3D) === true && this.sceneObject.connectables.indexOf(o.uid) === -1);
                    const globalObjectsGroup = new DropdownOptionGroup({
                        caption: trans('labels.global_objects'),
                        isSeparator: true,
                        collapsible: true,
                        referenceUid: 'no-ref',
                    });
                    // @NOTE: Disabled since we always want to be able to collapse groups no matter how many options they have
                    //globalObjectsGroup.collapsible = (matchingGlobalObjects.length > globalObjectsGroup.autoCollapseLimit);
                    matchingGlobalObjects.forEach(o => {
                        globalObjectsGroup.options[globalObjectsGroup.options.length] = new DropdownOption({
                            caption: o.title,
                            disabled: false,
                            value: o.uid,
                            icon: o.icon,
                            referenceUid: o.uid,
                        });
                    });
                    if (globalObjectsGroup.options.length >= 1)
                    {
                        options[options.length] = globalObjectsGroup;
                    }
                }

                // Other scene objects:
                const scenes = (this.sceneObject.isGlobal === true) ? this.unitData.scenes : [this.sceneObject.parentTrainingScene];
                scenes.filter(s => s.hasObjects).forEach(s => {
                    const sceneObjects = s.allSceneObjects.filter(o => o.typeOf(SceneObjectType.Assets.Model3D) === true && this.sceneObject.connectables.indexOf(o.uid) === -1);
                    const optGroup = new DropdownOptionGroup({
                        caption: s.indexAndTitleFormatted,
                        isSeparator: true,
                        collapsible: true,
                        referenceUid: s.uid,
                    });
                    // @NOTE: Disabled since we always want to be able to collapse groups no matter how many options they have
                    //optGroup.collapsible = (sceneObjects.length > optGroup.autoCollapseLimit);
                    sceneObjects.forEach(so => {
                        optGroup.options[optGroup.options.length] = new DropdownOption({
                            caption: so.title,
                            disabled: false,
                            value: so.uid,
                            icon: so.icon,
                            referenceUid: so.uid,
                        });
                    });
                    if (optGroup.options.length >= 1)
                    {
                        options[options.length] = optGroup;
                    }
                });

                return options;
            },

            /**
             * Whether or not the hint should be visible
             * @returns {boolean}
             */
            shouldShowNoConnectableHint() {
                return this.optionsForAddConnectable.length <= 0 && this.sceneObject.hasConnectables === false;
            },
        },
        methods: {

            /**
             * Click handler for removing a connectable
             *
             * @param {String} uid
             */
            onClickRemoveConnectable(uid) {
                // Remove the connectable:
                this.sceneObject.connectables = this.sceneObject.connectables.filter(c => c !== uid);
                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             * Select handler for add new connectable dropdown
             */
            onSelectAddConnectable(value) {

                // Add UID of the connectable to the module:
                if (this.sceneObject.connectables.indexOf(value) === -1)
                {
                    this.sceneObject.connectables.push(value);
                }

                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             *
             * @param {String|null} caption
             * @param {number} lengthFromWhichToAddTitleAttribute
             */
            getTitleAttributeForCaption(caption, lengthFromWhichToAddTitleAttribute = 28) {
                return caption.length >= lengthFromWhichToAddTitleAttribute ? caption : null;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
