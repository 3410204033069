<template>
    <div v-if="isVisible"
         id="dialog-about-learning"
         class="modal-dialog fullscreen"
         v-not-focusable v-shortcuts.global.prevent.stop
    >
        <div class="dialog">
            <h2 class="title">{{ title }}</h2>
            <p class="description version">{{ versionText }}</p>

            <h3 class="title">{{ trans('labels.support') }}</h3>
            <p class="description">
                <a class="link-external" href="mailto:support@3spin.com" target="_blank">support@3spin.com</a>
                <span class="phone">+49-6151-870101-87</span>
            </p>

            <h3 class="title">{{ trans('modals.about.headline_imprint_and_licenses') }}</h3>
            <p class="description">
                <a class="link-external" :href="trans('modals.about.link_target_open_source')" target="_blank">
                    {{ trans('modals.about.link_text_open_source') }}
                </a>
                <a class="link-external" :href="trans('modals.about.link_target_imprint')" target="_blank">
                    {{ trans('modals.about.link_text_imprint') }}
                </a>
                <a class="link-external" :href="trans('modals.about.link_target_tos')" target="_blank">
                    {{ trans('modals.about.link_text_tos') }}
                </a>
            </p>

            <div class="buttons">
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="labels.okay"/>
            </div>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import { trans }                from '@/Utility/Helpers';

    export default {
        name: 'DialogAbout',
        props: {
            title: String,
            version: String,
            release: String,
            build: {
                type: String,
                default: null,
            },
        },
        data() {
            return {
                isVisible: false,               // Visibility state
                previousFocusElement: null,     // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickApply],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_ABOUT_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_ABOUT_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_ABOUT_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_ABOUT_HIDE, this.hide);
        },

        computed: {
            versionText() {
                // E.g. "Version 2021.1 (2.0.0-1234)", "Version 2021.1 (2.0.0)""
                return trans('labels.version') + ' ' + this.release + ' (' + this.version + this.buildString + ')';
            },

            buildString() {
                return this.build !== null && this.build.length > 0 ? `-${this.build}` : '';
            },
        },

        methods: {

            /**
             * Show the dialog
             */
            show() {
                this.previousFocusElement = document.activeElement;
                this.isVisible = true;
                this.$nextTick(() => {
                    if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();
                });
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function) {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType.MODAL_ABOUT_HIDE);
                this.hide();
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
