<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 max-content-width">
                <h1 v-text="headline"></h1>
                <p v-text="headline_sub"></p>
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center d-flex">
                            <div class="col-lg-4 justify-content-center d-flex">
                                <img class="mw-100" src="/images/undraw_my_password.svg" />
                            </div>
                            <div class="col-lg-6">
                                <form method="post" :action="url">
                                    <input type="hidden" name="token" :value="form.token">
                                    <input v-if="form._token" type="hidden" name="_token" :value="form._token">

                                    <UserFormTextInput
                                        :key="'email'+key"
                                        :model="form"
                                        property="email"
                                        :readonly="true"
                                        type="text"
                                        name="email"
                                        :required="true"
                                        :maxlength="50"
                                        :label="trans('labels.email')"
                                        @change="removeValidationError('email')"
                                        :validationErrors="validationErrors('email')"
                                        labelClasses="col-lg-4 text-md-left text-lg-right"
                                        inputSizeClasses="col-lg-8"
                                    />

                                    <UserFormTextInput
                                        :key="'password'+key"
                                        :model="form"
                                        property="password"
                                        :type="passwordFieldType"
                                        name="password"
                                        :required="true"
                                        :maxlength="50"
                                        :minlength="8"
                                        :label="trans('labels.password')"
                                        @change="removeValidationError('password')"
                                        :validationErrors="validationErrors('password')"
                                        labelClasses="col-lg-4 text-md-left text-lg-right"
                                        inputSizeClasses="col-lg-8"
                                        :labelHint="trans('tooltips.form_labels.password')"
                                    />

                                    <UserFormTextInput
                                        :key="'password_confirmation'+key"
                                        :model="form"
                                        property="password_confirmation"
                                        :type="passwordFieldType"
                                        name="password_confirmation"
                                        :required="true"
                                        :maxlength="50"
                                        :minlength="8"
                                        :label="trans('labels.password_confirm')"
                                        @change="removeValidationError('password_confirmation')"
                                        :validationErrors="validationErrors('password_confirmation')"
                                        labelClasses="col-lg-4 text-md-left text-lg-right"
                                        inputSizeClasses="col-lg-8"
                                    />

                                    <div class="form-group row">
                                        <div class="col-lg-6 offset-lg-4">
                                            <div class="form-check">
                                                <input
                                                    :key="'show_password'+key"
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    name="show_password"
                                                    id="show_password"
                                                    v-model="form.show_password"
                                                    :true-value="true"
                                                    :false-value="false"
                                                >
                                                <label class="form-check-label" for="show_password">
                                                    {{ trans('users.set_initial_password.show_password') }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group row mb-0">
                                        <div class="col-lg-6 offset-lg-4">
                                            <button type="submit" class="btn btn-primary">
                                                {{ trans('labels.save') }}
                                            </button>
                                            <span class="d-xs-none d-sm-none"></span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import { route, trans } from '@/Utility/Helpers';

import UserFormTextInput from '@/Vue/Users/UserFormTextInput';

export default {
    name: 'SetInitialPasswordPage',
    components: {
        UserFormTextInput,
    },
    props: {
        name: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        token: {
          type: String,
          default: null,
        },
        initial_errors: {
            type: Object,
            default: null,
        },
        csrf: {
            type: String,
            default: null,
        },
    },

    data() {
        return {
            form: {
                _token: null,
                token: null,
                email: null,
                password: null,
                password_confirmation: null,
                show_password: null,
            },
            errors: {},
            key: 0,
        }
    },

    beforeMount() {
        if (this.email !== null) {
            this.form.email = this.email;
        }

        if (this.initial_errors !== null) {
            this.errors = this.initial_errors;
        }

        if (this.token !== null) {
            this.form.token = this.token;
        }

        if (this.csrf !== null) {
            this.form._token = this.csrf;
        }
    },

    computed: {
        headline() {
            return trans('users.set_initial_password.headline', { name: this.name });
        },

        headline_sub() {
            return trans('users.set_initial_password.headline_sub');
        },

        passwordFieldType() {
            return this.form.show_password ? 'text' : 'password';
        },
    },

    methods: {

        /**
         * Get the validation errors for a specific field.
         *
         * @param property
         * @returns {array}
         */
        validationErrors(property) {
            if (property === 'email') {
                // If the email is not valid show the error but add a link to request a password reset as the current
                // link for the welcome mail could have expired.
                if (this.errors.hasOwnProperty(property)) {
                    const requestPasswordRoute = route('password.request') + '?email=' + _.escape(this.email);
                    const requestPasswordlink = '<a href="' + requestPasswordRoute + '">' + trans('users.set_initial_password.request_new_password_link') + '</a>';

                    // Only append the reset link once, as the method is called multiple times
                    if (!this.errors[property][0].includes(requestPasswordlink)) {
                        this.errors[property][0] = this.errors[property][0] + ' ' + requestPasswordlink;
                    }

                    return this.errors[property];
                } else {
                    return [];
                }
            }

            return this.errors.hasOwnProperty(property) ? this.errors[property] : [];
        },

        /**
         * Clear the validatin errors for a specific field.
         *
         * @param property
         */
        removeValidationError(property) {
            delete this.errors[property];
        },
    },
}
</script>

<style lang="scss" scoped>
    .card {
        border: none;
        border-radius: 4px;
        margin-top: 32px;
    }

    .card-body {
        padding-top: 80px;
        padding-bottom: 64px;
    }

    .max-content-width {
        max-width: 1035px;
    }

</style>
