<template>
    <StatisticsCard class="statistics-card-users" :is-loading="isLoading">

        <StatisticsCardHeader
            :title="trans('statistics.index.users.title')"
            :count="userCount"
        />

        <figure class="chart-wrapper" ref="chartWrapper">
            <svg ref="chart" class="chart" viewBox="0 0 200 200">
                <g class="root">
                </g>
            </svg>

            <figcaption>
                <ul class="legend">
                </ul>
            </figcaption>
        </figure>
    </StatisticsCard>
</template>

<script lang="ts">

import {defineComponent, PropType} from "vue";
import StatisticsCard from "@/Vue/Statistics/StatisticsCard.vue";
import {TenantRoleWithCount} from "@/Models/Statistics/Statistics";
import {trans} from "@/Utility/Helpers";
import {select} from "d3-selection";
import {arc, pie, PieArcDatum, scaleOrdinal} from "d3";
import StatisticsCardHeader from "@/Vue/Statistics/General/Cards/StatisticsCardHeader.vue";

export default defineComponent({
    name: "StatisticsCardUsers",

    components: {
        StatisticsCardHeader,
        StatisticsCard
    },

    props: {
        users: {
            type: Object as PropType<Array<TenantRoleWithCount>>,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },

    mounted() {
        if (!this.isLoading) {
            this.redraw();
        }
    },

    computed: {
        userCount() {
            return this.users.reduce((prev, data) => prev + data.count, 0);
        }
    },

    methods: {
        trans,

        redraw() {
            const chartElement = this.$refs.chart as HTMLElement;

            if (!chartElement) {
                return;
            }

            const innerRadius = 50;
            const outerRadius = 100;

            const colorTransform = scaleOrdinal<TenantRoleWithCount, string>()
                .domain(this.users)
                .range(['#D5DCDD', '#0098A5', '#29C9D7', '#FAB200'])

            const dataTransform = pie<TenantRoleWithCount>().value(user => user.count);

            select(chartElement)
                .select('.root')
                .attr('transform', `translate(${outerRadius}, ${outerRadius})`)
                .selectAll('.piece')
                .data(dataTransform(this.users))
                .enter()
                .append('path')
                .attr('class', 'piece')
                .attr('d', arc<PieArcDatum<TenantRoleWithCount>>()
                    .innerRadius(innerRadius)
                    .outerRadius(outerRadius)
                )
                .attr('fill', d => colorTransform(d.data));

            select('.statistics-card-users .legend')
                .selectAll()
                .data(this.users)
                .enter()
                .append('li')
                .html(d => trans(`statistics.index.users.tenant_role_${d.role}`, {count: d.count}))
                .append('span')
                .attr('class', 'color-indicator')
                .style('background-color', d => colorTransform(d));
        },
    },

    watch: {
        users() {
            // delay to make sure the dom is ready
            this.$nextTick(() => {
                this.redraw();
            });
        }
    },
});

</script>

<style lang="scss">

.statistics-card-users {

    .chart-wrapper {

        display: flex;
        flex-direction: row;
        margin-top: 16px;
        padding: 32px;
        gap: 24px;

        figcaption {
            font-size: var(--font-size-small);
            font-family: var(--font-family-condensed);
            white-space: nowrap;

            .legend {
                list-style-type: none;

                li {
                    display: flex;
                    flex-direction: row-reverse;
                    align-items: center;
                    justify-content: start;
                    gap: 8px;
                }

                .color-indicator {
                    width: 21px;
                    height: 8px;
                }
            }
        }
    }

    .chart {
        width: 100%;
        aspect-ratio: 1;
    }
}

</style>
