<template :data-loading="isLoading">
    <div class="learning-records-statistics-cards component-collection" :key="key">

        <div
            v-if="units.length > 0 && selectedUnit"
            class="statistics-card statistics-card-select-unit"
        >
            <div class="texts">
                <span class="title-text">{{ trans('learning_records.statistics.labels.select') }}</span>
                <Dropdown
                    class="unit-dropdown"
                    :initial-value="selectedUnit"
                    :options="getOptionsForUnitsDropdown"
                    @select="onSelectUnit"
                    @click.stop
                />
                <Dropdown
                    :key="'user-dropdown-' + key"
                    class="unit-dropdown"
                    :initial-value="selectedUser"
                    :options="getOptionsForUsersDropdown"
                    @select="onSelectUser"
                    @click.stop
                />
            </div>
        </div>

        <StatisticsCardCount
            v-if="units.length > 0 && selectedUnit && sessionDuration != 0"
            class="unit-avg-duration"
            icon="icon_time"
            :title="trans('learning_records.statistics.labels.avg_session_length')"
            :count="sessionDuration"
            :is-loading="isLoading"
        />

        <LearningRecordsCardStartedCompletedBars
            v-if="started && completed"
            class="unit-started-completed"
            :started="started"
            :completed="completed"
            :is-loading="isLoading"
        />

        <LearningRecordsCardScenes
            class="scenes-avg-durations"
            v-if="scenes.length > 0"
            :is-loading="isLoading"
            :scenes="scenes"
        />

        <div v-if="isLoading" class="loader centered">
            <LoadingIndicator/>
        </div>
        <div
            v-if="!isLoading && !(units.length > 0 && selectedUnit && sessionDuration != 0) && !(started && completed) && !(scenes.length > 0)"
            class="learning-records-statistics-cards-no-data"
        >
            <Icon name="icon_info"/>
            <span v-if="units.length === 0 || users.length === 0" v-html="trans('learning_records.statistics.labels.no_data_hint')"></span>
            <span v-else v-html="trans('learning_records.statistics.labels.no_records_hint')"></span>
        </div>
    </div>
</template>

<script lang="ts">

import {defineComponent, inject} from "vue";
import StatisticsCardCount from "@/Vue/Statistics/General/Cards/StatisticsCardCount.vue";
import {shortId, sortArrayByProperty, trans} from "@/Utility/Helpers";
import {learningRecordsStatisticsServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import LearningRecordsStatisticsService from "@/Services/LearningRecordsStatisticsService";
import Dropdown from "@/Vue/Common/Dropdown.vue";
import DropdownOption from "@/Utility/DropdownOption";
import moment from "moment/moment";

import LearningRecordsCardStartedCompletedBars from "@/Vue/Statistics/LearningRecordsDashboards/Cards/LearningRecordsCardStartedCompletedBars.vue";
import LearningRecordsCardScenes from "@/Vue/Statistics/LearningRecordsDashboards/Cards/LearningRecordsCardScenes.vue";
import {SceneDuration} from "@/Models/Statistics/LearningRecordsStatistics";
import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";
import Icon from "@/Vue/Common/Icon.vue";

export default defineComponent({
    name: "LearningRecordsStatisticsCards",

    components: {
        Icon,
        LoadingIndicator,
        LearningRecordsCardScenes,
        Dropdown,
        StatisticsCardCount,
        LearningRecordsCardStartedCompletedBars
    },

    data() {
        return {
            statisticsService: inject(learningRecordsStatisticsServiceKey) as LearningRecordsStatisticsService,
            fetchStatisticsAbortController: null as AbortController | null,
            selectedUnit: null as string | null,
            selectedUser: 'all',
            key: shortId(),
        }
    },

    mounted() {
        if (this.statisticsService.statistics === null) {
            this.fetchStatistics();
        } else {
            this.selectedUnit = this.units.length > 0 ? this.units[0].value : null;
        }
    },

    unmounted() {
        this.fetchStatisticsAbortController?.abort();
    },

    computed: {
        isLoading() {
            return this.statisticsService.isLoading;
        },

        sessionDuration() {
            return this.getSessionDurationForUser(this.selectedUser);
        },

        /**
         * Get dropdown options for completion column
         *
         * @returns {Array<DropdownOption>}
         */
        getOptionsForUnitsDropdown() {
            return [
                ...this.units.map((value) => {
                    return new DropdownOption({
                        caption: value.caption ? value.caption : value.value,
                        value: value.value,
                    })
                })
            ];
        },

        units() {
            if (this.statisticsService.statistics === null) {
                return [];
            }

            return sortArrayByProperty(
                Object.values(this.statisticsService.statistics.unitsAvgDurations).map((value) => {
                    return value.all;
                }),
                'title'
            ).map((value) => {
                return {
                    caption: value.title,
                    value: value.uid,
                };
            });
        },

        /**
         * Get dropdown options for completion column
         *
         * @returns {Array<DropdownOption>}
         */
        getOptionsForUsersDropdown() {
            return [
                new DropdownOption({
                    caption: trans('learning_records.statistics.labels.all_users'),
                    value: 'all',
                    disabled: false,
                }),
                ...this.users.map((user) => {
                    return new DropdownOption({
                        caption: user.name,
                        value: user.uid,
                        disabled: (
                            this.selectedUnit === null
                            || this.statisticsService.statistics === null
                            || !this.userHasData(user.uid)
                        )
                    })
                })
            ];
        },

        users() {
            if (this.statisticsService.statistics === null) {
                return [];
            }

            return Object.values(this.statisticsService.statistics.users);
        },

        scenes() {
            return this.getScenesForUser(this.selectedUser);
        },

        started() {
            return this.getStartedForUser(this.selectedUser);
        },

        completed() {
            return this.getCompletedForUser(this.selectedUser);
        },
    },

    methods: {
        trans,

        userHasData(user: string) {
            return (
                this.getSessionDurationForUser(user) > 0
                || this.getStartedForUser(user) !== null
                || this.getCompletedForUser(user) !== null
            );
        },

        getSessionDurationForUser(user: string) {
            if (
                this.selectedUnit !== null
                && this.statisticsService.statistics?.unitsAvgDurations[this.selectedUnit].hasOwnProperty(user)
            ) {
                return moment.duration(this.statisticsService.statistics?.unitsAvgDurations[this.selectedUnit][user].duration, 'seconds').humanize();
            }
            return 0;
        },

        getScenesForUser(user: string) {
            if (
                this.statisticsService.statistics === null
                || this.selectedUnit === null
                || !this.statisticsService.statistics.scenesAvgDurations.hasOwnProperty(this.selectedUnit)
                || !this.statisticsService.statistics.scenesAvgDurations[this.selectedUnit].hasOwnProperty(user)
            ) {
                return [];
            }

            return Object.values(this.statisticsService.statistics.scenesAvgDurations[this.selectedUnit][user]) as Array<SceneDuration>;
        },

        getStartedForUser(user: string) {
            if (
                this.statisticsService.statistics === null
                || this.selectedUnit === null
                || this.units.length === 0
                || !this.statisticsService.statistics.unitsStartedCount.hasOwnProperty(this.selectedUnit)
                || !this.statisticsService.statistics.unitsStartedCount[this.selectedUnit].hasOwnProperty(user)
            ) {
                return null;
            }

            return this.statisticsService.statistics.unitsStartedCount[this.selectedUnit][user];
        },

        getCompletedForUser(user: string) {
            const started = this.getStartedForUser(user);

            if (
                this.statisticsService.statistics === null
                || this.selectedUnit === null
                || this.units.length === 0
                || started === null
            ) {
                return null;
            }

            const completed = {
                count: 0,
                title: started.title,
                uid: started.uid,
            };

            return (
                this.statisticsService.statistics.unitsCompletedCount.hasOwnProperty(this.selectedUnit)
                && this.statisticsService.statistics.unitsCompletedCount[this.selectedUnit].hasOwnProperty(user)
                    ? this.statisticsService.statistics.unitsCompletedCount[this.selectedUnit][user]
                    : completed
            );
        },

        fetchStatistics() {
            this.fetchStatisticsAbortController = new AbortController();
            this.statisticsService
                .index(this.fetchStatisticsAbortController.signal)
                .then(() => {
                    this.selectedUnit = this.units.length > 0 ? this.units[0].value : null;
                })
                .catch(() => {
                    if (this.fetchStatisticsAbortController?.signal?.aborted === true) {
                        return;
                    }

                    this.$root?.showErrorDialog(trans('errors.server_communication_failed'));
                });
        },

        onSelectUnit(value) {
            this.selectedUnit = value;
        },

        onSelectUser(value) {
            this.selectedUser = value;
        },
    },

    watch: {
        selectedUnit() {
            this.key = shortId();
            return this;
        },
        selectedUser() {
            this.key = shortId();
            return this;
        },
    }
});
</script>

<style lang="scss">

.learning-records-statistics-cards {
    &.component-collection {
        display: grid;
        justify-items: stretch;
        row-gap: 32px;
        column-gap: 32px;
        transition: 250ms;

        grid-template-columns: repeat(3, minmax(300px, 420px));
        grid-template-areas:
            "unit-dropdown          unit-avg-duration       empty1"
            "unit-started-completed scenes-avg-durations    scenes-avg-durations";

        .statistics-card-select-unit {
            grid-area: unit-dropdown;
            display: flex;
            padding: 28px;
            gap: 16px;
            align-items: center;
            justify-content: start;

            background-color: var(--background-color-white);
            border-radius: var(--card-border-radius-small);
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

            .texts {
              display: flex;
              flex-direction: column;
              width: 100%;
            }

            .title-text {
                display: block;
                margin-bottom: 12px;
                font-family: var(--font-family-condensed-demibold);
                font-size: 14px;
                line-height: 18px;
                color: var(--color-anthracite40);
                text-transform: uppercase;
            }

            .unit-dropdown {
                font-family: var(--font-family-condensed-demibold);

                &:not(:last-child, :only-child) {
                    margin-bottom: 8px;
                }
            }
        }

        .unit-avg-duration {
            grid-area: unit-avg-duration;
            justify-content: start;
            align-items: center;
        }

        .unit-started-completed {
            grid-area: unit-started-completed;
        }

        .scenes-avg-durations {
            grid-area: scenes-avg-durations;
        }
    }

    .centered {
        position: absolute;
        left: 50%;
        top: calc(50% + var(--header-height));
        transform: translate(-50%, -50%);
    }

    .loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .learning-records-statistics-cards-no-data {
        position: absolute;
        left: 50%;
        top: clamp(var(--header-height) + 220px, 50%, 50%);
        transform: translate(-50%, -50%);

        text-align: center;
        min-width: 400px;
        font-family: var(--font-family-condensed-demibold);
        color: var(--color-anthracite40);

        & > span {
            display: block;
        }

        & > svg.icon {
            width: 24px;
            height: 24px;
            margin-bottom: 2px;
        }
    }
}

/* small screens */
@media (max-width: 1080px) {
    .learning-records-statistics-cards.component-collection {
        grid-template-columns: minmax(400px, min-content) minmax(400px, min-content);
        grid-template-areas:
            "unit-dropdown          unit-avg-duration"
            "unit-started-completed unit-started-completed"
            "scenes-avg-durations   scenes-avg-durations";
    }
}

</style>
