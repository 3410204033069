import Category from '@/Filters/Category';

export default class MultiFilterCategory extends Category
{
    /**
     * Constructor
     *
     * @param {string} title                        // Title of the group
     * @param {Array<FilterSection>} sections       // Sections used for this category
     */
    constructor(title = '', sections = null, icon = null) {
        super({title: title, icon: icon});

        this.sections = sections;
    }

    /**
     * Reset to initial state
     *
     * @returns {Category}
     */
    reset() {
        super.reset();
        this.sections.forEach(s => s.category.reset());
        return this;
    }

    /**
     * @returns {Array<FilterSection>}
     */
    get activeSections() {
        return this.sections.filter(fs => fs.category.isActive);
    }

    /**
     * @returns {Boolean}
     */
    get hasActiveSection() {
        return this.sections.some(fs => fs.category.isActive);
    }

    /**
     * @returns {Boolean}
     */
    get hasInactiveSection() {
        return this.sections.some(fs => !fs.category.isActive);
    }
}
