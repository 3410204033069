<template>
    <main
        id="layout-main"
        v-shortcuts
        :data-loading="isLoading"
        :data-saving="isSaving"
        class="course-edit">

        <PageHeader
            :page-title="pageHeadline"
            :page-subtitle="pageSubtitle"
            :buttons="headerButtons"
            :labels="pageHeaderLabels"
        />

        <div id="layout-content">
            <div v-if="courseToEdit !== null"
                 id="content"
                 ref="content"
                 v-not-focusable>

                <div
                    v-if="!hasUnits"
                    class="no-units">
                    <p>{{ trans('courses.edit.course_empty') }}</p>
                </div>

                <TransitionGroup
                    name="list-items"
                    tag="div"
                    class="unit-list"
                    ref="unitList"
                >

                    <UnitListItem
                        v-for="unit in unitsInCourse"
                        :key="'unit_' + unit.uid"
                        :unit="unit"
                        :current-unit-revision-uid="unit.latest_released_revision_uid"
                        :selected="(selectedUnit && selectedUnit.uid === unit.uid)"
                        :unit-is-removable="true"
                        v-focusable
                        @focus="selectedUnit = unit;"
                        @click="selectedUnit = unit;"
                        @click-edit="onClickUnitEdit"
                        @click-remove="onClickUnitRemove"
                    />

                </TransitionGroup>

                <ButtonPrimary
                    v-tooltip="'buttons.courses.add_unit'"
                    :disabled="!shouldEnableAddUnitButton"
                    caption="courses.edit.btn_add_unit"
                    class="btn-add-unit"
                    icon="icon_add"
                    @trigger="onTriggerBtnAddUnit"
                />

            </div>

            <aside
                v-if="courseToEdit !== null"
                id="layout-inspector"
                class="open">
                <SidepanelEditCourse
                    :key="sidepanelEditCourseKey"
                    :course="courseToEdit"
                    @change="onCourseChanged"
                />
            </aside>

            <SidepanelUnitRevisions
                :revisions="revisionsThatCanBeAdded"
                :key="'sidepanel-unit-revisions-'+revisionsThatCanBeAdded.length"
            />

            <DialogLoading/>
            <DialogSaving/>
            <DialogNotification/>

            <DialogSaveCourseChanges/>
        </div>
    </main>
</template>

<script>

    import EventType from "@/Utility/EventType";
    import DialogApplyCancel from '@/Vue/Modals/DialogApplyCancel.vue';
    import DialogLoading from '@/Vue/Modals/DialogLoading.vue';
    import DialogNotification from '@/Vue/Modals/DialogNotification.vue';
    import DialogSaveCourseChanges from '@/Vue/Modals/DialogSaveCourseChanges.vue';
    import moment from 'moment';
    import AuthorizationError from "@/Errors/AuthorizationError";
    import PageHeaderButton from "@/Utility/PageHeaderButton";
    import {permission, trans} from "@/Utility/Helpers";
    import SidepanelEditCourse from "@/Vue/Sidepanel/SidepanelEditCourse";
    import SidepanelUnitRevisions from "@/Vue/Sidepanel/SidepanelUnitRevisions";
    import DialogSaving from "@/Vue/Modals/DialogSaving";
    import UnitListItem from "@/Vue/Courses/UnitListItem";
    import {Permission} from "@/Models/User/Permission";
    import Course from "@/Models/Course/Course";
    import {UnitPermissionPolicyStandard} from "@/Models/Unit/UnitPermissionPolicy";
    import StatusLabelConfig from "@/Utility/StatusLabelConfig";
    import {inject} from "vue";
    import {userServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

    export default {

        name: 'PageCourseEdit',

        components: {
            SidepanelEditCourse,
            SidepanelUnitRevisions,
            DialogApplyCancel,
            DialogLoading,
            DialogSaving,
            DialogSaveCourseChanges,
            DialogNotification,
            UnitListItem,
        },

        props: {
            initialCourseUid: {
                type: String,
                default: null
            }
        },

        mounted() {
            // fetch full course from api
            this.fetchCourse();

            this.userService.fetchUsers();

            // Add global events:
            this.$globalEvents.on(EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav);
            this.$globalEvents.on(EventType.MODAL_SAVE_COURSE_CHANGES_CANCEL, this.onCancelSaveCourseChanges);
            this.$globalEvents.on(EventType.MODAL_SAVE_COURSE_CHANGES_APPLY, this.onApplySaveCourseChanges);
            this.$globalEvents.on(EventType.MODAL_SAVE_COURSE_CHANGES_DISMISS, this.onDismissSaveCourseChanges);
            this.$globalEvents.on(EventType.SIDEPANEL_UNITREVISIONS_SELECT, this.onSelectUnitRevisionForAdding);
            this.$globalEvents.on(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
            this.$globalEvents.addEvent('click.global.course-unit-list', this.onClickGlobal);
        },

        beforeUnmount() {
            // Remove global events:
            this.$globalEvents.off(EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav);
            this.$globalEvents.off(EventType.MODAL_SAVE_COURSE_CHANGES_CANCEL, this.onCancelSaveCourseChanges);
            this.$globalEvents.off(EventType.MODAL_SAVE_COURSE_CHANGES_APPLY, this.onApplySaveCourseChanges);
            this.$globalEvents.off(EventType.MODAL_SAVE_COURSE_CHANGES_DISMISS, this.onDismissSaveCourseChanges);
            this.$globalEvents.off(EventType.SIDEPANEL_UNITREVISIONS_SELECT, this.onSelectUnitRevisionForAdding);
            this.$globalEvents.off(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
            this.$globalEvents.removeEvent('click.global.course-unit-list', this.onClickGlobal);
        },

        data() {
            return {
                /**
                 * @type CourseService
                 */
                courseService: this.$courseService,

                /**
                 * @type UnitService
                 */
                unitService: this.$unitService,

                /**
                 * @type UserService
                 */
                userService: inject(userServiceKey),

                /**
                 * @type Course|null
                 */
                courseToEdit: null,

                /**
                 * @type Course|null
                 */
                originalCourseToEdit: null,

                /**
                 * @type Array<Unit>
                 */
                unitsInCourse: [],

                /**
                 * @type Array<UnitRevision>
                 */
                revisionsThatCanBeAdded: [],

                /**
                 * Whether the course has unsaved changes
                 * @type Boolean
                 */
                courseHasChanged: false,

                /**
                 * @type Unit
                 */
                selectedUnit: null,

                shortcuts: new Map([
                    ['Save.global.prevent', this.saveCourse],
                    ['Backspace.global.prevent', null],         // Prevent going back in browser history
                    ['Reload.global', this.onShortcutReload],   // Catch page reloading
                    ['Delete', this.onShortcutDelete],          // Delete course
                ])
            }
        },

        computed: {

            /**
             * @returns {{saveCourse: PageHeaderButton}}
             */
            headerButtons() {
                return this.courseToEdit === null ? {} : {
                    saveCourse: new PageHeaderButton({
                        disabled: !this.canSaveCourse,
                        caption: trans('labels.save'),
                        tooltip: 'buttons.courses.save',
                        icon: this.courseHasChanged ? 'icon_save' : 'icon_saved',
                        callback: this.saveCourse
                    }),
                };
            },

            /**
             * @returns {boolean}
             */
            canSaveCourse() {
                return this.courseToEdit !== null
                    && !this.isLoading
                    && !this.isSaving
                    && this.courseHasChanged
                    && this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), this.courseToEdit);
            },

            /**
             * @returns {Boolean}
             */
            shouldEnableAddUnitButton() {
                return this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), this.courseToEdit);
            },

            sidepanelEditCourseKey() {
                if (this.courseHasChanged) {
                    return 'sidepanel-edit-course-changed'
                }
                return 'sidepanel-edit-course-unchanged';
            },

            users() {
                return this.userService.users;
            },

            /**
             * Header page title
             *
             * @returns {String}
             */
            pageHeadline() {
                return this.courseToEdit === null ? '' : this.courseToEdit.title;
            },

            pageSubtitle() {
                let subtitle = '';

                if (this.courseToEdit === null) {
                    return null;
                }

                subtitle = moment(this.courseToEdit.updated_at).format(trans('courses.edit.header.revision_date_format'));

                if (this.courseToEditOwner !== null) {
                    subtitle = trans('courses.edit.header.subtitle_by', {
                        text: subtitle,
                        user: this.courseToEditOwner.firstname + ' ' + this.courseToEditOwner.lastname,
                    });
                }

                return subtitle;
            },

            /**
             * @returns {Array<StatusLabelConfig>}
             */
            pageHeaderLabels() {
                const labels = [];

                if (this.courseToEdit === null) {
                    return [];
                }

                // Policy (if non-standard)
                if (this.courseToEdit.policy !== UnitPermissionPolicyStandard.type)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'policy',
                            caption: this.courseToEdit.policy
                        })
                    );
                }

                return labels;
            },


            courseToEditOwner() {
                const user = this.userService.getUserByUid(this.courseToEdit.owned_by);

                if (user === null && this.courseToEdit.owned_by === window.currentUser.uid) {
                    return window.currentUser;
                }

                return user;
            },

            courseIsDraft() {
                return (
                    this.courseToEdit !== null
                    && this.courseToEdit.isDraft
                );
            },

            /**
             * @returns {boolean}
             */
            courseIsEditable() {
                return (
                    this.courseToEdit !== null
                    && this.$gate.allows(Permission.ability(Permission.CoursesUpdate()), this.courseToEdit)
                );
            },

            /**
             * @return {Array<String>} Units of the current revision or empty array when not yet loaded
             */
            units() {
                // TODO: load from api
                return this.courseToEdit === null ? [] : this.courseToEdit.unit_uids;
            },

            /**
             * @return {boolean} true if the current revision has any units
             */
            hasUnits() {
                return this.units?.length > 0;
            },

            /**
             * Loading state
             *
             * @returns {Boolean}
             */
            isLoading() {
                if (this.courseService.isLoading || this.userService.isLoading || this.unitService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
                return false;
            },

            /**
             * Saving state
             *
             * @returns {Boolean}
             */
            isSaving() {
                if (this.courseService.isSaving || this.userService.isSaving || this.unitService.isSaving) {
                    this.$globalEvents.emit(EventType.MODAL_SAVING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_SAVING_HIDE);
                return false;
            }
        },

        methods: {
            /**
             * Fetch current course to edit and save it inside {@link courseToEdit}.
             */
            fetchCourse() {
                this.courseService
                    .fetchCourse(this.initialCourseUid)
                    .catch(this.onErrorApi)
                    .then(course => {
                        this.courseToEdit = course;
                        this.originalCourseToEdit = new Course(course);
                        this.fetchUnitsForCourse();
                    });
            },

            /**
             * Fetch released units to make available for adding.
             */
            fetchUnitsForAdding() {
                this.unitService
                    .fetchUnits(1, 999999, {status: ['released']}, null, 'title')
                    .then(data => {
                        this.revisionsThatCanBeAdded = data.unitList
                            .filter(
                                unit => this.courseToEdit.parsedPolicy.doesAllowForUnitWithPolicy(unit.parsedPolicy)
                                        && !this.unitsInCourse.map(revision => revision.uid).includes(unit.uid)
                            ).map(unit => unit.latestReleasedRevision);
                    })
                    .catch(this.onErrorApi);
            },

            saveCourse() {
                if (!this.canSaveCourse) {
                    return this;
                }

                this.courseService
                    .saveCourse(this.courseToEdit)
                    .then(this.onSuccessSaveCourse)
                    .catch(this.onErrorApi);

                return this;
            },

            /**
             * Success handler for saving the course
             *
             * @param {Course} course
             */
            onSuccessSaveCourse(course) {
                this.courseToEdit = course;
                this.courseHasChanged = false;

                if (typeof this.afterSaveCallback === 'function') {
                    this.onAfterSaveOrDismissChanges();
                }

                this.fetchUnitsForCourse();

                return this;
            },

            fetchUnitsForCourse() {
                // We use this.courseToEdit.numberOfUnits as the per_page param as a workaround
                // until we support pagination in the list.
                this.courseService
                    .fetchUnitsForCourse(
                        this.courseToEdit.uid,
                        this.courseToEdit.numberOfUnits
                    )
                    .then(units => this.unitsInCourse = units)
                    .then(() => this.fetchUnitsForAdding())
                    .catch(this.onErrorApi)
                return this;
            },

            onCourseChanged() {
                this.courseHasChanged = true;
                return this;
            },

            /**
             * Click handler for header navigation buttons that delegates the action to the button callback method
             *
             * @param {PageHeaderButton} buttonConfig
             */
            onClickHeaderNav(buttonConfig) {
                if (buttonConfig.callback === null) {
                    return this;
                }

                buttonConfig.callback.call(this, buttonConfig);
                return this;
            },

            /**
             * Opens the given unit for editing (if allowed)
             * @param {Unit} unit
             */
            onClickUnitEdit(unit) {
                if (!this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), unit)) {
                    return this;
                }

                // Prevent losing unsaved changes
                if (this.courseHasChanged === true && permission(Permission.CoursesUpdate())) {
                    this.afterSaveCallback = function() {this.onClickUnitEdit(unit);}.bind(this);
                    this.$globalEvents.emit(EventType.MODAL_SAVE_COURSE_CHANGES_SHOW, this.courseToEdit);
                    return this;
                }

                window.location.href = this.$root.route('units.edit', {'unit': unit.uid});
                return this;
            },

            /**
             * Removes the given unit from the course
             * @param {Unit} unit
             */
            onClickUnitRemove(unit) {
                if (!this.courseIsEditable) {
                    return this;
                }

                // Prepare for API
                this.courseToEdit.unit_uids.splice(this.courseToEdit.unit_uids.findIndex(ref => ref === unit.uid), 1);

                // Update UI
                this.selectedUnit = null;
                const unitToRemove = this.unitService.unitPage.unitList.find(u => u.uid === unit.uid) || null;
                const unitIndex = this.unitsInCourse.findIndex(u => u.uid === unit.uid);
                if (unitToRemove && unitIndex >= 0) {
                    unitToRemove.is_new = false;
                    this.unitsInCourse.splice(unitIndex, 1);
                    const revisionThatCanBeAdded = unit.latestReleasedRevision;
                    if (revisionThatCanBeAdded !== null) {
                        this.revisionsThatCanBeAdded.push(revisionThatCanBeAdded);
                    }

                    // Select next unit
                    const nextUnit = this.unitsInCourse[unitIndex] || this.unitsInCourse[unitIndex - 1] || null;
                    if (nextUnit) {
                        this.selectedUnit = nextUnit;
                        (this.$refs.unitList.$el.children[unitIndex + 1] || this.$refs.unitList.$el.children[unitIndex - 1])?.focus();
                    }
                }

                this.courseHasChanged = true;

                return this;
            },

            /**
             * Trigger handler for unit adding button
             * @param {Event} e
             */
            onTriggerBtnAddUnit(e) {
                this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_SHOW);
                return this;
            },

            /**
             * Selection handler for adding unit revisions from the sidepanel
             * @param unitRevision
             */
            onSelectUnitRevisionForAdding(unitRevision) {
                // Prepare for API
                this.courseToEdit.unit_uids.push(unitRevision.unit_uid);

                // Update UI
                const unitToAdd = this.unitService.unitPage.unitList.find(u => u.uid === unitRevision.unit_uid) || null;
                const revisionIndex = this.revisionsThatCanBeAdded.findIndex(r => r.uid === unitRevision.uid);
                if (unitToAdd && revisionIndex >= 0) {
                    this.selectedUnit = null;
                    unitToAdd.is_new = true;
                    this.unitsInCourse.push(unitToAdd);
                    this.revisionsThatCanBeAdded.splice(revisionIndex, 1);

                    this.$globalEvents.emit(EventType.SIDEPANEL_UNITREVISIONS_HIDE);

                    // Scroll to bottom of the list
                    this.$nextTick(() => {
                        this.$refs.content.scrollTo(0, this.$refs.content.scrollHeight);
                    });
                }

                this.courseHasChanged = true;

                return this;
            },

            /**
             * Cancel click handler for save changes dialog
             */
            onCancelSaveCourseChanges() {
                // Clear the callback:
                this.afterSaveCallback = null;
                return this;
            },

            /**
             * Apply click handler for save changes dialog
             * @param {Course} course
             */
            onApplySaveCourseChanges(course) {
                // Save the course changes:
                this.saveCourse();
                return this;
            },

            /**
             * Dismiss click handler for save changes dialog
             * @param {Course} course
             */
            onDismissSaveCourseChanges(course) {
                if (this.courseToEdit !== null) {
                    const originalCourse = this.originalCourseToEdit

                    if (originalCourse !== null) {
                        // @NOTE: Creating a new instance so we can later revert any changes
                        this.courseToEdit = new Course(originalCourse);
                    }
                }
                this.courseHasChanged = false;
                this.onAfterSaveOrDismissChanges();
                return this;
            },

            /**
             * Execute the callback after saving data
             */
            onAfterSaveOrDismissChanges() {
                if (typeof this.afterSaveCallback === 'function') {
                    this.afterSaveCallback.call(this);
                    this.afterSaveCallback = null;
                }
                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = () => {
                        this.courseHasChanged = false;
                        this.$root.forceLogout();
                    };
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * Shortcut handler for reloading
             *
             * @param {CustomEvent} e
             */
            onShortcutReload(e) {
                if (this.courseHasChanged === true && permission(Permission.CoursesUpdate()))
                {
                    this.afterSaveCallback = function() {window.location.reload();}.bind(this);
                    this.onBeforeUnload(e.detail.keyboardEvent);
                }
                return this;
            },

            onShortcutDelete() {
                if (
                    this.selectedUnit
                    && (
                        this.$refs.unitList.$el.contains(document.activeElement)
                        || document.activeElement.matches('.panels')
                    )
                ) {
                    this.onClickUnitRemove(this.selectedUnit);
                }
                return this;
            },

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                // Prevent losing unsaved changes
                if (
                    this.courseHasChanged === true
                    && permission(Permission.CoursesUpdate())
                    && this.$globalEvents.isEventTargetDescendantOfSelector(e, '#layout-sidemenu a, #inspector > .buttons > .btn', '[href="#"], [target="_blank"]') === true
                ) {
                    e.preventDefault();
                    e.returnValue = '';
                    // Show modal dialog:
                    this.afterSaveCallback = function() {
                        e.target.closest('a, .btn').click();
                    }.bind(this);
                    this.$globalEvents.emit(EventType.MODAL_SAVE_COURSE_CHANGES_SHOW, this.courseToEdit);
                }
                return this;
            },

            /**
             * Before unload handler
             *
             * @param {BeforeUnloadEvent} e
             */
            onBeforeUnload(e) {
                if (this.courseHasChanged === true && permission(Permission.CoursesUpdate())) {
                    e.preventDefault();
                    e.returnValue = '';
                    this.$globalEvents.emit(EventType.MODAL_SAVE_COURSE_CHANGES_SHOW, this.courseToEdit);
                }
                else
                {
                    this.courseService.cancelRequests();
                    this.userService.cancelRequests();
                    // @NOTE: $nextTick() doesn't work here since isLoading will be recomputed afterwards (hiding the overlay) so the quick fix is to use setTimeout
                    setTimeout(() => {this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);},1);
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                }
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

    .unit-list {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .pagination {
        margin-top: 40px;
    }
</style>
