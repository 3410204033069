<template>
    <div
        :data-loading="isLoading"
        class="container">

        <PageImportListItem
            v-for="(content, packageName) in contentPackages"
            :content="content"
            :package="packageName"
        />

        <DialogLoading :title="trans('units.import.importing')" :description="trans('units.import.importing_text')" />
        <DialogNotification/>
    </div>
</template>

<script>
    import {shortId} from "@/Utility/Helpers";
    import EventType from "@/Utility/EventType";
    import PageImportListItem from "@/Vue/Import/PageImportListItem.vue";
    import DialogNotification from "@/Vue/Modals/DialogNotification.vue";
    import DialogLoading from "@/Vue/Modals/DialogLoading.vue";

    export default {
        name: "PageImportList",
        components: {
            DialogNotification,
            DialogLoading,
            PageImportListItem,
        },

        props: {
            packages: {
                type: Object,
                default: {},
            },
            url: {
                type: String,
                default: '/api/units/import/',
            },
        },

        data() {
            return {
                uid: shortId('page-import-list'),
                contentPackages: {},
                unitService: this.$unitService,
            }
        },

        beforeMount() {
            this.contentPackages = this.packages;
        },

        computed: {
            isLoading() {
                if (this.unitService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_LOADING_SHOW);
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_LOADING_HIDE);
                return false;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>
