<template>
    <Collapsible class="inspector-panel panel-behaviours" :initial-collapsed="true" v-shortcuts>
        <template #header>
            {{ trans('labels.behaviours') }}
        </template>
        <template #body>
            <div ref="panelBehaviours" class="sceneobject-behaviour-selection">

                <Draggable
                    v-for="(behaviour, behaviourIndex) in sceneObject.behaviours"
                    groups="sceneobject-behaviour"
                    :disabled="shouldDragAndDropBeDisabled"
                    :value="{sceneObject: sceneObject, behaviour: behaviour, index: behaviourIndex}"
                    :draggable-selector="'header[draggable]'"
                    @drop="onDropBehaviour"
                    class="panel-card sceneobject-behaviour"
                    :key="'behaviours'+behaviour.uid+behaviour.type+behaviourIndex+'_'+sceneObject.behaviours.length"
                    v-focusable
                    :data-behaviour-uid="behaviour.uid"
                >
                    <header :draggable="!shouldDragAndDropBeDisabled">
                        <label>{{ trans('authoring.behaviours.types.' + behaviour.type) }}</label>
                        <Icon name="icon_close" class="icon-delete" @click="onClickRemoveBehaviour(behaviour)" />
                    </header>

                    <PanelBehaviour
                        :behaviour="behaviour"
                        @change="onChangeBehaviour"
                    />

                </Draggable>

                <div class="btn-add-with-dropdown">
                    <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
                    <ButtonSecondary v-not-focusable icon="icon_add" :disabled="!shouldShowAddBehaviourSelection" caption="authoring.add_behaviour" />
                    <Dropdown
                        :key="'dropdown-'+sceneObject.uid+'_'+sceneObject.behaviours.length"
                        @select="onSelectBehaviour"
                        :options="availableBehaviourOptionsForSceneObject"
                        :deselected-caption="trans('authoring.select_behaviour')"
                        :disabled="!shouldShowAddBehaviourSelection"
                    />
                </div>
            </div>
        </template>
    </Collapsible>
</template>

<script>
import { supportedBehaviourDropdownOptions }    from '@/Models/UnitData/Behaviours/BehaviourHelpers';
import { sortArrayByProperty }                  from '@/Utility/Helpers';
import Behaviour, { getBehaviourClassFromType } from '@/Models/UnitData/Behaviours/Behaviour';
import SceneObject                              from '@/Models/UnitData/SceneObjects/SceneObject';

export default {
    name: 'PanelBehaviours',
    emits: [
        'change',
    ],
    props: {
        sceneObject: {
            type: SceneObject,
            default: null
        }
    },
    data() {
        return {
            shortcuts: new Map([
                ['Delete', this.onShortcutDelete],          // Delete content
                ['Duplicate.stop.prevent', null],           // Prevent duplicate shortcut from bubbling
                ['Cut.stop.prevent', null],                 // Prevent cut shortcut from bubbling
                ['Copy.stop.prevent', null],                // Prevent copy shortcut from bubbling
                ['Paste.stop.prevent', null],               // Prevent paste shortcut from bubbling
            ]),
        }
    },
    computed: {
        /**
         * Transform the behaviours to options for the dropdown
         *
         * @returns {Array<DropdownOption>}
         */
        availableBehaviourOptionsForSceneObject() {
            const possibleOptions = this.possibleBehaviourDropdownOptionsData;
            const possibleOptionsKeys = Object.keys(possibleOptions);
            const alreadyAddedBehaviours = [... new Set(this.sceneObject.behaviours.map(behaviour => behaviour.type))];

            const availableOptions = possibleOptionsKeys.filter((value) => {
                return alreadyAddedBehaviours.includes(value) === false;
            })

            const options = [];

            availableOptions.forEach(function(option){
                options.push(possibleOptions[option]);
            })

            return sortArrayByProperty(options, 'caption', false);
        },

        /**
         * Determines if the drag and drop functionality should be disabled.
         *
         * @returns {Boolean}
         */
        shouldDragAndDropBeDisabled() {
            // Disable Drag and Drop if there are less than 2 behaviours added.
            return (this.sceneObject.behaviours.length < 2);
        },

        /**
         * Possible options for the dropdown
         *
         * @returns {Object<Object>}
         */
        possibleBehaviourDropdownOptionsData() {
            return supportedBehaviourDropdownOptions(this.sceneObject);
        },

        /**
         * Whether to show the selection for adding a new behaviour
         *
         * @returns {Boolean}
         */
        shouldShowAddBehaviourSelection() {
            return this.availableBehaviourOptionsForSceneObject.length > 0;
        },
    },

    methods: {

        /**
         * Change handler for the behaviour settings
         *
         * @param {Behaviour} behaviour
         */
        onChangeBehaviour(behaviour) {
            this.$emit('change', behaviour);
            return this;
        },

        /**
         * Click handler for behaviour remove button
         *
         * @param {Behaviour} behaviour   // Behaviour object reference
         */
        onClickRemoveBehaviour(behaviour) {
            this.sceneObject.removeBehaviour(behaviour);
            this.$emit('change', this.sceneObject.behaviours);
            return this;
        },

        /**
         * Drop handler for behaviours
         *
         * @param {Event} e
         */
        onDropBehaviour(e) {
            e.preventDefault();
            const sourceSceneObject = e.dataDraggable?.value?.sceneObject || null;
            const targetSceneObject = e.dataDropTarget?.value?.sceneObject || null;
            const currentIndex = e.dataDraggable?.value?.index || 0;
            const targetIndex = e.dataDropTarget?.value?.index || 0;

            if (sourceSceneObject === null || targetSceneObject === null) {
                console.warn('PanelBehaviours->onDropBehaviour(): Invalid dragging data.', e);
                return this;
            }

            const dropBehaviour = sourceSceneObject.behaviours[currentIndex];
            let hasChanged = false;

            // Source and target sceneObjects are the same:
            if (sourceSceneObject.uid === targetSceneObject.uid)
            {
                if (targetIndex > currentIndex)
                {
                    targetSceneObject.behaviours.splice(targetIndex + 1, 0, dropBehaviour);
                    targetSceneObject.behaviours.splice(currentIndex, 1);
                    hasChanged = true;
                }
                else if (targetIndex < currentIndex)
                {
                    targetSceneObject.behaviours.splice(targetIndex, 0, dropBehaviour);
                    targetSceneObject.behaviours.splice(currentIndex + 1, 1);
                    hasChanged = true;
                }
            }
            // Different sceneObject targets:
            else
            {
                targetSceneObject.behaviours.splice(currentIndex, 1);
                targetSceneObject.behaviours.splice(targetIndex, 0, dropBehaviour);
                hasChanged = true;
            }
            if (hasChanged === true)
            {
                this.$emit('change', this.sceneObject.behaviours);
            }
            return this;
        },

        /**
         * Select handler for the add behaviour dropdown
         *
         * @param {String} value
         */
        onSelectBehaviour(value) {
            if (getBehaviourClassFromType(value) === null)
            {
                throw new TypeError('PanelBehaviours->onSelectBehaviour(): Invalid BehaviorType "' + value + '"');
            }

            this.sceneObject.behaviours.push(Behaviour.createWithType(value, null, this.sceneObject));
            this.$emit('change', this.sceneObject.behaviours);

            return this;
        },

        /**
         * Shortcut handler: Delete
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            const behaviourUid = e.target.dataset.behaviourUid || null;
            if (behaviourUid !== null || this.$refs.panelBehaviours.contains(e.target))
            {
                e.stopImmediatePropagation();
                e.detail.keyboardEvent.stopImmediatePropagation();
                const behaviour = this.sceneObject.behaviours.find(b => b.uid === behaviourUid) || null;
                if (behaviour !== null)
                {
                    return this.onClickRemoveBehaviour(behaviour);
                }
            }

            return this;
        }
    },
}
</script>
