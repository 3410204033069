import UserFile from "@/Utility/Helpers/UserFile";

/**
 * Transforms the given DataTransferItem (e.g. from a drop operation) recursively into a list of Files.
 * Directories will be traversed until all files have been found.
 *
 * @param inputFiles item from drop operation or a directory file input field to get files from
 */
export async function parseUserFiles(inputFiles: DataTransferItem | DataTransferItemList | FileList): Promise<Array<UserFile>> {
    if (inputFiles instanceof DataTransferItem) {
        return parseUserFilesFromDataTransferItem(inputFiles);
    } else if (inputFiles instanceof DataTransferItemList) {
        return parseUserFilesFromDataTransferItemList(inputFiles);
    } else {
        return parseUserFilesFromFileList(inputFiles);
    }
}

async function parseUserFilesFromFileList(fileList: FileList): Promise<Array<UserFile>> {
    const rootPath = fileList[0].webkitRelativePath.split('/')[0];

    const files: Array<UserFile> = [];
    for (const file of fileList) {
        const relativePath = getRelativePath(rootPath, file.webkitRelativePath);
        files.push(new UserFile(file, relativePath));
    }

    return files;
}

async function parseUserFilesFromDataTransferItemList(dataTransferItemList: DataTransferItemList): Promise<Array<UserFile>> {
    const parseUserFilesPromises: Array<Promise<UserFile[]>> = [];

    const firstEntry = dataTransferItemList[0].webkitGetAsEntry();
    const rootPath = dataTransferItemList.length == 1 && firstEntry?.isDirectory ?
        // we are processing a single directory - its path should be the root path
        firstEntry.fullPath :
        // we are processing one or multiple files - no special root path
        '/';

    for (const item of dataTransferItemList) {
        parseUserFilesPromises.push(parseUserFilesFromDataTransferItem(item, rootPath));
    }

    const nestedUserFiles = await Promise.all(parseUserFilesPromises);
    return nestedUserFiles.flat();
}

async function parseUserFilesFromDataTransferItem(dataTransferItem: DataTransferItem, rootPath: string|undefined = undefined): Promise<Array<UserFile>> {
    const entry = dataTransferItem.webkitGetAsEntry();

    if (entry === null) {
        return [];
    }

    const validatedRootPath = rootPath ?? entry.fullPath;
    const fileEntries = await getFileEntriesRecursiveInternal(entry);

    const files: Array<UserFile> = [];
    for (const fileEntry of fileEntries) {
        const file = await fileSystemFileEntryToFileAsync(fileEntry);

        const relativePath = getRelativePath(validatedRootPath, fileEntry.fullPath);
        files.push(new UserFile(file, relativePath));
    }

    return files;
}

async function getFileEntriesRecursiveInternal(entry: FileSystemEntry, pastEntries: Array<FileSystemFileEntry> = []): Promise<Array<FileSystemFileEntry>> {
    if (entry.isFile) {
        pastEntries.push(entry as FileSystemFileEntry);
        return pastEntries;
    }

    const childEntries = await getFileEntriesFromDirectory(entry as FileSystemDirectoryEntry);

    for (const childEntry of childEntries) {
        await getFileEntriesRecursiveInternal(childEntry, pastEntries);
    }

    return pastEntries;
}

async function getFileEntriesFromDirectory(entry: FileSystemDirectoryEntry): Promise<Array<FileSystemEntry>> {
    return new Promise((resolve, reject) =>
        entry
            .createReader()
            .readEntries(
                res => resolve(res),
                error => reject(error)
            )
    );
}

/**
 * Async version of FileSystemFileEntry.file()
 */
async function fileSystemFileEntryToFileAsync(fileSystemFileEntry: FileSystemFileEntry): Promise<File> {
    return new Promise((resolve, reject) =>
        fileSystemFileEntry.file(
            res => resolve(res),
            error => reject(error)
        )
    );
}

function getRelativePath(rootPath: string, path: string): string {
    let relativePath = path;

    if (relativePath.startsWith(rootPath) && relativePath !== rootPath) {
        relativePath = relativePath.substring(rootPath.length);
    }

    if (relativePath.startsWith('/')) {
        relativePath = relativePath.substring(1);
    }

    return relativePath;
}
