<template>
    <div v-if="isVisible" id="dialog-unit-history" class="modal-dialog fullscreen" @click="onClickCancel" v-not-focusable v-shortcuts.global.prevent.stop>
        <div ref="dialog" class="dialog" @click.stop>
            <Icon name="icon_close" class="icon-close" @click="onClickCancel" />
            <h2 class="title">{{ trans('modals.unit_history.title') }}</h2>
            <ul class="selectable-list" v-not-focusable>

                <!-- Revisions -->
                <template
                    v-for="(revision, index) in unit.revisions"
                    :key="'history'+revision.uid"
                >
                    <li
                        :class="(!revision.isCompatible ? 'incompatible' : '')"
                        v-focusable
                        @click.capture.stop="onSelectRevision(revision)"
                        @keyup.enter="onSelectRevision(revision)"
                    >
                        {{ (index === 0) ? trans('labels.current_version') : getDateForRevision(revision) }}
                        {{ (revision.platform !== 'wms') ? '(App)' : '' }}
                        {{ getAuthorNameForRevision(revision) }}
                        <StatusLabel
                            v-for="labelConfig in getLabelsForRevision(revision)"
                            :key="'page-header-statuslabel'+(labelConfig.type || labelConfig.caption)"
                            :config="labelConfig"
                        />
                    </li>

                </template>
            </ul>
            <p class="description" v-html="trans('modals.unit_history.description')"></p>
        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    //

    // Import classes:
    import moment                   from 'moment';
    import Unit                     from '@/Models/Unit/Unit';
    import {trans}                  from '@/Utility/Helpers';
    import EventType                from '@/Utility/EventType';
    import StatusLabelConfig        from '@/Utility/StatusLabelConfig';

    export default {
        name: 'DialogUnitHistory',
        emits: [
            'select',
        ],
        props: {
            unit: {                                     // Unit for which to show the revisions history
                type: Unit,
                default: null
            }
        },
        data() {
            return {
                isVisible: false,                       // Visibility state
                previousFocusElement: null,             // DOM element that had focus before the dialog was show
                shortcuts: new Map([             // Shortcut mapping to methods
                    ['Escape', this.onClickCancel],
                    ['Any', null],                      // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_UNIT_HISTORY_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_UNIT_HISTORY_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_UNIT_HISTORY_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_UNIT_HISTORY_HIDE, this.hide);
        },

        methods: {

            /**
             * Show the dialog
             */
            show() {
                this.previousFocusElement = document.activeElement;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_UNIT_HISTORY_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Select handler for a revision entry
             *
             * @param {UnitRevision} revision
             */
            onSelectRevision(revision) {
                this.hide();
                this.$emit('select', revision);
                return this;
            },

            /**
             * @param {UnitRevision} revision
             * @returns {String}
             */
            getDateForRevision(revision) {
                return moment(revision.updated_at).format(trans('units.history.date_format_with_time'));
            },

            /**
             * @param {UnitRevision} revision
             * @returns {String}
             */
            getAuthorNameForRevision(revision) {
                const authorName = revision.owner ? revision.owner.fullName : trans('labels.unknown_author');
                return `${trans('labels.by')} ${authorName}`;
            },

            /**
             * @param {UnitRevision} revision
             * @returns {Array<StatusLabelConfig>}
             */
            getLabelsForRevision(revision) {
                const labels = [];

                if (revision === null) {
                    return [];
                }

                if (revision.isReleased) {
                    const isLatestReleased = (this.unit.latest_released_revision_uid === revision.uid);

                    labels.push(
                        new StatusLabelConfig({
                            type: isLatestReleased ? 'status' : 'disabled',
                            caption: 'released'
                        })
                    );
                }

                return labels;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
