<template>
    <div
        v-if="isVisible"
        id="dialog-duplicate-trainingscene"
        v-not-focusable
        v-shortcuts.global.prevent.stop
        class="modal-dialog fullscreen">

        <div class="dialog">

            <div class="title">
                {{ trans('tokens.dialogs.view.headline') }}
            </div>

            <div class="description">
                {{ trans('tokens.dialogs.view.description') }}
            </div>

            <textarea
                class="access-token"
                v-text="token.access_token"/>

            <div class="buttons">
                <ButtonPrimary
                    caption="labels.okay"
                    class="btn-okay"
                    @trigger="onClickOkay"/>
            </div>
        </div>

    </div>
</template>

<script>

    export default {
        name: 'DialogAccessToken',
        data() {
            return {
                isVisible: false,
                token: null,
                previousFocusElement: null,
                shortcuts: new Map([
                    ['Escape', this.onClickOkay],
                ])
            }
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {Token} token
             */
            show(token) {
                this.previousFocusElement = document.activeElement;
                this.token = token;
                this.isVisible = true;
                this.$nextTick(() => {
                    if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();
                });
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.token = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function) {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for okay button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickOkay(e) {
                this.hide();
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

    .access-token {
        width: 100%;
        height: 200px;
        font-size: var(--font-size-small);
        padding: 0 20px;
        border-top: 2px solid var(--color-anthracite20);
        border-right: 0;
        border-bottom: 2px solid var(--color-anthracite20);
        border-left: 0;
        border-radius: 0;
        background-color: var(--background-color-light);
    }

</style>
