<template>
    <div v-if="isVisible" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.stop>
        <div class="dialog modal-asset-preview">

            <Icon name="icon_close" class="icon-close" @click="onClickHide"/>
            <h2 class="title">{{ model.name }}</h2>

            <iframe
                class="preview-wrapper"
                ref="viewerIframe"
                v-show="viewerLoaded"
                allow="autoplay; fullscreen; xr-spatial-tracking">
            </iframe>

            <div
                class="error"
                v-if="hasError">
                <Icon name="icon_info" class="icon-info" />
                <p>{{ error }}</p>
            </div>
        </div>
    </div>
</template>

<script>

// Import classes:
import EventType from '@/Utility/EventType';
import Sketchfab from '@sketchfab/viewer-api';
import Icon from "@/Vue/Common/Icon.vue";


export default {
    name: 'SketchfabModelPreviewModal',
    components: {Icon},

    data() {
        return {
            isVisible: false,                       // Visibility state
            previousFocusElement: null,             // DOM element that had focus before the dialog was shown
            shortcuts: new Map([            // Shortcut mapping to methods
                ['Escape', this.onShortcutEscape],
                ['Any.prevent', null],              // Prevent any other shortcut
            ]),

            /**
             * @type {SketchfabModel}
             */
            model: null,

            /**
             * @type {string|null}
             */
            error: null,

            viewerLoaded: false,
        }
    },

    mounted() {
        this.$globalEvents.on(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_SHOW, this.show);
        this.$globalEvents.on(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_HIDE, this.hide);
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_SHOW, this.show);
        this.$globalEvents.off(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_HIDE, this.hide);
    },

    computed: {

        hasError() {
            return this.error !== null;
        }

    },

    methods: {

        initSketchfabViewer() {
            const client = new Sketchfab('1.12.1', this.$refs.viewerIframe);

            client.init(this.model.uid, {
                error: this.onLoadViewerError,
                success: this.onLoadViewerSuccess,
                autospin: 0.2,
                autostart: 1,
                dnt: 1,
                ui_stop: 0,
            });
        },

        /**
         * Show the dialog
         *
         * @param {SketchfabModel} sketchfabModel
         */
        show(sketchfabModel) {
            this.model = sketchfabModel;
            this.previousFocusElement = document.activeElement;
            this.isVisible = true;

            this.$nextTick(() => {
                if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();

                // delay, because we need dom initialized
                this.initSketchfabViewer();
            });

            return this;
        },

        /**
         * Hide the dialog
         */
        hide() {
            this.error = null;
            this.viewerLoaded = false;

            this.isVisible = false;
            this.$nextTick(() => {
                if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function) {
                    this.previousFocusElement.focus();
                }
                this.previousFocusElement = null;
            });
            return this;
        },

        /**
         * Click handler for cancel button
         *
         * @param {CustomEvent|MouseEvent} e
         */
        onClickHide(e) {
            this.$globalEvents.emit(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_HIDE);
            this.hide();
            return this;
        },

        /**
         * Click handler for cancel button
         *
         * @param {CustomEvent|MouseEvent} e
         */
        onClickCancel(e) {
            this.$globalEvents.emit(EventType.MODAL_SKETCHFAB_MODEL_PREVIEW_CANCEL);
            this.hide();
            return this;
        },

        /**
         * Shortcut handler for Escape key
         *
         * @param {CustomEvent} e
         */
        onShortcutEscape(e) {
            this.onClickCancel(e);
        },

        onLoadViewerSuccess() {
            this.viewerLoaded = true;
        },

        /**
         * @param {string} error
         */
        onLoadViewerError(error) {
            this.error = error;
        },
    }
}
</script>

<style lang="scss" scoped>

.modal-dialog .dialog.modal-asset-preview {
    height: 100%;
    aspect-ratio: 16/9;

    .preview-wrapper {
        border: none;
        width: 100%;
        height: 100%;
        border-radius: var(--card-border-radius);
    }

    .error {
        margin: auto;
        text-align: center;

        .icon-info {
            width: 24px;
            margin-bottom: 4px;
        }
    }
}

</style>
