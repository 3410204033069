<template>
    <div v-if="isVisible" id="dialog-change-unit-type" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <p class="title">{{ trans('modals.change_unit_type.title') }}</p>
            <h2 v-if="unitType" class="training-type">{{ unitType.title }}</h2>
            <p class="description" v-html="trans('modals.change_unit_type.description')"></p>
            <Checkbox
                :initialValue="keepAssetLocations"
                layout="checkbox-right"
                @change="onChangeKeepLocations"
                :caption="trans('modals.change_unit_type.keep_locations')"
            />
            <div class="buttons">
                <ButtonSecondary class="btn-cancel" @trigger="onClickCancel" caption="modals.change_unit_type.cancel" />
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="modals.change_unit_type.apply" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    //

    // Import classes:
    import EventType                from '@/Utility/EventType';

    export default {
        name: 'DialogChangeTrainingType',
        data() {
            return {
                isVisible: false,                       // Visibility state
                unitType: null,                         // Reference to the UnitType for which this dialog is being shown
                keepAssetLocations: true,               // Checkbox state for whether the asset locations should be reset
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onClickApply],
                    ['Escape', this.onClickCancel],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_CHANGE_UNIT_TYPE_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_CHANGE_UNIT_TYPE_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_CHANGE_UNIT_TYPE_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_CHANGE_UNIT_TYPE_HIDE, this.hide);
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {UnitType} unitType
             */
            show(unitType) {
                this.previousFocusElement = document.activeElement;
                this.unitType = unitType;
                this.keepAssetLocations = true;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.unitType = null;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_CHANGE_UNIT_TYPE_CANCEL);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType.MODAL_CHANGE_UNIT_TYPE_APPLY, this.unitType, this.keepAssetLocations);
                this.hide();
                return this;
            },

            /**
             * Change handler for keep locations checkbox
             */
            onChangeKeepLocations(checked) {
                this.keepAssetLocations = checked;
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
