<template>
    <tr>
        <td class="user-preview-cell">
            <div class="user-preview">
                <img :src="user?.image" alt=""/>
            </div>
        </td>
        <td class="user-info-cell">
            <div class="user-info">
                <h2 v-if="canBeEditedByCurrentUser" class="user-info-title">
                    <a :href="route('users.updateForm', {'user': this.user.uid})">{{ user.fullName }}</a>
                </h2>
                <h2 v-else class="user-info-title">{{ user.fullName }}</h2>
                <p class="user-info-description">{{ user.email }}</p>
            </div>
        </td>
        <td class="user-separator-cell"><span></span></td>
        <td class="tenant-role text-center">
            <span class="cell-title">{{ trans('labels.tenant_role') }}</span>
            <span class="cell-description">
                {{ user.tenant_role.caption }}
                <svg v-if="isAdminUser" class="icon icon-admin"><use xlink:href="#icon_admin"></use></svg>
                <svg v-if="isLmsUser" class="icon icon-temp"><use xlink:href="#icon_temp"></use></svg>
            </span>
        </td>
    </tr>
</template>

<script lang="ts">

    import {route, trans} from '@/Utility/Helpers';
    import {defineComponent, PropType} from "vue";
    import User from "@/Models/User/User";
    import {UserRole} from "@/Models/User/UserRole";
    import {Permission} from "@/Models/User/Permission";

    export default defineComponent({

        props: {
            user: {
                type: Object as PropType<User>,
                required: true,
            },
        },

        computed: {

            isAdminUser(): boolean {
                return this.user.role === UserRole.Admin || this.user.role === UserRole.SuperAdmin;
            },

            isLmsUser(): boolean {
                return this.user.role === UserRole.Lms;
            },

            canBeEditedByCurrentUser(): boolean {
                return this.$gate.allows(Permission.ability(Permission.UsersUpdate()), this.user);
            },
        },

        methods: {
            route,
            trans,
        }
    });
</script>

<style lang="scss" scoped>

</style>
