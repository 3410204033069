<template>
    <div v-if="isVisible" class="modal-dialog fullscreen" @click="onClickCloseWhileTimer" v-not-focusable v-shortcuts.global.prevent.stop>
        <div class="dialog">
            <Icon v-if="closeTimer !== null" name="icon_close" class="icon-close" />
            <h2 class="title">{{ title }}</h2>
            <p class="description" v-html="description"></p>
            <div class="buttons" v-if="closeTimer === null">
                <ButtonPrimary class="btn-apply" @trigger="onClickApply" caption="labels.okay" />
            </div>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import { nl2br }                from '@/Utility/Helpers';

    export default {
        name: 'DialogNotification',
        props: {
            eventType: {
                type: String,
                default: 'MODAL_NOTIFICATION' // Must be a string from EventType class
            }
        },
        data() {
            return {
                isVisible: false,                       // Visibility state
                title: null,                            // Title text
                description: null,                      // Description text
                callback: null,                         // Callback function when the dialog is closed
                closeTimer: null,                       // Timer for closing the dialog after a certain time
                showDuration: 4000,                     // Duration in ms for how long the notification should be visible
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Enter', this.onShortcutEnter],
                    ['Escape', this.onShortcutEscape],
                    ['Any', null],              // Prevent any other shortcut
                ])
            }
        },
        created() {
            // Make sure the given type's events exist:
            ['SHOW', 'HIDE', 'CANCEL', 'APPLY'].forEach((e) => {
                if (typeof EventType[this.eventType + '_' + e] !== 'string')
                {
                    throw new TypeError('DialogNotification: EventType ' + (this.eventType + '_' + e) + ' doesn\'t exist.');
                }
            });
        },
        mounted() {
            this.$globalEvents.on(EventType[this.eventType + '_SHOW'], this.show);
            this.$globalEvents.on(EventType[this.eventType + '_HIDE'], this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType[this.eventType + '_SHOW'], this.show);
            this.$globalEvents.off(EventType[this.eventType + '_HIDE'], this.hide);
        },
        methods: {

            /**
             * Show the dialog
             *
             * @param {String} title
             * @param {String} description
             * @param {Boolean} autoClose
             * @param {Boolean} busy
             * @param {Function} callback
             */
            show(title, description, autoClose = false, busy = false, callback = null) {
                this.previousFocusElement = document.activeElement;
                if (typeof title !== 'string') {
                    throw new TypeError('DialogNotification->show(): Title parameter must be a valid string.');
                }
                if (typeof description !== 'string') {
                    throw new TypeError('DialogNotification->show(): Description parameter must be a valid string.');
                }
                this.title = title;
                this.description = nl2br(description);
                this.callback = callback;
                this.isVisible = true;

                // Add "busy" animation:
                if (busy === true)
                {
                    this.$nextTick(() => {
                        this.$el.classList.add('is-busy');
                    });
                }
                else
                {
                    this.$nextTick(() => {
                        this.$el.classList.remove('is-busy');
                    });
                }

                // Set or clear the timer for automatic closing:
                if (autoClose === true)
                {
                    this.closeTimer = window.setTimeout(function() {
                        this.hide();
                        window.clearTimeout(this.closeTimer);
                        this.closeTimer = null;
                    }.bind(this), this.showDuration);
                }
                else if (this.closeTimer !== null)
                {
                    window.clearTimeout(this.closeTimer);
                    this.closeTimer = null;
                }
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                if (this.closeTimer !== null)
                {
                    window.clearTimeout(this.closeTimer);
                    this.closeTimer = null;
                }
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                if (typeof this.callback === 'function')
                {
                    this.callback();
                }
                return this;
            },

            /**
             * Click handler for close button
             *
             * @param {MouseEvent} e
             */
            onClickCloseWhileTimer(e) {
                // Only close when the timer is active:
                if (this.closeTimer !== null)
                {
                    this.$globalEvents.emit(EventType[this.eventType + '_CANCEL']);
                    this.hide();
                }
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType[this.eventType + '_CANCEL']);
                this.hide();
                return this;
            },

            /**
             * Click handler for apply button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickApply(e) {
                this.$globalEvents.emit(EventType[this.eventType + '_APPLY']);
                this.hide();
                return this;
            },

            /**
             * Shortcut handler for Enter key
             *
             * @param {CustomEvent} e
             */
            onShortcutEnter(e) {
                if (this.closeTimer === null)
                {
                    this.onClickApply(e);
                }
                else
                {
                    this.onClickCancel(e);
                }
            },

            /**
             * Shortcut handler for Escape key
             *
             * @param {CustomEvent} e
             */
            onShortcutEscape(e) {
                if (this.closeTimer !== null)
                {
                    this.onClickCancel(e);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
