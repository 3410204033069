<template>
    <div v-if="isVisible" id="dialog-installation" class="modal-dialog fullscreen" v-not-focusable v-shortcuts.global.stop>
        <div class="dialog">
            <Icon name="icon_close" class="icon-close" @click="onClickCancel"/>
            <h1 class="title">{{ trans('modals.install_app.title') }}</h1>
            <div class="dialog-installation-wrapper">

                <DialogInstallationCellApp
                    logoName="logo_windows"
                    :title="trans('modals.install_app.apps.windows.title')"
                    :description="trans('modals.install_app.apps.windows.description')"
                    :buttonCaption="trans('modals.install_app.apps.windows.button.caption')"
                    :buttonHref="trans('modals.install_app.apps.windows.button.href')"
                    :linkCaption=" trans('modals.install_app.apps.windows.link.caption')"
                    :linkHref="trans('modals.install_app.apps.windows.link.href')"
                />

                <DialogInstallationCellApp
                    logoName="logo_windows"
                    :title="trans('modals.install_app.apps.hololens.title')"
                    :description="trans('modals.install_app.apps.hololens.description')"
                    :buttonCaption="trans('modals.install_app.apps.hololens.button.caption')"
                    :buttonHref="trans('modals.install_app.apps.hololens.button.href')"
                    :linkCaption=" trans('modals.install_app.apps.hololens.link.caption')"
                    :linkHref="trans('modals.install_app.apps.hololens.link.href')"
                />

                <DialogInstallationCellApp
                    logoName="logo_meta"
                    :title="trans('modals.install_app.apps.oculus_consumer.title')"
                    :description="trans('modals.install_app.apps.oculus_consumer.description')"
                    :buttonCaption="trans('modals.install_app.apps.oculus_consumer.button.caption')"
                    :buttonHref="trans('modals.install_app.apps.oculus_consumer.button.href')"
                    :linkCaption=" trans('modals.install_app.apps.oculus_consumer.link.caption')"
                    :linkHref="trans('modals.install_app.apps.oculus_consumer.link.href')"
                />

                <DialogInstallationCellAppQuestBusiness
                    logoName="logo_meta"
                    :title="trans('modals.install_app.apps.oculus_business.title')"
                    :buttonCaption="trans('modals.install_app.apps.oculus_business.button.caption')"
                    :buttonHref="trans('modals.install_app.apps.oculus_business.button.href')"
                    :linkCaption=" trans('modals.install_app.apps.oculus_business.link.caption')"
                    :linkHref="trans('modals.install_app.apps.oculus_business.link.href')"
                />

                <DialogInstallationCellApp
                    logoName="logo_pico"
                    :title="trans('modals.install_app.apps.pico.title')"
                    :description="trans('modals.install_app.apps.pico.description')"
                    :buttonCaption="trans('modals.install_app.apps.pico.button.caption')"
                    :buttonHref="trans('modals.install_app.apps.pico.button.href')"
                    :linkCaption=" trans('modals.install_app.apps.pico.link.caption')"
                    :linkHref="trans('modals.install_app.apps.pico.link.href')"
                />

                <DialogInstallationCellApp
                    logoName="logo_vive"
                    :title="trans('modals.install_app.apps.vive_focus.title')"
                    :description="trans('modals.install_app.apps.vive_focus.description')"
                    :buttonCaption="trans('modals.install_app.apps.vive_focus.button.caption')"
                    :buttonHref="trans('modals.install_app.apps.vive_focus.button.href')"
                    :linkCaption=" trans('modals.install_app.apps.vive_focus.link.caption')"
                    :linkHref="trans('modals.install_app.apps.vive_focus.link.href')"
                />

            </div>
        </div>
    </div>
</template>

<script>

    // Import classes:
    import EventType                    from '@/Utility/EventType';
    import DialogInstallationCellApp    from '@/Vue/Modals/Installation/DialogInstallationCellApp.vue';
    import DialogInstallationCellAppQuestBusiness    from '@/Vue/Modals/Installation/DialogInstallationCellAppQuestBusiness.vue';

    export default {
        name: 'DialogInstallation',
        components: {
            DialogInstallationCellApp,
            DialogInstallationCellAppQuestBusiness,
        },
        data() {
            return {
                isVisible: false,               // Visibility state
                previousFocusElement: null,     // DOM element that had focus before the dialog was shown
                shortcuts: new Map([            // Shortcut mapping to methods
                    ['Escape', this.onClickCancel],
                    ['Copy', null],
                    ['Paste', null],
                    ['Any.prevent', null],             // Prevent any other shortcut
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.MODAL_INSTALL_APP_SHOW, this.show);
            this.$globalEvents.on(EventType.MODAL_INSTALL_APP_HIDE, this.hide);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_INSTALL_APP_SHOW, this.show);
            this.$globalEvents.off(EventType.MODAL_INSTALL_APP_HIDE, this.hide);
        },
        methods: {

            /**
             * Show the dialog
             */
            show() {
                this.previousFocusElement = document.activeElement;
                this.isVisible = true;
                this.$nextTick(() => {if (this.$el instanceof Object && this.$el.focus instanceof Function) this.$el.focus();});
                return this;
            },

            /**
             * Hide the dialog
             */
            hide() {
                this.isVisible = false;
                this.$nextTick(() => {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                });
                return this;
            },

            /**
             * Click handler for cancel button
             *
             * @param {CustomEvent|MouseEvent} e
             */
            onClickCancel(e) {
                this.$globalEvents.emit(EventType.MODAL_INSTALL_APP_CANCEL);
                this.hide();
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

    #dialog-installation .dialog {
        width: 100%;
        max-width: 1048px;
        padding: var(--modal-padding);
    }

    .dialog-installation-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-column-gap: 48px;
        grid-row-gap: 32px;
    }

    @media screen and (max-width: 480px) {
        #dialog-installation .dialog {
            padding: var(--modal-padding);
        }

        .dialog-installation-wrapper {
            display: flex;
            flex-direction: column;
        }
    }

    @media screen and (min-width: 784px) {
        .dialog-installation-wrapper {
            grid-row-gap: 24px;
        }
    }

</style>
